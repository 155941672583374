import { instance } from './api';

export interface Response {
  accessToken: string;
  refreshToken: string;
}

export type ChangePasswordDto = {
  body: {
    password: string;
    newPassword: string;
  };
};

export interface ResetPasswordDto {
  token: string;
  body: {
    newPassword: string;
  };
}

export const apiAuth = {
  login: async (email: string, password: string) => {
    const response = await instance.post<Response>('auth/log-in/app', {
      email,
      password
    });
    return response.data;
  },
  register: async (username: string, email: string, password: string) => {
    const response = await instance.post<Response>('auth/registration', {
      username,
      email,
      password
    });
    return response.data;
  },
  changePassword: async ({ body }: ChangePasswordDto) => {
    const response = await instance.post('auth/change-password', body);
    return response.data;
  },
  authGoogle: async (token: string) => {
    const response = await instance.get<Response>(`auth/google/token/${token}`);
    return response.data;
  },
  resetPasswordSendLink: async (email: string) => {
    const response = await instance.get(`auth/reset-password-send-link/${email}`);
    return response.data;
  },
  resetPassword: async ({ token, body }: ResetPasswordDto) => {
    const response = await instance.post(`auth/reset-password/${token}`, body);
    return response.data;
  },
  verifyEmailSendLink: async () => {
    const response = await instance.get('auth/verify-email');
    return response.data;
  },
  verifyEmail: async (token: string) => {
    const response = await instance.get(`auth/verify-email/${token}`);
    return response.data;
  }
};
