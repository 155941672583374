import { User as IUser } from 'models';
import { FC } from 'react';

import styles from './User.module.scss';

interface UserProps {
  user: IUser;
}

const User: FC<UserProps> = ({ user }) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{user.username}</p>
    </div>
  );
};

export default User;
