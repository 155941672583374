import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RecentBoard as IRecentBoard } from 'api';
import { ROUTES } from 'consts';
import { useBoardColor, useBoardIcon, useBoardLabel } from 'hooks';
import styles from './RecentBoard.module.scss';

interface RecentBoardProps {
  board: IRecentBoard;
}

const RecentBoard: FC<RecentBoardProps> = ({
  board: {
    id: boardId,
    name: boardName,
    type: boardType,
    project: { id: projectId, name: projectName }
  }
}) => {
  const navigate = useNavigate();
  const color = useBoardColor(boardType);
  const Icon = useBoardIcon(boardType);
  const label = useBoardLabel(boardType);
  const { teamId } = useParams<{ teamId: string }>() as { teamId: string };
  const goToBoard = () =>
    navigate(
      `${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${projectId}/${ROUTES.BOARDS}/${boardId}`
    );

  return (
    <button className={styles.container} style={{ borderBottomColor: color }} onClick={goToBoard}>
      <header className={styles.header}>
        <span className={styles['project-name']} title={projectName}>
          {projectName}
        </span>
        <div className={styles['board-type']} style={{ color: color }}>
          <Icon className={styles['board-type__icon']} />
          <span className={styles['board-type__label']}>{label}</span>
        </div>
      </header>

      {/* add prompt text when backend is ready */}
      <p className={styles['board-name']} title={boardName}>
        {boardName}
      </p>
      <p className={styles['board-prompt']}>{label} Root node prompt</p>

      {/* add users list when backend is ready */}
      {/* <div className={styles['board-users']}></div> */}
      <p className={styles['board-last-change']}>Users: unknown</p>

      {/* add change date when backend is ready */}
      <p className={styles['board-last-change']}>Last change: unknown</p>
    </button>
  );
};

export default RecentBoard;
