import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useClickOutside } from 'hooks';
import { SelectOption } from 'models';
import styles from './SelectTeam.module.scss';

import { Button, IconCheckCircle, IconPlus, IconStrokeDown, IconTeam } from 'components/atoms';

type SelectTeampProps = {
  options: SelectOption[];
  placeholder: string;
  currentOption: SelectOption | undefined;
  setCurrentOption: (value: SelectOption) => void;
  className: string;
};

const SelectTeam: FC<SelectTeampProps> = ({
  options,
  placeholder,
  currentOption,
  setCurrentOption,
  className
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setMenuOpen(false));

  return (
    <div ref={ref} tabIndex={1} className={`${styles.select} ${className}`}>
      <button
        type='button'
        className={`${styles.header} ` + `${!currentOption ? styles.header_placeholder : ''}`}
        onClick={() => setMenuOpen(prev => !prev)}
      >
        <div className='flex items-center gap-[20px]'>
          <div className={styles.header__icon}>
            <IconTeam />
          </div>
          <span className={styles.header__title}>
            {currentOption ? currentOption.label : placeholder}
          </span>
        </div>

        <IconStrokeDown
          className={
            menuOpen
              ? `${styles.header__stroke} ${styles.header__stroke_active}`
              : styles.header__stroke
          }
        />
      </button>

      <div className={`${styles.menu} ${menuOpen ? styles.menu_active : ''}`}>
        {options.length > 0 ? (
          <div className={styles.options}>
            {options.map(option => (
              <button
                key={option.value}
                type='button'
                className={
                  currentOption === option
                    ? `${styles.option} ${styles.option_active}`
                    : styles.option
                }
                onClick={() => {
                  setCurrentOption(option);
                  setMenuOpen(prev => !prev);
                }}
              >
                <span>{option.label}</span>
                {currentOption === option && <IconCheckCircle color='#19b829' />}
              </button>
            ))}
          </div>
        ) : (
          <span>{t('common.noOptions')}</span>
        )}

        <div className={styles['container-add-team']}>
          <Button
            title={t('TeamPage.settings.addNewTeam')}
            type='button'
            variant='secondary'
            wide
            icon={<IconPlus />}
            iconPosition='left'
            onClick={() => {
              navigate(ROUTES.CREATE_TEAM);
              setMenuOpen(prev => !prev);
            }}
            className={styles['button-add-team']}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectTeam;
