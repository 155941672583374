import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useBoardColor, useBoardIcon, useBoardLabel } from 'hooks';
import { Board, Project } from 'models';
import styles from './ProjectBoard.module.scss';

interface ProjectBoardProps {
  project: Project;
  board: Board;
}

const ProjectBoard: FC<ProjectBoardProps> = ({ project, board }) => {
  const navigate = useNavigate();
  const color = useBoardColor(board.type);
  const Icon = useBoardIcon(board.type);
  const label = useBoardLabel(board.type);
  const { teamId } = useParams<{ teamId: string }>() as { teamId: string };
  const goToBoard = () =>
    navigate(
      `${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${project.id}/${ROUTES.BOARDS}/${board.id}`
    );

  return (
    <button className={styles.container} style={{ borderBottomColor: color }} onClick={goToBoard}>
      <header className={styles.header}>
        <span className={styles['project-name']} title={project.name}>
          {project.name}
        </span>
        <div className={styles['board-type']} style={{ color: color }}>
          <Icon className={styles['board-type__icon']} />
          <span className={styles['board-type__label']}>{label}</span>
        </div>
      </header>

      {/* add prompt text when backend is ready */}
      <p className={styles['board-name']} title={board.name}>
        {board.name}
      </p>
      <p className={styles['board-prompt']}>{label} Root node prompt</p>

      {/* add users list when backend is ready */}
      {/* <div className={styles['board-users']}></div> */}
      <p className={styles['board-last-change']}>Users: unknown</p>

      {/* add change date when backend is ready */}
      <p className={styles['board-last-change']}>Last change: unknown</p>
    </button>
  );
};

export default ProjectBoard;
