import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Team } from 'models';

import { Plans, Success } from './components';
import { notifySuc } from 'helpers';

type UpgradeProps = {
  team: Team;
};

const Upgrade: FC<UpgradeProps> = ({ team }) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id') || '');
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(t('PricingAndPlans.cancelMessage') || '');
      notifySuc(t('PricingAndPlans.cancelMessage'));
    }
  }, [sessionId]);

  if (!success && message === '') {
    return <Plans team={team} />;
  } else if (success && sessionId !== '') {
    return <Success team={team} sessionId={sessionId} />;
  } else {
    return <Plans team={team} />;
  }
};

export default Upgrade;
