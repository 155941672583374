import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconDislike: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='thumbs-down'>
        <path
          id='Vector'
          d='M34 28V4'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M17.9998 36.24L19.9998 28H8.33984C7.71886 28 7.10641 27.8554 6.55099 27.5777C5.99557 27.3 5.51243 26.8968 5.13984 26.4C4.76726 25.9032 4.51545 25.3265 4.40436 24.7155C4.29328 24.1046 4.32597 23.4761 4.49984 22.88L9.15984 6.88C9.40218 6.04914 9.90746 5.31929 10.5998 4.8C11.2922 4.28071 12.1344 4 12.9998 4H39.9998C41.0607 4 42.0781 4.42143 42.8283 5.17157C43.5784 5.92172 43.9998 6.93913 43.9998 8V24C43.9998 25.0609 43.5784 26.0783 42.8283 26.8284C42.0781 27.5786 41.0607 28 39.9998 28H34.4798C33.7357 28.0004 33.0064 28.2084 32.3739 28.6006C31.7415 28.9927 31.231 29.5536 30.8998 30.22L23.9998 44C23.0567 43.9883 22.1284 43.7637 21.2842 43.3428C20.4401 42.922 19.702 42.3158 19.125 41.5696C18.5481 40.8234 18.1472 39.9565 17.9524 39.0336C17.7575 38.1107 17.7738 37.1557 17.9998 36.24Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
