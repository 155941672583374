import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TeamBoard as ITeamBoard } from 'api';
import { ROUTES } from 'consts';
import { useBoardColor, useBoardIcon } from 'hooks';
import styles from './TeamBoard.module.scss';

interface TeamBoardProps {
  teamBoard: ITeamBoard;
}

const TeamBoard: FC<TeamBoardProps> = ({
  teamBoard: { boardId, boardName, boardType, projectId, projectName }
}) => {
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const navigate = useNavigate();
  const color = useBoardColor(boardType);
  const Icon = useBoardIcon(boardType);

  return (
    <button
      key={boardId}
      type='button'
      title={boardName}
      className={styles.option}
      onClick={() =>
        navigate(
          `${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${projectId}/${ROUTES.BOARDS}/${boardId}`
        )
      }
    >
      <div className={styles['icon-wrapper']} style={{ backgroundColor: color }}>
        <Icon />
      </div>
      <div className={styles.text}>
        <h3 className={styles['board-name']}>{boardName}</h3>
        <h3 className={styles['project-name']}>{projectName}</h3>
      </div>
    </button>
  );
};

export default TeamBoard;
