import { FC, FormEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useCreatePortalSessionMutation } from 'hooks/stripe/mutations';
import { Team } from 'models';

import { Button } from 'components/atoms';

type SuccessProps = {
  team: Team;
  sessionId: string;
};

const Success: FC<SuccessProps> = ({ team, sessionId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: createPortalSession, isLoading: createPortalLoading } =
    useCreatePortalSessionMutation();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();

    createPortalSession(
      { teamId: team.id, sessionId },
      {
        onSuccess: data => {
          window.open(data.url, '_self');
        }
      }
    );
  };

  const backToTeam = () => {
    navigate(`${ROUTES.TEAMS}/${team.id}`);
  };

  return (
    <section className='flex flex-col gap-[32px] pt-[100px] items-center'>
      <h2 className='text-[32px] font-[500] leading-[48px] tracking-[-0.96px] text-[#2D3C59] text-center'>
        {t('PricingAndPlans.congrats')}
        <br />
        {t('PricingAndPlans.youHaveSuccessfully')}
        <br />
        <Link to={`${ROUTES.TEAMS}/${team?.id}`} className='text-[#19B829]'>
          {team?.name || t('PricingAndPlans.upgradeYour')}
        </Link>{' '}
        {t('common.to')} {t('PricingAndPlans.plans.team+.title')} {t('PricingAndPlans.plan')}
      </h2>

      {/* <p>{t('PricingAndPlans.paymentInfo')}</p> */}

      <div className='flex flex-col gap-[12px] w-[328px]'>
        <Button
          title={t('PricingAndPlans.backToTeam')}
          variant='primary'
          size='big'
          wide
          isDisabled={createPortalLoading}
          onClick={backToTeam}
        />

        <form onSubmit={handleSubmit} className='flex'>
          <input type='hidden' id='session-id' name='session_id' value={sessionId} />
          <Button
            type='submit'
            title={t('PricingAndPlans.manageBilling')}
            variant='dark'
            size='big'
            wide
            isLoading={createPortalLoading}
          />
        </form>
      </div>
    </section>
  );
};

export default Success;
