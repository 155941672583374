import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useDocumentIcon } from 'hooks';
import { Document } from 'models';
import styles from './DocumentCard.module.scss';

interface DocumentCardProps {
  document: Document;
}

const DocumentCard: FC<DocumentCardProps> = ({ document }) => {
  const Icon = useDocumentIcon(document.extension);
  const boardAmount = useMemo(
    () => (document.linkedBoards && document.linkedBoards.length > 1 ? 'boards' : 'board'),
    [document.linkedBoards]
  );

  return (
    <Link to={`${document.id}`} className={styles.container}>
      <Icon className={styles.icon} />
      <p className={styles.name} title={document.name}>
        {document.name}
      </p>
      {document.linkedBoards && (
        <p className={styles.used} title={`Used by ${document.linkedBoards.length} ${boardAmount}`}>
          Used by {document.linkedBoards.length} {boardAmount}
        </p>
      )}
    </Link>
  );
};

export default DocumentCard;
