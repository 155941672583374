import { FC, useMemo } from 'react';
import { Tooltip } from 'react-tooltip';

import { useUserQuery } from 'hooks/user';
import { User } from 'models';
import { useTranslation } from 'react-i18next';
import { getFirstLetter } from 'utils';
import styles from './UserBadge.module.scss';

interface UserBadgeProps {
  user: User;
  className: string;
}

const UserBadge: FC<UserBadgeProps> = ({ user, className }) => {
  const { t } = useTranslation();
  const { data: viewer } = useUserQuery();

  const isViewer = useMemo(() => viewer?.id === user.id, [viewer, user]);

  return (
    <>
      <div className={`${styles.badge} ${className}`} data-tooltip-id={`user-tooltip-${user.id}`}>
        {getFirstLetter(user.username)}
      </div>

      <Tooltip
        key={user.id}
        id={`user-tooltip-${user.id}`}
        className={styles.tooltip}
        offset={10}
        place='bottom'
      >
        <h2 className={styles.name}>
          {isViewer ? `${user.username} (${t('common.you')})` : user.username}
        </h2>
      </Tooltip>
    </>
  );
};

export default UserBadge;
