import { useMemo } from 'react';

import { BOARD_TYPES } from 'models';

export const useBoardColor = (type: BOARD_TYPES) => {
  const color = useMemo(() => {
    switch (type) {
      case BOARD_TYPES.MINDMAP:
        return '#25BD33';
      case BOARD_TYPES.ROADMAP:
        return '#009CF4';
      case BOARD_TYPES.FLOWCHART:
        return '#FF6633';
      case BOARD_TYPES.DIAGRAM:
        return '#FFCB33';
      case BOARD_TYPES.WBS:
        return '#674FFF';
    }
  }, [type]);
  return color;
};
