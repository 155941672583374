import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useToggle } from 'hooks';
import { useDeleteTeamMutation, useUpdateTeamMutation } from 'hooks/mutations';
import styles from './EditTeamForm.module.scss';

import { Button, InputText, Modal } from 'components/atoms';
import { DeleteSmthForm } from 'components/organisms';
import { ROUTES } from 'consts';

type Params = { teamId: string };

interface EditTeamFormProps {
  currentName: string;
  onCancel?: () => void;
}

// TODO remove component

const EditTeamForm: FC<EditTeamFormProps> = ({ currentName, onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId } = useParams<keyof Params>() as Params;
  const [deleteTeamModalActive, toggleDeleteTeamModalActive] = useToggle(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit', defaultValues: { name: currentName } });

  const { mutate: updateTeamMutate, isLoading: updateTeamLoading } = useUpdateTeamMutation();
  const { mutate: deleteTeamMutate, isLoading: deleteTeamLoading } = useDeleteTeamMutation();

  const onSubmit = async ({ name }: FieldValues) => {
    updateTeamMutate(
      { teamId: Number(teamId), body: { name: name.trim() } },
      { onSuccess: onCancel }
    );
  };

  const onDelete = async () => {
    deleteTeamMutate(
      { teamId: Number(teamId) },
      {
        onSuccess: () => {
          toggleDeleteTeamModalActive();
          onCancel && onCancel();
          navigate(ROUTES.CHOOSE_TEAM);
        }
      }
    );
  };

  return (
    <>
      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={styles.title}>Edit team</h2>
          <h3 className={styles.subtitle}>Enter new name for your team</h3>

          <InputText
            type='text'
            width='big'
            name='name'
            label='Team name'
            control={control}
            rules={{
              required: { value: true, message: t('common.validation.requiredField') },
              maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
              validate: {
                doesntConsistOfSpaces: (value: string) => {
                  return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                }
              }
            }}
            isLoading={updateTeamLoading}
            autoComplete='off'
            containerClassName={styles['input-name']}
          />

          <div className={styles.buttons}>
            <Button
              type='reset'
              variant='secondary'
              size='big'
              wide
              title='Cancel'
              onClick={onCancel}
              isDisabled={updateTeamLoading}
              className={styles['button-cancel']}
            />
            <Button
              type='submit'
              variant='primary'
              size='big'
              wide
              title='Save'
              isDisabled={!isDirty}
              isLoading={updateTeamLoading}
              className={styles['button-submit']}
            />
          </div>
        </form>

        <button
          type='button'
          disabled={updateTeamLoading}
          className={styles['button-delete']}
          onClick={toggleDeleteTeamModalActive}
        >
          Delete team
        </button>
      </div>

      <Modal isOpen={deleteTeamModalActive} onClose={toggleDeleteTeamModalActive}>
        <DeleteSmthForm
          entity='team'
          onDelete={onDelete}
          isLoading={deleteTeamLoading}
          onCancel={toggleDeleteTeamModalActive}
        />
      </Modal>
    </>
  );
};

export default EditTeamForm;
