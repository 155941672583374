import { FC, KeyboardEvent, MouseEvent, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactFlow, {
  Background,
  Connection,
  EdgeChange,
  EdgeTypes,
  NodeChange,
  NodeTypes,
  SelectionMode,
  useOnSelectionChange,
  useReactFlow
} from 'reactflow';
import { shallow } from 'zustand/shallow';

import styles from './MindMapFlow.module.scss';

import {
  EDGE_TYPES,
  RF_DEFAULT_PAN_ON_SCROLL,
  RF_DEFAULT_ZOOM_ACTIVATION_KEY_CODE,
  RF_FIT_VIEW_ZOOM_MAXIMUM,
  RF_ZOOM_MAXIMUM,
  RF_ZOOM_MINIMUM
} from 'consts';
import { useSocket } from 'hooks';
import { RFState, useDocumentsStore, useRFStore } from 'store';

import { Controls } from '../common';
import { TextNode } from '../common/nodes/TextNode';
import { Toolbar } from './components';
import { MindMapEdge } from './components/edges';
import { MindMapConnectionLine } from './components/lines';
import { MindMapNode } from './components/nodes';

const selector = (state: RFState) => ({
  setReactFlowInstance: state.setReactFlowInstance,
  controlType: state.controlType,
  currentEdgeType: state.currentEdgeType,
  setCurrentInstruction: state.setCurrentInstruction,
  nodeToAdd: state.nodeToAdd,
  setNodeToAdd: state.setNodeToAdd,
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  backgroundVariant: state.backgroundVariant
});

export const nodeTypes: NodeTypes = {
  mindMapNode: MindMapNode,
  textNode: TextNode
};

export const edgeTypes: EdgeTypes = {
  mindMapEdge: MindMapEdge
};

type Params = { boardId: string };
const MindMapFlow: FC = () => {
  const { boardId } = useParams<keyof Params>() as Params;
  const { updateNodeById, addNode, deleteNodes, addEdge, deleteEdges } = useSocket(Number(boardId));
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const {
    setReactFlowInstance,
    controlType,
    currentEdgeType,
    setCurrentInstruction,
    nodeToAdd,
    setNodeToAdd,
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    backgroundVariant
  } = useRFStore(selector, shallow);
  const selectDocument = useDocumentsStore(state => state.selectDocument);

  const reactFlowInstance = useReactFlow();
  useEffect(() => {
    setReactFlowInstance(reactFlowInstance);
    return () => setReactFlowInstance(null);
  }, [reactFlowInstance]);

  const onClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (!reactFlowWrapper.current || !nodeToAdd) return;

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });

      addNode({ boardId: Number(boardId), type: nodeToAdd.nodeType, position });
      setNodeToAdd(null);
    },
    [reactFlowWrapper, nodeToAdd]
  );

  const onContextMenu = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (!reactFlowWrapper.current) return;

      setNodeToAdd(null);
    },
    [reactFlowWrapper, nodeToAdd]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      // event.preventDefault();
      if (!reactFlowWrapper.current) return;

      if (event.key === 'Escape') {
        setNodeToAdd(null);
      }
    },
    [reactFlowWrapper, nodeToAdd]
  );

  const handleConnect = (connection: Connection) => {
    onConnect(connection);

    if (!connection.source || !connection.target) return;
    addEdge({
      boardId: Number(boardId),
      type: EDGE_TYPES.MINDMAP,
      source: connection.source,
      target: connection.target,
      sourceHandle: connection.sourceHandle || undefined,
      targetHandle: connection.targetHandle || undefined
    });
  };

  const handleNodesChange = (changes: NodeChange[]) => {
    onNodesChange(changes);

    const nodesToRemove: string[] = [];

    changes.forEach(change => {
      switch (change.type) {
        case 'position': {
          if (change.position) {
            updateNodeById({
              boardId: Number(boardId),
              nodeId: change.id,
              position: change.position
            });
          }
          return;
        }
        case 'dimensions': {
          if (change.dimensions && change.dimensions.height && change.dimensions.width) {
            updateNodeById({
              boardId: Number(boardId),
              nodeId: change.id,
              height: change.dimensions.height,
              width: change.dimensions.width
            });
          }
          return;
        }
        case 'add': {
          console.log('add change');
          return;
        }
        case 'remove': {
          nodesToRemove.push(change.id);
          return;
        }
      }
    });

    if (nodesToRemove.length) {
      deleteNodes({
        boardId: Number(boardId),
        nodeIds: nodesToRemove
      });
    }
  };

  const handleEdgesChange = (changes: EdgeChange[]) => {
    onEdgesChange(changes);

    const edgesToRemove: string[] = [];

    changes.forEach(change => {
      switch (change.type) {
        case 'remove': {
          edgesToRemove.push(change.id);
          return;
        }
      }
    });

    if (edgesToRemove.length) {
      deleteEdges({
        boardId: Number(boardId),
        edgeIds: edgesToRemove
      });
    }
  };

  // show instruction on single node select
  useOnSelectionChange({
    onChange: ({ nodes }) => {
      if (nodes.length === 1) {
        const node = nodes[0];
        setCurrentInstruction(node.id);
        selectDocument(null);
      } else {
        setCurrentInstruction(null);
      }
    }
  });

  return (
    <div className={styles.container}>
      <div ref={reactFlowWrapper} className={styles.reactflow}>
        <ReactFlow
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          edges={edges}
          defaultEdgeOptions={{ type: currentEdgeType || 'mindMapEdge' }}
          connectionLineComponent={MindMapConnectionLine}
          onNodesChange={handleNodesChange}
          onEdgesChange={handleEdgesChange}
          onConnect={handleConnect}
          onClick={onClick}
          onContextMenu={onContextMenu}
          onKeyDown={handleKeyDown}
          panOnScroll={controlType === 'Figma-like'}
          panOnScrollSpeed={RF_DEFAULT_PAN_ON_SCROLL}
          zoomOnScroll={controlType === 'Default'}
          selectionOnDrag={controlType === 'Figma-like'}
          minZoom={RF_ZOOM_MINIMUM}
          maxZoom={RF_ZOOM_MAXIMUM}
          fitView
          fitViewOptions={{ maxZoom: RF_FIT_VIEW_ZOOM_MAXIMUM }}
          preventScrolling
          zoomActivationKeyCode={RF_DEFAULT_ZOOM_ACTIVATION_KEY_CODE}
          panOnDrag={controlType === 'Figma-like' ? [1, 2] : true}
          selectionMode={SelectionMode.Partial}
          proOptions={{ hideAttribution: true }}
          className={nodeToAdd ? 'crosshair' : undefined}
        >
          <Toolbar position='top-left' />
          <Controls position='bottom-right' />
          <Background color='#6b7a99' variant={backgroundVariant} />
        </ReactFlow>
      </div>
    </div>
  );
};

export default MindMapFlow;
