import { FC } from 'react';

import { Member } from 'models';
import styles from './MemberList.module.scss';

import { MemberCard } from '..';

interface MemberListProps {
  members: Member[];
  className: string;
}

const MemberList: FC<MemberListProps> = ({ members, className }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {/* <h3 className={styles.title}>Users</h3> */}

      <div className={styles.list}>
        {members.map(member => (
          <MemberCard key={member.id} member={member} />
        ))}
      </div>
    </div>
  );
};

export default MemberList;
