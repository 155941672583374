import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DEFAULT_EDITORS_ALLOWED, DEFAULT_PROJECTS_AMOUNT, ROUTES } from 'consts';
import { useSubscriptionStatus } from 'hooks/common';
import { TEAM_ROLES, Team } from 'models';

import { IconUser } from 'components/atoms';

type PlanDetailsProps = {
  team: Team;
  className?: string;
};

const PlanDetails: FC<PlanDetailsProps> = ({ team }) => {
  const { t } = useTranslation();
  const subscribtionStatus = useSubscriptionStatus(team.product?.subscriptionStatus);
  const editorsAmount =
    team.members?.filter(
      member =>
        member.userRole.role === TEAM_ROLES.ADMIN ||
        member.userRole.role === TEAM_ROLES.EDITOR ||
        member.userRole.role === TEAM_ROLES.OWNER
    ).length || 0;

  return (
    <div
      className={
        'p-[13px] flex flex-col gap-[15px] rounded-[20px] ' +
        'border-[2px] border-solid border-[var(--color-light-light-gray)]'
      }
    >
      <h4 className='text-[20px] leading-[30px] font-[400] tracking-[0.2px] text-[#2D3C59]'>
        {t('TeamPage.settings.planDetails')}
      </h4>

      <div className='py-[12px] flex flex-col gap-[16px]'>
        <div className='flex flex-col gap-[4px]'>
          <h6 className='text-[14px] font-[400] leading-[20px] tracking-[0.14px] text-[var(--color-gray)]'>
            {t('TeamPage.settings.currentPlan')}
          </h6>

          <div className='flex gap-[8px] items-center'>
            <span className='text-[18px] font-[700] leading-[24px] text-[var(--color-dark)]'>
              {subscribtionStatus}
            </span>

            <Link
              to={`${ROUTES.TEAMS}/${team.id}/settings/upgrade`}
              className='text-[18px] font-[400] leading-[24px] text-[var(--color-blue)]'
            >
              {t('TeamPage.settings.changePlan')}
            </Link>
          </div>
        </div>

        <div className='flex flex-col gap-[4px]'>
          <h6 className='text-[14px] font-[400] leading-[20px] tracking-[0.14px] text-[var(--color-gray)]'>
            {t('TeamPage.settings.totalProjects')}
          </h6>

          <div className='flex gap-[8px] items-center'>
            <span className='text-[18px] font-[700] leading-[24px] text-[var(--color-dark)]'>
              {team.projects?.length} {t('TeamPage.settings.projects')}
            </span>

            <span className='text-[18px] font-[400] leading-[24px] text-[var(--color-dark-gray)]'>
              ({DEFAULT_PROJECTS_AMOUNT - (team.projects?.length || 0)}{' '}
              {t('TeamPage.settings.left')})
            </span>

            <Link
              to={`${ROUTES.TEAMS}/${team.id}/settings/upgrade`}
              className='text-[18px] font-[400] leading-[24px] text-[var(--color-blue)]'
            >
              {t('TeamPage.settings.upgradeToTeamPlus')}
            </Link>
          </div>
        </div>

        <div className='flex flex-col gap-[4px]'>
          <h6 className='text-[14px] font-[400] leading-[20px] tracking-[0.14px] text-[var(--color-gray)]'>
            {t('TeamPage.settings.teamUsers')}
          </h6>

          <div className='flex gap-[8px] items-center'>
            <span className='text-[18px] font-[700] leading-[24px] text-[var(--color-dark)]'>
              {editorsAmount} {t('TeamPage.settings.editors')}
            </span>

            <span className='text-[18px] font-[400] leading-[24px] text-[var(--color-dark-gray)]'>
              ({(team.product?.numberEditorsAllowed || DEFAULT_EDITORS_ALLOWED) - editorsAmount}{' '}
              {t('TeamPage.settings.left')})
            </span>

            <Link
              to={`${ROUTES.TEAMS}/${team.id}/settings/upgrade`}
              className='text-[18px] font-[400] leading-[24px] text-[var(--color-blue)]'
            >
              {t('TeamPage.settings.upgradeTeamSize')}
            </Link>
          </div>
        </div>

        <div className='flex flex-col gap-[4px]'>
          <h6 className='text-[14px] font-[400] leading-[20px] tracking-[0.14px] text-[var(--color-gray)]'>
            {t('TeamPage.settings.paidBy')}
          </h6>

          <div className='h-[32px] flex gap-[8px] items-center'>
            <span className='h-[24px] w-[24px] rounded-full'>
              <IconUser />
            </span>

            <span className='text-[18px] font-[700] leading-[24px] text-[var(--color-dark)]'>
              {team.subscriber?.username}
            </span>

            <Link
              to={`${ROUTES.TEAMS}/${team.id}/settings/upgrade`}
              className='text-[18px] font-[400] leading-[24px] text-[var(--color-blue)]'
            >
              {t('TeamPage.settings.switchToMonthly')}
            </Link>
          </div>

          <div />

          <Link
            to={`${ROUTES.TEAMS}/${team.id}/settings/upgrade`}
            className='text-[14px] font-[700] leading-[20px] tracking-[0.14px] text-[var(--color-blue)]'
          >
            {t('TeamPage.settings.cancelSubscription')}
          </Link>

          <span className='text-[14px] font-[700] leading-[20px] text-[var(--color-gray)]'>
            {t('TeamPage.settings.note')}{' '}
            <Link
              to={`${ROUTES.TEAMS}/${team.id}/settings/upgrade`}
              className='text-[var(--color-blue)]'
            >
              {t('TeamPage.settings.reducing')}
            </Link>{' '}
            {t('common.or')}{' '}
            <Link
              to={`${ROUTES.TEAMS}/${team.id}/settings/upgrade`}
              className='text-[var(--color-blue)]'
            >
              {t('TeamPage.settings.switching')}
            </Link>
            {'.'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
