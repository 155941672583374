import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { Handle, NodeProps, NodeToolbar, Position, useReactFlow } from 'reactflow';
import { shallow } from 'zustand/shallow';

import {
  DEFAULT_TIMEOUT,
  NODE_TYPES,
  RF_FIT_VIEW_DURATION,
  RF_FIT_VIEW_TIMEOUT,
  RF_FIT_VIEW_ZOOM_MAXIMUM
} from 'consts';
import { notifyWarn } from 'helpers';
import { useSocket } from 'hooks';
import { useGenerateNodesMutation } from 'hooks/mutations';
import { NodeData, RFState, useRFStore } from 'store';
import styles from './RoadmapRootNode.module.scss';

import { IconKnowledge, IconLoader, IconMagicWand, IconRoadmap } from 'components/atoms';
import { Contextbar } from 'components/organisms/flows/common';

const selector = (state: RFState) => ({
  currentEdgeType: state.currentEdgeType,
  nodes: state.nodes,
  selectNode: state.selectNode,
  setData: state.setData,
  updateNodeValue: state.updateNodeValue
});

const RoadmapRootNode: FC<NodeProps<NodeData>> = ({ id, data, selected, dragging }) => {
  const [inputActive, setInputActive] = useState<boolean>(false);
  const { boardId } = useParams<keyof { boardId: string }>() as { boardId: string };
  const { updateNodeById } = useSocket(Number(boardId));
  const { fitView } = useReactFlow();
  const documentCount = useMemo(() => data.linkedDocuments?.length, [data.linkedDocuments]);
  const { currentEdgeType, nodes, selectNode, setData, updateNodeValue } = useRFStore(
    selector,
    shallow
  );

  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.value]);

  const handleChange = ({
    target: { selectionStart, value }
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setCursor(selectionStart);
    updateNodeValue(id, value);
    updateNodeById({ boardId: Number(boardId), nodeId: id, data: { value } });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleGenerate();
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.detail === 2) {
      setInputActive(true);
      setTimeout(() => ref.current?.focus());
    }
  };

  // when creating the board, focus on the root node for text entry
  useEffect(() => {
    if (
      nodes.find(
        node => node.type === NODE_TYPES.ROADMAP_EPIC || node.type === NODE_TYPES.ROADMAP_TASK
      )
    ) {
      return;
    }

    selectNode(id);
    setInputActive(true);
    setTimeout(() => ref.current?.focus(), DEFAULT_TIMEOUT);
  }, []);

  const { mutate: generateEpicNodes, isLoading: generateDataLoading } = useGenerateNodesMutation();

  const handleGenerate = async () => {
    if (!data.value?.length) {
      notifyWarn('Please enter some value in the node');
      return;
    }

    generateEpicNodes(
      { boardId: Number(boardId), title: data.value },
      {
        onSuccess: data => {
          const parsedData = JSON.parse(data.schema);
          setData(parsedData);
          setTimeout(
            () => fitView({ maxZoom: RF_FIT_VIEW_ZOOM_MAXIMUM, duration: RF_FIT_VIEW_DURATION }),
            RF_FIT_VIEW_TIMEOUT
          );
        }
      }
    );
  };

  return (
    <>
      <Contextbar
        nodeId={id}
        isVisible={!dragging && data.toolbar?.visible}
        position={Position.Top}
        offset={30}
      />

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Right} offset={11}>
        <button
          className={styles.buttonGenerate}
          disabled={generateDataLoading}
          onClick={handleGenerate}
        >
          {generateDataLoading ? <IconLoader className={styles.spin} /> : <IconMagicWand />}
        </button>
      </NodeToolbar>

      <Handle
        type='source'
        position={Position.Left}
        id='d'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />

      <button
        className={
          `${styles.container} ` +
          `${selected ? styles.container_active : ''} ` +
          `${inputActive ? styles.container_double : ''}`
        }
        disabled={generateDataLoading}
        onClick={handleClick}
      >
        {!inputActive && <IconRoadmap className={styles.knowledge} />}

        <form
          className={styles.form}
          style={{
            maxWidth: 308 - (!inputActive && !!documentCount ? 34 : 0)
          }}
          onSubmit={e => {
            e.preventDefault();
            handleGenerate();
          }}
        >
          {inputActive ? (
            <textarea
              ref={ref}
              id={`roadmap-root-${id}`}
              name='roadmap-root-input'
              placeholder='Please enter the title of the roadmap'
              title={data.value || 'Please enter the title of the roadmap'}
              value={data.value}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onBlur={() => setInputActive(false)}
              className={`${styles.textarea} nodrag`}
              disabled={generateDataLoading}
            />
          ) : (
            <span
              title={data.value || 'Please enter the title of the roadmap'}
              className={styles.text}
            >
              {data.value || 'Please enter the title of the roadmap'}
            </span>
          )}
        </form>

        {!inputActive && !!documentCount && (
          <div className={styles.documents}>
            <IconKnowledge className={styles.documents__icon} />
            <span className={styles.documents__badge}>{documentCount}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default RoadmapRootNode;
