import { FC, useEffect, useRef, useState } from 'react';
import { Controller, FieldError, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useTeams } from 'hooks/queries';
import { SelectOption } from 'models';
import { useCreateProjectMutation, useCreateTeamMutation } from 'hooks/mutations';
import styles from './ChooseTeam.module.scss';

import { Button, IconCheckCircle, IconPlus, IconStrokeDown } from 'components/atoms';
import { useClickOutside } from 'hooks';

interface SelectProps {
  options: SelectOption[];
  placeholder: string;
  currentOption: SelectOption | undefined;
  setCurrentOption: (value: SelectOption) => void;
  error: FieldError | undefined;
  className: string;
}

const Select: FC<SelectProps> = ({
  options,
  placeholder,
  currentOption,
  setCurrentOption,
  error,
  className
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setMenuOpen(false));

  return (
    <>
      <div ref={ref} tabIndex={1} className={`${styles.select} ${className}`}>
        <button
          type='button'
          className={
            `${styles.header} ` +
            `${!currentOption ? styles.header_placeholder : ''} ` +
            `${error ? styles.header_error : ''}`
          }
          onClick={() => setMenuOpen(prev => !prev)}
        >
          <span>{currentOption ? currentOption.label : placeholder}</span>
          <div className={menuOpen ? `${styles.icon} ${styles.icon_active}` : styles.icon}>
            <IconStrokeDown />
          </div>
        </button>

        <div className={`${styles.menu} ${menuOpen ? styles.menu_active : ''}`}>
          {options.length > 0 ? (
            <div className={styles.options}>
              {options.map(option => (
                <button
                  key={option.value}
                  type='button'
                  className={
                    currentOption === option
                      ? `${styles.option} ${styles.option_active}`
                      : styles.option
                  }
                  onClick={() => {
                    setCurrentOption(option);
                    setMenuOpen(prev => !prev);
                  }}
                >
                  <span>{option.label}</span>
                  {currentOption === option && <IconCheckCircle color='#19b829' />}
                </button>
              ))}
            </div>
          ) : (
            <span>{t('common.noOptions')}</span>
          )}

          <div className={styles['container-add-team']}>
            <Button
              title={t('ChooseTeam.form.addNewTeam')}
              type='button'
              variant='secondary'
              icon={<IconPlus />}
              iconPosition='left'
              onClick={() => {
                navigate(ROUTES.CREATE_TEAM);
                setMenuOpen(prev => !prev);
              }}
              className={styles['button-add-team']}
            />
          </div>
        </div>

        {!!error && <span className={styles.errorMessage}>{error.message}</span>}
      </div>
    </>
  );
};

const ChooseTeam: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });
  const { data: teamsInfo, isLoading: teamsLoading } = useTeams();
  const [teamOptions, setTeamOptions] = useState<SelectOption[]>([]);

  const { mutateAsync: createProjectMutate } = useCreateProjectMutation({});
  const { mutateAsync: createTeamMutate } = useCreateTeamMutation();

  useEffect(() => {
    const teams = teamsInfo?.teams;
    if (teams) {
      const teamAmount = teams.length;
      if (teamAmount) {
        if (teamAmount > 1) {
          const teamOptions: SelectOption[] = teams.map(({ name, id }) => ({
            value: id.toString(),
            label: name
          }));
          setTeamOptions(teamOptions);
          return;
        }
        navigate(`${ROUTES.TEAMS}/${teams[0].id}`);
        return;
      }
      // navigate(ROUTES.CREATE_TEAM);
      createDefaultTeam('Default team');
    }
  }, [teamsInfo]);

  const createDefaultTeam = async (name: string) => {
    createTeamMutate(
      { name },
      {
        onSuccess: async data => {
          await createProjectMutate({ teamId: data.id, name: t('common.defaultProject') });
          navigate(`${ROUTES.TEAMS}/${data.id}`);
        }
      }
    );
  };

  const onSubmit = async ({ teamId }: FieldValues) => {
    navigate(`${ROUTES.TEAMS}/${teamId}`);
  };

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          <h1 className={styles.content__title}>{t('ChooseTeam.title')}</h1>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.form__fields}>
              {teamsLoading ? (
                <h4>{t('common.loading')}</h4>
              ) : (
                <>
                  {teamOptions ? (
                    <Controller
                      control={control}
                      name='teamId'
                      rules={{
                        required: { value: true, message: t('common.validation.requiredField') }
                      }}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <Select
                          placeholder={t('ChooseTeam.form.placeholder')}
                          options={teamOptions}
                          currentOption={teamOptions.find(option => option.value === value)}
                          setCurrentOption={option => onChange(option.value)}
                          error={error}
                          className={styles.select}
                        />
                      )}
                    />
                  ) : (
                    <h4>Can&apos;t fetch teams</h4>
                  )}
                </>
              )}
            </div>

            <Button
              type='submit'
              variant='primary'
              size='big'
              wide
              title={t('ChooseTeam.form.button')}
              isDisabled={!isDirty}
            />
          </form>
        </div>
      </div>
    </main>
  );
};

export default ChooseTeam;
