import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useToggle } from 'hooks';
import { useDeleteProjectMutation, useUpdateProjectMutation } from 'hooks/mutations';
import styles from './EditProjectForm.module.scss';

import { Button, InputText, Modal } from 'components/atoms';
import { DeleteSmthForm } from 'components/organisms';

type Params = { teamId: string; projectId: string };

interface EditProjectFormProps {
  currentName: string;
  onCancel?: () => void;
}

const EditProjectForm: FC<EditProjectFormProps> = ({ currentName, onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId, projectId } = useParams<keyof Params>() as Params;
  const [deleteProjectModalActive, toggleDeleteProjectModalActive] = useToggle(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit', defaultValues: { name: currentName } });

  const { mutate: updateProjectMutate, isLoading: updateProjectLoading } = useUpdateProjectMutation(
    {
      onSuccess: () => {
        onCancel && onCancel();
      }
    }
  );

  const { mutate: deleteProjectMutate, isLoading: deleteProjectLoading } = useDeleteProjectMutation(
    {
      teamId: Number(teamId),
      onSuccess: () => {
        toggleDeleteProjectModalActive();
        onCancel && onCancel();
        navigate(`${ROUTES.TEAMS}/${teamId}`);
      }
    }
  );

  const onSubmit = async ({ name }: FieldValues) => {
    updateProjectMutate({
      teamId: Number(teamId),
      projectId: Number(projectId),
      body: { name: name.trim() }
    });
  };

  const onDelete = async () => {
    deleteProjectMutate({
      teamId: Number(teamId),
      projectId: Number(projectId)
    });
  };

  return (
    <>
      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={styles.title}>Edit project</h2>
          <h3 className={styles.subtitle}>Enter new name for your project</h3>

          <InputText
            type='text'
            width='big'
            name='name'
            label='Project name'
            control={control}
            rules={{
              required: { value: true, message: t('common.validation.requiredField') },
              maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
              validate: {
                doesntConsistOfSpaces: (value: string) => {
                  return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                }
              }
            }}
            isLoading={updateProjectLoading || deleteProjectLoading}
            autoComplete='off'
            containerClassName={styles['input-name']}
          />

          <div className={styles.buttons}>
            <Button
              type='reset'
              variant='secondary'
              size='big'
              wide
              title='Cancel'
              isDisabled={updateProjectLoading || deleteProjectLoading}
              onClick={onCancel}
              className={styles['button-cancel']}
            />

            <Button
              type='submit'
              variant='primary'
              size='big'
              wide
              title='Save'
              isDisabled={!isDirty}
              isLoading={updateProjectLoading || deleteProjectLoading}
              className={styles['button-submit']}
            />
          </div>
        </form>

        <button
          type='button'
          disabled={updateProjectLoading}
          className={styles['button-delete']}
          onClick={toggleDeleteProjectModalActive}
        >
          Delete project
        </button>
      </div>

      <Modal isOpen={deleteProjectModalActive} onClose={toggleDeleteProjectModalActive}>
        <DeleteSmthForm
          entity='project'
          onDelete={onDelete}
          isLoading={deleteProjectLoading}
          onCancel={toggleDeleteProjectModalActive}
        />
      </Modal>
    </>
  );
};

export default EditProjectForm;
