import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateProjectDto } from 'api';
import { useCreateProjectMutation } from 'hooks/mutations';
import { Project } from 'models';
import styles from './AddProjectForm.module.scss';

import { Button, InputText } from 'components/atoms';

interface AddProjectFormProps {
  teamId: string;
  onSuccess?: (data: Project, variables: CreateProjectDto) => void;
  onCancel?: () => void;
}

const AddProjectForm: FC<AddProjectFormProps> = ({ teamId, onSuccess, onCancel }) => {
  const { t } = useTranslation();
  const { mutate: createProjectMutate, isLoading: createProjectLoading } = useCreateProjectMutation(
    { onSuccess, onCancel }
  );

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async ({ name }: FieldValues) => {
    createProjectMutate({ teamId: Number(teamId), name });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <h2 className={styles.title}>{t('TeamPage.sidebar.addNewProjectForm.title')}</h2>
        <h3 className={styles.subtitle}>{t('TeamPage.sidebar.addNewProjectForm.subtitle')}</h3>

        <InputText
          type='text'
          width='big'
          name='name'
          label={t('TeamPage.sidebar.addNewProjectForm.placeholder') as string}
          control={control}
          rules={{
            required: { value: true, message: t('common.validation.requiredField') },
            maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
            validate: {
              doesntConsistOfSpaces: (value: string) => {
                return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
              }
            }
          }}
          isLoading={createProjectLoading}
          autoComplete='off'
          containerClassName={styles['input-name']}
        />

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            size='big'
            wide
            title={t('common.cancel')}
            isDisabled={createProjectLoading}
            onClick={onCancel}
            className={styles['button-cancel']}
          />

          <Button
            type='submit'
            variant='primary'
            size='big'
            wide
            title={t('TeamPage.sidebar.addNewProjectForm.submitButton')}
            isDisabled={!isDirty}
            isLoading={createProjectLoading}
            className={styles['button-submit']}
          />
        </div>
      </form>
    </div>
  );
};

export default AddProjectForm;
