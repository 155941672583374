import { FC, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';

import styles from './HeaderLayout.module.scss';

import { Header } from 'components/organisms';

interface HeaderLayoutProps {
  children: ReactNode;
}

const HeaderLayout: FC<HeaderLayoutProps> = ({ children }) => {
  return (
    <div className={`wrapper ${styles.container}`}>
      <Header />
      {children}
      <ToastContainer />
    </div>
  );
};

export default HeaderLayout;
