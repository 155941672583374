// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

import { ROUTES } from 'consts';
import { useCreateBoardMutation } from 'hooks/mutations';
import { BOARD_TYPES } from 'models';
import styles from './AddMindCharts.module.scss';

import { IconDiagram, IconFlowchart, IconMindMap, IconRoadmap, IconWBS } from 'components/atoms';

const boardsToCreate: {
  name: string;
  type: BOARD_TYPES;
  color: string;
  icon?: JSX.Element;
  available: boolean;
}[] = [
  {
    name: 'Mind Map',
    type: BOARD_TYPES.MINDMAP,
    color: '#25BD33',
    icon: <IconMindMap />,
    available: true
  },
  {
    name: 'Road Map',
    type: BOARD_TYPES.ROADMAP,
    color: '#009CF4',
    icon: <IconRoadmap />,
    available: true
  },
  {
    name: 'Flow Chart',
    type: BOARD_TYPES.FLOWCHART,
    color: '#FF6633',
    icon: <IconFlowchart />,
    available: true
  },
  {
    name: 'WBS',
    color: '#674FFF',
    type: BOARD_TYPES.WBS,
    icon: <IconWBS />,
    available: false
  },
  {
    name: 'Diagram',
    type: BOARD_TYPES.DIAGRAM,
    color: '#FFCB33',
    icon: <IconDiagram />,
    available: false
  }
];

interface AddMindChartsProps {
  className?: string;
}

const AddMindCharts: FC<AddMindChartsProps> = ({ className }) => {
  const { t } = useTranslation();
  const { teamId, projectId } = useParams<keyof Params>() as Params;
  const navigate = useNavigate();

  const { mutateAsync: createBoardMutate, isLoading: createBoardLoading } = useCreateBoardMutation({
    onSuccess: async (data, variables) => {
      navigate(
        `${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${variables.projectId}/${ROUTES.BOARDS}/${data.id}`
      );
    }
  });

  const handleCreateBoard = async (type: BOARD_TYPES) => {
    createBoardMutate({
      projectId: Number(projectId),
      teamId: Number(teamId),
      name: 'New board',
      type
    });
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <h5 className={styles.title}>{t('TeamPage.project.createFromTemplate')}</h5>

      <Swiper slidesPerView='auto' spaceBetween='10px' className={styles.slider}>
        {boardsToCreate.map(({ name, color, icon, available, type }, index) => (
          <SwiperSlide key={index} className={styles.slide}>
            <button
              className={`${styles.card} ${!available && styles.card_unavailable}`}
              style={{ background: color }}
              disabled={!available || createBoardLoading}
              onClick={() => handleCreateBoard(type)}
            >
              <div className={styles.icon}>{icon}</div>
              <p className={styles.name}>{name}</p>
            </button>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default AddMindCharts;
