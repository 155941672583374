import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TeamAvatarPlaceholderPNG from 'assets/images/png/team-avatar-placeholder.png';
import { useUpdateTeamMutation } from 'hooks/mutations';
import { useUserQuery } from 'hooks/user';
import { Team, UpdateTeamFormValues } from 'models';

import { Avatar, Button, InputText } from 'components/atoms';
import { DeleteTeamButton } from '..';

type UpdateTeamProps = {
  team: Team;
  className?: string;
};

const UpdateTeam: FC<UpdateTeamProps> = ({ team }) => {
  const { t } = useTranslation();
  const { data: viewer } = useUserQuery();
  const isOwner = viewer?.id === team.owner?.id;
  const { mutate: updateTeamMutate, isLoading: updateTeamLoading } = useUpdateTeamMutation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<UpdateTeamFormValues>({
    mode: 'onSubmit',
    defaultValues: { teamName: team.name }
  });

  useEffect(() => {
    reset({ teamName: team.name });
  }, [team]);

  const onSubmit = async (data: UpdateTeamFormValues) => {
    const { teamName } = data;

    updateTeamMutate(
      { teamId: team.id, body: { name: teamName.trim() } },
      { onSuccess: data => reset({ teamName: data.name }) }
    );
  };

  const handleReset = () => {
    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={
        'p-[13px] flex flex-col gap-[38px] rounded-[20px] ' +
        'border-[2px] border-solid border-[var(--color-light-light-gray)]'
      }
    >
      <div className='flex items-center gap-[24px]'>
        <Avatar
          name='image'
          control={control}
          cover={team.avatarUrl || TeamAvatarPlaceholderPNG}
          rules={{
            required: { value: false, message: t('common.validation.requiredField') }
          }}
          loading={updateTeamLoading}
        />

        <InputText
          type='text'
          name='teamName'
          label={t('TeamPage.settings.teamName') as string}
          control={control}
          rules={{
            required: { value: true, message: t('common.validation.requiredField') },
            maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
            validate: {
              doesntConsistOfSpaces: (value: any) => {
                return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
              }
            }
          }}
          containerClassName='max-w-[480px]'
          isLoading={updateTeamLoading}
        />
      </div>

      <div className={`flex pl-[20px] ${isOwner ? 'justify-between' : 'self-end'}`}>
        {isOwner && <DeleteTeamButton teamId={team.id} disabled={updateTeamLoading} />}

        <div className='flex gap-[12px] self-end'>
          <Button
            type='reset'
            variant='secondary'
            size='big'
            wide
            title={t('common.cancel')}
            onClick={handleReset}
            isDisabled={!isDirty || updateTeamLoading}
            className='min-w-[149px]'
          />

          <Button
            type='submit'
            variant='primary'
            size='big'
            wide
            title={t('common.save')}
            isLoading={updateTeamLoading}
            isDisabled={!isDirty}
            className='min-w-[180px]'
          />
        </div>
      </div>
    </form>
  );
};

export default UpdateTeam;
