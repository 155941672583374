import { useMutation } from 'react-query';

import { UnsubscribeDto, apiStripe } from 'api/stripe';
import { handleError, notifySuc } from 'helpers';

export const useUnsubscribeMutation = () => {
  return useMutation(
    (dto: UnsubscribeDto) => {
      return apiStripe.unsubscribe(dto);
    },
    {
      onSuccess() {
        notifySuc('Subscription cancelled');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
