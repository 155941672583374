import { SubscriptionStatus } from 'models';

export const useSubscriptionStatus = (subscriptionStatus: string | undefined) => {
  switch (subscriptionStatus) {
    case 'active':
      return SubscriptionStatus.TEAM_PLUS;
    default:
      return SubscriptionStatus.FREE;
  }
};
