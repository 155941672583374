import { FC } from 'react';
import { NavLink, useMatch, useParams } from 'react-router-dom';

import { ROUTES } from 'consts';
import { Project as IProject } from 'models';
import styles from './Project.module.scss';

import { IconClipboard, IconKnowledge, IconStrokeRight } from 'components/atoms';

interface ProjectProps {
  project: IProject;
}

const Project: FC<ProjectProps> = ({ project }) => {
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const match = useMatch(
    project.id === 0
      ? `${ROUTES.TEAMS}/${teamId}`
      : `${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${project.id}/*`
  );

  return (
    <div className={styles.container}>
      <NavLink
        to={
          project.id === 0
            ? `${ROUTES.TEAMS}/${teamId}`
            : `${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${project.id}/${ROUTES.BOARDS}`
        }
        end
        className={match ? `${styles.header} ${styles.header_active}` : styles.header}
      >
        <p className={styles.title}>{project.name}</p>
        <IconStrokeRight className={styles.stroke} />
      </NavLink>

      {project.id !== 0 && match && (
        <div className={styles.links}>
          <NavLink
            to={`${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${project.id}/${ROUTES.BOARDS}`}
            end
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.link_active}` : styles.link
            }
          >
            <IconClipboard className={styles.icon} />
            Boards
          </NavLink>
          <NavLink
            to={`${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${project.id}/${ROUTES.DOCUMENTS}`}
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.link_active}` : styles.link
            }
          >
            <IconKnowledge className={styles.icon} />
            Documents
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default Project;
