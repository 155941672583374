import { FC, useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import { Panel } from 'reactflow';
import { shallow } from 'zustand/shallow';

import { EDGE_TYPES, NODE_TYPES } from 'consts';
import { NODE_SIZE, RFState, useRFStore } from 'store';
import styles from './Toolbar.module.scss';

import { IconConnectionLine, IconNode, IconStart, IconTextNode } from 'components/atoms';

const selector = (state: RFState) => ({
  nodeToAdd: state.nodeToAdd,
  setNodeToAdd: state.setNodeToAdd,
  nodes: state.nodes,
  currentEdgeType: state.currentEdgeType,
  setCurrentEdgeType: state.setCurrentEdgeType
});

const Toolbar: FC<any> = ({ ...props }) => {
  const { nodeToAdd, setNodeToAdd, nodes, currentEdgeType, setCurrentEdgeType } = useRFStore(
    selector,
    shallow
  );

  const nodeList: {
    name: string;
    type: NODE_TYPES;
    level: number;
    icon: JSX.Element;
    key: string;
  }[] = [
    {
      name: 'Start Mindmap',
      type: NODE_TYPES.MINDMAP,
      level: 0,
      icon: <IconStart />,
      key: 'S'
    },
    {
      name: 'Add Node',
      type: NODE_TYPES.MINDMAP,
      level: 1,
      icon: <IconNode />,
      key: 'N'
    }
  ];

  const edges: {
    name: string;
    type: EDGE_TYPES;
    icon: JSX.Element;
    key: string;
  }[] = [
    {
      name: 'Connection Line',
      type: EDGE_TYPES.MINDMAP,
      icon: <IconConnectionLine />,
      key: 'L'
    }
  ];

  const isRootNodeExists = useMemo(() => {
    return !!nodes.find(node => node.data.level === 0);
  }, [nodes]);

  const handleNodeToAdd = (type: NODE_TYPES) => {
    if (nodeToAdd && nodeToAdd.nodeType === type) {
      setNodeToAdd(null);
    } else {
      setNodeToAdd({ nodeType: type, level: isRootNodeExists ? 1 : 0 });
      setCurrentEdgeType(null);
    }
  };

  const handleTextNode = () => {
    if (nodeToAdd && nodeToAdd.nodeType === NODE_TYPES.TEXT) {
      setNodeToAdd(null);
    } else {
      setNodeToAdd({ nodeType: NODE_TYPES.TEXT, size: NODE_SIZE.SMALL });
      setCurrentEdgeType(null);
    }
  };

  const handleConnectionLine = (type: EDGE_TYPES) => {
    if (currentEdgeType === type) {
      setCurrentEdgeType(null);
    } else {
      setCurrentEdgeType(type);
      setNodeToAdd(null);
    }
  };

  return (
    <Panel className={styles.container} {...props} onClick={e => e.stopPropagation()}>
      {nodeList.map((node, index) => (
        <button
          key={index}
          className={
            nodeToAdd && nodeToAdd.level === node.level
              ? `${styles.button} ${styles.button_active}`
              : styles.button
          }
          data-tooltip-id={`add-node-tooltip-${index}`}
          onClick={() => handleNodeToAdd(node.type)}
          disabled={node.level === 0 && isRootNodeExists}
        >
          {node.icon}
        </button>
      ))}

      {nodeList.map((node, index) => (
        <Tooltip
          key={index}
          id={`add-node-tooltip-${index}`}
          className={styles.tooltip}
          offset={20}
          place='right'
        >
          <button className={styles.tooltip__item}>
            {node.name}
            <div className={styles.key}>{node.key}</div>
          </button>
        </Tooltip>
      ))}

      <button
        className={currentEdgeType ? `${styles.button} ${styles.button_active}` : styles.button}
        data-tooltip-id='choose-connection-line-tooltip'
        onClick={() => currentEdgeType && setCurrentEdgeType(null)}
      >
        <IconConnectionLine />
      </button>
      <Tooltip
        id='choose-connection-line-tooltip'
        className={styles.tooltip}
        offset={20}
        place='right'
        clickable
      >
        <div className={styles.tooltip__list}>
          {edges.map((edge, index) => (
            <button
              key={index}
              className={
                currentEdgeType === edge.type
                  ? `${styles.tooltip__item} ${styles.tooltip__item_active}`
                  : styles.tooltip__item
              }
              onClick={() => handleConnectionLine(edge.type)}
            >
              {edge.icon}
              {edge.name}
              <div className={styles.key}>{edge.key}</div>
            </button>
          ))}
        </div>
      </Tooltip>

      <button
        className={
          nodeToAdd?.nodeType === NODE_TYPES.TEXT
            ? `${styles.button} ${styles.button_active}`
            : styles.button
        }
        onClick={handleTextNode}
      >
        <IconTextNode />
      </button>
    </Panel>
  );
};

export default Toolbar;
