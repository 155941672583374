import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useToggle } from 'hooks';
import { useUserQuery } from 'hooks/user';
import { Member, TEAM_ROLES } from 'models';
import styles from './Users.module.scss';

import { IconStrokeDown, IconUsers } from 'components/atoms';
import { User } from '../User';

interface UsersProps {
  members: Member[];
}

const Users: FC<UsersProps> = ({ members }) => {
  const { t } = useTranslation();
  const { data: user } = useUserQuery();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const [showUsers, toggleShowUsers] = useToggle(false);

  const showManageUsers = useMemo(() => {
    const admins = members.filter(member => member.userRole.role === TEAM_ROLES.ADMIN);
    const owners = members.filter(member => member.userRole.role === TEAM_ROLES.OWNER);
    return !![...admins, ...owners].find(member => user?.id === member.id);
  }, [members, user]);

  return (
    <div className={styles.container}>
      <button
        className={showUsers ? `${styles.header} ${styles.header_active}` : styles.header}
        onClick={toggleShowUsers}
      >
        <IconUsers className={styles.header__icon} />
        <p className={styles.header__title}>{t('TeamPage.sidebar.usersInTeam')}</p>
        <div className={styles.header__amount}>{members.length}</div>
        <IconStrokeDown className={styles.header__stroke} />
      </button>

      {showUsers && (
        <div className={styles.list}>
          {!!members.length && members.map(member => <User key={member.id} user={member} />)}

          {showManageUsers && (
            <NavLink
              to={`${ROUTES.TEAMS}/${teamId}${ROUTES.MANAGE_USERS}`}
              end
              className={({ isActive }) =>
                isActive
                  ? `${styles['link-manage']} ${styles['link-manage_active']}`
                  : styles['link-manage']
              }
            >
              {t('TeamPage.sidebar.manageUsers')}
            </NavLink>
          )}
        </div>
      )}
    </div>
  );
};

export default Users;
