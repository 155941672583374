import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserQuery } from 'hooks/user';

import { DeleteProfile, UpdateInfoForm } from './components';
import { TermsPrivacy } from 'components/atoms';

const ProfilePage: FC = () => {
  const { t } = useTranslation();
  const { data: viewer, isLoading: viewerLoading } = useUserQuery();

  if (viewerLoading) return <h2>{t('common.loading')}</h2>;
  if (!viewer) return <h2>{t('ProfilePage.error')}</h2>;
  return (
    <main className='main'>
      <div className='container flex justify-center px-[16px] py-[24px]'>
        <div className='max-w-[800px] flex flex-col gap-[20px] '>
          <h3 className='text-[21px] leading-[30px] font-[500] tracking-[0.21px] text-[#2D3C59]'>
            {t('ProfilePage.title')}
          </h3>

          <UpdateInfoForm user={viewer} className='mb-auto' />
          <DeleteProfile />
          <div className='mt-[24px] flex justify-center'>
            <TermsPrivacy />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProfilePage;
