import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { DeleteUsersDto, apiTeam } from 'api';
import { handleError, notifySuc } from 'helpers';
import { Team } from 'models';

export const useDeleteTeamUserMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (data: DeleteUsersDto) => {
      return apiTeam.deleteUser(data);
    },
    {
      async onSuccess(_, variables) {
        notifySuc(t('common.notifications.teamUserDeleted'));

        // update team query cache
        const teamQuery = `teams/one/${variables.teamId}`;
        await queryClient.invalidateQueries([teamQuery], {
          refetchActive: false
        });

        const oldTeamData = queryClient.getQueryData<Team>([teamQuery]);
        if (!oldTeamData) return;

        const updatedMembers = oldTeamData.members?.filter(
          member => member.id !== variables.userId
        );
        const updatedTeamData: Team = { ...oldTeamData, members: updatedMembers };

        queryClient.setQueryData([teamQuery], updatedTeamData);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
