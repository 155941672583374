import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { UpdateProjectDto, apiProject } from 'api';
import { handleError, notifySuc } from 'helpers';
import { Project } from 'models';
import { useInvalidate } from '../useInvalidate';

interface Params {
  onCancel?: () => void;
  onSuccess?: (data: Project, variables: UpdateProjectDto) => void | Promise<void>;
}

export const useUpdateProjectMutation = ({ onCancel, onSuccess }: Params) => {
  const { t } = useTranslation();
  const invalidate = useInvalidate();

  return useMutation(
    (data: UpdateProjectDto) => {
      return apiProject.updateProject(data);
    },
    {
      async onSuccess(data, variables) {
        notifySuc(t('common.notifications.projectUpdated'));
        await invalidate(`teams/one/${variables.teamId}`);
        await invalidate(`projects/${data.id}`);

        onCancel && onCancel();
        onSuccess && onSuccess(data, variables);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
