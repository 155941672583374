import axios from 'axios';

import { handleError } from 'helpers';

export type SendMessageToSlackDto = {
  webhookUrl: string;
  text: string;
  channelId?: string;
};

export const sendMessageToSlack = async ({
  webhookUrl,
  text,
  channelId
}: SendMessageToSlackDto) => {
  const message = {
    channel: channelId,
    text
  };

  try {
    const response = await axios.post(webhookUrl, JSON.stringify(message), {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          headers && delete headers['Content-Type'];
          return data;
        }
      ]
    });

    if (response.data === 'ok') {
      return 'ok';
    }
  } catch (error) {
    handleError(error);
  }
};
