export enum ROUTES {
  START = '/',
  HOME = '/',
  PROFILE = '/profile',

  CREATE_TEAM = '/create-team',
  CHOOSE_TEAM = '/choose-team',
  COOKIE_POLICY = '/cookie-policy',
  TEAMS = '/teams',

  TEAM_SETTINGS = 'settings',
  TEAM_USERS = 'settings/users',
  TEAM_INTEGRATIONS = 'settings/apps-and-integrations',
  TEAM_UPGRADE = 'settings/upgrade',

  PROJECTS = '/projects',
  MANAGE_USERS = '/manage-users',
  PRICING_AND_PLANS = 'pricing-and-plans',

  BOARDS = 'boards',
  DOCUMENTS = 'documents',
  LOGIN = '/login',
  SIGNUP = '/registration',
  ERROR = '/404',
  TERMS_OF_USE = '/terms-of-use',
  PRIVACY_POLICY = '/privacy-policy',
  RESET_PASSWORD = '/reset-password',
  NEW_PASSWORD = '/new-password',
  VERIFY_EMAIL = '/verify-email',

  // temporary pages.
  MINDMAP = '/mindmap',
  ROADMAP = '/roadmap',
  WBS = 'wbs',
  FLOWCHART = '/flowchart'
}

export enum TEAM_DASHBOARD_FILTER {
  MIND_CHARTS = 'mind-charts',
  RECENT_BOARDS = 'recent-boards'
}
