import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from 'consts';
import styles from './TermsPrivacy.module.scss';

interface TermsPrivacyProps {
  className?: string;
}

const TermsPrivacy: FC<TermsPrivacyProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.termsAndPolicy} ${className ? className : ''}`}>
      <Link to={ROUTES.TERMS_OF_USE} className='link'>
        {t('common.termsOfUse')}
      </Link>
      <Link to={ROUTES.PRIVACY_POLICY} className='link'>
        {t('common.privacyPolicy')}
      </Link>
      <Link to={ROUTES.COOKIE_POLICY} className='link'>
        {t('common.cookiesPolicy')}
      </Link>
    </div>
  );
};

export default TermsPrivacy;
