import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Handle, NodeProps, NodeToolbar, Position } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { shallow } from 'zustand/shallow';

import { EDGE_TYPES, NODE_TYPES } from 'consts';
import { NodeData, RFState, useRFStore } from 'store';
import { generateTaskNodes } from '../../../api';
import { DEFAULT_WBS_EPIC_COLOR } from '../../../const';
import { normalizeEpicNodes } from '../../../utils';
import styles from './WBSEpicNode.module.scss';

import { IconKnowledge, IconMagicWand, IconPlusBold } from 'components/atoms';
import { Contextbar } from 'components/organisms/flows/common';

const selector = (state: RFState) => ({
  currentEdgeType: state.currentEdgeType,
  nodes: state.nodes,
  setNodes: state.setNodes,
  updateNodeValue: state.updateNodeValue,
  addNodes: state.addNodes,
  addEdges: state.addEdges
});

const WBSEpicNode: FC<NodeProps<NodeData>> = ({ id, data, selected, dragging }) => {
  const [inputActive, setInputActive] = useState<boolean>(false);
  const documentCount = useMemo(() => data.linkedDocuments?.length, [data.linkedDocuments]);
  const { currentEdgeType, nodes, setNodes, updateNodeValue, addNodes, addEdges } = useRFStore(
    selector,
    shallow
  );

  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.value]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setCursor(target.selectionStart);
    updateNodeValue(id, target.value);
  };

  const onDoubleClick = () => {
    setInputActive(true);
    setTimeout(() => {
      const input = ref.current;
      if (!input) return;
      input.focus();
    });
  };

  const handleGenerate = async () => {
    const currentNode = nodes.find(node => node.id === id);
    if (currentNode) {
      const { nodes: nodesToCreate, edges: edgesToCreate } = await generateTaskNodes(currentNode);

      addNodes(nodesToCreate);
      addEdges(edgesToCreate);
    }
  };

  const handleAddEpic = (position: 'left' | 'right') => {
    const currentNode = nodes.find(node => node.id === id);
    const rootNode = nodes.find(node => node.type === NODE_TYPES.WBS_ROOT);
    if (!currentNode || !rootNode) return;

    const epicNodes = nodes.filter(node => node.type === NODE_TYPES.WBS_EPIC);
    const otherNodes = nodes.filter(node => node.type !== NODE_TYPES.WBS_EPIC);

    const currentNodeIndex = epicNodes.indexOf(currentNode);
    const newNodeIndex = position === 'left' ? currentNodeIndex : currentNodeIndex + 1;

    const newNodeId = uuid();
    const newEpicNode = {
      id: newNodeId,
      type: NODE_TYPES.WBS_EPIC,
      data: {
        value: '',
        toolbar: { position: Position.Right }
      },
      position: { x: currentNode.position.x, y: currentNode.position.y },
      parentNode: rootNode.id
    };

    const newEdgeId = uuid();
    const newEdge = {
      id: newEdgeId,
      type: EDGE_TYPES.WBS,
      source: rootNode.id,
      target: newNodeId
    };

    epicNodes.splice(newNodeIndex, 0, newEpicNode);
    const normalizedEpicNodes = normalizeEpicNodes(epicNodes);

    setNodes([...normalizedEpicNodes, ...otherNodes]);
    addEdges([newEdge]);
  };

  return (
    <>
      <Contextbar
        nodeId={id}
        isVisible={!dragging && data.toolbar?.visible}
        position={Position.Top}
        offset={30}
      />

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Left} offset={-12}>
        <button
          className={styles.buttonAddEpic}
          // disabled={generateDataLoading}
          onClick={() => handleAddEpic('left')}
        >
          <IconPlusBold />
        </button>
      </NodeToolbar>

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Right} offset={-12}>
        <button
          className={styles.buttonAddEpic}
          // disabled={generateDataLoading}
          onClick={() => handleAddEpic('right')}
        >
          <IconPlusBold />
        </button>
      </NodeToolbar>

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Bottom} offset={11}>
        <button
          className={styles.buttonGenerate}
          // disabled={generateDataLoading}
          onClick={handleGenerate}
        >
          {/* {generateDataLoading ? <IconLoader className={styles.spin} /> : <IconMagicWand />} */}
          <IconMagicWand color='#FFFFFF' />
        </button>
      </NodeToolbar>

      <Handle
        type='target'
        position={Position.Top}
        id='a'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />
      <Handle
        type='source'
        position={Position.Bottom}
        id='c'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
        style={{ left: 15 }}
      />

      <button
        className={
          `${styles.container} ` +
          `${selected ? styles.container_active : ''} ` +
          `${inputActive ? styles.container_double : ''}`
        }
        style={{
          background: data.color || DEFAULT_WBS_EPIC_COLOR,
          borderColor: data.color || DEFAULT_WBS_EPIC_COLOR
        }}
        // disabled={generateDataLoading}
        onDoubleClick={onDoubleClick}
      >
        <form
          className={styles.form}
          style={{
            maxWidth: 264 - (!inputActive && !!documentCount ? 34 : 0)
          }}
          onSubmit={e => {
            e.preventDefault();
            handleGenerate();
          }}
        >
          {inputActive ? (
            <input
              ref={ref}
              id={`wbs-${id}`}
              type='text'
              name='wbs-input'
              placeholder='Please enter the title'
              title={data.value || 'Please enter the title'}
              value={data.value}
              onChange={handleChange}
              onBlur={() => setInputActive(false)}
              className={`${styles.input} nodrag`}
              // disabled={generateDataLoading}
            />
          ) : (
            <span title={data.value || 'Please enter the title'} className={styles.text}>
              {data.value || 'Please enter the title'}
            </span>
          )}
        </form>

        {!inputActive && !!documentCount && (
          <div className={styles.documents}>
            <IconKnowledge className={styles.documents__icon} />
            <span className={styles.documents__badge}>{documentCount}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default WBSEpicNode;
