import { FC, ReactNode } from 'react';

import { useToggle } from 'hooks';

import { IconLike, Modal } from 'components/atoms';
import { Survey } from 'components/organisms';

import styles from './FeedbackButton.module.scss';

type FeedbackButtonProps = {
  children?: ReactNode;
  className?: string;
};

const FeedbackButton: FC<FeedbackButtonProps> = ({ children }) => {
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <>
      {children ? (
        children
      ) : (
        <div className={styles.container}>
          <button type='button' className={styles.header} onClick={toggleOpen}>
            <h4 className={styles.title}>Feedback</h4>
            <IconLike className={styles.icon} />
          </button>
        </div>
      )}

      <Modal isOpen={isOpen} onClose={toggleOpen} className='!px-[30px]'>
        <Survey onClose={toggleOpen} />
      </Modal>
    </>
  );
};

export default FeedbackButton;
