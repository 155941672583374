import { FC, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';

import { useClickOutside } from 'hooks';
import { SelectOption } from 'models';
import styles from './Select.module.scss';

import { useTranslation } from 'react-i18next';
import { IconCheckCircle, IconStrokeDown } from '../icons';

interface SelectProps {
  options: SelectOption[];
  placeholder: string;
  currentOption: SelectOption | undefined;
  setCurrentOption: (value: SelectOption) => void;
  error?: FieldError | undefined;
  isDisabled?: boolean;
  className?: string;
  headerClassName?: string;
  menuClassName?: string;
}

const Select: FC<SelectProps> = ({
  options,
  placeholder,
  currentOption,
  setCurrentOption,
  error,
  isDisabled,
  className,
  headerClassName,
  menuClassName
}) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setMenuOpen(false));

  return (
    <div ref={ref} tabIndex={1} className={`${styles.container} ${className}`}>
      <button
        type='button'
        title={currentOption?.label || ''}
        className={
          `${styles.header} ` +
          `${!currentOption ? styles.header_placeholder : ''} ` +
          `${error ? styles.header_error : ''} ` +
          `${headerClassName ? headerClassName : ''}`
        }
        disabled={isDisabled}
        onClick={() => setMenuOpen(prev => !prev)}
      >
        <span>{currentOption ? currentOption.label : placeholder}</span>
        <div className={menuOpen ? `${styles.stroke} ${styles.stroke_active}` : styles.stroke}>
          <IconStrokeDown />
        </div>
      </button>

      <div className={`${styles.menu} ${menuOpen ? styles.menu_active : ''} ${menuClassName}`}>
        {options.length > 0 ? (
          <div className={styles.options}>
            {options.map(option => (
              <button
                key={option.value}
                type='button'
                title={option.label}
                className={
                  currentOption === option
                    ? `${styles.option} ${styles.option_active}`
                    : styles.option
                }
                onClick={() => {
                  setCurrentOption(option);
                  setMenuOpen(prev => !prev);
                }}
              >
                {option.Icon && <div className={styles.icon}>{option.Icon}</div>}
                <span>{option.label}</span>
                {currentOption === option && <IconCheckCircle color='#19b829' />}
              </button>
            ))}
          </div>
        ) : (
          <span className={styles['no-options']}>{t('common.noOptions')}</span>
        )}
      </div>

      {!!error && <span className={styles.errorMessage}>{error.message}</span>}
    </div>
  );
};

export default Select;
