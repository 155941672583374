import { BOARD_TYPES, Project } from 'models';
import { instance } from './api';

export interface CreateProjectDto {
  teamId: number;
  name: string;
}

export interface DeleteProjectDto {
  teamId: number;
  projectId: number;
}

export interface UpdateProjectDto {
  teamId: number;
  projectId: number;
  body: { name?: string };
}

export interface MoveBoardDto {
  projectId: number;
  body: { boardId: number; newProjectId: number };
}

export interface MoveBoardResponse {
  id: number;
  name: string;
  type: BOARD_TYPES;
  project: { id: number };
}

export const apiProject = {
  createProject: async (data: CreateProjectDto) => {
    const response = await instance.post<Project>('projects', data);
    return response.data;
  },
  getProject: async (projectId: number) => {
    const response = await instance.get<Project>(`projects/${projectId}`);
    return response.data;
  },
  deleteProject: async ({ projectId }: DeleteProjectDto) => {
    const response = await instance.delete(`projects/${projectId}`);
    return response.data;
  },
  updateProject: async ({ projectId, body }: UpdateProjectDto) => {
    const response = await instance.patch<Project>(`projects/one/${projectId}`, body);
    return response.data;
  },
  moveBoard: async ({ body }: MoveBoardDto) => {
    const response = await instance.patch<MoveBoardResponse>('projects/board-project', body);
    return response.data;
  }
};
