export type Product = {
  id: number;
  productPriceId: string | null;
  productId: string;
  subscriptionId: string | null;
  subscriptionStatus: string;
  numberEditorsAllowed: number;
  personalPricePerUser: number | null;
  personalYearDiscount: number | null;
  defaultPricePerUser: number;
  defaultYearDiscount: number;
};

export enum SubscriptionStatus {
  TEAM_PLUS = 'TEAM+',
  FREE = 'FREE'
}
