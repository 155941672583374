import { FC, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PlaceholderPNG from 'assets/images/png/recent-boards-placeholder.png';

import { TeamBoard } from 'api';
import { useDocumentIcon } from 'hooks';
import { useTeamBoardsQuery } from 'hooks/queries';
import { Document, Project } from 'models';
import styles from './DocumentScreen.module.scss';

import { IconStrokeRight } from 'components/atoms';
import { BoardCard } from 'components/molecules';

type Params = { teamId: string; documentId: string };

type DocumentScreenProps = {
  project: Project;
};

// get data from document query when backend is ready
const DocumentScreen: FC<DocumentScreenProps> = ({ project: { files: documents } }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { teamId, documentId } = useParams<keyof Params>() as Params;
  // const documents = useDocumentsStore(store => store.documents);
  const { data: teamBoards } = useTeamBoardsQuery(Number(teamId));

  const document: Document | undefined = useMemo(
    () => documents.find(document => document.id.toString() === documentId),
    [documentId, documents]
  );
  const Icon = useDocumentIcon(document?.extension || '');

  const linkedBoards: TeamBoard[] = useMemo(() => {
    if (!teamBoards?.length || !document?.linkedBoards?.length) return [];
    const filteredBoards = teamBoards.filter(
      board => !!document?.linkedBoards?.includes(board.boardId)
    );
    return filteredBoards;
  }, [document, teamBoards]);

  const goToDocuments = () => {
    navigate(location.pathname.split('/').slice(0, -1).join('/'));
  };

  // if (documentLoading) return <h2>{t('common.loading')}</h2>;
  if (!document) return <h2>Failed to load the document</h2>;
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <button type='button' className={styles['button-back']} onClick={goToDocuments}>
          <IconStrokeRight className={styles['icon-back']} />
        </button>
        <Icon className={styles['icon-document']} />
        <h3 className={styles.name} title={document.name}>
          {document.name}
        </h3>
      </header>

      <div className={styles.linked}>
        <h4 className={styles.linked__title}>Linked Boards</h4>
        {linkedBoards.length ? (
          <div className={styles.linked__list}>
            {linkedBoards.map(board => (
              <BoardCard key={document.id} board={board} />
            ))}
          </div>
        ) : (
          <div className={styles.placeholder}>
            <img src={PlaceholderPNG} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentScreen;
