import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import { Button, Toggle } from 'components/atoms';

import styles from './CustomiseCookies.module.scss';

type ToggleProps = {
  onClose?: () => void;
  onCustomizeClickAccept?: () => void;
};

interface CustomWindow extends Window {
  dataLayer: any[];
}

const CustomiseCookies: FC<ToggleProps> = ({ onClose, onCustomizeClickAccept }) => {
  const { t } = useTranslation();

  const [isCheckedAnalyticsCookies, setIsCheckedAnalyticsCookies] = useState(false);
  const [isCheckedMarketingCookies, setIsCheckedMarketingCookies] = useState(false);
  const handleToggleAnalyticsCookies = (value: boolean) => {
    setIsCheckedAnalyticsCookies(value);
  };
  const handleToggleMarketingCookies = (value: boolean) => {
    setIsCheckedMarketingCookies(value);
  };

  const { handleSubmit } = useForm({ mode: 'onSubmit' });

  const [cookies, setCookie] = useCookies(['analytics', 'marketing']);

  useEffect(() => {
    setIsCheckedAnalyticsCookies(cookies.analytics !== undefined);
    setIsCheckedMarketingCookies(cookies.marketing !== undefined);
  }, [cookies]);

  const updateTagManagerDataLayer = () => {
    const customWindow = window as unknown as CustomWindow; // Cast window to the custom interface
    customWindow.dataLayer = customWindow.dataLayer || [];
    customWindow.dataLayer.push({
      event: 'cookieConsentUpdate',
      analyticsConsent: isCheckedAnalyticsCookies,
      marketingConsent: isCheckedMarketingCookies
    });
  };

  const onSubmit = () => {
    if (isCheckedAnalyticsCookies) {
      setCookie('analytics', true, { path: '/' });
    } else {
      setCookie('analytics', null, { path: '/', expires: new Date(0) });
    }

    if (isCheckedMarketingCookies) {
      setCookie('marketing', true, { path: '/' });
    } else {
      setCookie('marketing', null, { path: '/', expires: new Date(0) });
    }

    updateTagManagerDataLayer();

    if (onCustomizeClickAccept) {
      onCustomizeClickAccept();
    }
  };

  const rejectAll = () => {
    setCookie('analytics', null, { path: '/', expires: new Date(0) });
    setCookie('marketing', null, { path: '/', expires: new Date(0) });

    updateTagManagerDataLayer();

    if (onCustomizeClickAccept) {
      onCustomizeClickAccept();
    }

    if (onClose) {
      onClose();
    }
  };

  const acceptAll = () => {
    setCookie('analytics', true, { path: '/' });
    setCookie('marketing', true, { path: '/' });

    updateTagManagerDataLayer();

    if (onCustomizeClickAccept) {
      onCustomizeClickAccept();
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerForm}>
        <div className={styles.content}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <h2 className={styles.title}>{t('CustomiseCookies.customiseCookies')}</h2>
            <div className='flex flex-row justify-between'>
              <h3 className={styles.subtitle}>{t('CustomiseCookies.essentialCookies')}</h3>
              <h3 className={styles['subtitle-grey']}>{t('CustomiseCookies.required')}</h3>
            </div>
            <div className='flex mb-[20px]'>
              <h3 className={styles['subtitle-grey']}>{t('CustomiseCookies.theseAreNecessary')}</h3>
            </div>
            <div className='flex flex-row justify-between'>
              <h3 className={styles.subtitle}>{t('CustomiseCookies.analyticsCookies')}</h3>
              <Toggle
                isChecked={isCheckedAnalyticsCookies}
                onChange={handleToggleAnalyticsCookies}
              />
            </div>
            <div className='flex mb-[20px]'>
              <h3 className={styles['subtitle-grey']}>
                {t('CustomiseCookies.theseHelpUsImprove')}
              </h3>
            </div>
            <div className='flex flex-row justify-between'>
              <h3 className={styles.subtitle}>{t('CustomiseCookies.marketingCookies')}</h3>
              <Toggle
                isChecked={isCheckedMarketingCookies}
                onChange={handleToggleMarketingCookies}
              />
            </div>
            <div className='flex mb-[32px]'>
              <h3 className={styles['subtitle-grey']}>{t('CustomiseCookies.theseAreUsed')}</h3>
            </div>

            <div className={styles.buttons}>
              <Button
                type='button'
                variant='secondary'
                size='big'
                wide
                title={t('CustomiseCookies.buttons.rejectAllCookies')}
                isDisabled={false}
                onClick={rejectAll}
              />

              <Button
                type='button'
                variant='black'
                size='big'
                wide
                title={t('CustomiseCookies.buttons.acceptAllCookies')}
                isDisabled={false}
                onClick={acceptAll}
              />

              <Button
                type='submit'
                variant='primary'
                size='big'
                wide
                title={t('CustomiseCookies.buttons.savePreferences')}
                isDisabled={false}
                onClick={onClose}
                isLoading={false}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomiseCookies;
