import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TeamBoard as ITeamBoard } from 'api';
import { useClickOutside } from 'hooks';
import { useTeamBoardsQuery } from 'hooks/queries';
import styles from './SearchBoards.module.scss';

import { SearchField } from 'components/atoms';
import { TeamBoard } from 'components/molecules';

interface SearchBoardsProps {
  className: string;
}

const SearchBoards: FC<SearchBoardsProps> = ({ className }) => {
  const { t } = useTranslation();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const { data: teamBoards, isLoading: teamBoardsLoading } = useTeamBoardsQuery(Number(teamId));
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setMenuOpen(false));
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredBoards, setFilteredBoards] = useState<ITeamBoard[]>([]);

  useEffect(() => {
    if (teamBoards) {
      const filteredBoards = teamBoards.filter(({ boardName, projectName }) => {
        if (boardName && projectName) {
          return boardName.concat(projectName).toLowerCase().includes(searchValue.toLowerCase());
        }
      });
      setFilteredBoards(filteredBoards);
    }
  }, [searchValue, teamBoards]);

  return (
    <div ref={ref} tabIndex={1} className={`${styles.container} ${className}`}>
      <SearchField
        placeholder={t('Header.searchBoard') as string}
        onFocus={() => setMenuOpen(true)}
        setSearchValue={setSearchValue}
        isLoading={teamBoardsLoading}
      />

      <div className={`${styles.menu} ${menuOpen ? styles.menu_active : ''}`}>
        {teamBoardsLoading ? (
          <span className={styles['loading']}>{t('common.loading')}</span>
        ) : (
          <>
            {filteredBoards.length > 0 ? (
              <div className={styles.options}>
                {filteredBoards.map(teamBoard => (
                  <TeamBoard key={teamBoard.boardId} teamBoard={teamBoard} />
                ))}
              </div>
            ) : (
              <span className={styles['no-options']}>{t('common.noOptions')}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchBoards;
