import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Handle, NodeProps, NodeToolbar, Position } from 'reactflow';
import { shallow } from 'zustand/shallow';

import { NodeData, RFState, useRFStore } from 'store';
import { generateEpicNodes } from '../../../api';
import styles from './WBSRootNode.module.scss';

import { IconKnowledge, IconMagicWand, IconWBS } from 'components/atoms';
import { Contextbar } from 'components/organisms/flows/common';
import { DEFAULT_TIMEOUT, NODE_TYPES } from 'consts';

const selector = (state: RFState) => ({
  currentEdgeType: state.currentEdgeType,
  nodes: state.nodes,
  selectNode: state.selectNode,
  updateNodeValue: state.updateNodeValue,
  addNode: state.addNode,
  addNodes: state.addNodes,
  addEdges: state.addEdges
});

const WBSRootNode: FC<NodeProps<NodeData>> = ({ id, data, selected, dragging }) => {
  const [inputActive, setInputActive] = useState<boolean>(false);
  const documentCount = useMemo(() => data.linkedDocuments?.length, [data.linkedDocuments]);
  const { currentEdgeType, nodes, selectNode, updateNodeValue, addNodes, addEdges } = useRFStore(
    selector,
    shallow
  );

  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.value]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setCursor(target.selectionStart);
    updateNodeValue(id, target.value);
  };

  const onDoubleClick = () => {
    setInputActive(true);
    setTimeout(() => {
      const input = ref.current;
      if (!input) return;
      input.focus();
    });
  };

  // when creating the board, focus on the root node for text entry
  useEffect(() => {
    if (
      nodes.find(node => node.type === NODE_TYPES.WBS_EPIC || node.type === NODE_TYPES.WBS_TASK)
    ) {
      return;
    }

    selectNode(id);
    setTimeout(onDoubleClick, DEFAULT_TIMEOUT);
  }, []);

  const handleGenerate = async () => {
    const currentNode = nodes.find(node => node.id === id);
    if (currentNode) {
      const { nodes: nodesToCreate, edges: edgesToCreate } = await generateEpicNodes(currentNode);

      addNodes(nodesToCreate);
      addEdges(edgesToCreate);
    }
  };

  return (
    <>
      <Contextbar
        nodeId={id}
        isVisible={!dragging && data.toolbar?.visible}
        position={Position.Top}
        offset={30}
      />

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Bottom} offset={11}>
        <button
          className={styles.buttonGenerate}
          // disabled={generateDataLoading}
          onClick={handleGenerate}
        >
          {/* {generateDataLoading ? <IconLoader className={styles.spin} /> : <IconMagicWand />} */}
          <IconMagicWand color='#FFFFFF' />
        </button>
      </NodeToolbar>

      <Handle
        type='source'
        position={Position.Bottom}
        id='c'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />

      <button
        className={
          `${styles.container} ` +
          `${selected ? styles.container_active : ''} ` +
          `${inputActive ? styles.container_double : ''}`
        }
        // disabled={generateDataLoading}
        onDoubleClick={onDoubleClick}
      >
        {!inputActive && <IconWBS className={styles.icon} />}

        <form
          className={styles.form}
          style={{
            maxWidth: 308 - (!inputActive ? 34 : 0) - (!inputActive && !!documentCount ? 34 : 0)
          }}
          onSubmit={e => {
            e.preventDefault();
            handleGenerate();
          }}
        >
          {inputActive ? (
            <input
              ref={ref}
              id={`wbs-${id}`}
              type='text'
              name='wbs-input'
              placeholder='Please enter the title'
              title={data.value || 'Please enter the title'}
              value={data.value}
              onChange={handleChange}
              onBlur={() => setInputActive(false)}
              className={`${styles.input} nodrag`}
              // disabled={generateDataLoading}
            />
          ) : (
            <span title={data.value || 'Please enter the title of the WBS'} className={styles.text}>
              {data.value || 'Please enter the title of the WBS'}
            </span>
          )}
        </form>

        {!inputActive && !!documentCount && (
          <div className={styles.documents}>
            <IconKnowledge className={styles.documents__icon} />
            <span className={styles.documents__badge}>{documentCount}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default WBSRootNode;
