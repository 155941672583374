import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { UpdateProfileDto, apiUser } from 'api';
import { handleError, notifySuc } from 'helpers';

export const useUpdateProfileMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateProfileDto) => {
      return apiUser.updateProfile(data);
    },
    {
      async onSuccess(data) {
        notifySuc(t('common.notifications.profileUpdated'));

        const profileQuery = 'users/me';

        await queryClient.invalidateQueries([profileQuery], {
          refetchActive: false,
          refetchInactive: false
        });

        queryClient.setQueryData([profileQuery], data);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
