import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useToggle } from 'hooks';
import { useUpdateProfileMutation, useUploadAvatarMutation } from 'hooks/user';
import { AuthProvider, User, UserInfoFormValues } from 'models';

import { Avatar, Button, InputText, Modal } from 'components/atoms';
import { ChangeEmailForm, ChangePasswordForm } from '..';

type UpdateInfoFormProps = {
  user: User;
  className?: string;
};

const UpdateInfoForm: FC<UpdateInfoFormProps> = ({ user, className }) => {
  const { t } = useTranslation();
  const [changeEmailOpen, toggleChangeEmailOpen] = useToggle(false);
  const [changePasswordOpen, toggleChangePasswordOpen] = useToggle(false);
  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } =
    useUpdateProfileMutation();
  const { mutate: uploadAvatar, isLoading: uploadAvatarLoading } = useUploadAvatarMutation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<UserInfoFormValues>({
    mode: 'onSubmit'
  });

  useEffect(() => {
    reset({
      image: undefined,
      username: user.username,
      companyName: user.companyName,
      industry: user.industry,
      position: user.position
    });
  }, [user]);

  const canChangeEmail = useMemo(() => {
    return user.authProvider === AuthProvider.MIGNED;
  }, [user]);

  const onSubmit = async (data: UserInfoFormValues) => {
    const { image, username, companyName, industry, position } = data;

    if (
      username !== user.username ||
      companyName !== user.companyName ||
      industry !== user.industry ||
      position !== user.position
    ) {
      updateProfileMutate({ body: { username, companyName, industry, position } });
    }

    if (image) {
      uploadAvatar({ image });
    }
  };

  const handleReset = () => {
    reset();
  };

  return (
    <div
      className={
        'flex items-start gap-[24px] p-[15px] border-[#EDEFF2] ' +
        `rounded-[20px] border-[2px] border-solid ${className}`
      }
    >
      <Avatar
        name='image'
        control={control}
        cover={user.avatarUrl}
        loading={updateProfileLoading || uploadAvatarLoading}
      />

      <div className='grow pt-[32px] flex flex-col gap-[16px]'>
        <form className='flex flex-col gap-[16px]' onSubmit={handleSubmit(onSubmit)}>
          <InputText
            type='text'
            width='big'
            name='username'
            label={t('ProfilePage.form.name') as string}
            control={control}
            rules={{
              required: { value: true, message: t('common.validation.requiredField') },
              maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                }
              }
            }}
            isLoading={updateProfileLoading || uploadAvatarLoading}
          />

          <InputText
            type='text'
            width='big'
            name='companyName'
            label={t('ProfilePage.form.companyName') as string}
            control={control}
            rules={{
              required: { value: true, message: t('common.validation.requiredField') },
              maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                }
              }
            }}
            isLoading={updateProfileLoading || uploadAvatarLoading}
          />

          <InputText
            type='text'
            width='big'
            name='industry'
            label={t('ProfilePage.form.industry') as string}
            control={control}
            rules={{
              required: { value: true, message: t('common.validation.requiredField') },
              maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                }
              }
            }}
            isLoading={updateProfileLoading || uploadAvatarLoading}
          />

          <InputText
            type='text'
            width='big'
            name='position'
            label={t('ProfilePage.form.role') as string}
            control={control}
            rules={{
              required: { value: true, message: t('common.validation.requiredField') },
              maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                }
              }
            }}
            isLoading={updateProfileLoading || uploadAvatarLoading}
          />

          <div className='self-end flex gap-[12px]'>
            <Button
              type='reset'
              variant='secondary'
              size='big'
              wide
              title={t('common.cancel')}
              onClick={handleReset}
              isDisabled={!isDirty || updateProfileLoading || uploadAvatarLoading}
              className='min-w-[149px]'
            />

            <Button
              type='submit'
              variant='primary'
              size='big'
              wide
              title={t('common.save')}
              isDisabled={!isDirty}
              isLoading={updateProfileLoading || uploadAvatarLoading}
              className='min-w-[180px]'
            />
          </div>
        </form>

        <div className='flex flex-col gap-[4px]'>
          <h6 className='text-[14px] font-[400] leading-[20px] tracking-[0.14px] text-[#6B7A99]'>
            {t('ProfilePage.form.email')}
          </h6>

          <div className='flex gap-[8px]'>
            <span className='text-[18px] font-[400] leading-[24px] text-[#2D3C59]'>
              {t('ProfilePage.form.yourEmail')} <b>{user.email}</b>
            </span>

            <label
              className={'text-[18px] font-[400] leading-[24px] text-[#4971FF] cursor-pointer'}
              onClick={toggleChangeEmailOpen}
            >
              {t('ProfilePage.form.change')}
            </label>
          </div>
        </div>

        {canChangeEmail && (
          <div className='flex flex-col gap-[4px]'>
            <h6 className='text-[14px] font-[400] leading-[20px] tracking-[0.14px] text-[#6B7A99]'>
              {t('ProfilePage.form.password')}
            </h6>

            <label
              className='text-[18px] font-[400] leading-[24px] text-[#4971FF] cursor-pointer'
              onClick={toggleChangePasswordOpen}
            >
              {t('ProfilePage.form.change')}
            </label>
          </div>
        )}
      </div>

      <Modal isOpen={changeEmailOpen} onClose={toggleChangeEmailOpen}>
        <ChangeEmailForm currentEmail={user.email} onClose={toggleChangeEmailOpen} />
      </Modal>

      <Modal isOpen={changePasswordOpen} onClose={toggleChangePasswordOpen}>
        <ChangePasswordForm onClose={toggleChangePasswordOpen} />
      </Modal>
    </div>
  );
};

export default UpdateInfoForm;
