import { User } from 'models';
import { instance } from './api';

export type UpdateProfileDto = {
  body: {
    username?: string;
    email?: string;
    position?: string;
    industry?: string;
    companyName?: string;
  };
};

export type UploadAvatarDto = {
  image: File;
};

export const apiUser = {
  getInfo: async () => {
    const response = await instance.get<User>('users/me');
    return response.data;
  },
  updateProfile: async ({ body }: UpdateProfileDto) => {
    const response = await instance.patch<User>('users', body);
    return response.data;
  },
  deleteProfile: async () => {
    const response = await instance.delete<User>('users?anyway=true');
    return response.data;
  },
  uploadAvatar: async ({ image }: UploadAvatarDto) => {
    const formData = new FormData();
    formData.append('image', image);
    const response = await instance.patch<User>('users/avatar', formData);
    return response.data;
  }
};
