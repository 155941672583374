import { useMutation } from 'react-query';

import { CreateCheckoutSessionDto, apiStripe } from 'api/stripe';
import { handleError } from 'helpers';

export const useCreateCheckoutSessionMutation = () => {
  return useMutation(
    (dto: CreateCheckoutSessionDto) => {
      return apiStripe.createCheckoutSession(dto);
    },
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
