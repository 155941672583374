import { Edge, Node, Position } from 'reactflow';
import { v4 as uuid } from 'uuid';

import {
  DEFAULT_ROADMAP_EPIC_HEIGHT,
  DEFAULT_ROADMAP_MILESTONE_X_OFFSET,
  DEFAULT_ROADMAP_MILESTONE_Y_OFFSET,
  DEFAULT_ROADMAP_TASK_CONTAINER_WIDTH,
  DEFAULT_ROADMAP_TASK_CONTAINER_X_OFFSET,
  DEFAULT_ROADMAP_TASK_CONTAINER_Y_OFFSET,
  ROADMAP_EPIC_COLORS
} from 'components/organisms/flows/RoadmapFlow/const';
import { EdgeData, NodeData } from 'store';
import { NODE_TYPES } from './react-flow';

const rootId = uuid();

const epicContainerId = uuid();
const epicId1 = uuid();
const epicId2 = uuid();

const taskContainerId = uuid();
const taskId1 = uuid();

const intervalContainerId = uuid();
const intervalId1 = uuid();
const intervalId2 = uuid();
const intervalId3 = uuid();

const milestoneContainerId = uuid();
const milestoneId1 = uuid();

export const roadmapInitialData: {
  nodes: Node<NodeData>[];
  edges: Edge<EdgeData>[];
} = {
  nodes: [
    {
      id: rootId,
      type: NODE_TYPES.ROADMAP_ROOT,
      data: {
        value: '',
        toolbar: { position: Position.Right }
      },
      position: { x: 0, y: 0 }
    },
    {
      id: intervalContainerId,
      type: NODE_TYPES.ROADMAP_INTERVAL_CONTAINER,
      data: {},
      selectable: false,
      position: { x: 350, y: 0 },
      width: 1000,
      style: {
        width: 1000
      }
    },
    {
      id: intervalId1,
      type: NODE_TYPES.ROADMAP_INTERVAL,
      data: {
        value: 'Month 1'
      },
      position: { x: 1, y: 1 },
      parentNode: intervalContainerId,
      extent: 'parent'
    },
    {
      id: intervalId2,
      type: NODE_TYPES.ROADMAP_INTERVAL,
      data: {
        value: 'Month 2'
      },
      position: { x: 301, y: 1 },
      parentNode: intervalContainerId,
      extent: 'parent'
    },
    {
      id: intervalId3,
      type: NODE_TYPES.ROADMAP_INTERVAL,
      data: {
        value: 'Month 3'
      },
      position: { x: 601, y: 1 },
      parentNode: intervalContainerId,
      extent: 'parent'
    },
    {
      id: milestoneContainerId,
      type: NODE_TYPES.ROADMAP_MILESTONE_CONTAINER,
      data: {},
      selectable: false,
      position: { x: 350, y: 47 },
      width: 1000,
      style: {
        width: 1000
      }
    },
    {
      id: milestoneId1,
      type: NODE_TYPES.ROADMAP_MILESTONE,
      data: {
        value: 'Milestone 1'
      },
      position: { x: DEFAULT_ROADMAP_MILESTONE_X_OFFSET, y: DEFAULT_ROADMAP_MILESTONE_Y_OFFSET },
      parentNode: milestoneContainerId,
      extent: 'parent',
      draggable: true
    },
    {
      id: epicContainerId,
      type: NODE_TYPES.ROADMAP_EPIC_CONTAINER,
      data: {},
      selectable: false,
      position: { x: 0, y: 125 },
      height: 1000,
      style: {
        height: 1000
      },
      parentNode: rootId
    },
    {
      id: epicId1,
      type: NODE_TYPES.ROADMAP_EPIC,
      data: {
        value: 'Market Research',
        toolbar: { position: Position.Right },
        color: ROADMAP_EPIC_COLORS.RED
      },
      height: DEFAULT_ROADMAP_EPIC_HEIGHT,
      style: {
        height: DEFAULT_ROADMAP_EPIC_HEIGHT
      },
      position: { x: 0, y: 0 },
      parentNode: epicContainerId
    },
    {
      id: epicId2,
      type: NODE_TYPES.ROADMAP_EPIC,
      data: {
        value: 'Very very very very very very very very very very very very very very big text',
        toolbar: { position: Position.Right },
        color: ROADMAP_EPIC_COLORS.RED
      },
      height: DEFAULT_ROADMAP_EPIC_HEIGHT,
      style: {
        height: DEFAULT_ROADMAP_EPIC_HEIGHT
      },
      position: { x: 0, y: 80 },
      parentNode: epicContainerId
    },
    {
      id: taskContainerId,
      type: NODE_TYPES.ROADMAP_TASK_CONTAINER,
      data: {},
      selectable: false,
      parentNode: epicId1,
      position: {
        x: DEFAULT_ROADMAP_TASK_CONTAINER_X_OFFSET,
        y: DEFAULT_ROADMAP_TASK_CONTAINER_Y_OFFSET
      },
      width: DEFAULT_ROADMAP_TASK_CONTAINER_WIDTH,
      style: {
        width: DEFAULT_ROADMAP_TASK_CONTAINER_WIDTH
      }
    },
    {
      id: taskId1,
      type: NODE_TYPES.ROADMAP_TASK,
      data: {
        value: '',
        toolbar: { position: Position.Right },
        color: ROADMAP_EPIC_COLORS.RED
      },
      parentNode: taskContainerId,
      extent: 'parent',
      position: { x: 0, y: 0 },
      draggable: true
    }
  ],
  edges: []
};
