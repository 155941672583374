import { useQuery } from 'react-query';

import { apiUser } from 'api';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers';

export const useUserQuery = () => {
  return useQuery(['users/me'], async () => await apiUser.getInfo(), {
    onError(error) {
      handleError(error);
    },
    staleTime: DEFAULT_STALE_TIME
  });
};
