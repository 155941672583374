import { useTranslation } from 'react-i18next';

export const useFeatures = () => {
  const { t } = useTranslation();

  const featuresFree: string[] = [
    t('PricingAndPlans.plans.free.features.1'),
    t('PricingAndPlans.plans.free.features.2'),
    t('PricingAndPlans.plans.free.features.3'),
    t('PricingAndPlans.plans.free.features.4')
  ];

  const featuresTeamPlus: string[] = [
    t('PricingAndPlans.plans.team+.features.1'),
    t('PricingAndPlans.plans.team+.features.2'),
    t('PricingAndPlans.plans.team+.features.3'),
    t('PricingAndPlans.plans.team+.features.4'),
    t('PricingAndPlans.plans.team+.features.5')
  ];

  const featuresEnterprise: string[] = [
    t('PricingAndPlans.plans.enterprise.features.1'),
    t('PricingAndPlans.plans.enterprise.features.2'),
    t('PricingAndPlans.plans.enterprise.features.3'),
    t('PricingAndPlans.plans.enterprise.features.4'),
    t('PricingAndPlans.plans.enterprise.features.5')
  ];

  return { featuresFree, featuresTeamPlus, featuresEnterprise } as const;
};
