import { FC } from 'react';
import { EdgeProps, getSmoothStepPath } from 'reactflow';

import { EdgeData } from 'store';

const WBSEdge: FC<EdgeProps<EdgeData>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  selected
}) => {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  return (
    <path
      id={id}
      style={{ stroke: selected ? '#8b92a0' : '#C3CAD9', strokeWidth: 2 }}
      className='react-flow__edge-path'
      d={edgePath}
      markerEnd={markerEnd}
    />
  );
};

export default WBSEdge;
