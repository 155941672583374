import { FC } from 'react';
import { ROUTES } from 'consts/routes';

import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface CookiesConsentProps {
  onCustomizeClick: () => void;
  onCustomizeClickAccept: () => void;
}

const CookiesConsent: FC<CookiesConsentProps> = ({ onCustomizeClick, onCustomizeClickAccept }) => {
  const { t } = useTranslation();

  return (
    <CookieConsent
      buttonText={t('Cookies.acceptAll')}
      flipButtons
      disableButtonStyles={true}
      onAccept={() => {
        document.cookie = 'analytics=true; path=/';
        document.cookie = 'marketing=true; path=/';
        onCustomizeClickAccept();
      }}
      style={{
        background: 'white',
        borderRadius: '10px',
        maxWidth: '1090px',
        padding: '30px 40px 30px 40px',
        color: 'black',
        marginBottom: '40px',
        transform: 'translateX(-50%)',
        left: '50%',
        display: 'block',
        zIndex: '9'
      }}
      contentStyle={{
        margin: '0'
      }}
      buttonStyle={{
        color: 'white',
        background: '#19B829',
        fontWeight: 'bolder',
        width: '183px',
        height: '48px',
        marginTop: '-32px',
        borderRadius: '8px',
        float: 'right'
      }}
    >
      <h3 className='mb-[8px]'>{t('Cookies.cookieConsent')}</h3>
      <p className='text-[16px] leading-6 tracking-normal mb-[32px]'>
        {t('Cookies.ourWebsite')}
        <Link to={ROUTES.COOKIE_POLICY} className='text-[#4971FF]'>
          {t('Cookies.cookiePolicy')}
        </Link>
        .
      </p>
      <div className='flex justify-end items-center self-stretch mr-[203px]'>
        <p className='text-[16px]'>
          {t('Cookies.youHave')}
          <span className='text-[#4971FF] cursor-pointer' onClick={onCustomizeClick}>
            {t('Cookies.customizeYour')}
          </span>
          {t('Cookies.preferences')}
        </p>
      </div>
    </CookieConsent>
  );
};
export default CookiesConsent;
