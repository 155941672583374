import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EMAIL_REGEXP, PASSWORD_NO_MATCH_REGEXP, ROUTES } from 'consts';
import { useAuth } from 'hooks';
import styles from './SignupPage.module.scss';

import { Button, ButtonGoogle, Divider, InputText, TermsPrivacy } from 'components/atoms';

const SignupPage: FC = () => {
  const { t } = useTranslation();
  const { register, isLoading } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async ({ name, email, password }: FieldValues) => {
    register(name, email, password);
  };

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          <h1 className={styles.content__title}>{t('SignupPage.title')}</h1>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.form__fields}>
              <InputText
                type='email'
                width='big'
                name='email'
                label={t('common.form.email') as string}
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  maxLength: { value: 50, message: `${t('common.form.maximumLengthIs')} 50` },
                  validate: {
                    doesntContainSpaces: (value: string) => {
                      return !value.includes(' ')
                        ? true
                        : t('common.validation.doesntConsistOfSpaces');
                    },
                    validEmail: (value: string) => {
                      return EMAIL_REGEXP.test(value)
                        ? true
                        : t('common.validation.incorrectEmail');
                    }
                  }
                }}
                isLoading={isLoading}
                autoComplete='off'
                containerClassName={styles.email}
              />

              <InputText
                type='text'
                width='big'
                name='name'
                label={t('common.form.yourName') as string}
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
                  validate: {
                    doesntConsistOfSpaces: (value: string) => {
                      return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                    }
                  }
                }}
                isLoading={isLoading}
                autoComplete='off'
                containerClassName={styles.name}
              />

              <InputText
                type='password'
                width='big'
                name='password'
                label={t('common.form.password') as string}
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  minLength: { value: 6, message: `${t('common.form.minimumLengthIs')} 6` },
                  maxLength: { value: 20, message: `${t('common.form.maximumLengthIs')} 20` },
                  validate: {
                    doesntContainSpaces: (value: string) => {
                      return !value.includes(' ')
                        ? true
                        : t('common.validation.doesntContainSpaces');
                    },
                    validPassword: (value: string) => {
                      return !value.match(PASSWORD_NO_MATCH_REGEXP)
                        ? true
                        : t('common.validation.incorrectPassword');
                    }
                  }
                }}
                isLoading={isLoading}
                autoComplete='off'
                containerClassName={styles.password}
              />

              <InputText
                type='password'
                width='big'
                name='confirm'
                label={t('common.form.confirmPassword') as string}
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  minLength: { value: 6, message: `${t('common.form.minimumLengthIs')} 6` },
                  maxLength: { value: 20, message: `${t('common.form.maximumLengthIs')} 20` },
                  validate: {
                    doesntContainSpaces: (value: string) => {
                      return !value.includes(' ')
                        ? true
                        : t('common.validation.doesntContainSpaces');
                    },
                    validPassword: (value: string) => {
                      return !value.match(PASSWORD_NO_MATCH_REGEXP)
                        ? true
                        : t('common.validation.incorrectPassword');
                    },
                    matchPassword: (value: string, formValues: any) => {
                      return value === formValues.password
                        ? true
                        : t('common.validation.incorrectConfirmPassword');
                    }
                  }
                }}
                isLoading={isLoading}
                autoComplete='off'
                containerClassName={styles.confirm}
              />
            </div>

            <Button
              type='submit'
              variant='primary'
              size='big'
              wide
              title={t('common.form.signup') as string}
              isDisabled={!isDirty}
              isLoading={isLoading}
            />
          </form>

          <Divider className={styles.divider} />

          <ButtonGoogle className={styles.buttonGoogle} />

          <span className={styles.login}>
            {t('common.alreadyHaveAnAccount')}{' '}
            <Link to={ROUTES.LOGIN} className='link'>
              {t('common.logInHere')}
            </Link>
          </span>

          <TermsPrivacy />
        </div>
      </div>
    </main>
  );
};

export default SignupPage;
