import { BOARD_TYPES, Board } from 'models';
import { instance } from './api';

export interface RecentBoard {
  id: string;
  name: string;
  type: BOARD_TYPES;
  project: {
    id: string;
    name: string;
  };
}

export interface CreateBoardDto {
  name: string;
  type: BOARD_TYPES;
  teamId: number;
  projectId: number;
}

export interface UpdateBoardDto {
  teamId: number;
  projectId: number;
  boardId: number;
  body: {
    name: string;
  };
}

export interface DeleteBoardDto {
  teamId: number;
  projectId: number;
  boardId: number;
}

export interface GenerateMindmapNodesDto {
  boardId: number;
  nodeId?: string;
  title?: string;
}

export interface GenerateMindmapResponse {
  schema: string;
}

export const apiBoard = {
  createBoard: async ({ name, type, projectId }: CreateBoardDto) => {
    const response = await instance.post<Board>('boards', { name, type, projectId });
    return response.data;
  },
  getBoard: async (boardId: number) => {
    const response = await instance.get<Board>(`boards/one/${boardId}`);
    return response.data;
  },
  getRecentBoards: async () => {
    const response = await instance.get<RecentBoard[]>('boards/recently-edited');
    return response.data;
  },
  updateBoard: async ({ boardId, body }: UpdateBoardDto) => {
    const response = await instance.patch<Board>(`boards/${boardId}`, body);
    return response.data;
  },
  deleteBoard: async ({ boardId }: DeleteBoardDto) => {
    const response = await instance.delete(`boards/${boardId}`);
    return response.data;
  },

  generateMindmapNodes: async (data: GenerateMindmapNodesDto) => {
    const response = await instance.post<GenerateMindmapResponse>('boards/nodes', data);
    return response.data;
  }
};
