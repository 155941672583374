import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { UpdateBoardDto } from 'api';
import { useMoveBoardMutation } from 'hooks/mutations';
import { Board } from 'models';
import styles from './MoveToProjectForm.module.scss';

import { Button } from 'components/atoms';
import { ROUTES } from 'consts';
import { notifyErr } from 'helpers';
import { SelectProject } from '..';

type Params = { teamId: string; projectId: string; boardId: string };

interface MoveToProjectFormProps {
  boardId: number;
  onSuccess?: (data: Board, variables: UpdateBoardDto) => void;
  onCancel?: () => void;
}

const MoveToProjectForm: FC<MoveToProjectFormProps> = ({ onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId, projectId, boardId } = useParams<keyof Params>() as Params;
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });

  const { mutate: moveBoardMutate, isLoading: moveBoardLoading } = useMoveBoardMutation({
    onSuccess: ({ id: boardId, project: { id: newProjectId } }) => {
      onCancel && onCancel();
      navigate(
        `${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${newProjectId}/${ROUTES.BOARDS}/${boardId}`
      );
    }
  });

  const onSubmit = async ({ newProjectId }: FieldValues) => {
    if (newProjectId === projectId) {
      notifyErr('The board is already in this project');
    } else {
      moveBoardMutate({
        projectId: Number(projectId),
        body: { boardId: Number(boardId), newProjectId: Number(newProjectId) }
      });
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <h2 className={styles.title}>Move to project</h2>
        <h3 className={styles.subtitle}>Move your board to another project</h3>

        <SelectProject control={control} className={styles['select-project']} />

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            size='big'
            wide
            title={t('common.cancel')}
            isDisabled={moveBoardLoading}
            onClick={onCancel}
            className={styles['button-cancel']}
          />

          <Button
            type='submit'
            variant='primary'
            size='big'
            wide
            title='Move'
            isDisabled={!isDirty}
            isLoading={moveBoardLoading}
            className={styles['button-submit']}
          />
        </div>
      </form>
    </div>
  );
};

export default MoveToProjectForm;
