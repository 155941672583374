import { FC, useEffect } from 'react';

import { mindmapInitialData } from 'consts';
import { useRFStore } from 'store';
import styles from './MindmapPage.module.scss';

import { MindMapFlow } from 'components/organisms';

const MindmapPage: FC = () => {
  const setData = useRFStore(state => state.setData);

  useEffect(() => {
    setData(mindmapInitialData);
  }, []);

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <MindMapFlow />
      </div>
    </main>
  );
};

export default MindmapPage;
