import { FC } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyFormValues } from 'models';

import { Button, Textarea } from 'components/atoms';

type ProblemsScreenProps = {
  control: Control<SurveyFormValues>;
  isLoading: boolean;
};

const ProblemsScreen: FC<ProblemsScreenProps> = ({ control, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col items-center'>
      <h1
        className={
          'mb-[40px] text-[32px] leading-[48px] text-center ' +
          'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
        }
      >
        {t('Survey.haveYouHad')}
      </h1>

      <Textarea
        name='problems'
        placeholder='Type your message here'
        width='big'
        control={control}
        rules={{ required: { value: true, message: t('common.validation.requiredField') } }}
        containerClassName='mb-[44px]'
      />

      <Button
        type='submit'
        variant='primary'
        size='big'
        title={t('Survey.done')}
        isLoading={isLoading}
        className='min-w-[240px]'
      />
    </div>
  );
};

export default ProblemsScreen;
