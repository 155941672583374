import { useQuery } from 'react-query';

import { apiBoard } from 'api';
import { handleError } from 'helpers';

export const useRecentBoardsQuery = () => {
  return useQuery(['boards/recently-edited'], async () => await apiBoard.getRecentBoards(), {
    onError(error) {
      handleError(error);
    }
  });
};
