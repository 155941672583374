import { FC } from 'react';
import { NodeProps } from 'reactflow';

import { NodeData } from 'store';
import styles from './RoadmapEpicContainer.module.scss';

const RoadmapEpicContainer: FC<NodeProps<NodeData>> = ({ selected }) => {
  return (
    <div
      className={selected ? `${styles.container} ${styles.container_selected}` : styles.container}
    ></div>
  );
};

export default RoadmapEpicContainer;
