import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import LogoHorizontalSVG from 'assets/images/svg/logo-horizontal.svg';

import { ROUTES } from 'consts';
import styles from './HeaderDefault.module.scss';

import { Button, IconLogin } from 'components/atoms';

const HeaderDefault: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Link to={ROUTES.HOME} className={styles.logo}>
        <img src={LogoHorizontalSVG} alt='logo.svg' />
      </Link>

      <div className={styles.controls}>
        <Button
          title={t('common.form.login')}
          type='button'
          variant='secondary'
          size='small'
          icon={<IconLogin color='#19b829' />}
          iconPosition='left'
          onClick={() => navigate(ROUTES.LOGIN)}
          className={styles.button}
        />
        <Button
          title={t('common.form.signup')}
          type='button'
          variant='light'
          size='small'
          onClick={() => navigate(ROUTES.SIGNUP)}
          className={styles.button}
        />
      </div>
    </>
  );
};

export default HeaderDefault;
