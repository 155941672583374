import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconDOC: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='512'
      height='512'
      viewBox='0 0 512 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M137 488H429C445.569 488 459 474.569 459 458V127.5L355 24H137C120.431 24 107 37.4315 107 54V458C107 474.569 120.431 488 137 488Z'
        fill='#EDEDED'
      />
      <path d='M385 128H459L355 24V98C355 114.569 368.431 128 385 128Z' fill='#D9D9D9' />
      <path
        d='M53 100C53 88.9543 61.9543 80 73 80H247C258.046 80 267 88.9543 267 100V174C267 185.046 258.046 194 247 194H73C61.9543 194 53 185.046 53 174V100Z'
        fill='#FFCB33'
      />
      <rect x='53' y='174' width='54' height='40' fill='#FFCB33' />
      <path
        d='M183 361C183 355.477 187.477 351 193 351L371 351C376.523 351 381 355.477 381 361C381 366.523 376.523 371 371 371H193C187.477 371 183 366.523 183 361Z'
        fill='#6B7A99'
      />
      <path
        d='M183 401C183 395.477 187.477 391 193 391L371 391C376.523 391 381 395.477 381 401C381 406.523 376.523 411 371 411H193C187.477 411 183 406.523 183 401Z'
        fill='#6B7A99'
      />
      <path
        d='M303 241C303 235.477 307.477 231 313 231L371 231C376.523 231 381 235.477 381 241C381 246.523 376.523 251 371 251H313C307.477 251 303 246.523 303 241Z'
        fill='#6B7A99'
      />
      <path
        d='M303 281C303 275.477 307.477 271 313 271L371 271C376.523 271 381 275.477 381 281C381 286.523 376.523 291 371 291H313C307.477 291 303 286.523 303 281Z'
        fill='#6B7A99'
      />
      <path
        d='M303 321C303 315.477 307.477 311 313 311L371 311C376.523 311 381 315.477 381 321C381 326.523 376.523 331 371 331H313C307.477 331 303 326.523 303 321Z'
        fill='#6B7A99'
      />
      <path
        d='M53 214C53 202.954 61.9543 194 73 194H107V244H73C61.9543 244 53 235.046 53 224V214Z'
        fill='#EDB207'
      />
      <path
        d='M109.305 164H97.1562L97.2344 156.227H109.305C112.82 156.227 115.763 155.458 118.133 153.922C120.529 152.385 122.326 150.185 123.523 147.32C124.747 144.456 125.359 141.044 125.359 137.086V134C125.359 130.927 125.008 128.206 124.305 125.836C123.628 123.466 122.612 121.474 121.258 119.859C119.93 118.245 118.289 117.021 116.336 116.188C114.409 115.354 112.182 114.938 109.656 114.938H96.9219V107.125H109.656C113.432 107.125 116.883 107.763 120.008 109.039C123.133 110.289 125.828 112.099 128.094 114.469C130.385 116.839 132.143 119.677 133.367 122.984C134.591 126.292 135.203 129.99 135.203 134.078V137.086C135.203 141.174 134.591 144.872 133.367 148.18C132.143 151.487 130.385 154.326 128.094 156.695C125.802 159.039 123.068 160.849 119.891 162.125C116.74 163.375 113.211 164 109.305 164ZM102.586 107.125V164H92.7812V107.125H102.586Z'
        fill='white'
      />
      <path
        d='M191.198 134V137.125C191.198 141.422 190.639 145.276 189.519 148.688C188.399 152.099 186.797 155.003 184.714 157.398C182.657 159.794 180.183 161.63 177.292 162.906C174.402 164.156 171.198 164.781 167.683 164.781C164.193 164.781 161.003 164.156 158.113 162.906C155.248 161.63 152.761 159.794 150.652 157.398C148.542 155.003 146.902 152.099 145.73 148.688C144.584 145.276 144.011 141.422 144.011 137.125V134C144.011 129.703 144.584 125.862 145.73 122.477C146.876 119.065 148.49 116.161 150.573 113.766C152.683 111.344 155.17 109.508 158.034 108.258C160.925 106.982 164.115 106.344 167.605 106.344C171.12 106.344 174.323 106.982 177.214 108.258C180.105 109.508 182.592 111.344 184.675 113.766C186.758 116.161 188.36 119.065 189.48 122.477C190.626 125.862 191.198 129.703 191.198 134ZM181.394 137.125V133.922C181.394 130.745 181.081 127.945 180.456 125.523C179.857 123.076 178.959 121.031 177.761 119.391C176.589 117.724 175.144 116.474 173.425 115.641C171.706 114.781 169.766 114.352 167.605 114.352C165.443 114.352 163.516 114.781 161.823 115.641C160.131 116.474 158.685 117.724 157.488 119.391C156.316 121.031 155.417 123.076 154.792 125.523C154.167 127.945 153.855 130.745 153.855 133.922V137.125C153.855 140.302 154.167 143.115 154.792 145.562C155.417 148.01 156.329 150.081 157.527 151.773C158.751 153.44 160.209 154.703 161.902 155.562C163.594 156.396 165.521 156.812 167.683 156.812C169.87 156.812 171.81 156.396 173.503 155.562C175.196 154.703 176.628 153.44 177.8 151.773C178.972 150.081 179.857 148.01 180.456 145.562C181.081 143.115 181.394 140.302 181.394 137.125Z'
        fill='white'
      />
      <path
        d='M235.397 145.484H245.163C244.85 149.208 243.808 152.529 242.038 155.445C240.267 158.336 237.78 160.615 234.577 162.281C231.373 163.948 227.48 164.781 222.897 164.781C219.381 164.781 216.217 164.156 213.405 162.906C210.592 161.63 208.183 159.833 206.178 157.516C204.173 155.172 202.636 152.346 201.569 149.039C200.527 145.732 200.006 142.034 200.006 137.945V133.219C200.006 129.13 200.54 125.432 201.608 122.125C202.702 118.818 204.264 115.992 206.295 113.648C208.327 111.279 210.761 109.469 213.6 108.219C216.465 106.969 219.681 106.344 223.248 106.344C227.78 106.344 231.608 107.177 234.733 108.844C237.858 110.51 240.28 112.815 241.998 115.758C243.743 118.701 244.811 122.073 245.202 125.875H235.436C235.176 123.427 234.603 121.331 233.717 119.586C232.858 117.841 231.582 116.513 229.889 115.602C228.196 114.664 225.983 114.195 223.248 114.195C221.009 114.195 219.056 114.612 217.389 115.445C215.722 116.279 214.329 117.503 213.209 119.117C212.09 120.732 211.243 122.724 210.67 125.094C210.123 127.438 209.85 130.12 209.85 133.141V137.945C209.85 140.81 210.097 143.414 210.592 145.758C211.113 148.076 211.894 150.068 212.936 151.734C214.004 153.401 215.358 154.69 216.998 155.602C218.639 156.513 220.605 156.969 222.897 156.969C225.683 156.969 227.936 156.526 229.655 155.641C231.399 154.755 232.715 153.466 233.6 151.773C234.511 150.055 235.11 147.958 235.397 145.484Z'
        fill='white'
      />
      <path
        d='M183 240C183 234.477 187.477 230 193 230H274C279.523 230 284 234.477 284 240V321C284 326.523 279.523 331 274 331H193C187.477 331 183 326.523 183 321V240Z'
        fill='#6B7A99'
      />
    </svg>
  );
};
