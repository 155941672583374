import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { UpdateTeamDto, apiTeam } from 'api';
import { handleError, notifySuc } from 'helpers';

export const useUpdateTeamMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateTeamDto) => {
      return apiTeam.updateTeam(data);
    },
    {
      async onSuccess(data, variables) {
        notifySuc(t('common.notifications.teamUpdated'));
        await queryClient.invalidateQueries([`teams/one/${variables.teamId}`]);
        await queryClient.invalidateQueries(['teams/my']);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
