import { Dispatch, FC, SetStateAction } from 'react';
import { Control, Controller, UseFormGetValues, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyFormValues, SurveyScreens } from 'models';
import { satisfactionOptions } from './const';

import { Button } from 'components/atoms';

type SatisfactionScreenProps = {
  control: Control<SurveyFormValues>;
  getValues: UseFormGetValues<SurveyFormValues>;
  trigger: UseFormTrigger<SurveyFormValues>;
  setCurrentScreen: Dispatch<SetStateAction<SurveyScreens>>;
};

const SatisfactionScreen: FC<SatisfactionScreenProps> = ({
  control,
  getValues,
  trigger,
  setCurrentScreen
}) => {
  const { t } = useTranslation();

  const handleNext = async () => {
    const isValid = await trigger('satisfaction');
    if (!isValid) return;

    const satisfaction = getValues('satisfaction');

    switch (satisfaction) {
      case 1:
      case 2:
        setCurrentScreen(SurveyScreens.PROBLEMS);
        return;
      case 3:
        setCurrentScreen(SurveyScreens.SUGGESTIONS);
        return;
      case 4:
      case 5:
        setCurrentScreen(SurveyScreens.VALUABLE_FEATURES);
        return;
      default:
        setCurrentScreen(SurveyScreens.VALUABLE_FEATURES);
        return;
    }
  };

  const handleSkip = () => {
    setCurrentScreen(SurveyScreens.VALUABLE_FEATURES);
  };

  return (
    <div className='flex flex-col items-center'>
      <h1
        className={
          'mb-[40px] w-[300px] text-[32px] leading-[48px] text-center ' +
          'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
        }
      >
        {t('Survey.howSatisfied')}
      </h1>

      <Controller
        name='satisfaction'
        control={control}
        rules={{ required: { value: true, message: t('common.validation.requiredField') } }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <div className='flex flex-col gap-[8px] mb-[44px]'>
            <ul className='flex gap-[8px]'>
              {satisfactionOptions.map(({ value: option, Icon }) => (
                <li key={option} className='flex'>
                  <input
                    id={`satisfaction-option-${option}`}
                    type='radio'
                    value={value}
                    checked={option === value}
                    onChange={() => onChange(option)}
                    className='hidden'
                  />
                  <label
                    htmlFor={`satisfaction-option-${option}`}
                    className='h-[68px] w-[68px] p-[8px] cursor-pointer'
                  >
                    <Icon
                      className={
                        'h-[52px] w-[52px] transition-colors duration-200 ' +
                        `${
                          option === value
                            ? 'text-[var(--color-primary)]'
                            : 'text-[var(--color-gray)]'
                        }`
                      }
                    />
                  </label>
                </li>
              ))}
            </ul>

            {error && (
              <span
                className={
                  'block mt-[3px] ml-[10px] font-[300] text-[16px] ' +
                  'leading-[24px] tracking-[0.01em] text-[#f01c35]'
                }
              >
                {error.message}
              </span>
            )}
          </div>
        )}
      />

      <Button
        variant='primary'
        size='big'
        title={t('Survey.next')}
        onClick={handleNext}
        className='mb-[12px] min-w-[240px]'
      />
      <Button
        variant='secondary'
        size='big'
        title={t('Survey.skip')}
        onClick={handleSkip}
        className='min-w-[240px]'
      />
    </div>
  );
};

export default SatisfactionScreen;
