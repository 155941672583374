import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TeamBoard } from 'api';
import { ROUTES } from 'consts';
import { useBoardColor, useBoardIcon, useBoardLabel } from 'hooks';
import styles from './BoardCard.module.scss';

interface BoardCardProps {
  board: TeamBoard;
}

const BoardCard: FC<BoardCardProps> = ({ board }) => {
  const navigate = useNavigate();
  const color = useBoardColor(board.boardType);
  const Icon = useBoardIcon(board.boardType);
  const label = useBoardLabel(board.boardType);
  const { teamId } = useParams<{ teamId: string }>() as { teamId: string };
  const goToBoard = () =>
    navigate(
      `${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${board.projectId}/${ROUTES.BOARDS}/${board.boardId}`
    );

  return (
    <button className={styles.container} style={{ borderBottomColor: color }} onClick={goToBoard}>
      <header className={styles.header}>
        <span className={styles['project-name']} title={board.projectName}>
          {board.projectName}
        </span>
        <div className={styles['board-type']} style={{ color: color }}>
          <Icon className={styles['board-type__icon']} />
          <span className={styles['board-type__label']}>{label}</span>
        </div>
      </header>

      {/* add prompt text when backend is ready */}
      <p className={styles['board-name']} title={board.boardName}>
        {board.boardName}
      </p>
      <p className={styles['board-prompt']}>{label} Root node prompt</p>

      {/* add users list when backend is ready */}
      {/* <div className={styles['board-users']}></div> */}
      <p className={styles['board-last-change']}>Users: unknown</p>

      {/* add change date when backend is ready */}
      <p className={styles['board-last-change']}>Last change: unknown</p>
    </button>
  );
};

export default BoardCard;
