import { FC, ReactNode } from 'react';

import styles from './DeleteSmthForm.module.scss';

import { Button } from 'components/atoms';

interface DeleteSmthFormProps {
  entity: string;
  warning?: ReactNode;
  onDelete: () => Promise<void>;
  isLoading?: boolean;
  onCancel?: () => void;
}

const DeleteSmthForm: FC<DeleteSmthFormProps> = ({
  entity,
  warning,
  onDelete,
  isLoading,
  onCancel
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h2 className={styles.title}>Delete {entity}</h2>

        {warning ? (
          warning
        ) : (
          <h3 className={styles.subtitle}>
            You want to delete {entity} permanently, are you sure?
          </h3>
        )}

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            size='big'
            wide
            title='Cancel'
            onClick={onCancel}
            isDisabled={isLoading}
            className={styles['button-cancel']}
          />
          <Button
            type='button'
            variant='warning'
            size='big'
            wide
            title={`Delete ${entity}`}
            onClick={onDelete}
            isLoading={isLoading}
            className={styles['button-submit']}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteSmthForm;
