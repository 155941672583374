import { useMemo } from 'react';

import { NODE_SIZE } from 'store';
import styles from './useNodeSize.module.scss';

import { IconLarge, IconMiddle, IconSmall } from 'components/atoms';

export const useNodeSize = (size: NODE_SIZE) => {
  const sizeIcon = useMemo(() => {
    switch (size) {
      case NODE_SIZE.SMALL:
        return <IconSmall />;
      case NODE_SIZE.MIDDLE:
        return <IconMiddle />;
      case NODE_SIZE.LARGE:
        return <IconLarge />;
    }
  }, [size]);

  const sizeLabel = useMemo(() => {
    switch (size) {
      case NODE_SIZE.SMALL:
        return 'Small';
      case NODE_SIZE.MIDDLE:
        return 'Middle';
      case NODE_SIZE.LARGE:
        return 'Large';
    }
  }, [size]);

  const sizeClass = useMemo(() => {
    switch (size) {
      case NODE_SIZE.SMALL:
        return styles.small;
      case NODE_SIZE.MIDDLE:
        return styles.middle;
      case NODE_SIZE.LARGE:
        return styles.large;
    }
  }, [size]);

  const minHeight = useMemo(() => {
    switch (size) {
      case NODE_SIZE.SMALL:
        return 44;
      case NODE_SIZE.MIDDLE:
        return 54;
      case NODE_SIZE.LARGE:
        return 68;
    }
  }, [size]);

  return { sizeIcon, sizeLabel, sizeClass, minHeight };
};
