import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { Handle, NodeProps, NodeToolbar, Position, useReactFlow } from 'reactflow';
import { shallow } from 'zustand/shallow';

import { RF_FIT_VIEW_DURATION, RF_FIT_VIEW_TIMEOUT, RF_FIT_VIEW_ZOOM_MAXIMUM } from 'consts';
import { notifyWarn } from 'helpers';
import { useSocket } from 'hooks';
import { useGenerateNodesMutation } from 'hooks/mutations';
import { NodeData, RFState, useRFStore } from 'store';
import { DEFAULT_ROADMAP_EPIC_COLOR } from '../../../const';
import { useRoadmap } from '../../../useRoadmap';
import styles from './RoadmapEpicNode.module.scss';

import { IconKnowledge, IconLoader, IconMagicWand, IconPlusBold } from 'components/atoms';
import { Contextbar } from 'components/organisms/flows/common';

const selector = (state: RFState) => ({
  currentEdgeType: state.currentEdgeType,
  setData: state.setData,
  updateNodeValue: state.updateNodeValue
});

const RoadmapEpicNode: FC<NodeProps<NodeData>> = ({ id, data, selected, dragging }) => {
  const { boardId } = useParams<keyof { boardId: string }>() as { boardId: string };
  const { updateNodeById } = useSocket(Number(boardId));
  const { fitView } = useReactFlow();
  const documentCount = useMemo(() => data.linkedDocuments?.length, [data.linkedDocuments]);
  const [inputActive, setInputActive] = useState<boolean>(false);
  const { handleAddEpic, handleAddTaskContainer } = useRoadmap(Number(boardId));
  const { currentEdgeType, setData, updateNodeValue } = useRFStore(selector, shallow);

  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.value]);

  const handleChange = ({
    target: { selectionStart, value }
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setCursor(selectionStart);
    updateNodeValue(id, value);
    updateNodeById({ boardId: Number(boardId), nodeId: id, data: { value } });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleGenerate();
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.detail === 2) {
      setInputActive(true);
      setTimeout(() => ref.current?.focus());
    }
  };

  const { mutate: generateTaskNodes, isLoading: generateDataLoading } = useGenerateNodesMutation();

  const handleGenerate = async () => {
    if (!data.value?.length) {
      notifyWarn('Please enter some value in the node');
      return;
    }

    generateTaskNodes(
      { boardId: Number(boardId), nodeId: id },
      {
        onSuccess: data => {
          const parsedData = JSON.parse(data.schema);
          setData(parsedData);
          setTimeout(
            () => fitView({ maxZoom: RF_FIT_VIEW_ZOOM_MAXIMUM, duration: RF_FIT_VIEW_DURATION }),
            RF_FIT_VIEW_TIMEOUT
          );
        }
      }
    );
  };

  return (
    <>
      <Contextbar
        nodeId={id}
        isVisible={!dragging && data.toolbar?.visible}
        position={Position.Top}
        offset={30}
      />

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Top} offset={-13}>
        <button
          className={styles.buttonAddEpic}
          disabled={generateDataLoading}
          onClick={() => handleAddEpic(id, 'top')}
        >
          <IconPlusBold />
        </button>
      </NodeToolbar>

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Bottom} offset={-13}>
        <button
          className={styles.buttonAddEpic}
          disabled={generateDataLoading}
          onClick={() => handleAddEpic(id, 'bottom')}
        >
          <IconPlusBold />
        </button>
      </NodeToolbar>

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Right} offset={11}>
        <div className={styles['toolbar-right']}>
          <button
            className={styles.buttonAddTaskContainer}
            disabled={generateDataLoading}
            onClick={() => handleAddTaskContainer(id, 'top')}
          >
            <IconPlusBold />
          </button>

          <button
            className={styles.buttonGenerate}
            disabled={generateDataLoading}
            onClick={handleGenerate}
          >
            {generateDataLoading ? <IconLoader className={styles.spin} /> : <IconMagicWand />}
          </button>

          <button
            className={styles.buttonAddTaskContainer}
            disabled={generateDataLoading}
            onClick={() => handleAddTaskContainer(id, 'bottom')}
          >
            <IconPlusBold />
          </button>
        </div>
      </NodeToolbar>

      <Handle
        type='target'
        position={Position.Left}
        id='d'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />
      <Handle
        type='source'
        position={Position.Right}
        id='b'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />

      <button
        className={
          `${styles.container} ` +
          `${selected ? styles.container_active : ''} ` +
          `${inputActive ? styles.container_double : ''}`
        }
        style={{
          background: data.color || DEFAULT_ROADMAP_EPIC_COLOR,
          borderColor: data.color || DEFAULT_ROADMAP_EPIC_COLOR
        }}
        disabled={generateDataLoading}
        onClick={handleClick}
      >
        <form
          className={styles.form}
          style={{
            maxWidth: 308 - (!inputActive && !!documentCount ? 34 : 0)
          }}
          onSubmit={e => {
            e.preventDefault();
            handleGenerate();
          }}
        >
          {inputActive ? (
            <textarea
              ref={ref}
              id={`roadmap-epic-${id}`}
              name='roadmap-epic-input'
              placeholder='Please enter epic'
              title={data.value || 'Please enter epic'}
              value={data.value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onBlur={() => setInputActive(false)}
              className={`${styles.textarea} nodrag`}
              disabled={generateDataLoading}
            />
          ) : (
            <span title={data.value || 'Please enter epic'} className={styles.text}>
              {data.value || 'Please enter epic'}
            </span>
          )}
        </form>

        {!inputActive && !!documentCount && (
          <div className={styles.documents}>
            <IconKnowledge className={styles.documents__icon} />
            <span className={styles.documents__badge}>{documentCount}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default RoadmapEpicNode;
