import axios from 'axios';

import { ERROR_MESSAGES } from 'consts';
import { notifyErr } from './notification';

export const handleError = (error: unknown) => {
  if (axios.isAxiosError(error) && error.response) {
    const errorMessage = error.response.data.message;
    notifyErr(errorMessage);
  } else {
    notifyErr(ERROR_MESSAGES.DEFAULT);
  }
};
