import { ChangeEventHandler, Dispatch, FC, SetStateAction } from 'react';

import { IconMinus, IconPlus } from 'components/atoms/icons';

type CounterProps = {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  min?: number;
};

const Counter: FC<CounterProps> = ({ value, setValue, min = 1 }) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value;
    const valueAsNumber = Number(value);

    if (Number.isNaN(valueAsNumber)) return;
    setValue(valueAsNumber);
  };

  const handleDecrement = () => {
    setValue(prev => {
      if (prev === 0) return min;
      if (prev === min) return min;
      if (prev < min) return Math.abs(prev) - 1;
      return prev - 1;
    });
  };

  const handleIncrement = () => {
    setValue(prev => {
      if (prev < min) return Math.abs(prev) + 1;
      return prev + 1;
    });
  };

  return (
    <div className='flex gap-[4px] items-center'>
      <button
        type='button'
        onClick={handleDecrement}
        className='h-[24px] w-[24px] bg-transparent text-[#2D3C59]'
      >
        <IconMinus />
      </button>

      <input
        type='number'
        min={min}
        value={value}
        onChange={handleChange}
        className={
          'h-[38px] w-[64px] p-[4px] bg-[#FFFFFF] text-center ' +
          'rounded-[8px] border-[1px] border-solid border-[#6B7A99] ' +
          'text-[18px] font-[400] leading-[30px] tracking-[0.18px] text-[#2D3C59]'
        }
      />

      <button
        type='button'
        onClick={handleIncrement}
        className='h-[24px] w-[24px] bg-transparent text-[#2D3C59]'
      >
        <IconPlus />
      </button>
    </div>
  );
};

export default Counter;
