import { useQuery } from 'react-query';

import { apiProject } from 'api';
import { handleError } from 'helpers';

export const useProject = (projectId: number) => {
  return useQuery([`projects/${projectId}`], async () => await apiProject.getProject(projectId), {
    onError(error) {
      handleError(error);
    }
  });
};
