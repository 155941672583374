import { Product, Project, User } from 'models';

export enum TEAM_ROLES {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
  OWNER = 'OWNER'
}

export interface Member extends User {
  userRole: { role: TEAM_ROLES };
}

export interface Team {
  id: number;
  name: string;
  owner?: User;
  members?: Member[];
  projects?: Project[];
  product?: Product;
  subscriber?: User;
  avatarUrl?: string;
}

export type UpdateTeamFormValues = {
  image?: File;
  teamName: string;
};
