import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { DeleteProjectDto, apiProject } from 'api';
import { handleError, notifySuc } from 'helpers';
import { useInvalidate } from '../useInvalidate';

interface Params {
  teamId: number;
  onCancel?: () => void;
  onSuccess?: () => void | Promise<void>;
}

export const useDeleteProjectMutation = ({ teamId, onCancel, onSuccess }: Params) => {
  const { t } = useTranslation();
  const invalidate = useInvalidate();

  return useMutation(
    (data: DeleteProjectDto) => {
      return apiProject.deleteProject(data);
    },
    {
      async onSuccess() {
        notifySuc(t('common.notifications.projectDeleted'));
        await invalidate(`teams/one/${teamId}`);

        onCancel && onCancel();
        onSuccess && onSuccess();
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
