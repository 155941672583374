import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { SurveyScreens } from 'models';

import { Button } from 'components/atoms';

type StartScreenProps = {
  setCurrentScreen: Dispatch<SetStateAction<SurveyScreens>>;
  onClose?: () => void;
};

const StartScreen: FC<StartScreenProps> = ({ setCurrentScreen, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col items-center'>
      <h1
        className={
          'mb-[16px] w-[490px] text-[32px] leading-[48px] text-center ' +
          'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
        }
      >
        {t('Survey.pleaseAnswer')}
      </h1>
      <p className='text-[21px] leading-[32px] font-[400] text-[var(--color-dark)] mb-[40px]'>
        {t('Survey.itWillHelp')}
      </p>

      <Button
        variant='primary'
        size='big'
        title={t('common.ok')}
        onClick={() => setCurrentScreen(SurveyScreens.SATISFACTION)}
        className='mb-[8px] uppercase min-w-[240px]'
      />
      <Button
        variant='secondary'
        size='big'
        title={t('Survey.mayBeLater')}
        onClick={onClose}
        className='mb-[32px] min-w-[240px]'
      />
    </div>
  );
};

export default StartScreen;
