import { Edge, MarkerType, Node, Position } from 'reactflow';
import { v4 as uuid } from 'uuid';

import { EDGE_COLOR, EdgeData, NodeData } from 'store';
import { EDGE_TYPES, NODE_TYPES } from './react-flow';

const nodeId1 = uuid();
const nodeId2 = uuid();
const nodeId3 = uuid();
const nodeId4 = uuid();
const nodeId5 = uuid();
const nodeId6 = uuid();
const nodeId7 = uuid();
const nodeId8 = uuid();
const nodeId9 = uuid();

const edgeId1 = uuid();
const edgeId2 = uuid();
const edgeId3 = uuid();
const edgeId4 = uuid();
const edgeId5 = uuid();
const edgeId6 = uuid();
const edgeId7 = uuid();
const edgeId8 = uuid();
const edgeId9 = uuid();
const edgeId10 = uuid();

export const flowchartInitialData: {
  nodes: Node<NodeData>[];
  edges: Edge<EdgeData>[];
} = {
  nodes: [
    {
      id: nodeId1,
      type: NODE_TYPES.FLOWCHART_ROOT,
      data: {
        value: 'Start',
        toolbar: { position: Position.Right }
      },
      position: { x: 0, y: 0 }
    },
    {
      id: nodeId2,
      type: NODE_TYPES.FLOWCHART_QUESTION,
      data: {
        value: 'Registered',
        toolbar: { position: Position.Right }
      },
      position: { x: 0, y: 300 }
    },
    {
      id: nodeId3,
      type: NODE_TYPES.FLOWCHART_DEFAULT,
      data: {
        value: 'New user',
        toolbar: { position: Position.Right }
      },
      position: { x: 400, y: 300 }
    },
    {
      id: nodeId4,
      type: NODE_TYPES.FLOWCHART_DEFAULT,
      data: {
        value: 'Login',
        toolbar: { position: Position.Right }
      },
      position: { x: 0, y: 600 }
    },
    {
      id: nodeId5,
      type: NODE_TYPES.FLOWCHART_DEFAULT,
      data: {
        value: 'Record on database',
        toolbar: { position: Position.Right }
      },
      position: { x: 1000, y: 300 }
    },
    {
      id: nodeId6,
      type: NODE_TYPES.FLOWCHART_END,
      data: {
        value: 'End',
        toolbar: { position: Position.Right }
      },
      position: { x: 400, y: 1000 }
    },
    {
      id: nodeId7,
      type: NODE_TYPES.FLOWCHART_QUESTION,
      data: {
        value: 'Forget password',
        toolbar: { position: Position.Right }
      },
      position: { x: 400, y: 600 }
    },
    {
      id: nodeId8,
      type: NODE_TYPES.FLOWCHART_DEFAULT,
      data: {
        value: 'New password',
        toolbar: { position: Position.Right }
      },
      position: { x: 1000, y: 600 }
    },
    {
      id: nodeId9,
      type: NODE_TYPES.FLOWCHART_QUESTION,
      data: {
        value: 'Correct password',
        toolbar: { position: Position.Right }
      },
      position: { x: 400, y: 800 }
    }
  ],
  edges: [
    {
      id: edgeId1,
      source: nodeId1,
      sourceHandle: 'b',
      target: nodeId2,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    },
    {
      id: edgeId2,
      source: nodeId2,
      sourceHandle: 'b',
      target: nodeId3,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    },
    {
      id: edgeId3,
      source: nodeId2,
      sourceHandle: 'b',
      target: nodeId4,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    },
    {
      id: edgeId3,
      source: nodeId3,
      sourceHandle: 'b',
      target: nodeId5,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    },
    {
      id: edgeId4,
      source: nodeId4,
      sourceHandle: 'b',
      target: nodeId7,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    },
    {
      id: edgeId5,
      source: nodeId7,
      sourceHandle: 'b',
      target: nodeId5,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    },
    {
      id: edgeId6,
      source: nodeId5,
      sourceHandle: 'b',
      target: nodeId8,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    },
    {
      id: edgeId7,
      source: nodeId7,
      sourceHandle: 'b',
      target: nodeId9,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    },
    {
      id: edgeId8,
      source: nodeId8,
      sourceHandle: 'b',
      target: nodeId9,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    },
    {
      id: edgeId9,
      source: nodeId9,
      sourceHandle: 'b',
      target: nodeId6,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    },
    {
      id: edgeId10,
      source: nodeId9,
      sourceHandle: 'b',
      target: nodeId4,
      targetHandle: 'd',
      type: EDGE_TYPES.FLOWCHART,
      markerEnd: {
        type: MarkerType.Arrow
      },
      data: {
        color: '#6B7A99' as EDGE_COLOR,
        toolbar: { position: Position.Right }
      }
    }
  ]
};
