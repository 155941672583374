import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconWBS: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.5 3H15.5V7.5H8.5V3ZM3 16.5H10V21H3V16.5ZM14 16.5H21V21H14V16.5Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M12 8V12M6.5 16.5V12H17.5V16.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
