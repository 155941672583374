import { FC } from 'react';

type ToggleProps = {
  isChecked: boolean;
  onChange: (value: boolean) => void;
};

const Toggle: FC<ToggleProps> = ({ isChecked, onChange }) => {
  const handleCheckboxChange = () => {
    onChange(!isChecked);
  };

  return (
    <>
      <label className='autoSaverSwitch relative inline-flex cursor-pointer select-none items-center'>
        <input
          type='checkbox'
          name='autoSaver'
          className='sr-only'
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span
          className={`slider flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200 transition ${
            isChecked ? 'bg-[#19B82933]' : 'bg-[#CCCCCE]'
          }`}
        >
          <span
            className={`dot h-[18px] w-[18px] rounded-full duration-200 transition ${
              isChecked ? 'translate-x-6 bg-[#19B829]' : 'bg-white'
            }`}
          ></span>
        </span>
      </label>
    </>
  );
};

export default Toggle;
