import { Team } from 'models';
import { create } from 'zustand';

export type TeamState = {
  team: Team | null;
  setTeam: (team: Team | null) => void;
};

export const useTeamStore = create<TeamState>(set => ({
  team: null,
  setTeam: (team: Team | null) => {
    set({ team });
  }
}));
