import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { DEFAULT_ANIMATION_DELAY } from 'consts';
import styles from './Modal.module.scss';

import { IconCross, Portal } from 'components/atoms';

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  lazy?: boolean;
  className?: string;
}

const Modal: FC<IModalProps> = ({ isOpen, onClose, children, lazy = true, className }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    timerRef.current = setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, DEFAULT_ANIMATION_DELAY);
  }, [onClose]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      clearTimeout(timerRef.current);
    };
  }, [isOpen, onKeyDown]);

  if (lazy && !isMounted) {
    return null;
  }

  return (
    <Portal>
      <div
        className={
          `${styles.container} ` +
          `${isClosing ? styles.closing : ''} ` +
          `${isOpen ? styles.opened : ''}`
        }
      >
        <div className={styles.backdrop} onClick={handleClose}>
          <div
            className={`${styles.content} ` + className}
            onClick={event => event.stopPropagation()}
          >
            <button className={styles['button-delete']} onClick={handleClose}>
              <IconCross />
            </button>
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
