import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { UploadAvatarDto, apiUser } from 'api';
import { handleError, notifySuc } from 'helpers';
import { User } from 'models';

export const useUploadAvatarMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (data: UploadAvatarDto) => {
      return apiUser.uploadAvatar(data);
    },
    {
      async onSuccess(data) {
        notifySuc(t('common.notifications.avatarUploaded'));

        const profileQuery = 'users/me';

        await queryClient.invalidateQueries([profileQuery], {
          refetchActive: false,
          refetchInactive: false
        });

        const oldData = queryClient.getQueryData<User>([profileQuery]);
        if (!oldData) return;

        const updateData = { ...oldData, avatarUrl: data.avatarUrl };
        queryClient.setQueryData([profileQuery], updateData);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
