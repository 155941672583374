import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionPlan } from 'api/stripe';
import { DEFAULT_EDITORS_ALLOWED, MONTHS_IN_YEAR } from 'consts';
import { useFeatures, useToggle } from 'hooks';
import { useCreateCheckoutSessionMutation, useUpdateSubscriptionMutation } from 'hooks/stripe';
import { Team } from 'models/Team';
import { centToDollar, roundNumber } from 'utils';

import { Counter, IconCheck, Modal } from 'components/atoms';
import { CommonUnscubscribedModal } from 'components/organisms';

type TeamPlusProps = {
  team: Team | undefined;
  isSubscribed: boolean;
  unsubscribeLoading: boolean;
  unsubscribe: () => Promise<void>;
};

const TeamPlus: FC<TeamPlusProps> = ({ team, isSubscribed, unsubscribeLoading, unsubscribe }) => {
  const { t } = useTranslation();
  const { featuresTeamPlus } = useFeatures();
  const { mutate: createCheckoutSession, isLoading: createCheckoutLoading } =
    useCreateCheckoutSessionMutation();
  const { mutate: updateSubscriptionMutate, isLoading: updateSubscriptionLoading } =
    useUpdateSubscriptionMutation();

  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlan>(
    SubscriptionPlan.MONTH
  );

  const [numberCustomer, setNumberCustomer] = useState<number>(
    team?.product?.numberEditorsAllowed || DEFAULT_EDITORS_ALLOWED
  );
  useEffect(
    () => setNumberCustomer(team?.product?.numberEditorsAllowed || DEFAULT_EDITORS_ALLOWED),
    [team?.product?.numberEditorsAllowed]
  );

  const yearDiscount =
    team?.product?.personalYearDiscount || team?.product?.defaultYearDiscount || 40;

  const costPerUser = useMemo<number>(() => {
    let result = centToDollar(
      team?.product?.personalPricePerUser || team?.product?.defaultPricePerUser || 800
    );

    if (subscriptionPlan === SubscriptionPlan.YEAR) {
      result = result - (result / 100) * yearDiscount;
    }
    return result;
  }, [team, yearDiscount, subscriptionPlan]);

  const totalPrice = useMemo<number>(() => {
    let result = numberCustomer * costPerUser;

    if (subscriptionPlan === SubscriptionPlan.YEAR) {
      result = result * MONTHS_IN_YEAR;
    }
    return result;
  }, [numberCustomer, costPerUser, yearDiscount, subscriptionPlan]);

  const handleSubscribe = async () => {
    if (!team || !team.product) return;
    if (numberCustomer <= 0) return;

    if (isSubscribed) {
      updateSubscriptionMutate({
        teamId: team.id,
        numberCustomer,
        subscriptionPlan
      });
    } else {
      createCheckoutSession(
        { teamId: team.id, numberCustomer, subscriptionPlan },
        {
          onSuccess: data => {
            window.open(data.url, '_self');
          }
        }
      );
    }
  };

  const [isUnsubscribedModalOpen, unsubscribedModalToggleOpen] = useToggle(false);

  return (
    <div
      className={
        'flex flex-col rounded-[10px] max-w-[323px] w-full bg-[#FFFFFF] ' +
        `${
          isSubscribed
            ? 'shadow-[0_4px_12px_0_rgba(0,0,0,0.09)]'
            : 'shadow-[0_20px_36px_0_rgba(0,0,0,0.09)] border-[1px] border-solid border-[#19B829]'
        }`
      }
    >
      <header className='flex flex-col p-[20px] gap-[24px] items-start'>
        <h3
          className={
            'flex gap-[8px] text-[18px] font-[700] leading-[24px] ' +
            `${isSubscribed ? 'text-[#2D3C59]' : 'text-[#19B829]'}`
          }
        >
          {t('PricingAndPlans.plans.team+.title')}
          {isSubscribed && (
            <div
              className={
                'px-[4px] text-[16px] leading-[24px] font-[500] text-[#FFFFFF] ' +
                'bg-[#19B829] rounded-[4px]'
              }
            >
              {t('PricingAndPlans.currentPlan')}
            </div>
          )}
        </h3>
        <span className='h-[1px] w-full bg-[#C3CAD9]' />
        <h3 className='max-w-[240px] text-[18px] font-[700] leading-[26px] tracking-[0.18px] text-[#2D3C59]'>
          {t('PricingAndPlans.plans.team+.description')}
        </h3>
      </header>

      <div className='flex p-[20px] flex-col gap-[20px] h-[150px] bg-[#F9FAFC]'>
        <div className='flex border-[2px] border-solid border-[#C3CAD9] rounded-[28px] bg-[#F3F5F8] self-start'>
          <button
            type='button'
            className={
              'px-[22px] py-[6px] rounded-[22px] text-[16px] leading-[24px] font-[500] ' +
              'transition-all duration-300 ease-in-out select-none ' +
              `${
                subscriptionPlan === SubscriptionPlan.MONTH
                  ? 'bg-[#2D3C59] text-[#FFFFFF]'
                  : 'bg-transparent text-[#6B7A99]'
              }`
            }
            onClick={() => setSubscriptionPlan(SubscriptionPlan.MONTH)}
          >
            Month
          </button>
          <button
            type='button'
            className={
              'pl-[22px] pr-[18px] py-[6px] rounded-[22px] text-[16px] leading-[24px] font-[500] ' +
              'transition-all duration-300 ease-in-out flex items-center gap-[8px] select-none ' +
              `${
                subscriptionPlan === SubscriptionPlan.YEAR
                  ? 'bg-[#2D3C59] text-[#FFFFFF]'
                  : 'bg-transparent text-[#6B7A99]'
              }`
            }
            onClick={() => setSubscriptionPlan(SubscriptionPlan.YEAR)}
          >
            Year
            <span
              className={
                'h-[20px] px-[4px] py-[2px] rounded-[12px] bg-[#19B829] ' +
                'text-[12px] font-[500] leading-[16px] tracking-[0.12px] text-[#FFFFFF]'
              }
            >
              -40%
            </span>
          </button>
        </div>

        <div className='flex gap-[4px]'>
          <div className='flex flex-col items-center gap-[2px] shrink-0'>
            <Counter value={numberCustomer} setValue={setNumberCustomer} min={1} />
            <span className='text-[12px] font-[400] leading-[16px] tracking-[0.12px] text-[#2D3C59]'>
              Users
            </span>
          </div>

          <div className='flex flex-col flex-1 items-end'>
            <span className='text-[30px] font-[700] leading-[39px] tracking-[-0.9px] text-[#2D3C59]'>
              ${roundNumber(totalPrice, 1)}
            </span>
            <span className='text-[12px] font-[400] leading-[16px] tracking-[0.12px] text-[#2D3C59]'>
              ${costPerUser}
              {subscriptionPlan === SubscriptionPlan.YEAR ? '/month per user' : '/per user'}
            </span>
          </div>
        </div>
      </div>

      <div className='flex p-[20px] flex-col gap-[24px]'>
        {isSubscribed ? (
          <>
            {numberCustomer === team?.product?.numberEditorsAllowed ? (
              <button
                id='checkout-and-portal-button'
                type='submit'
                disabled={true}
                className={
                  'w-full flex justify-center items-center px-[12px] py-[8px] rounded-[8px] h-[56px] bg-[#F7F8FA] ' +
                  'text-[16px] leading-[24px] font-[500] text-[#2D3C59] shadow-[0_2px_6px_0_rgba(0,0,0,0.07)] cursor-auto'
                }
                onClick={handleSubscribe}
              >
                <>{t('PricingAndPlans.plans.team+.currentPlan')}</>
              </button>
            ) : (
              <button
                id='checkout-and-portal-button'
                type='submit'
                disabled={createCheckoutLoading || unsubscribeLoading || updateSubscriptionLoading}
                className={
                  'w-full flex justify-center items-center px-[12px] py-[8px] rounded-[8px] ' +
                  'h-[56px] bg-[#19B829] text-[16px] leading-[24px] font-[500] text-[#FFFFFF] ' +
                  'disabled:opacity-80 disabled:cursor-auto shadow-[0_10px_20px_0_rgba(37,189,51,0.20)] '
                }
                onClick={handleSubscribe}
              >
                {team?.product?.numberEditorsAllowed &&
                numberCustomer < team?.product?.numberEditorsAllowed ? (
                  <>{t('PricingAndPlans.plans.team+.downgrade')}</>
                ) : (
                  <>{t('PricingAndPlans.plans.team+.upgrade')}</>
                )}
              </button>
            )}
          </>
        ) : (
          <>
            <button
              id='checkout-and-portal-button'
              type='submit'
              disabled={createCheckoutLoading || unsubscribeLoading || updateSubscriptionLoading}
              className={
                'w-full flex justify-center items-center px-[12px] py-[8px] rounded-[8px] ' +
                'h-[56px] bg-[#19B829] text-[16px] leading-[24px] font-[500] text-[#FFFFFF] ' +
                'disabled:opacity-80 disabled:cursor-auto shadow-[0_10px_20px_0_rgba(37,189,51,0.20)] '
              }
              onClick={handleSubscribe}
            >
              {t('PricingAndPlans.plans.team+.upgrade')}
            </button>
          </>
        )}

        <div className='flex flex-col gap-[16px]'>
          <p className='text-[21px] leading-[32px] text-[#3D4E72]'>
            {t('PricingAndPlans.plans.team+.freeFeaturesPlus')}
          </p>
          {featuresTeamPlus.map((feature, index) => (
            <div key={index} className='flex gap-[12px] items-start'>
              <span className='h-[20px] w-[20px]'>
                <IconCheck color='#25BD33' />
              </span>
              <span className='text-[14px] font-[500] leading-[20px] text-[#6B7A99]'>
                {feature}
              </span>
            </div>
          ))}
        </div>

        {isSubscribed && (
          <button
            type='button'
            className={
              'w-full flex justify-center items-center px-[12px] py-[8px] rounded-[8px] h-[56px] bg-[#EDEFF2] ' +
              'text-[14px] leading-[20px] font-[500] text-[#6B7A99] tracking-[0.14px] shadow-[0_1px_2px_0_rgba(0,0,0,0.12)]'
            }
            disabled={unsubscribeLoading}
            onClick={unsubscribedModalToggleOpen}
          >
            {t('PricingAndPlans.unsubscribe')}
          </button>
        )}
      </div>

      <Modal
        isOpen={isUnsubscribedModalOpen}
        onClose={unsubscribedModalToggleOpen}
        className='!px-[30px]'
      >
        <CommonUnscubscribedModal
          onClose={unsubscribedModalToggleOpen}
          team={team}
          unsubscribe={unsubscribe}
        />
      </Modal>
    </div>
  );
};

export default TeamPlus;
