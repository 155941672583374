import { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Handle, NodeProps, NodeToolbar, Position, useReactFlow } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { shallow } from 'zustand/shallow';

import {
  DEFAULT_TIMEOUT,
  EDGE_TYPES,
  NODE_TYPES,
  RF_FIT_VIEW_DURATION,
  RF_FIT_VIEW_TIMEOUT,
  RF_FIT_VIEW_ZOOM_MAXIMUM
} from 'consts';
import { notifyWarn } from 'helpers';
import { useSocket } from 'hooks';
import { useGenerateNodesMutation } from 'hooks/mutations';
import { NodeData, RFState, useRFStore } from 'store';
import { normalizeNodes } from '../../../utils/normalizeNodes';
import styles from './MindMapNode.module.scss';

import {
  IconKnowledge,
  IconLoader,
  IconMagicWand,
  IconMindMap,
  IconPlusBold
} from 'components/atoms';
import { Contextbar } from 'components/organisms/flows/common';

const selector = (state: RFState) => ({
  currentEdgeType: state.currentEdgeType,
  nodes: state.nodes,
  setNodes: state.setNodes,
  setData: state.setData,
  updateNodeValue: state.updateNodeValue,
  selectNode: state.selectNode,
  addNode: state.addNode,
  addEdges: state.addEdges
});

type Params = { boardId: string };

const MindMapNode: FC<NodeProps<NodeData>> = ({ id, data, selected, dragging }) => {
  const { boardId } = useParams<keyof Params>() as Params;
  const { updateNodeById } = useSocket(Number(boardId));
  const { fitView } = useReactFlow();
  const isRootNode = useMemo(() => data.level === 0, [data.level]);
  const documentCount = useMemo(() => data.linkedDocuments?.length, [data.linkedDocuments]);
  const [inputActive, setInputActive] = useState<boolean>(false);
  const { currentEdgeType, nodes, setNodes, setData, updateNodeValue, selectNode, addEdges } =
    useRFStore(selector, shallow);

  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.value]);

  const handleTextareaChange = ({
    target: { scrollHeight, value, style, selectionStart }
  }: ChangeEvent<HTMLTextAreaElement>) => {
    style.height = '24px';
    style.height = `${scrollHeight}px`;

    setCursor(selectionStart);
    updateNodeValue(id, value);
    updateNodeById({ boardId: Number(boardId), nodeId: id, data: { value } });
  };

  const handleInputChange = ({
    target: { value, selectionStart }
  }: ChangeEvent<HTMLInputElement>) => {
    setCursor(selectionStart);
    updateNodeValue(id, value);
    updateNodeById({ boardId: Number(boardId), nodeId: id, data: { value } });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      ref.current?.blur();
      handleGenerate();
    }
  };

  const onDoubleClick = () => {
    setInputActive(true);
    setTimeout(() => {
      const textarea = ref.current;
      if (!textarea) return;
      textarea.focus();
      textarea.style.height = '24px';
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  };

  // when creating the board, focus on the root node for text entry
  useEffect(() => {
    if (!isRootNode || nodes.length > 1) return;

    selectNode(id);
    setTimeout(onDoubleClick, DEFAULT_TIMEOUT);
  }, []);

  const { mutate: generateMindmapNodes, isLoading: generateDataLoading } =
    useGenerateNodesMutation();

  const handleGenerate = async () => {
    if (!data.value?.length) {
      notifyWarn('Please enter some value in the node');
      return;
    }

    if (isRootNode) {
      generateMindmapNodes(
        { boardId: Number(boardId), title: data.value },
        {
          onSuccess: data => {
            const parsedData = JSON.parse(data.schema);
            setData(parsedData);
            setTimeout(
              () => fitView({ maxZoom: RF_FIT_VIEW_ZOOM_MAXIMUM, duration: RF_FIT_VIEW_DURATION }),
              RF_FIT_VIEW_TIMEOUT
            );
          }
        }
      );
    } else {
      generateMindmapNodes(
        { boardId: Number(boardId), nodeId: id },
        {
          onSuccess: data => {
            const parsedData = JSON.parse(data.schema);
            setData(parsedData);
            setTimeout(
              () => fitView({ maxZoom: RF_FIT_VIEW_ZOOM_MAXIMUM, duration: RF_FIT_VIEW_DURATION }),
              RF_FIT_VIEW_TIMEOUT
            );
          }
        }
      );
    }
  };

  const handleAddNode = (position: 'top' | 'bottom') => {
    const currentNode = nodes.find(node => node.id === id);
    if (!currentNode) return;

    // const closestToRootNode = findClosestToRootNode(currentNode);
    // if (!closestToRootNode) return;

    const rootNode = nodes.find(node => node.data.level === 0);
    if (!rootNode) return;

    const firstLevelNodes = nodes.filter(node => node.data.level === 1);
    const otherNodes = nodes.filter(node => node.data.level !== 1);

    const currentNodeIndex = firstLevelNodes.indexOf(currentNode);
    const newNodeIndex = position === 'top' ? currentNodeIndex : currentNodeIndex + 1;

    const newNodeId = uuid();
    const newNode = {
      id: newNodeId,
      type: NODE_TYPES.MINDMAP,
      data: {
        value: '',
        level: 1,
        toolbar: { position: Position.Right }
      },
      position: { x: currentNode.position.x, y: currentNode.position.y }
      // parentNode: rootNode.id
    };

    const newEdgeId = uuid();
    const newEdge = {
      id: newEdgeId,
      type: EDGE_TYPES.MINDMAP,
      source: rootNode.id,
      target: newNodeId,
      targetHandle: 'd',
      sourceHandle: 'b'
    };

    firstLevelNodes.splice(newNodeIndex, 0, newNode);
    const normalizedNodes = normalizeNodes([...firstLevelNodes, ...otherNodes]);

    setNodes(normalizedNodes);
    addEdges([newEdge]);
  };

  return (
    <>
      <Contextbar
        nodeId={id}
        isVisible={!dragging && data.toolbar?.visible}
        position={Position.Top}
        offset={30}
      />

      <NodeToolbar
        isVisible={data.level === 1 && data.toolbar?.visible}
        position={Position.Top}
        offset={-13}
      >
        <button
          className={styles.buttonAddNode}
          // disabled={generateDataLoading}
          onClick={() => handleAddNode('top')}
        >
          <IconPlusBold />
        </button>
      </NodeToolbar>

      <NodeToolbar
        isVisible={data.level === 1 && data.toolbar?.visible}
        position={Position.Bottom}
        offset={-13}
      >
        <button
          className={styles.buttonAddNode}
          // disabled={generateDataLoading}
          onClick={() => handleAddNode('bottom')}
        >
          <IconPlusBold />
        </button>
      </NodeToolbar>

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Right} offset={11}>
        <button
          className={styles.buttonGenerate}
          disabled={generateDataLoading}
          onClick={handleGenerate}
        >
          {generateDataLoading ? <IconLoader className={styles.spin} /> : <IconMagicWand />}
        </button>
      </NodeToolbar>

      <Handle
        type='target'
        position={Position.Top}
        id='a'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />
      <Handle
        type='source'
        position={Position.Right}
        id='b'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />
      <Handle
        type='source'
        position={Position.Bottom}
        id='c'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />
      <Handle
        type='target'
        position={Position.Left}
        id='d'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />

      <button
        type='button'
        className={
          `${styles.container} ` +
          `${isRootNode ? styles.container_root : ''} ` +
          `${selected ? styles.container_active : ''} ` +
          `${inputActive ? styles.container_double : ''} `
          // `${selectedDocument?.linkedNodes.includes(id) ? styles.container_active : ''}`
        }
        disabled={generateDataLoading}
        onDoubleClick={onDoubleClick}
      >
        {isRootNode && !inputActive && (
          <div className={styles.icon}>
            <IconMindMap />
          </div>
        )}

        <form
          className={styles.form}
          style={{
            maxWidth:
              308 -
              (!inputActive && isRootNode ? 34 : 0) -
              (!inputActive && !!documentCount ? 34 : 0)
          }}
          onSubmit={e => {
            e.preventDefault();
            handleGenerate();
          }}
        >
          {inputActive ? (
            <>
              {isRootNode ? (
                <textarea
                  ref={ref}
                  id={`mindmap-${id}`}
                  name='mindmap-textarea'
                  placeholder='Please enter the title'
                  title={data.value || 'Please enter the title'}
                  value={data.value}
                  onKeyDown={handleKeyDown}
                  onChange={handleTextareaChange}
                  onBlur={() => setInputActive(false)}
                  className={`${styles.textarea} nodrag`}
                  rows={1}
                  disabled={generateDataLoading}
                />
              ) : (
                <input
                  ref={ref}
                  id={`mindmap-${id}`}
                  type='text'
                  name='mindmap-input'
                  placeholder='Please enter the title'
                  title={data.value || 'Please enter the title'}
                  value={data.value}
                  onChange={handleInputChange}
                  onBlur={() => setInputActive(false)}
                  className={`${styles.input} nodrag`}
                  disabled={generateDataLoading}
                />
              )}
            </>
          ) : (
            <span
              title={data.value || 'Please enter the title'}
              className={isRootNode ? styles.text_root : styles.text}
            >
              {data.value || 'Please enter the title'}
            </span>
          )}
        </form>

        {!inputActive && !!documentCount && (
          <div className={styles.documents}>
            <IconKnowledge className={styles.documents__icon} />
            <span className={styles.documents__badge}>{documentCount}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default MindMapNode;
