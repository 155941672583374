import { create } from 'zustand';

import { Board, User } from 'models';

export type BoardState = {
  board: Board | null;
  setBoard: (board: Board | null) => void;
  users: User[];
  setUsers: (users: User[]) => void;
};

export const useBoardStore = create<BoardState>(set => ({
  board: null,
  setBoard: (board: Board | null) => {
    set({ board });
  },
  users: [],
  setUsers: (users: User[]) => {
    set({ users });
  }
}));
