import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconDividerVertical: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='9'
      height='40'
      viewBox='0 0 9 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='4' y='8' width='1' height='24' fill='currentColor' />
    </svg>
  );
};
