import { ChangeEvent, TextareaHTMLAttributes, useState } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';

import styles from './Textarea.module.scss';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  width?: 'small' | 'big';
  rows?: number;
  name: string;
  placeholder?: string;
  rules?: RegisterOptions;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  resize?: boolean;
  autoComplete?: 'off' | 'on';
}

export type TextFieldProps<T extends FieldValues> = UseControllerProps<T> & TextareaProps;

const Textarea = <T extends FieldValues>(props: TextFieldProps<T>) => {
  const {
    width = 'small',
    rows = 4,
    name,
    placeholder = '',
    control,
    rules = {},
    className,
    containerClassName,
    disabled,
    isLoading,
    resize = true,
    autoComplete = 'off',
    ...rest
  } = props;

  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(event.target.value);
  };

  const [focus, setFocus] = useState<boolean>(false);

  return (
    <div
      className={
        `${styles.container} ` +
        `${width === 'small' ? styles.container_small : styles.container_big} ` +
        `${disabled ? styles.container_disabled : ''} ` +
        `${containerClassName ? containerClassName : ''}`
      }
    >
      <label
        htmlFor={name}
        className={
          `${styles.label} ` +
          `${focus ? styles.label_focus : ''} ` +
          `${fieldState.error ? styles.label_error : ''}`
        }
        onClick={() => setFocus(true)}
      >
        <textarea
          id={name}
          className={
            `${styles.textarea} ` +
            `${width === 'small' ? styles.textarea_small : styles.textarea_big} ` +
            `${resize ? '' : styles['textarea_resize-none']} ` +
            `${className ? className : ''}`
          }
          placeholder={placeholder}
          disabled={disabled || isLoading}
          readOnly={disabled || isLoading}
          autoComplete={autoComplete}
          rows={rows}
          value={field.value || ''}
          onChange={handleChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          {...rest}
        />
      </label>

      {!!fieldState.error && (
        <span className={styles.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
};

export default Textarea;
