import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconPremium: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.10005 3.30005C6.93887 3.30014 6.78068 3.3435 6.642 3.42561C6.50331 3.50773 6.38922 3.62558 6.31165 3.76685L3.01165 9.76685C2.92468 9.92511 2.88732 10.1059 2.90445 10.2856C2.92157 10.4654 2.99237 10.6359 3.10765 10.7748L11.8076 21.2748C11.8921 21.3768 11.998 21.4588 12.1178 21.5151C12.2376 21.5714 12.3683 21.6006 12.5006 21.6006C12.633 21.6006 12.7637 21.5714 12.8835 21.5151C13.0033 21.4588 13.1092 21.3768 13.1936 21.2748L21.8936 10.7748C22.0089 10.6359 22.0797 10.4654 22.0968 10.2856C22.114 10.1059 22.0766 9.92511 21.9896 9.76685L18.6896 3.76685C18.612 3.6254 18.4977 3.50743 18.3588 3.42531C18.2199 3.34318 18.0614 3.29992 17.9 3.30005H7.10005ZM5.48725 9.00005L7.63165 5.10005H9.43165L8.06605 9.00005H5.48725ZM7.87285 10.8L10.1876 16.4976L5.46565 10.8H7.87285ZM12.4868 17.3772L9.81565 10.8H15.0788L12.4868 17.3772ZM9.97405 9.00005L11.3384 5.10005H13.6724L15.1088 9.00005H9.97405ZM17.0276 9.00005L15.5912 5.10005H17.3672L19.5128 9.00005H17.0276ZM17.0132 10.8H19.5332L14.7272 16.6008L17.0132 10.8Z'
        fill='currentColor'
      />
    </svg>
  );
};
