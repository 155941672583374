import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconSmile2: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='52'
      height='52'
      viewBox='0 0 52 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='smile'>
        <path
          id='Vector'
          d='M26.0002 47.6663C37.9663 47.6663 47.6668 37.9658 47.6668 25.9997C47.6668 14.0335 37.9663 4.33301 26.0002 4.33301C14.034 4.33301 4.3335 14.0335 4.3335 25.9997C4.3335 37.9658 14.034 47.6663 26.0002 47.6663Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M32.3642 34.5675C30.8746 33.4842 28.708 32.4008 25.9996 32.4008C23.2083 32.4008 20.9923 33.5515 19.5 34.667'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle
          id='Ellipse 66'
          cx='32.5002'
          cy='20.8'
          r='1.3'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle
          id='Ellipse 67'
          cx='19.5002'
          cy='20.8'
          r='1.3'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
