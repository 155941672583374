import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useBoardIcon, useBoardLabel } from 'hooks';
import { useCreateBoardMutation, useCreateProjectMutation } from 'hooks/mutations';
import { BOARD_TYPES, Project } from 'models';
import styles from './AddBoardCard.module.scss';

import { IconDevelop } from 'components/atoms';

interface AddBoardCardProps {
  projects: Project[] | undefined;
  board: {
    type: BOARD_TYPES;
    color: string;
    available: boolean;
    image?: string;
  };
}

const AddBoardCard: FC<AddBoardCardProps> = ({
  projects,
  board: { type, color, available, image }
}) => {
  const { t } = useTranslation();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const navigate = useNavigate();
  const Icon = useBoardIcon(type);
  const label = useBoardLabel(type);

  const { mutateAsync: createProjectMutate, isLoading: createProjectLoading } =
    useCreateProjectMutation({});

  const { mutateAsync: createBoardMutate, isLoading: createBoardLoading } = useCreateBoardMutation({
    onSuccess: async (data, variables) => {
      navigate(
        `${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${variables.projectId}/${ROUTES.BOARDS}/${data.id}`
      );
    }
  });

  const handleCreateBoard = async (type: BOARD_TYPES) => {
    if (projects && !!projects.length) {
      createBoardMutate({
        projectId: projects[0].id,
        teamId: Number(teamId),
        name: 'New board',
        type
      });
    } else {
      const newProject = await createProjectMutate({
        teamId: Number(teamId),
        name: t('common.defaultProject')
      });
      if (newProject) {
        createBoardMutate({
          projectId: newProject.id,
          teamId: Number(teamId),
          name: 'New board',
          type
        });
      }
    }
  };

  return (
    <button
      className={`${styles.card} ${!available && styles.card_unavailable}`}
      style={{ borderColor: color }}
      disabled={!available || createBoardLoading || createProjectLoading}
      onClick={() => handleCreateBoard(type)}
    >
      <div className={styles.image}>{image && <img src={image} />}</div>
      <div className={styles.info}>
        <div className={styles.type}>
          <Icon className={styles.icon} />
          <p className={styles.name}>{label}</p>
        </div>
        {!available && <IconDevelop className={styles.icon} />}
      </div>
    </button>
  );
};

export default AddBoardCard;
