import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconPlusBold: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6 2.5V9.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 6H9.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
