import { IconSmile1, IconSmile2, IconSmile3, IconSmile4, IconSmile5 } from 'components/atoms';

export const satisfactionOptions: {
  value: 1 | 2 | 3 | 4 | 5;
  Icon: typeof IconSmile1;
}[] = [
  {
    value: 1,
    Icon: IconSmile1
  },
  {
    value: 2,
    Icon: IconSmile2
  },
  {
    value: 3,
    Icon: IconSmile3
  },
  {
    value: 4,
    Icon: IconSmile4
  },
  {
    value: 5,
    Icon: IconSmile5
  }
];
