export enum SurveyScreens {
  START = 'start',
  SATISFACTION = 'satisfaction',
  VALUABLE_FEATURES = 'valuable-features',
  PROBLEMS = 'problems',
  SUGGESTIONS = 'suggestions',
  RECOMMENDATION = 'recommendation',
  END = 'end'
}

export type SurveyFormValues = {
  satisfaction?: 1 | 2 | 3 | 4 | 5;
  valuableFeatures?: string;
  problems?: string;
  suggestions?: string;
  recommendation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
};
