import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyFormValues } from 'models';
import { recommendationOptions } from './const';

import { Button, IconDislike, IconLike } from 'components/atoms';

type RecommendationScreenProps = {
  control: Control<SurveyFormValues>;
  isLoading: boolean;
};

const RecommendationScreen: FC<RecommendationScreenProps> = ({ control, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col items-center'>
      <h1
        className={
          'mb-[40px] w-[560px] text-[32px] leading-[48px] text-center ' +
          'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
        }
      >
        {t('Survey.howLikely')}
      </h1>

      <Controller
        name='recommendation'
        control={control}
        rules={{ required: { value: true, message: t('common.validation.requiredField') } }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <div className='flex flex-col gap-[8px] mb-[44px]'>
            <ul
              className={
                'flex rounded-[8px] border-[1px] border-solid ' +
                'border-[var(--color-light-gray)] overflow-hidden'
              }
            >
              {recommendationOptions.map(({ value: option }) => (
                <li key={option} className='flex'>
                  <input
                    id={`recommendation-option-${option}`}
                    type='radio'
                    value={value}
                    checked={option === value}
                    onChange={() => onChange(option)}
                    className='hidden'
                  />
                  <label
                    htmlFor={`recommendation-option-${option}`}
                    className={
                      'h-[56px] w-[66px] p-[8px] flex justify-center items-center ' +
                      'border-[var(--color-light-gray)] border-solid ' +
                      'text-[28px] leading-[33.6px] font-[300] ' +
                      'transition-colors duration-300 cursor-pointer ' +
                      `${option !== 10 ? 'border-r-[1px]' : ''} ` +
                      `${
                        option === value
                          ? 'bg-[var(--color-primary)] text-[var(--color-light)]'
                          : 'text-[var(--color-gray)]'
                      }`
                    }
                  >
                    {option}
                  </label>
                </li>
              ))}
            </ul>

            <div className='flex justify-between px-[8px] text-[var(--color-gray)]'>
              <IconDislike className='h-[48px] w-[48px' />
              <IconLike className='h-[48px] w-[48px]' />
            </div>

            {error && (
              <span
                className={
                  'block mt-[3px] ml-[10px] font-[300] text-[16px] ' +
                  'leading-[24px] tracking-[0.01em] text-[#f01c35]'
                }
              >
                {error.message}
              </span>
            )}
          </div>
        )}
      />

      <Button
        type='submit'
        variant='primary'
        size='big'
        title={t('Survey.done')}
        isLoading={isLoading}
        className='min-w-[240px]'
      />
    </div>
  );
};

export default RecommendationScreen;
