import { FC, useMemo, useRef, useState } from 'react';
import { Control, Controller, FieldError, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useClickOutside, useToggle } from 'hooks';
import { useTeam } from 'hooks/queries';
import { SelectOption } from 'models';
import styles from './SelectProject.module.scss';

import { Button, IconCheckCircle, IconPlus, IconStrokeDown, Modal } from 'components/atoms';
import { AddProjectForm } from 'pages/TeamPage/components/Sidebar/components';

interface SelectProps {
  options: SelectOption[];
  currentOption: SelectOption | undefined;
  setCurrentOption: (value: SelectOption) => void;
  error: FieldError | undefined;
  className: string;
}

const Select: FC<SelectProps> = ({
  options,
  currentOption,
  setCurrentOption,
  error,
  className
}) => {
  const { t } = useTranslation();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const [addProjectModalActive, toggleAddProjectModalActive] = useToggle(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setMenuOpen(false));

  return (
    <>
      <div ref={ref} tabIndex={1} className={`${styles.container} ${className}`}>
        <button
          type='button'
          className={
            `${styles.header} ` +
            `${!currentOption ? styles.header_placeholder : ''} ` +
            `${error ? styles.header_error : ''}`
          }
          onClick={() => setMenuOpen(prev => !prev)}
        >
          <span>{currentOption ? currentOption.label : 'Choose the Project'}</span>
          <div className={menuOpen ? `${styles.icon} ${styles.icon_active}` : styles.icon}>
            <IconStrokeDown />
          </div>
        </button>

        <div className={`${styles.menu} ${menuOpen ? styles.menu_active : ''}`}>
          {options.length > 0 ? (
            <div className={styles.options}>
              {options.map(option => (
                <button
                  key={option.value}
                  type='button'
                  className={
                    currentOption === option
                      ? `${styles.option} ${styles.option_active}`
                      : styles.option
                  }
                  onClick={() => {
                    setCurrentOption(option);
                    setMenuOpen(prev => !prev);
                  }}
                >
                  <span>{option.label}</span>
                  {currentOption === option && <IconCheckCircle color='#19b829' />}
                </button>
              ))}
            </div>
          ) : (
            <span>{t('common.noOptions')}</span>
          )}

          <div className={styles['container-add-project']}>
            <Button
              title={t('TeamPage.dashboard.addNewProject')}
              type='button'
              variant='secondary'
              icon={<IconPlus />}
              iconPosition='left'
              onClick={() => {
                toggleAddProjectModalActive();
                setMenuOpen(prev => !prev);
              }}
              className={styles['button-add-project']}
            />
          </div>
        </div>

        {!!error && <span className={styles.errorMessage}>{error.message}</span>}
      </div>

      <Modal isOpen={addProjectModalActive} onClose={toggleAddProjectModalActive}>
        <AddProjectForm
          teamId={teamId}
          onCancel={toggleAddProjectModalActive}
          onSuccess={async ({ id, name }) => {
            const newOption = { value: id.toString(), label: name };
            setCurrentOption(newOption);
          }}
        />
      </Modal>
    </>
  );
};

interface SelectProjectProps {
  control: Control<FieldValues>;
  className: string;
}

const SelectProject: FC<SelectProjectProps> = ({ control, className }) => {
  const { t } = useTranslation();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const { data: team, isLoading: teamLoading } = useTeam(teamId);

  const teamOptions = useMemo(
    () => team?.projects?.map(({ id, name }) => ({ value: id.toString(), label: name })) || [],
    [team]
  );

  if (teamLoading) return <h2>{t('common.loading')}</h2>;

  return (
    <Controller
      control={control}
      name='newProjectId'
      rules={{
        required: { value: true, message: t('common.validation.requiredField') }
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Select
          options={teamOptions}
          currentOption={teamOptions.find(option => option.value === value)}
          setCurrentOption={option => onChange(option.value)}
          error={error}
          className={className}
        />
      )}
    />
  );
};

export default SelectProject;
