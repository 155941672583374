import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ROUTES } from 'consts/routes';
import { useAuthStore } from 'store';

import {
  BoardPage,
  ChooseTeam,
  CreateTeam,
  CookiePolicy,
  FlowchartPage,
  LoginPage,
  MindmapPage,
  NewPasswordPage,
  NotFoundPage,
  PrivacyPolicy,
  ProfilePage,
  ResetPasswordPage,
  RoadmapPage,
  SignupPage,
  TeamPage,
  TermsOfService,
  VerifyEmailPage,
  WBSPage
} from 'pages';

const AppRouter: FC = () => {
  const isLogin = useAuthStore(state => state.isLogin);

  return (
    <>
      {isLogin ? (
        <Routes>
          <Route index path={ROUTES.HOME} Component={ChooseTeam} />
          <Route index path={ROUTES.PROFILE} Component={ProfilePage} />
          <Route path={ROUTES.CHOOSE_TEAM} Component={ChooseTeam} />
          <Route path={ROUTES.CREATE_TEAM} Component={CreateTeam} />
          <Route path={ROUTES.COOKIE_POLICY} Component={CookiePolicy} />
          <Route path={ROUTES.TERMS_OF_USE} Component={TermsOfService} />
          <Route path={ROUTES.PRIVACY_POLICY} Component={PrivacyPolicy} />
          <Route path={`${ROUTES.TEAMS}/:teamId/*`} Component={TeamPage} />
          <Route
            path={`${ROUTES.TEAMS}/:teamId${ROUTES.PROJECTS}/:projectId/${ROUTES.BOARDS}/:boardId`}
            Component={BoardPage}
          />
          <Route path={ROUTES.MINDMAP} Component={MindmapPage} />
          <Route path={ROUTES.ROADMAP} Component={RoadmapPage} />
          <Route path={ROUTES.WBS} Component={WBSPage} />
          <Route path={ROUTES.FLOWCHART} Component={FlowchartPage} />
          <Route path={ROUTES.VERIFY_EMAIL} Component={VerifyEmailPage} />
          <Route path={ROUTES.ERROR} Component={NotFoundPage} />
          <Route path='*' Component={NotFoundPage} />
        </Routes>
      ) : (
        <Routes>
          <Route index path={ROUTES.START} Component={LoginPage} />
          <Route path={ROUTES.LOGIN} Component={LoginPage} />
          <Route path={ROUTES.SIGNUP} Component={SignupPage} />
          <Route path={ROUTES.RESET_PASSWORD} Component={ResetPasswordPage} />
          <Route path={`${ROUTES.NEW_PASSWORD}/:token`} Component={NewPasswordPage} />
          <Route path={ROUTES.COOKIE_POLICY} Component={CookiePolicy} />
          <Route path={ROUTES.PRIVACY_POLICY} Component={PrivacyPolicy} />
          <Route path={ROUTES.TERMS_OF_USE} Component={TermsOfService} />
          <Route path='*' Component={LoginPage} />
        </Routes>
      )}
    </>
  );
};

export default AppRouter;
