import {
  Dispatch,
  FC,
  InputHTMLAttributes,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';

import { IconCross, IconSearch, IconSpinner } from 'components/atoms/icons';
import { DEFAULT_DEBOUNCE_TIME } from 'consts';
import { useDebounce } from 'hooks/common';
import styles from './SearchField.module.scss';

type SearchFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  setSearchValue: Dispatch<SetStateAction<string>>;
  debounceTime?: number;
  className?: string;
  containerClassName?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
};

const SearchField: FC<SearchFieldProps> = ({
  setSearchValue,
  debounceTime = DEFAULT_DEBOUNCE_TIME,
  className,
  containerClassName,
  isDisabled = false,
  isLoading = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>('');

  const debouncedSearchValue = useDebounce(value, debounceTime);
  useEffect(() => {
    setSearchValue(value);
  }, [debouncedSearchValue]);

  const handleClear = () => {
    setValue('');
    setSearchValue('');
    inputRef.current?.focus();
  };

  return (
    <label className={`${styles.label} ${containerClassName}`}>
      <input
        type='text'
        value={value}
        onChange={e => setValue(e.target.value)}
        className={`${styles.input} ${className}`}
        disabled={isDisabled || isLoading}
        readOnly={isDisabled || isLoading}
        {...rest}
      />
      {!!value.length && (
        <button className={styles.button} disabled={isDisabled || isLoading} onClick={handleClear}>
          <IconCross />
        </button>
      )}
      <IconSpinner className={`${styles.spinner} ${isLoading ? '' : 'hidden'}`} />
      <IconSearch className={`${styles.icon} ${isLoading ? 'hidden' : ''}`} />
    </label>
  );
};

export default SearchField;
