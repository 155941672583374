import { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useReactFlow } from 'reactflow';
import { shallow } from 'zustand/shallow';

import {
  INSTRUCTION_MAX_LENGTH,
  NODE_TYPES,
  RF_FIT_VIEW_DURATION,
  RF_FIT_VIEW_TIMEOUT,
  RF_FIT_VIEW_ZOOM_MAXIMUM
} from 'consts';
import { useSocket } from 'hooks';
import { useGenerateNodesMutation } from 'hooks/mutations';
import { RFState, useRFStore } from 'store';
import styles from './Instruction.module.scss';

import { Button, IconEdit, IconMagicWand } from 'components/atoms';

const selector = (state: RFState) => ({
  currentInstruction: state.currentInstruction,
  setCurrentInstruction: state.setCurrentInstruction,
  setData: state.setData,
  updateNodeValue: state.updateNodeValue,
  nodes: state.nodes
});

type Params = { boardId: string };

interface InstructionProps {
  className?: string;
}

const Instruction: FC<InstructionProps> = ({ className }) => {
  const { t } = useTranslation();
  const { fitView } = useReactFlow();
  const { boardId } = useParams<keyof Params>() as Params;
  const { updateNodeById } = useSocket(Number(boardId));
  const { currentInstruction, setCurrentInstruction, setData, updateNodeValue, nodes } = useRFStore(
    selector,
    shallow
  );

  const currentNode = useMemo(() => {
    const node = nodes.find(node => node.id === currentInstruction);
    return node;
  }, [currentInstruction, nodes]);

  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textarea = ref.current;
    if (!textarea) return;
    textarea.style.height = '24px';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [ref, currentNode?.data.value]);

  const handleChange = ({
    target: { scrollHeight, value, style }
  }: ChangeEvent<HTMLTextAreaElement>) => {
    if (!currentNode) return;

    style.height = '24px';
    style.height = `${scrollHeight}px`;
    updateNodeValue(currentNode.id, value);
    updateNodeById({ boardId: Number(boardId), nodeId: currentNode.id, data: { value } });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleGenerate();
    }
  };

  const { mutateAsync: generateNodesMutate, isLoading: generateDataLoading } =
    useGenerateNodesMutation();

  const handleGenerate = async () => {
    if (!currentNode || !currentInstruction) return;

    const isRootNode = currentNode.data.level === 0 || currentNode.type === NODE_TYPES.ROADMAP_ROOT;

    if (isRootNode) {
      await generateNodesMutate(
        { boardId: Number(boardId), title: currentNode.data.value },
        {
          onSuccess: data => {
            const parsedData = JSON.parse(data.schema);
            setData(parsedData);
            setTimeout(
              () => fitView({ maxZoom: RF_FIT_VIEW_ZOOM_MAXIMUM, duration: RF_FIT_VIEW_DURATION }),
              RF_FIT_VIEW_TIMEOUT
            );
          }
        }
      );
    } else {
      await generateNodesMutate(
        { boardId: Number(boardId), nodeId: currentNode.id },
        {
          onSuccess: data => {
            const parsedData = JSON.parse(data.schema);
            setData(parsedData);
            setTimeout(
              () => fitView({ maxZoom: RF_FIT_VIEW_ZOOM_MAXIMUM, duration: RF_FIT_VIEW_DURATION }),
              RF_FIT_VIEW_TIMEOUT
            );
          }
        }
      );
    }

    setCurrentInstruction(null);
  };

  if (!currentNode) return null;
  return (
    <aside className={`${styles.container} ${className}`}>
      <label htmlFor='instruction-textarea' className={styles.label}>
        <textarea
          id='instruction-textarea'
          ref={ref}
          placeholder={t('Instruction.placeholder') as string}
          maxLength={INSTRUCTION_MAX_LENGTH}
          className={styles.textarea}
          rows={1}
          value={currentNode.data.value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />

        <IconEdit className={styles['icon-edit']} />

        <div className={styles.counter}>
          {currentNode.data.value?.length || 0}/{INSTRUCTION_MAX_LENGTH}
        </div>
      </label>

      <Button
        type='button'
        variant='primary'
        title={t('common.generate')}
        icon={<IconMagicWand />}
        iconPosition='left'
        className={styles['button-generate']}
        isLoading={generateDataLoading}
        onClick={handleGenerate}
      />
    </aside>
  );
};

export default Instruction;
