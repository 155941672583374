import { Edge, Node, Position } from 'reactflow';
import { v4 as uuid } from 'uuid';

import { EdgeData, NodeData } from 'store';
import { NODE_TYPES } from './react-flow';

const rootId = uuid();
// const nodeId1 = uuid();
// const nodeId2 = uuid();

// const edgeId1 = uuid();

export const wbsInitialData: {
  nodes: Node<NodeData>[];
  edges: Edge<EdgeData>[];
} = {
  nodes: [
    {
      id: rootId,
      type: NODE_TYPES.WBS_ROOT,
      data: {
        value: '',
        toolbar: { position: Position.Right }
      },
      position: { x: 0, y: 0 }
    }
  ],
  edges: []
};
