import { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Handle, NodeProps, NodeToolbar, Position } from 'reactflow';
import { shallow } from 'zustand/shallow';

import { DEFAULT_TIMEOUT, NODE_TYPES } from 'consts';
import { NodeData, RFState, useRFStore } from 'store';
import { useSocket } from 'hooks';
import styles from './FlowchartRootNode.module.scss';

import { IconFlowchart, IconKnowledge, IconMagicWand } from 'components/atoms';
import { Contextbar } from 'components/organisms/flows/common';

const selector = (state: RFState) => ({
  currentEdgeType: state.currentEdgeType,
  nodes: state.nodes,
  selectNode: state.selectNode,
  updateNodeValue: state.updateNodeValue
});

type Params = { boardId: string };

const FlowchartRootNode: FC<NodeProps<NodeData>> = ({ id, data, selected, dragging }) => {
  const { boardId } = useParams<keyof Params>() as Params;
  const { updateNodeById } = useSocket(Number(boardId));
  const [inputActive, setInputActive] = useState<boolean>(false);
  const documentCount = useMemo(() => data.linkedDocuments?.length, [data.linkedDocuments]);
  const { currentEdgeType, nodes, selectNode, updateNodeValue } = useRFStore(selector, shallow);

  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.value]);

  const handleChange = ({
    target: { scrollHeight, value, style, selectionStart }
  }: ChangeEvent<HTMLTextAreaElement>) => {
    style.height = '24px';
    style.height = `${scrollHeight}px`;

    setCursor(selectionStart);
    updateNodeValue(id, value);
    updateNodeById({ boardId: Number(boardId), nodeId: id, data: { value } });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      ref.current?.blur();
      handleGenerate();
    }
  };

  const onDoubleClick = () => {
    setInputActive(true);
    setTimeout(() => {
      const textarea = ref.current;
      if (!textarea) return;
      textarea.focus();
      textarea.style.height = '24px';
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  };

  // when creating the board, focus on the root node for text entry
  useEffect(() => {
    if (
      nodes.find(
        node =>
          node.type === NODE_TYPES.FLOWCHART_DEFAULT ||
          NODE_TYPES.FLOWCHART_DATA ||
          NODE_TYPES.FLOWCHART_QUESTION ||
          NODE_TYPES.FLOWCHART_END
      )
    ) {
      return;
    }

    selectNode(id);
    setTimeout(onDoubleClick, DEFAULT_TIMEOUT);
  }, []);

  const handleGenerate = async () => {
    console.log('generate!');
  };

  return (
    <>
      <Contextbar
        nodeId={id}
        isVisible={!dragging && data.toolbar?.visible}
        position={Position.Top}
        offset={30}
      />

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Right} offset={11}>
        <button
          className={styles.buttonGenerate}
          // disabled={generateDataLoading}
          onClick={handleGenerate}
        >
          {/* {generateDataLoading ? <IconLoader className={styles.spin} /> : <IconMagicWand />} */}
          <IconMagicWand color='#FFFFFF' />
        </button>
      </NodeToolbar>

      <Handle
        type='target'
        position={Position.Top}
        id='a'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />
      <Handle
        type='source'
        position={Position.Right}
        id='b'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />
      <Handle
        type='source'
        position={Position.Bottom}
        id='c'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />
      <Handle
        type='target'
        position={Position.Left}
        id='d'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />

      <button
        className={
          `${styles.container} ` +
          `${selected ? styles.container_active : ''} ` +
          `${inputActive ? styles.container_double : ''}`
        }
        // disabled={generateDataLoading}
        onDoubleClick={onDoubleClick}
      >
        {!inputActive && <IconFlowchart className={styles.icon} />}

        <form
          className={styles.form}
          style={{
            maxWidth: 292 - (inputActive ? 0 : 34) - (!inputActive && !!documentCount ? 34 : 0)
          }}
          onSubmit={e => {
            e.preventDefault();
            handleGenerate();
          }}
        >
          {inputActive ? (
            <textarea
              ref={ref}
              id={`flowchart-root-${id}`}
              name='flowchart-root-input'
              placeholder='Enter flowchart title'
              title={data.value || 'Enter flowchart title'}
              value={data.value}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onBlur={() => setInputActive(false)}
              className={`${styles.textarea} nodrag`}
              rows={1}
              // disabled={generateDataLoading}
            />
          ) : (
            <span title={data.value || 'Enter flowchart title'} className={styles.text}>
              {data.value || 'Enter flowchart title'}
            </span>
          )}
        </form>

        {!inputActive && !!documentCount && (
          <div className={styles.documents}>
            <IconKnowledge className={styles.documents__icon} />
            <span className={styles.documents__badge}>{documentCount}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default FlowchartRootNode;
