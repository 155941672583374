import { useQuery } from 'react-query';

import { apiTeam } from 'api';
import { handleError } from 'helpers';

export const useTeams = () => {
  return useQuery(['teams/my'], async () => await apiTeam.getTeams(), {
    onError(error) {
      handleError(error);
    }
  });
};
