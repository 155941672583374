import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BOARD_TYPES } from 'models';

export const useBoardLabel = (type: BOARD_TYPES) => {
  const { t } = useTranslation();

  const color = useMemo(() => {
    switch (type) {
      case BOARD_TYPES.MINDMAP:
        return t('common.mindmap');
      case BOARD_TYPES.ROADMAP:
        return t('common.roadmap');
      case BOARD_TYPES.FLOWCHART:
        return t('common.flowchart');
      case BOARD_TYPES.DIAGRAM:
        return t('common.pertchart');
      case BOARD_TYPES.WBS:
        return t('common.wbs');
    }
  }, [type]);
  return color;
};
