// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import { useTeams } from 'hooks/queries';
import { useToggle } from 'hooks';
import styles from './SidebarTeams.module.scss';

import { IconPlus, Modal } from 'components/atoms';
import { NewTeam } from 'components/organisms';
import { TeamCard } from './components';

const SidebarTeams: FC = () => {
  const { t } = useTranslation();
  const { data: teams, isLoading: teamsLoading } = useTeams();

  const [isOpen, toggleOpen] = useToggle(false);

  if (teamsLoading) return <h2>{t('common.loading')}</h2>;
  if (!teams) return <h2>Failed to load teams</h2>;
  return (
    <div>
      <aside className={styles.container}>
        <div className={styles['team-list']}>
          <Swiper
            direction='vertical'
            slidesPerView='auto'
            spaceBetween='10px'
            className={styles.slider}
          >
            {!!teams.teams.length &&
              teams.teams.map(team => (
                <SwiperSlide key={team.id} className={styles.slide}>
                  <TeamCard key={team.id} team={team} />
                </SwiperSlide>
              ))}
          </Swiper>

          {!!teams.teams.length &&
            teams.teams.map(team => (
              <Tooltip
                key={team.id}
                id={`team-card-tooltip-${team.id}`}
                className={styles.tooltip}
                offset={15}
                place='right'
              >
                <span className={styles.name}>{team.name}</span>
              </Tooltip>
            ))}
        </div>

        <button type='button' className={styles['button-create']} onClick={toggleOpen}>
          <IconPlus className={styles['button-create__icon']} />
        </button>
      </aside>
      <Modal isOpen={isOpen} onClose={toggleOpen} className='!px-[30px]'>
        <NewTeam onClose={toggleOpen} />
      </Modal>
    </div>
  );
};

export default SidebarTeams;
