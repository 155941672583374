import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import LogoSVG from 'assets/images/svg/logo.svg';

import { ROUTES } from 'consts';
import { useTeam } from 'hooks/queries';
import styles from './HeaderBoard.module.scss';

import { Button, IconPremium } from 'components/atoms';
import { BoardBlock, ShareBlock, UserBlock } from 'components/molecules';
import { FeedbackButton } from 'components/atoms/FeedbackButton';

import { useBoardStore } from 'store';

const HeaderBoard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const { data: team } = useTeam(teamId);
  const isSubscribed = useMemo(() => team?.product?.subscriptionStatus === 'active', [team]);

  const goToPricingAndPlans = () => navigate(`${ROUTES.TEAMS}/${teamId}/${ROUTES.TEAM_UPGRADE}`);

  const getTypeMap = useBoardStore(state => state?.board?.type);
  const typeMap = () => {
    switch (getTypeMap) {
      case 'MINDMAP':
        return 'Mind Map';
        break;
      case 'ROADMAP':
        return 'Road Map';
        break;
      case 'WBS':
        return 'WBS';
        break;
      case 'FLOWCHART':
        return 'Flow Chart';
        break;
      default:
        return 'Mind Map';
        break;
    }
  };

  return (
    <>
      <Link to={team ? `${ROUTES.TEAMS}/${team.id}` : ROUTES.HOME} className={styles.logo}>
        <img src={LogoSVG} alt='logo.svg' />
      </Link>

      <h5 className='mr-[12px] text-[14px] text-[#6B7A99]'> {typeMap()} </h5>

      <BoardBlock className={styles['board-info']} />

      <FeedbackButton className='mr-[16px]' />

      <ShareBlock className={styles.share} />
      <Button
        type='button'
        variant='primary'
        size='small'
        title={isSubscribed ? t('common.upgrade') : t('common.upgradeToPro')}
        icon={<IconPremium />}
        iconPosition='left'
        onClick={goToPricingAndPlans}
        className={styles['button-premium']}
      />

      <UserBlock team={team} />
    </>
  );
};

export default HeaderBoard;
