import { useMutation } from 'react-query';

import { SendMessageToSlackDto, sendMessageToSlack } from 'api';
import { DEFAULT_RETRIES_AMOUNT } from 'consts';
import { handleError, notifySuc } from 'helpers';

export const useSendMessageToSlackMutation = () => {
  return useMutation(
    (dto: SendMessageToSlackDto) => {
      return sendMessageToSlack(dto);
    },
    {
      retry: DEFAULT_RETRIES_AMOUNT,
      onSuccess() {
        notifySuc('Success');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
