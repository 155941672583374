import { FC } from 'react';

import { Project } from 'models';
import styles from './Boards.module.scss';

import { AddMindCharts, ProjectBoards } from './components';

interface BoardsProps {
  project: Project;
}

const Boards: FC<BoardsProps> = ({ project }) => {
  return (
    <div className={styles.container}>
      <AddMindCharts />
      <ProjectBoards project={project} />
    </div>
  );
};

export default Boards;
