import { FC, MouseEvent, ReactNode, useCallback } from 'react';

import { IconSpinner } from 'components/atoms/icons';
import styles from './Button.module.scss';

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'transparent' | 'light' | 'dark' | 'black' | 'warning';
  size?: 'big' | 'medium' | 'small';
  wide?: boolean;
  title: string | ReactNode;
  className?: string;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const Button: FC<ButtonProps> = ({
  type = 'button',
  variant = 'primary',
  size = 'medium',
  wide = false,
  title,
  className,
  icon,
  iconPosition = 'left',
  onClick,
  isDisabled = false,
  isLoading = false
}) => {
  const getVariantClass = useCallback(() => {
    switch (variant) {
      case 'primary':
        return styles.button_primary;
      case 'secondary':
        return styles.button_secondary;
      case 'transparent':
        return styles.button_transparent;
      case 'light':
        return styles.button_light;
      case 'dark':
        return styles.button_dark;
      case 'black':
        return styles.button_black;
      case 'warning':
        return styles.button_warning;
      default:
        return '';
    }
  }, [variant]);

  const getSizeClass = useCallback(() => {
    switch (size) {
      case 'big':
        return styles.button_big;
      case 'medium':
        return styles.button_medium;
      case 'small':
        return styles.button_small;
      default:
        return '';
    }
  }, [size]);

  const getSpinnerClass = useCallback(() => {
    switch (variant) {
      case 'primary':
        return styles.spinner_primary;
      case 'secondary':
        return styles.spinner_secondary;
      case 'light':
        return styles.spinner_light;
      case 'dark':
        return styles.spinner_dark;
      default:
        return '';
    }
  }, [variant]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      disabled={isDisabled || isLoading}
      type={type}
      onClick={handleClick}
      className={
        `${styles.button} ` +
        `${getVariantClass()} ` +
        `${getSizeClass()} ` +
        `${wide ? styles.button_wide : ''} ` +
        `${className}`
      }
    >
      {isLoading ? (
        <IconSpinner className={`${styles.spinner} ${getSpinnerClass()}`} />
      ) : (
        <>
          {icon && iconPosition === 'left' && (
            <div className={`${styles.icon} ${styles.icon_left}`}>{icon}</div>
          )}

          {title}

          {icon && iconPosition === 'right' && (
            <div className={`${styles.icon} ${styles.icon_right}`}>{icon}</div>
          )}
        </>
      )}
    </button>
  );
};

export default Button;
