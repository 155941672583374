import { Position } from 'reactflow';
import { v4 as uuid } from 'uuid';

import { NODE_TYPES } from './react-flow';

const rootId = uuid();
// const nodeId1 = uuid();
// const nodeId2 = uuid();
// const nodeId3 = uuid();
// const edgeId1 = uuid();
// const edgeId2 = uuid();
// const edgeId3 = uuid();

export const mindmapInitialData = {
  nodes: [
    {
      id: rootId,
      type: NODE_TYPES.MINDMAP,
      data: {
        value: '',
        toolbar: { position: Position.Right },
        level: 0
      },
      position: { x: 0, y: 0 }
    }
    // {
    //   id: rootId,
    //   type: NODE_TYPES.MINDMAP_ROOT,
    //   data: { value: '3 Titles for an Educational Course' },
    //   position: { x: 250, y: 125 }
    // },
    // {
    //   id: nodeId1,
    //   type: NODE_TYPES.MINDMAP,
    //   data: {
    //     value: 'Mastering Time Management: Strategies for Boosting Productivity',
    //     toolbar: { position: Position.Right },
    //     level: 1
    //   },
    //   position: { x: 650, y: 25 }
    // },
    // {
    //   id: nodeId2,
    //   type: NODE_TYPES.MINDMAP,
    //   data: {
    //     value: 'Essential Communication Skills for Success in the Workplace',
    //     toolbar: { position: Position.Right },
    //     level: 1
    //   },
    //   position: { x: 650, y: 125 }
    // },
    // {
    //   id: nodeId3,
    //   type: NODE_TYPES.MINDMAP,
    //   data: {
    //     value: 'Introduction to Financial Literacy: Managing Your Money with Confidence',
    //     toolbar: { position: Position.Right },
    //     level: 1
    //   },
    //   position: { x: 650, y: 225 }
    // }
  ],
  edges: [
    //   {
    //     id: edgeId1,
    //     source: rootId,
    //     sourceHandle: 'b',
    //     target: nodeId1,
    //     targetHandle: 'd'
    //   },
    //   {
    //     id: edgeId2,
    //     source: rootId,
    //     sourceHandle: 'b',
    //     target: nodeId2,
    //     targetHandle: 'd'
    //   },
    //   {
    //     id: edgeId3,
    //     source: rootId,
    //     sourceHandle: 'b',
    //     target: nodeId3,
    //     targetHandle: 'd'
    //   }
  ]
};
