import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PASSWORD_NO_MATCH_REGEXP } from 'consts';
import { useChangePasswordMutation } from 'hooks/auth';

import { Button, InputText } from 'components/atoms';

type ChangePasswordFormValues = {
  password: string;
  newPassword: string;
};

type ChangePasswordFormProps = {
  onClose?: () => void;
  className?: string;
};

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ onClose, className }) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const { mutate: changePasswordMutate, isLoading: changePasswordLoading } =
    useChangePasswordMutation();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty }
  } = useForm<ChangePasswordFormValues>({
    mode: 'onSubmit'
  });

  const onSubmit = async (data: ChangePasswordFormValues) => {
    const { password, newPassword } = data;
    changePasswordMutate(
      { body: { password, newPassword } },
      { onSuccess: () => setSuccess(true) }
    );
  };

  const handleClose = () => {
    onClose?.();
    setSuccess(false);
  };

  const handleReset = () => {
    reset();
    handleClose();
  };

  return (
    <div className={`w-[480px] flex ${className}`}>
      {success ? (
        <div className='grow flex flex-col items-center gap-[32px]'>
          <div className='flex flex-col items-center gap-[28px]'>
            <h1 className='text-[32px] font-[500] leading-[48px] tracking-[-0.96px] text-[var(--color-dark)]'>
              {t('ProfilePage.changePassword.success')}
            </h1>
            <p className='text-[18px] leading-[24px] text-[var(--color-gray)]'>
              {t('ProfilePage.changePassword.yourPassword')}
            </p>
          </div>

          <Button
            variant='primary'
            size='big'
            title={t('common.ok')}
            onClick={handleReset}
            className='w-[240px] self-center uppercase'
          />
        </div>
      ) : (
        <div className='grow flex flex-col gap-[28px]'>
          <h1 className='self-center text-[32px] font-[500] leading-[48px] tracking-[-0.96px] text-[var(--color-dark)]'>
            {t('ProfilePage.changePassword.setNewPassword')}
          </h1>

          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-[32px]'>
            <div className='flex flex-col gap-[12px]'>
              <InputText
                type='password'
                width='big'
                name='password'
                label={t('ProfilePage.changePassword.password') as string}
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  minLength: { value: 6, message: `${t('common.form.minimumLengthIs')} 6` },
                  maxLength: { value: 20, message: `${t('common.form.maximumLengthIs')} 20` },
                  validate: {
                    doesntContainSpaces: (value: string) => {
                      return !value.includes(' ')
                        ? true
                        : t('common.validation.doesntContainSpaces');
                    },
                    validPassword: (value: string) => {
                      return !value.match(PASSWORD_NO_MATCH_REGEXP)
                        ? true
                        : t('common.validation.incorrectPassword');
                    }
                  }
                }}
                isLoading={changePasswordLoading}
                autoComplete='off'
              />

              <InputText
                type='password'
                width='big'
                name='newPassword'
                label={t('ProfilePage.changePassword.newPassword') as string}
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  minLength: { value: 6, message: `${t('common.form.minimumLengthIs')} 6` },
                  maxLength: { value: 20, message: `${t('common.form.maximumLengthIs')} 20` },
                  validate: {
                    doesntContainSpaces: (value: string) => {
                      return !value.includes(' ')
                        ? true
                        : t('common.validation.doesntContainSpaces');
                    },
                    validPassword: (value: string) => {
                      return !value.match(PASSWORD_NO_MATCH_REGEXP)
                        ? true
                        : t('common.validation.incorrectPassword');
                    },
                    doesNotMatchCurrentPassword: (value: string) => {
                      return value === getValues('password')
                        ? t('common.validation.doesNotMatchCurrentPassword')
                        : true;
                    }
                  }
                }}
                isLoading={changePasswordLoading}
                autoComplete='off'
              />
            </div>

            <div className='flex gap-[12px]'>
              <Button
                type='reset'
                variant='secondary'
                size='big'
                wide
                title={t('common.cancel')}
                isDisabled={changePasswordLoading}
                onClick={handleReset}
              />

              <Button
                type='submit'
                variant='primary'
                size='big'
                wide
                title={t('common.save')}
                isLoading={changePasswordLoading}
                isDisabled={!isDirty}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChangePasswordForm;
