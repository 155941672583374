import { instance } from './api';

export type ChargeDto = {
  paymentMethodId: string;
  amount: number;
};

export type AddCreditCardDto = {
  paymentMethodId: string;
};

export type ChooseDefaultCardDto = {
  paymentMethodId: string;
};

export type AddSubscriptionDto = {
  teamId: string;
  body: { productPriceId: string };
};

export type GetSubscriptionDto = {
  productPriceId: string;
};

export type UnsubscribeDto = {
  subscriptionId: string;
};

export enum SubscriptionPlan {
  MONTH = 'month',
  YEAR = 'year'
}

export type CreateCheckoutSessionDto = {
  teamId: number;
  numberCustomer: number;
  subscriptionPlan: SubscriptionPlan;
};

export type CreateCheckoutSessionResponse = {
  url: string;
};

export type CreatePortalSessionDto = {
  teamId: number;
  sessionId: string;
};

export type CreatePortalSessionResponse = {
  url: string;
};

export type UpdateSubscriptionDto = {
  teamId: number;
  numberCustomer: number;
  subscriptionPlan: SubscriptionPlan;
};

export type CreateCustomerPortalSessionResponse = {
  url: string;
};

export const apiStripe = {
  charge: async (dto: ChargeDto) => {
    const response = await instance.post<any>('stripe/charge', dto);
    return response.data;
  },
  addCreditCard: async (dto: AddCreditCardDto) => {
    const response = await instance.post<any>('stripe/credit-card', dto);
    return response.data;
  },
  getCardList: async () => {
    const response = await instance.get<any>('stripe/card-list');
    return response.data;
  },
  chooseDefaultCard: async (dto: ChooseDefaultCardDto) => {
    const response = await instance.post<any>('stripe/default-card', dto);
    return response.data;
  },
  addSubscription: async ({ body }: AddSubscriptionDto) => {
    const response = await instance.post<any>('stripe/subscription', body);
    return response.data;
  },
  getSubscription: async (dto: GetSubscriptionDto) => {
    const response = await instance.get<any>(`stripe/subscription/${dto.productPriceId}`);
    return response.data;
  },
  getSubscriptions: async () => {
    const response = await instance.get<any>('stripe/subscriptions');
    return response.data;
  },
  unsubscribe: async (data: UnsubscribeDto) => {
    const response = await instance.delete<any>('stripe/subscriptions', { data });
    return response.data;
  },
  createCheckoutSession: async (dto: CreateCheckoutSessionDto) => {
    const response = await instance.post<CreateCheckoutSessionResponse>(
      'stripe/create-checkout-session',
      dto
    );
    return response.data;
  },
  createPortalSession: async (dto: CreatePortalSessionDto) => {
    const response = await instance.post<CreatePortalSessionResponse>(
      'stripe/create-portal-session',
      dto
    );
    return response.data;
  },
  updateSubscription: async (dto: UpdateSubscriptionDto) => {
    const response = await instance.patch<CreateCheckoutSessionResponse>(
      'stripe/upgrade-subscription',
      dto
    );
    return response.data;
  },
  getCustomerPortalSession: async () => {
    const response = await instance.get<CreateCustomerPortalSessionResponse>(
      'stripe/customer-billing-portal-session'
    );
    return response.data;
  }
};
