export const DEFAULT_WBS_ROOT_OFFSET = 125;
export const DEFAULT_WBS_ROOT_WIDTH = 344;

export const DEFAULT_WBS_EPIC_HEIGHT = 60;
export const DEFAULT_WBS_EPIC_WIDTH = 300;
export const DEFAULT_WBS_EPIC_GAP = 20;

export const DEFAULT_WBS_TASK_X_OFFSET = 46;
export const DEFAULT_WBS_TASK_Y_OFFSET = 30;
export const DEFAULT_WBS_TASK_HEIGHT = 44;
export const DEFAULT_WBS_TASK_GAP = 10;

export enum WBS_EPIC_COLORS {
  RED = '#FF4C25',
  ORANGE = '#FFA51F',
  GREEN = '#11CB72',
  PURPLE = '#6865FF',
  PINK = '#FF57EE',
  CRIMSON = '#FF3767',
  BLUE = '#159FED',
  LIGHT_BLUE = '#05D4E1',
  LIGHT_GREEN = '#5BD459',
  YELLOW = '#F0CC0E'
}

export const DEFAULT_WBS_EPIC_COLOR = WBS_EPIC_COLORS.GREEN;
