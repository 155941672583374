import { FC } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { EMAIL_REGEXP, ROLE_OPTIONS } from 'consts';
import { useAddUsersMutation } from 'hooks/mutations';
import styles from './AddUsersForm.module.scss';

import { Button, InputText, Select } from 'components/atoms';

interface AddUsersFormProps {
  teamName: string;
  onCancel?: () => void;
}

const AddUsersForm: FC<AddUsersFormProps> = ({ teamName, onCancel }) => {
  const { t } = useTranslation();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const { mutate: addUsersMutate, isLoading: addUsersLoading } = useAddUsersMutation({
    onSuccess: onCancel
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async ({ emails, role }: FieldValues) => {
    const userEmails = emails.split(',').map((email: string) => email.trim());
    addUsersMutate({ teamId: Number(teamId), users: userEmails, role });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <h2 className={styles.title}>{t('TeamPage.sidebar.addNewUserForm.title')}</h2>
        <h3 className={styles.subtitle}>
          {t('TeamPage.sidebar.addNewUserForm.subtitle')} <b className={styles.bold}>{teamName}</b>
        </h3>

        <div className={styles.field}>
          <InputText
            type='text'
            width='big'
            name='emails'
            label={t('TeamPage.sidebar.addNewUserForm.placeholder') as string}
            control={control}
            rules={{
              required: { value: true, message: t('common.validation.requiredField') },
              maxLength: { value: 128, message: `${t('common.form.maximumLengthIs')} 128` },
              validate: {
                doesntConsistOfSpaces: (value: string) => {
                  return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                },
                isEmails: (value: string) => {
                  const emails = value.split(',').map(email => email.trim());
                  return emails.every(email => EMAIL_REGEXP.test(email))
                    ? true
                    : t('common.validation.incorrectEmail');
                }
              }
            }}
            isLoading={addUsersLoading}
            autoComplete='off'
            className={styles['input-name']}
          />

          <Controller
            control={control}
            name='role'
            rules={{
              required: { value: true, message: t('common.validation.requiredField') }
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                placeholder='Choose role'
                options={ROLE_OPTIONS}
                currentOption={ROLE_OPTIONS.find(option => option.value === value)}
                setCurrentOption={option => onChange(option.value)}
                error={error}
                isDisabled={addUsersLoading}
                className={styles['select-role']}
                headerClassName={styles['select-role__header']}
              />
            )}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            size='big'
            wide
            title={t('common.cancel')}
            isDisabled={addUsersLoading}
            onClick={onCancel}
            className={styles['button-cancel']}
          />

          <Button
            type='submit'
            variant='primary'
            size='big'
            wide
            title={t('TeamPage.sidebar.addNewUserForm.submitButton')}
            isDisabled={!isDirty}
            isLoading={addUsersLoading}
            className={styles['button-submit']}
          />
        </div>
      </form>
    </div>
  );
};

export default AddUsersForm;
