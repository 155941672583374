import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

import styles from './ErrorFallback.module.scss';

import { Button } from 'components/atoms';
import { useTranslation } from 'react-i18next';

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <div role='alert' className={styles.fallback}>
      <h2 className={styles.title}>{t('common.somethingWentWrong')}</h2>
      <pre className={styles.error}>{error.message}</pre>
      <Button
        variant='dark'
        size='big'
        type='button'
        title={t('common.goHome')}
        onClick={resetErrorBoundary}
      />
    </div>
  );
};

export default ErrorFallback;
