import { FC } from 'react';
import { NodeToolbar, NodeToolbarProps } from 'reactflow';

import { useRFStore } from 'store';
import styles from './Contextbar.module.scss';

import {
  IconBold,
  IconChain,
  IconCopy,
  IconDividerVertical,
  IconDotsHorizontal,
  IconItalic,
  IconTrash,
  IconUnderscore
} from 'components/atoms';

type ContextbarProps = NodeToolbarProps & {
  nodeId: string;
};

const Contextbar: FC<ContextbarProps> = ({ nodeId, ...rest }) => {
  const removeNode = useRFStore(state => state.removeNode);

  return (
    <NodeToolbar {...rest}>
      <div className={styles.contextbar}>
        <button className={styles.contextbar__button} disabled>
          <IconChain />
        </button>

        <IconDividerVertical color='#6B7A99' />

        <button className={styles.contextbar__button} disabled>
          <IconBold />
        </button>

        <button className={styles.contextbar__button} disabled>
          <IconItalic />
        </button>

        <button className={styles.contextbar__button} disabled>
          <IconUnderscore />
        </button>

        <button className={styles.contextbar__button} disabled>
          <IconDotsHorizontal />
        </button>

        <IconDividerVertical color='#6B7A99' />

        <button className={styles.contextbar__button} disabled>
          <IconCopy />
        </button>

        <IconDividerVertical color='#6B7A99' />

        <button
          className={`${styles.contextbar__button} ${styles.contextbar__button_delete}`}
          onClick={() => removeNode(nodeId)}
        >
          <IconTrash />
          Delete
        </button>
      </div>
    </NodeToolbar>
  );
};

export default Contextbar;
