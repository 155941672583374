import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { apiUser } from 'api';
import { handleError, notifySuc } from 'helpers';

export const useDeleteProfileMutation = () => {
  const { t } = useTranslation();

  return useMutation(
    () => {
      return apiUser.deleteProfile();
    },
    {
      async onSuccess() {
        notifySuc(t('common.notifications.profileDeleted'));
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
