import { useMemo } from 'react';

import { IconDOC, IconPDF, IconTXT } from 'components/atoms';

export const useDocumentIcon = (type: string) => {
  const Icon = useMemo(() => {
    switch (type) {
      case 'docx':
        return IconDOC;
      case 'txt':
        return IconTXT;
      case 'pdf':
        return IconPDF;
      default:
        return IconDOC;
    }
  }, [type]);
  return Icon;
};
