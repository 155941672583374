import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconSmile1: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='52'
      height='52'
      viewBox='0 0 52 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='smile'>
        <path
          id='Vector'
          d='M25.9999 47.6663C37.9661 47.6663 47.6666 37.9658 47.6666 25.9997C47.6666 14.0335 37.9661 4.33301 25.9999 4.33301C14.0337 4.33301 4.33325 14.0335 4.33325 25.9997C4.33325 37.9658 14.0337 47.6663 25.9999 47.6663Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M17.3333 34.667C17.3333 34.667 20.5833 30.3337 25.9999 30.3337C31.4166 30.3337 34.6666 34.667 34.6666 34.667'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle
          id='Ellipse 66'
          cx='32.5'
          cy='20.8'
          r='1.3'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle
          id='Ellipse 67'
          cx='19.5'
          cy='20.8'
          r='1.3'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
