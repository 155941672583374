import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { AddUsersDto, AddUsersResponse, apiTeam } from 'api';
import { handleError, notifySuc } from 'helpers';
import { useInvalidate } from '../useInvalidate';

interface Params {
  onCancel?: () => void;
  onSuccess?: (data: AddUsersResponse, variables: AddUsersDto) => void | Promise<void>;
}

export const useAddUsersMutation = ({ onCancel, onSuccess }: Params) => {
  const { t } = useTranslation();
  const invalidate = useInvalidate();

  return useMutation(
    (data: AddUsersDto) => {
      return apiTeam.addUsers(data);
    },
    {
      async onSuccess(data, variables) {
        notifySuc(t('common.notifications.usersAdded'));
        await invalidate(`teams/one/${variables.teamId}`);

        onCancel && onCancel();
        onSuccess && onSuccess(data, variables);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
