import { Dispatch, FC, useMemo, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_EDITORS_ALLOWED } from 'consts';
import { IconCheck, Button } from 'components/atoms';
import { Team } from 'models/Team';
import { UnsubscribedScreens } from 'models/unsubscribed';
import { useUserQuery } from 'hooks/user';

type UnsubscribedProps = {
  setCurrentScreen: Dispatch<SetStateAction<UnsubscribedScreens>>;
  onClose?: () => void;
  team: Team | undefined;
  unsubscribe: () => Promise<void>;
};

const UnsubscribedModal: FC<UnsubscribedProps> = ({
  setCurrentScreen,
  onClose,
  team,
  unsubscribe
}) => {
  const { t } = useTranslation();
  const subscriber = useMemo(() => team?.subscriber, [team]);
  const { data: viewer } = useUserQuery();

  const unsubAndOpenUnsubPage = () => {
    unsubscribe();
    setCurrentScreen(UnsubscribedScreens.HAVEUNSUBSCRIPTION);
  };

  return (
    <div className='flex flex-col items-center w-[740px] h-[608px]'>
      <h1
        className={
          'w-[560px] text-[32px] leading-[48px] text-center ' +
          'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
        }
      >
        Are you sure
      </h1>
      <h1
        className={
          'mb-[24px] w-[560px] text-[32px] leading-[48px] text-center ' +
          'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
        }
      >
        you want to cancel your subscription?
      </h1>

      {subscriber && (
        <div
          className={
            'h-[48px] py-[8px] pr-[8px] pl-[16px] flex items-center gap-[4px] mb-[24px] ' +
            'rounded-[24px] bg-[var(--color-light-light-gray)]'
          }
        >
          <span className='text-[14px] font-[500] leading-[20px] text-[#6B7A99] tracking-[0.14px]'>
            <span className='text-[#19B829]'>{t('Unsubscribed.team+')}</span>
            {team?.product?.numberEditorsAllowed || DEFAULT_EDITORS_ALLOWED}{' '}
            {t('PricingAndPlans.users')}
            {'. '}
            {/* {t('PricingAndPlans.paidBefore')} */}
            {/* date */}
            {/* {t('PricingAndPlans.by')} */}
            {t('PricingAndPlans.paidBy')}
            {':'}
          </span>
          <span className='text-[14px] font-[500] leading-[20px] text-[#2D3C59] tracking-[0.14px]'>
            {subscriber.username} {subscriber.id === viewer?.id && `(${t('common.you')})`}
          </span>
        </div>
      )}

      <p className='text-[20px] leading-[32px] font-[400] text-[var(--color-dark)]'>
        Access to the first 10 created projects and 5 users will be
      </p>
      <p className='text-[20px] leading-[32px] font-[400] text-[var(--color-dark)]'>
        saved. However, access to creating new projects will be
      </p>
      <p className='text-[20px] leading-[32px] font-[400] text-[var(--color-dark)] mb-[24px]'>
        restricted until you renew your subscription.
      </p>

      <div className='bg-[#F1F7FF] rounded-[10px] w-[560px] pt-[24px] pr-[32px] pl-[32px] mb-[24px]'>
        <h2
          className={
            'text-[21px] leading-[48px] text-left ' +
            'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
          }
        >
          Here’s what you will lose:
        </h2>

        <p className='text-[16px] leading-[32px] font-[400] text-[var(--color-dark)]'>
          <span className='mr-[12px] text-[#25BD33]'>
            <IconCheck />
          </span>
          Unlimited projects
        </p>
        <p className='text-[16px] leading-[32px] font-[400]'>
          <span className='mr-[12px] text-[#25BD33]'>
            <IconCheck />
          </span>
          Collaboration with team members
        </p>
        <p className='text-[16px] leading-[32px] font-[400] text-[var(--color-dark)]'>
          <span className='mr-[12px] text-[#25BD33]'>
            <IconCheck />
          </span>
          Full document suite (including flowcharts, diagrams, and WBS)
        </p>
        <p className='text-[16px] leading-[32px] font-[400] text-[var(--color-dark)]'>
          <span className='mr-[12px] text-[#25BD33]'>
            <IconCheck />
          </span>
          AI-driven tools for automated document generation
        </p>
        <p className='text-[16px] leading-[32px] font-[400] text-[var(--color-dark)] mb-[24px]'>
          <span className='mr-[12px] text-[#25BD33]'>
            <IconCheck />
          </span>
          Priority support
        </p>
      </div>

      <div className='text-[#6B7A99] leading-[24px] mb-[48px] text-center'>
        <p>You can downgrade your plan to pay less by</p>
        <p>
          <span className='text-[#4971FF]'>{t('Unsubscribed.reducingTeamSize')}</span>
          {t('Unsubscribed.or')}
          <span className='text-[#4971FF]'>switching to monthly payments.</span>
        </p>
      </div>

      <div className='flex flex-row items-center justify-center gap-x-[16px]'>
        <Button
          variant='secondary'
          size='big'
          title='I changed my mind'
          onClick={onClose}
          className='mb-[32px] min-w-[240px]'
        />

        <Button
          variant='primary'
          size='big'
          title='Unsubscribe'
          onClick={unsubAndOpenUnsubPage}
          className='mb-[32px] min-w-[240px]'
        />
      </div>
    </div>
  );
};

export default UnsubscribedModal;
