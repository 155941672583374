import { FC, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { ROUTES, flowchartInitialData, mindmapInitialData, roadmapInitialData } from 'consts';
import { useSocket } from 'hooks';
import { useBoard } from 'hooks/queries';
import { BOARD_TYPES } from 'models';
import { RFState, useBoardStore, useRFStore } from 'store';
import styles from './BoardPage.module.scss';

import {
  ErrorFallback,
  FlowchartFlow,
  Instruction,
  MindMapFlow,
  RoadmapFlow,
  WBSFlow
} from 'components/organisms';

type Params = { boardId: string; teamId: string };

const selector = (state: RFState) => ({
  setData: state.setData,
  currentInstruction: state.currentInstruction
});

const BoardPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { boardId, teamId } = useParams<keyof Params>() as Params;
  const { setData, currentInstruction } = useRFStore(selector, shallow);
  const setBoard = useBoardStore(state => state.setBoard);
  const [board, boardLoading] = useBoard(Number(boardId));
  const { joinToBoard, leaveFromBoard } = useSocket(board?.id);

  const Flow = useMemo(() => {
    if (!board) return;

    switch (board.type) {
      case BOARD_TYPES.MINDMAP:
        return MindMapFlow;
      case BOARD_TYPES.ROADMAP:
        return RoadmapFlow;
      case BOARD_TYPES.FLOWCHART:
        return FlowchartFlow;
      case BOARD_TYPES.WBS:
        return WBSFlow;
    }
  }, [board]);

  useEffect(() => {
    if (!board) return;
    setBoard(board);

    if (board.schema) {
      setData(board.schema);
    } else {
      switch (board.type) {
        case BOARD_TYPES.MINDMAP:
          setData(mindmapInitialData);
          break;
        case BOARD_TYPES.ROADMAP:
          setData(roadmapInitialData);
          break;
        case BOARD_TYPES.FLOWCHART:
          setData(flowchartInitialData);
          break;
      }
    }

    return () => setData({ nodes: [], edges: [] });
  }, [board]);

  useEffect(() => {
    if (
      !board ||
      !(
        board.type === BOARD_TYPES.MINDMAP ||
        board.type === BOARD_TYPES.ROADMAP ||
        board.type === BOARD_TYPES.FLOWCHART
      )
    )
      return;

    joinToBoard();

    return () => {
      leaveFromBoard();
    };
  }, [board]);

  if (boardLoading) return <h2>{t('common.loading')}</h2>;
  if (!board) return <h2>Failed to load the board</h2>;
  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles['flow-wrapper']}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => navigate(teamId ? `${ROUTES.TEAMS}/${teamId}` : ROUTES.HOME)}
          >
            {Flow && <Flow />}
          </ErrorBoundary>
        </div>
        {currentInstruction && <Instruction />}
        {/* <DocumentBar board={board} /> */}
      </div>
    </main>
  );
};

export default BoardPage;
