import { FC, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EMAIL_REGEXP, ROUTES } from 'consts';
import { useResetPassword } from 'hooks';
import styles from './ResetPasswordPage.module.scss';

import { Button, IconArrowLeft, InputText, TermsPrivacy } from 'components/atoms';

const ResetPasswordPage: FC = () => {
  const { sendLink, isLoading } = useResetPassword();
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const [sendedEmail, setSendedEmail] = useState<string>('');

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async ({ email }: FieldValues) => {
    const response = await sendLink(email);
    if (response === 'ok') {
      setSuccess(true);
      setSendedEmail(email);
    }
  };

  const resend = async () => {
    if (!isLoading) {
      await sendLink(sendedEmail);
    }
  };

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          {success ? (
            <div className={styles.success}>
              <h1 className={styles.success__title}>{t('ResetPasswordPage.checkYourEmail')}</h1>
              <p className={styles.success__subtitle}>{t('ResetPasswordPage.weSentPassword')}</p>
              <p className={styles.success__email}>{sendedEmail}</p>
              <Button
                type='submit'
                variant='primary'
                size='big'
                wide
                title={t('ResetPasswordPage.clickToResend')}
                className={styles.success__button}
                onClick={resend}
              />
              <p className={styles.success__resend}>
                <span>{t('ResetPasswordPage.didntReceiveEmail')}</span>{' '}
                <span className='link' onClick={resend}>
                  {t('ResetPasswordPage.clickToResend')}
                </span>
              </p>
            </div>
          ) : (
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <h1 className={styles.form__title}>{t('ResetPasswordPage.forgotYourPassword')}</h1>
              <h4 className={styles.form__subtitle}>{t('ResetPasswordPage.weWillSendYou')}</h4>

              <div className={styles.form__fields}>
                <InputText
                  type='email'
                  width='big'
                  name='email'
                  label='Email'
                  control={control}
                  rules={{
                    required: { value: true, message: t('common.validation.requiredField') },
                    maxLength: { value: 50, message: `${t('common.form.maximumLengthIs')} 50` },
                    validate: {
                      doesntContainSpaces: (value: string) => {
                        return !value.includes(' ')
                          ? true
                          : t('common.validation.doesntConsistOfSpaces');
                      },
                      validEmail: (value: string) => {
                        return EMAIL_REGEXP.test(value)
                          ? true
                          : t('common.validation.incorrectEmail');
                      }
                    }
                  }}
                  isLoading={isLoading}
                  autoComplete='off'
                />
              </div>

              <Button
                type='submit'
                variant='primary'
                size='big'
                wide
                title={t('ResetPasswordPage.resetPassword')}
                isDisabled={!isDirty}
                isLoading={isLoading}
              />
            </form>
          )}

          <span className={styles.backToLogin}>
            <Link to={ROUTES.LOGIN} className={`link ${styles.backToLogin__link}`}>
              <IconArrowLeft />
              {t('ResetPasswordPage.backToLogin')}
            </Link>
          </span>

          <TermsPrivacy />
        </div>
      </div>
    </main>
  );
};

export default ResetPasswordPage;
