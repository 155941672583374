import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useAuthStore } from 'store';
import styles from './Header.module.scss';

import { HeaderAuth, HeaderBoard, HeaderDefault, HeaderLanding, HeaderTeam } from './variants';

const Header: FC = () => {
  const isLogin = useAuthStore(state => state.isLogin);

  return (
    <header className={styles.header}>
      <div className={`container ${styles.container}`}>
        {isLogin ? (
          <Routes>
            <Route index path={ROUTES.HOME} Component={HeaderAuth} />
            <Route path={`${ROUTES.TEAMS}/:teamId/*`} Component={HeaderTeam} />
            <Route
              path={`${ROUTES.TEAMS}/:teamId${ROUTES.PROJECTS}/:projectId/${ROUTES.BOARDS}/:boardId`}
              Component={HeaderBoard}
            />
            <Route path='*' Component={HeaderAuth} />
          </Routes>
        ) : (
          <Routes>
            <Route index path={ROUTES.HOME} Component={HeaderLanding} />
            <Route path='*' Component={HeaderDefault} />
          </Routes>
        )}
      </div>
    </header>
  );
};

export default Header;
