export const DEFAULT_ROADMAP_ROOT_OFFSET = 65;
export const DEFAULT_ROADMAP_ROOT_WIDTH = 300;

export const DEFAULT_ROADMAP_EPIC_HEIGHT = 60;
export const DEFAULT_ROADMAP_EPIC_WIDTH = 300;
export const DEFAULT_ROADMAP_EPIC_GAP = 20;

export const DEFAULT_ROADMAP_TASK_HEIGHT = 44;
export const DEFAULT_ROADMAP_TASK_WIDTH = 300;
export const DEFAULT_ROADMAP_TASK_GAP = 6;

export const DEFAULT_ROADMAP_TASK_CONTAINER_HEIGHT = 44;
export const DEFAULT_ROADMAP_TASK_CONTAINER_WIDTH = 1000;
export const DEFAULT_ROADMAP_TASK_CONTAINER_X_OFFSET = 350;
export const DEFAULT_ROADMAP_TASK_CONTAINER_Y_OFFSET = 8;
export const DEFAULT_ROADMAP_TASK_CONTAINER_GAP = 10;
export const DEFAULT_ROADMAP_TASK_CONTAINER_SURPLUS = 100;

export const DEFAULT_ROADMAP_MILESTONE_X_OFFSET = 1;
export const DEFAULT_ROADMAP_MILESTONE_Y_OFFSET = 1;
export const DEFAULT_ROADMAP_MILESTONE_HEIGHT = 36;
export const DEFAULT_ROADMAP_MILESTONE_WIDTH = 200;
export const DEFAULT_ROADMAP_MILESTONE_GAP = 10;
export const DEFAULT_ROADMAP_MILESTONE_CONTAINER_WIDTH = 1000;
export const DEFAULT_ROADMAP_MILESTONE_CONTAINER_SURPLUS = 100;

export const DEFAULT_ROADMAP_INTERVAL_X_OFFSET = 1;
export const DEFAULT_ROADMAP_INTERVAL_Y_OFFSET = 1;
export const DEFAULT_ROADMAP_INTERVAL_HEIGHT = 48;
export const DEFAULT_ROADMAP_INTERVAL_WIDTH = 300;
export const DEFAULT_ROADMAP_INTERVAL_CONTAINER_WIDTH = 1000;
export const DEFAULT_ROADMAP_INTERVAL_CONTAINER_SURPLUS = 100;

export const DEFAULT_ROADMAP_CONTAINER_WIDTH = 1000;

export enum ROADMAP_EPIC_COLORS {
  RED = '#FF4C25',
  ORANGE = '#FFA51F',
  GREEN = '#11CB72',
  PURPLE = '#6865FF',
  PINK = '#FF57EE',
  CRIMSON = '#FF3767',
  BLUE = '#159FED',
  LIGHT_BLUE = '#05D4E1',
  LIGHT_GREEN = '#5BD459',
  YELLOW = '#F0CC0E'
}

export const DEFAULT_ROADMAP_EPIC_COLOR = ROADMAP_EPIC_COLORS.GREEN;
