import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconFlowchart: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='4' y='1' width='6' height='6' rx='1' stroke='currentColor' strokeWidth='2' />
      <rect x='17' y='14' width='6' height='6' rx='1' stroke='currentColor' strokeWidth='2' />
      <path d='M17 17H12' stroke='currentColor' strokeWidth='2' />
      <path d='M7 7V12' stroke='currentColor' strokeWidth='2' />
      <path
        d='M11.2929 16.2929L7.70711 12.7071C7.31658 12.3166 6.68342 12.3166 6.29289 12.7071L2.70711 16.2929C2.31658 16.6834 2.31658 17.3166 2.70711 17.7071L6.29289 21.2929C6.68342 21.6834 7.31658 21.6834 7.70711 21.2929L11.2929 17.7071C11.6834 17.3166 11.6834 16.6834 11.2929 16.2929Z'
        stroke='currentColor'
        strokeWidth='2'
      />
    </svg>
  );
};
