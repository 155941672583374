import { FC, KeyboardEvent, MouseEvent, useCallback, useEffect, useRef } from 'react';
import ReactFlow, {
  Background,
  EdgeTypes,
  Node,
  NodeTypes,
  Position,
  SelectionMode,
  useOnSelectionChange,
  useReactFlow
} from 'reactflow';
import { v4 as uuid } from 'uuid';
import { shallow } from 'zustand/shallow';

import {
  NODE_TYPES,
  RF_DEFAULT_PAN_ON_SCROLL,
  RF_DEFAULT_ZOOM_ACTIVATION_KEY_CODE,
  RF_FIT_VIEW_ZOOM_MAXIMUM,
  RF_ZOOM_MAXIMUM,
  RF_ZOOM_MINIMUM
} from 'consts';
import { NodeData, RFState, useDocumentsStore, useRFStore } from 'store';
import styles from './WBSFlow.module.scss';
import { normalizeEpicNodes, normalizeTaskNodes } from './utils';

import { MindMapConnectionLine } from '../MindMapFlow/components/lines';
import { Controls } from '../common/Controls';
import { TextNode } from '../common/nodes/TextNode';
import { Toolbar, WBSEdge, WBSEpicNode, WBSRootNode, WBSTaskNode } from './components';

const selector = (state: RFState) => ({
  setReactFlowInstance: state.setReactFlowInstance,
  controlType: state.controlType,
  currentEdgeType: state.currentEdgeType,
  setCurrentInstruction: state.setCurrentInstruction,
  nodeToAdd: state.nodeToAdd,
  setNodeToAdd: state.setNodeToAdd,
  setNodes: state.setNodes,
  nodes: state.nodes,
  edges: state.edges,
  addNode: state.addNode,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  backgroundVariant: state.backgroundVariant
});

export const nodeTypes: NodeTypes = {
  wbsRootNode: WBSRootNode,
  wbsEpicNode: WBSEpicNode,
  wbsTaskNode: WBSTaskNode,
  textNode: TextNode
};

export const edgeTypes: EdgeTypes = {
  wbsEdge: WBSEdge
};

const WBSFlow: FC = () => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const {
    setReactFlowInstance,
    controlType,
    currentEdgeType,
    setCurrentInstruction,
    nodeToAdd,
    setNodeToAdd,
    setNodes,
    nodes,
    edges,
    addNode,
    onNodesChange,
    onEdgesChange,
    onConnect,
    backgroundVariant
  } = useRFStore(selector, shallow);
  const selectDocument = useDocumentsStore(state => state.selectDocument);

  const reactFlowInstance = useReactFlow();
  useEffect(() => {
    setReactFlowInstance(reactFlowInstance);
    return () => setReactFlowInstance(null);
  }, [reactFlowInstance]);

  const onClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (!reactFlowWrapper.current) return;

      if (nodeToAdd) {
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top
        });
        const newNode = {
          id: uuid(),
          type: nodeToAdd.nodeType,
          position,
          data: {
            value: '',
            toolbar: { position: Position.Right },
            level: nodeToAdd.level,
            size: nodeToAdd.size
          }
        };

        addNode(newNode);
        setNodeToAdd(null);
      }
    },
    [reactFlowWrapper, nodeToAdd]
  );

  const onContextMenu = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (!reactFlowWrapper.current) return;

      setNodeToAdd(null);
    },
    [reactFlowWrapper, nodeToAdd]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      // event.preventDefault();
      if (!reactFlowWrapper.current) return;

      if (event.key === 'Escape') {
        setNodeToAdd(null);
      }
    },
    [reactFlowWrapper, nodeToAdd]
  );

  const onNodesDelete = (removedNodes: Node<NodeData>[]) => {
    if (removedNodes.find(node => node.type === NODE_TYPES.WBS_EPIC)) {
      const epicNodes = nodes.filter(node => node.type === NODE_TYPES.WBS_EPIC);
      const otherNodes = nodes.filter(node => node.type !== NODE_TYPES.WBS_EPIC);

      const removedEpicNodeIds = removedNodes
        .filter(node => node.type === NODE_TYPES.WBS_EPIC)
        .map(node => node.id);
      const updatedEpicNodes = epicNodes.filter(node => !removedEpicNodeIds.includes(node.id));

      const normalizedEpicNodes = normalizeEpicNodes(updatedEpicNodes);
      setNodes([...normalizedEpicNodes, ...otherNodes]);
    }

    if (removedNodes.find(node => node.type === NODE_TYPES.WBS_TASK)) {
      const removedTaskNodes = removedNodes.filter(node => node.type === NODE_TYPES.WBS_TASK);
      const removedTaskNodesParentIds = removedTaskNodes.map(node => node.parentNode);

      const originalRemovedTaskNodesParentIds = Array.from(new Set(removedTaskNodesParentIds));

      const removedTaskNodeIds = removedNodes
        .filter(node => node.type === NODE_TYPES.WBS_TASK)
        .map(node => node.id);

      const taskNodes = nodes.filter(node => node.type === NODE_TYPES.WBS_TASK);
      const otherNodes = nodes.filter(node => node.type !== NODE_TYPES.WBS_TASK);

      const updatedAndNormalizedTaskNodes = taskNodes.filter(
        node => !originalRemovedTaskNodesParentIds.includes(node.parentNode)
      );

      originalRemovedTaskNodesParentIds.forEach(taskNodesParentId => {
        const taskNodes = nodes.filter(
          node => node.type === NODE_TYPES.WBS_TASK && node.parentNode === taskNodesParentId
        );

        const updatedTaskNodes = taskNodes.filter(node => !removedTaskNodeIds.includes(node.id));
        const normalizedTaskNodes = normalizeTaskNodes(updatedTaskNodes);

        updatedAndNormalizedTaskNodes.push(...normalizedTaskNodes);
      });

      setNodes([...otherNodes, ...updatedAndNormalizedTaskNodes]);
    }
  };

  // show instruction on single node select
  useOnSelectionChange({
    onChange: ({ nodes }) => {
      if (nodes.length === 1) {
        const node = nodes[0];
        setCurrentInstruction(node.id);
        selectDocument(null);
      } else {
        setCurrentInstruction(null);
      }
    }
  });

  return (
    <div className={styles.container}>
      <div ref={reactFlowWrapper} className={styles.reactflow}>
        <ReactFlow
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          onNodesDelete={onNodesDelete}
          edges={edges}
          defaultEdgeOptions={{ type: currentEdgeType || 'wbsEdge' }}
          connectionLineComponent={MindMapConnectionLine}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onClick={onClick}
          onContextMenu={onContextMenu}
          onKeyDown={handleKeyDown}
          panOnScroll={controlType === 'Figma-like'}
          panOnScrollSpeed={RF_DEFAULT_PAN_ON_SCROLL}
          zoomOnScroll={controlType === 'Default'}
          selectionOnDrag={controlType === 'Figma-like'}
          minZoom={RF_ZOOM_MINIMUM}
          maxZoom={RF_ZOOM_MAXIMUM}
          fitView
          fitViewOptions={{ maxZoom: RF_FIT_VIEW_ZOOM_MAXIMUM }}
          preventScrolling
          zoomActivationKeyCode={RF_DEFAULT_ZOOM_ACTIVATION_KEY_CODE}
          panOnDrag={controlType === 'Figma-like' ? [1, 2] : true}
          selectionMode={SelectionMode.Partial}
          proOptions={{ hideAttribution: true }}
          className={nodeToAdd ? 'crosshair' : undefined}
        >
          <Toolbar position='top-left' />
          <Controls position='bottom-right' />
          <Background color='#6b7a99' variant={backgroundVariant} />
        </ReactFlow>
      </div>
    </div>
  );
};

export default WBSFlow;
