import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { apiAuth } from 'api';
import { ROUTES } from 'consts';
import { handleError, notifySuc } from 'helpers';
import { useToggle } from 'hooks';
import { useAuthStore } from 'store';

export const useAuth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setLogin = useAuthStore(state => state.setLogin);
  const [isLoading, toggleLoading] = useToggle(false);

  const login = async (email: string, password: string) => {
    toggleLoading();

    try {
      const response = await apiAuth.login(email, password);
      const { accessToken, refreshToken } = response;
      _saveCredentials(accessToken, refreshToken);
      notifySuc(t('common.notifications.authorized'));
      setLogin(true);
      navigate(ROUTES.CHOOSE_TEAM);
    } catch (error) {
      handleError(error);
    }

    toggleLoading();
  };

  const register = async (name: string, email: string, password: string) => {
    toggleLoading();

    try {
      const response = await apiAuth.register(name, email, password);
      const { accessToken, refreshToken } = response;
      _saveCredentials(accessToken, refreshToken);
      notifySuc(t('common.notifications.registered'));
      setLogin(true);
      navigate(ROUTES.VERIFY_EMAIL);
    } catch (error) {
      handleError(error);
    }

    toggleLoading();
  };

  const authGoogle = async (token: string) => {
    toggleLoading();

    try {
      const response = await apiAuth.authGoogle(token);
      const { accessToken, refreshToken } = response;
      _saveCredentials(accessToken, refreshToken);
      notifySuc(t('common.notifications.authorized'));
      setLogin(true);
      navigate(ROUTES.CHOOSE_TEAM);
    } catch (error) {
      handleError(error);
    }

    toggleLoading();
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    setLogin(false);
    navigate(ROUTES.START);
  };

  const _saveCredentials = (accessToken: string, refreshToken: string) => {
    localStorage.setItem('token', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  return { login, register, authGoogle, logout, isLoading };
};
