import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CreateBoardDto, apiBoard } from 'api';
import { handleError, notifySuc } from 'helpers';
import { Board } from 'models';
import { useInvalidate } from '../useInvalidate';

interface Params {
  onCancel?: () => void;
  onSuccess?: (data: Board, variables: CreateBoardDto) => void | Promise<void>;
}

export const useCreateBoardMutation = ({ onCancel, onSuccess }: Params) => {
  const { t } = useTranslation();
  const invalidate = useInvalidate();

  return useMutation(
    (data: CreateBoardDto) => {
      return apiBoard.createBoard(data);
    },
    {
      async onSuccess(data, variables) {
        notifySuc(t('common.notifications.boardCreated'));
        await invalidate(`projects/${variables.projectId}`);
        await invalidate(`teams/team-boards/${variables.teamId}`);

        onCancel && onCancel();
        onSuccess && onSuccess(data, variables);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
