import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { apiDocument } from 'api';
import { handleError, notifySuc } from 'helpers';
import { AddDocumentDto, Document, Project } from 'models';

export const useAddDocumentMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (data: AddDocumentDto) => {
      return apiDocument.addDocument(data);
    },
    {
      async onSuccess(data, variables) {
        notifySuc(t('common.notifications.documentAdded'));

        // update project query cache
        const projectQuery = `projects/${variables.projectId}`;
        await queryClient.invalidateQueries([projectQuery], {
          refetchActive: false,
          refetchInactive: false
        });

        const oldProjectData = queryClient.getQueryData<Project>([projectQuery]);
        if (!oldProjectData) return;

        const updatedDocuments: Document[] = [...oldProjectData.files, data];
        const updatedProjectData: Project = { ...oldProjectData, files: updatedDocuments };

        queryClient.setQueryData([projectQuery], updatedProjectData);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
