import axios from 'axios';
import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import 'react-tooltip/dist/react-tooltip.css';
import 'styles/main.scss';

import { instance } from 'api';
import { serverString } from 'appConfig';
import { useAuthStore } from 'store';
import { ROUTES } from 'consts/routes';

import AppRouter from 'AppRouter';
import { HeaderLayout } from 'components/layouts';
import { CookiesAndModal } from 'components/organisms';

const App: FC = () => {
  const setLogin = useAuthStore(state => state.setLogin);

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post(`${serverString}/auth/refresh/${refreshToken}`);
    return response.data;
  };

  instance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        try {
          originalRequest._retry = true;
          const { accessToken, refreshToken } = await refreshAccessToken();

          if (accessToken && refreshToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
            localStorage.setItem('token', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            return instance(originalRequest);
          }
        } catch (error) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          setLogin(false);
        }
      }
      return Promise.reject(error);
    }
  );

  instance.interceptors.request.use(
    config => {
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    config => {
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  const [hasAcceptedCookies, setHasAcceptedCookies] = useState<boolean>(false);

  const handleCookieAcceptance = () => {
    // Update the user's cookie acceptance status and set the state
    localStorage.setItem('hasAcceptedCookies', 'true');
    setHasAcceptedCookies(true);
  };

  const location = useLocation();
  const isCookiePolicyRoute = location.pathname === ROUTES.COOKIE_POLICY;

  return (
    <HeaderLayout>
      <AppRouter />
      {!hasAcceptedCookies && !isCookiePolicyRoute && (
        <CookiesAndModal onAccept={handleCookieAcceptance} />
      )}
    </HeaderLayout>
  );
};

export default App;
