import { ChangeEvent, InputHTMLAttributes, useMemo, useState } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';

import { IconEye, IconEyeCrossed } from '../icons';
import styles from './InputText.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'email' | 'password' | 'number' | 'tel';
  width?: 'small' | 'big';
  name: string;
  placeholder?: string;
  label?: string;
  rules?: RegisterOptions;
  className?: string;
  containerClassName?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  autoComplete?: 'off' | 'on' | 'new-password';
}

export type TextFieldProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const InputText = <T extends FieldValues>(props: TextFieldProps<T>) => {
  const {
    type = 'text',
    width = 'small',
    name,
    placeholder = '',
    label,
    control,
    rules = {},
    className,
    containerClassName,
    isDisabled = false,
    isLoading = false,
    autoComplete = 'off',
    ...rest
  } = props;

  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  const isPassword = useMemo(() => type === 'password', [type]);
  const [showPassword, setShowPassword] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.value);
  };

  return (
    <div
      className={
        `${styles.container} ` +
        `${width === 'small' ? styles.container_small : styles.container_big} ` +
        `${containerClassName ? containerClassName : ''}`
      }
    >
      {label && (
        <label
          htmlFor={name}
          className={`${styles.label} ${(inputFocus || !!field.value) && styles.label_compressed}`}
          onClick={() => setInputFocus(true)}
        >
          {label}
        </label>
      )}

      <input
        type={showPassword ? 'text' : type}
        id={name}
        className={
          `${styles.input} ` +
          `${label ? styles.input_withLabel : ''} ` +
          `${width === 'small' ? styles.input_small : styles.input_big} ` +
          `${isPassword ? styles.input_password : ''} ` +
          `${fieldState.error ? styles.input_error : ''} ` +
          `${className ? className : ''}`
        }
        placeholder={label ? '' : placeholder}
        disabled={isDisabled || isLoading}
        readOnly={isDisabled || isLoading}
        autoComplete={autoComplete}
        value={field.value || ''}
        onChange={handleChange}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        {...rest}
      />

      {isPassword && (
        <button
          type='button'
          className={styles.eyeIcon}
          onClick={() => setShowPassword(prev => !prev)}
          disabled={isDisabled || isLoading}
        >
          {showPassword && <IconEyeCrossed className='h-full w-full' color='#4971ff' />}
          {!showPassword && <IconEye className='h-full w-full' color='#4971ff' />}
        </button>
      )}

      {!!fieldState.error && (
        <span className={styles.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
};

export default InputText;
