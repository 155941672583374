import { BOARD_TYPES, TEAM_ROLES, Team } from 'models';
import { instance } from './api';

export interface CreateTeamDto {
  name: string;
}

export interface DeleteUsersDto {
  teamId: number;
  userId: number;
}

export type UpdateTeamDto = {
  teamId: number;
  body: {
    name?: string;
  };
};

export type DeleteTeamDto = {
  teamId: number;
};

export interface GetTeamsResponse {
  teams: { id: number; name: string }[];
  teamOwner: { id: number; name: string }[];
}

export interface AddUsersDto {
  teamId: number;
  users: string[];
  role: TEAM_ROLES;
}

export interface AddUsersResponse {
  successfullyAddedUsers: string[];
  usersAlreadyInTeam: string[];
}

export interface TeamBoard {
  projectId: number;
  projectName: string;
  boardId: number;
  boardName: string;
  boardType: BOARD_TYPES;
}

export interface SwitchRoleDto {
  teamId: number;
  body: {
    memberId: number;
    newRole: TEAM_ROLES;
  };
}

export const apiTeam = {
  createTeam: async (data: CreateTeamDto) => {
    const response = await instance.post<Team>('teams', data);
    return response.data;
  },
  deleteUser: async (data: DeleteUsersDto) => {
    const response = await instance.delete('teams', { data });
    return response.data;
  },
  getTeams: async () => {
    const response = await instance.get<GetTeamsResponse>('teams/my');
    return response.data;
  },
  getTeam: async (teamId: string) => {
    const response = await instance.get<Team>(`teams/one/${teamId}`);
    return response.data;
  },
  updateTeam: async ({ teamId, body }: UpdateTeamDto) => {
    const response = await instance.patch<Team>(`teams/${teamId}`, body);
    return response.data;
  },
  deleteTeam: async ({ teamId }: DeleteTeamDto) => {
    const response = await instance.delete(`teams/${teamId}`);
    return response.data;
  },
  addUsers: async (data: AddUsersDto) => {
    const response = await instance.post<AddUsersResponse>('teams/users', data);
    return response.data;
  },
  getTeamBoards: async (teamId: number) => {
    const response = await instance.get<TeamBoard[]>(`teams/team-boards/${teamId}`);
    return response.data;
  },
  switchRole: async ({ teamId, body }: SwitchRoleDto) => {
    await instance.patch(`teams/member-role/${teamId}`, body);
  }
};
