import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useVerifyEmail } from 'hooks';
import { useUserQuery } from 'hooks/user';
import styles from './VerifyEmailPage.module.scss';

import { Button, TermsPrivacy } from 'components/atoms';

const VerifyEmailPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: userData, isLoading: userLoading, refetch: refetchUser } = useUserQuery();
  const { sendLink, isLoading } = useVerifyEmail();
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    const intervalId = setInterval(() => refetchUser(), 5000);

    if (userData) {
      if (userData.emailConfirmed) {
        setSuccess(true);
        clearInterval(intervalId);
      }
    }

    () => clearInterval(intervalId);
  }, [userData]);

  const resend = async () => {
    if (!isLoading) {
      await sendLink();
      refetchUser();
    }
  };

  if (userLoading) return <h2>{t('common.loading')}</h2>;

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          {success ? (
            <div className={styles.success}>
              <h1 className={styles.success__title}>{t('VerifyEmailPage.success')}</h1>
              <p className={styles.success__subtitle}>
                {t('VerifyEmailPage.thankYouForConfirming1')}
                <br />
                <br />
                {t('VerifyEmailPage.thankYouForConfirming2')}
              </p>
              <Button
                type='button'
                variant='primary'
                size='big'
                wide
                title={t('VerifyEmailPage.start')}
                className={styles.success__button}
                onClick={() => navigate(ROUTES.CHOOSE_TEAM)}
              />
            </div>
          ) : (
            <form className={styles.form}>
              <h1 className={styles.form__title}>{t('VerifyEmailPage.thankYouForSigningUp')}</h1>
              <p className={styles.form__subtitle}>{t('VerifyEmailPage.pleaseConfirmEmail')}</p>
              <p className={styles.form__email}>{userData?.email}</p>

              <Button
                type='button'
                variant='primary'
                size='big'
                wide
                title={t('VerifyEmailPage.clickToResend')}
                className={styles.form__button}
                onClick={resend}
              />

              <p className={styles.form__resend}>
                <span>{t('VerifyEmailPage.didntReceiveEmail')}</span>{' '}
                <span className='link' onClick={resend}>
                  {t('VerifyEmailPage.clickToResend')}
                </span>
              </p>
            </form>
          )}

          <TermsPrivacy />
        </div>
      </div>
    </main>
  );
};

export default VerifyEmailPage;
