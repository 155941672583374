import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useToggle } from 'hooks';
import { Project as IProject } from 'models';
import styles from './Projects.module.scss';
import { DEFAULT_PROJECT } from './const';

import { Button, IconCommand, IconPlus, IconStrokeDown, Modal } from 'components/atoms';
import { AddProjectForm, Project } from '..';

interface ProjectProps {
  projects: IProject[];
}

const Projects: FC<ProjectProps> = ({ projects }) => {
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showProjects, toggleShow] = useToggle(true);
  const [addProjectModalActive, toggleAddProjectModalActive] = useToggle(false);

  return (
    <div className={styles.container}>
      <button
        className={showProjects ? `${styles.header} ${styles.header_active}` : styles.header}
        onClick={toggleShow}
      >
        <IconCommand className={styles.header__icon} />
        <p className={styles.header__title}>{t('TeamPage.sidebar.projects')}</p>
        <div className={styles.header__amount}>{projects.length}</div>
        <IconStrokeDown className={styles.header__stroke} />
      </button>

      {showProjects && (
        <div className={styles.list}>
          {!!projects.length &&
            [DEFAULT_PROJECT, ...projects].map((project, index) => (
              <Project key={index} project={project} />
            ))}

          <Button
            type='button'
            variant='dark'
            size='small'
            title={t('TeamPage.sidebar.addNewProject')}
            icon={<IconPlus />}
            iconPosition='left'
            className={styles['button-create']}
            onClick={toggleAddProjectModalActive}
          />
        </div>
      )}

      <Modal isOpen={addProjectModalActive} onClose={toggleAddProjectModalActive}>
        <AddProjectForm
          teamId={teamId}
          onSuccess={async data => {
            navigate(`${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${data.id}`);
          }}
          onCancel={toggleAddProjectModalActive}
        />
      </Modal>
    </div>
  );
};

export default Projects;
