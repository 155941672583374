import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Divider.module.scss';

interface DividerProps {
  className?: string;
}

const Divider: FC<DividerProps> = ({ className = '' }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.container} ${className}`}>
      <span className={styles.line}></span>
      <span className={styles.text}>{t('common.or')}</span>
      <span className={styles.line}></span>
    </div>
  );
};

export default Divider;
