import { FC, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { EMAIL_REGEXP, ROLE_OPTIONS } from 'consts';
import { useBoardIcon, useBoardLabel } from 'hooks';
import { useUserQuery } from 'hooks/user';
import { useCopyToClipboard } from 'hooks/common';
import { useAddUsersMutation } from 'hooks/mutations';
import { useTeam } from 'hooks/queries';
import { BOARD_TYPES } from 'models';
import { useBoardStore } from 'store';
import styles from './ShareModal.module.scss';

import { Button, InputText, Select, IconUser } from 'components/atoms';

type Params = { teamId: string };

interface ShareModalProps {
  onClose?: () => void;
}

const ShareModal: FC<ShareModalProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const copy = useCopyToClipboard();
  const board = useBoardStore(state => state.board);
  const { data: user } = useUserQuery();
  const { teamId } = useParams<keyof Params>() as Params;
  const { data: team } = useTeam(teamId);
  const boardLabel = useBoardLabel(board?.type || BOARD_TYPES.MINDMAP);
  const Icon = useBoardIcon(board?.type || BOARD_TYPES.MINDMAP);

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });
  const { mutate: addUsersMutate, isLoading: addUsersLoading } = useAddUsersMutation({});

  const onSubmit = async ({ emails, role }: FieldValues) => {
    const userEmails = emails.split(',').map((email: string) => email.trim());
    addUsersMutate({ teamId: Number(teamId), users: userEmails, role });
  };

  const [copied, setCopied] = useState<boolean>(false);
  const copyUrl = async () => {
    const pageUrl = 'https://migned.com' + location.pathname;

    const response = await copy(pageUrl);
    if (response) {
      setCopied(true);
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <h1 className={styles.title}>{t('ShareModal.title')}</h1>
        <h2 className={styles.type}>
          <Icon className={styles.icon} />
          <p className={styles.label}>
            {boardLabel} {t('common.board')}
          </p>
        </h2>

        <div className={styles.invite}>
          <form className={styles.invite__form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.invite__field}>
              <InputText
                type='text'
                width='big'
                name='emails'
                label={t('ShareModal.placeholder') as string}
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  maxLength: { value: 128, message: `${t('common.form.maximumLengthIs')} 128` },
                  validate: {
                    doesntConsistOfSpaces: (value: string) => {
                      return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                    },
                    isEmails: (value: string) => {
                      const emails = value.split(',').map(email => email.trim());
                      return emails.every(email => EMAIL_REGEXP.test(email))
                        ? true
                        : t('common.validation.incorrectEmail');
                    }
                  }
                }}
                isLoading={addUsersLoading}
                autoComplete='off'
                className={styles['input-name']}
              />

              <Controller
                control={control}
                name='role'
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') }
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    placeholder='Choose role'
                    options={ROLE_OPTIONS}
                    currentOption={ROLE_OPTIONS.find(option => option.value === value)}
                    setCurrentOption={option => onChange(option.value)}
                    error={error}
                    isDisabled={addUsersLoading}
                    className={styles['select-role']}
                    headerClassName={styles['select-role__header']}
                  />
                )}
              />

              <Button
                type='submit'
                variant='secondary'
                size='big'
                title={t('ShareModal.sendInvite')}
                isDisabled={!isDirty}
                isLoading={addUsersLoading}
                className={styles['button-submit']}
              />
            </div>
          </form>
        </div>

        <p className={styles.text}>
          {t('ShareModal.text1')} <br /> {t('ShareModal.text2')}
        </p>

        <h2 className={styles.invite__title}>
          {t('ShareModal.inviteUsers')} <b className={styles.bold}>{team?.name}</b>
        </h2>

        <div className={styles['user-list']}>
          <h5 className='self-center'>The board has been visited by:</h5>

          {user ? (
            <div className='flex flex-col w-full'>
              {team?.members?.map(user => (
                <div key={user.id} className={styles['one-user']}>
                  <div className='flex flex-row w-[280px]'>
                    <div className={styles.avatar}>
                      <IconUser />
                    </div>
                    <h5 className={styles.username}>{user.username}</h5>
                  </div>
                  <h5 className='text-[#4971FF] p-[5px] w-[300px]'>{user.email}</h5>
                  <h5 className={styles.role}>
                    {user?.userRole?.role.charAt(0).toUpperCase() +
                      user?.userRole?.role.slice(1).toLowerCase()}
                  </h5>
                </div>
              ))}
            </div>
          ) : (
            <h2>Failed to load the users</h2>
          )}
        </div>

        <div className={styles.buttonSubmit}>
          <Button
            type='button'
            variant='primary'
            size='big'
            wide
            title={copied ? t('ShareModal.copied') : t('ShareModal.copyLink')}
            className={styles['button-copy']}
            onClick={copyUrl}
          />
        </div>
      </form>
    </div>
  );
};

export default ShareModal;
