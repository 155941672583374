export const serverString = process.env.REACT_APP_API_URL;
export const clientId = process.env.REACT_APP_CLIENT_ID;

export const SLACK_BOT_TOKEN = process.env.REACT_APP_SLACK_BOT_TOKEN || '';
export const SLACK_SALES_CHANNEL_ID = process.env.REACT_APP_SALES_SLACK_CHANNEL_ID || '';
export const SLACK_CAREERS_CHANNEL_ID = process.env.REACT_APP_CAREERS_SLACK_CHANNEL_ID || '';
export const SLACK_FEEDBACK_CHANNEL_ID = process.env.REACT_APP_FEEDBACK_SLACK_CHANNEL_ID || '';
export const SLACK_SALES_WEBHOOK_URL = process.env.REACT_APP_SLACK_SALES_WEBHOOK_URL || '';
export const SLACK_CAREERS_WEBHOOK_URL = process.env.REACT_APP_SLACK_CAREERS_WEBHOOK_URL || '';
export const SLACK_FEEDBACK_WEBHOOK_URL = process.env.REACT_APP_SLACK_FEEDBACK_WEBHOOK_URL || '';
export const SLACK_ENTERPRISE_CONTACT_WEBHOOK_URL =
  process.env.REACT_APP_SLACK_ENTERPRISE_CONTACT_WEBHOOK_URL || '';
