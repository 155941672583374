import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DEFAULT_EDITORS_ALLOWED, ROUTES } from 'consts';
import { useFeatures, useToggle } from 'hooks';
import { useUserQuery } from 'hooks/user';
import { useUnsubscribeMutation } from 'hooks/stripe/mutations';
import { Team } from 'models/Team';

import { IconCheck, IconUser, Modal } from 'components/atoms';
import { ContactUs } from 'components/organisms';
import TeamPlus from './TeamPlus';

type PlansProps = {
  team: Team | undefined;
};

const Plans: FC<PlansProps> = ({ team }) => {
  const { t } = useTranslation();
  const { featuresFree, featuresEnterprise } = useFeatures();
  const { data: viewer } = useUserQuery();
  const isSubscribed = useMemo(() => team?.product?.subscriptionStatus === 'active', [team]);
  const subscriber = useMemo(() => team?.subscriber, [team]);
  const { mutate: unsubscribeMutate, isLoading: unsubscribeLoading } = useUnsubscribeMutation();

  const unsubscribe = async () => {
    if (!team || !team.product?.subscriptionId) return;
    unsubscribeMutate({ subscriptionId: team.product.subscriptionId });
  };

  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <div className='flex flex-col gap-[30px]'>
      <header className='flex items-center justify-between gap-[20px]'>
        <h3 className='text-[21px] font-[500] leading-[30px] tracking-[0.21px] text-[#2D3C59]'>
          {t('PricingAndPlans.upgradeYour')}{' '}
          <Link to={`${ROUTES.TEAMS}/${team?.id}`} className='text-[#19B829]'>
            {team?.name || t('PricingAndPlans.upgradeYour')}
          </Link>{' '}
          {isSubscribed
            ? t('PricingAndPlans.plans.team+.title')
            : t('PricingAndPlans.plans.free.title')}{' '}
          {t('PricingAndPlans.plan')}
        </h3>

        {subscriber && (
          <div
            className={
              'h-[48px] py-[8px] pr-[8px] pl-[16px] flex items-center gap-[4px] ' +
              'rounded-[24px] bg-[var(--color-light-light-gray)]'
            }
          >
            <span className='text-[14px] font-[500] leading-[20px] text-[#6B7A99] tracking-[0.14px]'>
              {team?.product?.numberEditorsAllowed || DEFAULT_EDITORS_ALLOWED}{' '}
              {t('PricingAndPlans.users')}
              {'. '}
              {/* {t('PricingAndPlans.paidBefore')} */}
              {/* date */}
              {/* {t('PricingAndPlans.by')} */}
              {t('PricingAndPlans.paidBy')}
              {':'}
            </span>
            <span className='text-[14px] font-[500] leading-[20px] text-[#2D3C59] tracking-[0.14px]'>
              {subscriber.username} {subscriber.id === viewer?.id && `(${t('common.you')})`}
            </span>
            <span className='h-[24px] w-[24px] rounded-full'>
              <IconUser />
            </span>
          </div>
        )}
      </header>

      <section
        id='pricing'
        className={
          'py-[13px] px-[26px] flex gap-[20px] rounded-[20px] border-[2px] ' +
          'border-solid border-[var(--color-light-light-gray)]'
        }
      >
        <div
          className={
            'flex flex-col rounded-[10px] max-w-[323px] w-full ' +
            'bg-[#FFFFFF] shadow-[0_4px_12px_0_rgba(0,0,0,0.09)]'
          }
        >
          <header className='flex flex-col p-[20px] gap-[24px] items-start'>
            <h3 className='flex gap-[8px] text-[18px] font-[700] leading-[24px] text-[#2D3C59]'>
              {t('PricingAndPlans.plans.free.title')}
              {!isSubscribed && (
                <div
                  className={
                    'px-[4px] text-[16px] leading-[24px] font-[500] text-[#FFFFFF] ' +
                    'bg-[#19B829] rounded-[4px]'
                  }
                >
                  {t('PricingAndPlans.currentPlan')}
                </div>
              )}
            </h3>
            <span className='h-[1px] w-full bg-[#C3CAD9]' />
            <h3 className='max-w-[240px] text-[18px] font-[400] leading-[26px] text-[#3D4E72] tracking-[0.18px]'>
              {t('PricingAndPlans.plans.free.description')}
            </h3>
          </header>

          <div className='flex p-[20px] items-start min-h-[150px] bg-[#EDEFF2]'>
            <h2 className='text-[30px] leading-[39px] font-[700] text-[#2D3C59]'>
              {t('PricingAndPlans.plans.free.price')}
            </h2>
          </div>

          <div className='flex p-[20px] flex-col gap-[24px]'>
            {isSubscribed ? (
              <button
                type='button'
                className={
                  'w-full flex justify-center items-center px-[12px] py-[8px] rounded-[8px] h-[56px] bg-[#F7F8FA] ' +
                  'text-[16px] leading-[24px] font-[500] text-[#2D3C59] shadow-[0_2px_6px_0_rgba(0,0,0,0.07)]'
                }
                disabled={unsubscribeLoading}
                onClick={unsubscribe}
              >
                {t('PricingAndPlans.plans.free.downgradeToFree')}
              </button>
            ) : (
              <button
                type='button'
                className={
                  'w-full flex justify-center items-center px-[12px] py-[8px] rounded-[8px] opacity-80 ' +
                  'h-[56px] bg-[#F7F8FA] text-[16px] leading-[24px] font-[500] text-[#C3CAD9] cursor-auto'
                }
                disabled
              >
                {t('PricingAndPlans.plans.free.currentPlan')}
              </button>
            )}

            <div className='flex flex-col gap-[16px]'>
              <p className='text-[21px] leading-[32px] text-[#3D4E72]'>
                {t('PricingAndPlans.plans.free.freeFeatures')}
              </p>
              {featuresFree.map((feature, index) => (
                <div key={index} className='flex gap-[12px] items-start'>
                  <span className='h-[20px] w-[20px]'>
                    <IconCheck color='#25BD33' />
                  </span>
                  <span className='text-[14px] font-[500] leading-[20px] text-[#6B7A99]'>
                    {feature}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <TeamPlus
          team={team}
          isSubscribed={isSubscribed}
          unsubscribeLoading={unsubscribeLoading}
          unsubscribe={unsubscribe}
        />

        <div
          className={
            'flex flex-col rounded-[10px] max-w-[323px] w-full ' +
            'bg-[#FFFFFF] shadow-[0_4px_12px_0_rgba(0,0,0,0.09)]'
          }
        >
          <header className='flex flex-col p-[20px] gap-[24px] items-start'>
            <h3 className='text-[18px] font-[700] leading-[24px] text-[#2D3C59]'>
              {t('PricingAndPlans.plans.enterprise.title')}
            </h3>
            <span className='h-[1px] w-full bg-[#C3CAD9]' />
            <h3 className='max-w-[240px] text-[18px] font-[400] leading-[26px] text-[#3D4E72] tracking-[0.18px]'>
              {t('PricingAndPlans.plans.enterprise.description')}
            </h3>
          </header>

          <div className='flex p-[20px] flex-col gap-[4px] items-start min-h-[150px] bg-[#F7F8FA]'>
            <h2 className='text-[30px] leading-[39px] font-[700] text-[#2D3C59]'>
              {t('PricingAndPlans.plans.enterprise.price1')}
            </h2>
            <p className='text-[18px] leading-[24px] text-[#6B7A99]'>
              {t('PricingAndPlans.plans.enterprise.price2')}
            </p>
          </div>

          <div className='flex p-[20px] flex-col gap-[24px]'>
            <button
              type='button'
              className={
                'w-full flex justify-center items-center px-[12px] py-[8px] rounded-[8px] h-[56px] bg-[#F7F8FA] ' +
                'text-[16px] leading-[24px] font-[500] text-[#2D3C59] shadow-[0_2px_6px_0_rgba(0,0,0,0.07)]'
              }
              disabled={unsubscribeLoading}
              onClick={toggleOpen}
            >
              {t('PricingAndPlans.plans.enterprise.contactUs')}
            </button>

            <div className='flex flex-col gap-[16px]'>
              <p className='text-[21px] leading-[32px] text-[#3D4E72]'>
                {t('PricingAndPlans.plans.enterprise.freeFeaturesPlus')}
              </p>
              {featuresEnterprise.map((feature, index) => (
                <div key={index} className='flex gap-[12px] items-start'>
                  <span className='h-[20px] w-[20px]'>
                    <IconCheck color='#25BD33' />
                  </span>
                  <span className='text-[14px] font-[500] leading-[20px] text-[#6B7A99]'>
                    {feature}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* <Faq /> */}
      <Modal isOpen={isOpen} onClose={toggleOpen} className='!px-[30px]'>
        <ContactUs onClose={toggleOpen} />
      </Modal>
    </div>
  );
};

export default Plans;
