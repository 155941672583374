import { useQuery } from 'react-query';

import { apiTeam } from 'api';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers';

export const useTeamBoardsQuery = (teamId: number) => {
  return useQuery(
    [`teams/team-boards/${teamId}`],
    async () => await apiTeam.getTeamBoards(teamId),
    {
      staleTime: DEFAULT_STALE_TIME,
      onError(error) {
        handleError(error);
      }
    }
  );
};
