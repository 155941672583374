import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { DeleteBoardDto, apiBoard } from 'api';
import { handleError, notifySuc } from 'helpers';
import { useInvalidate } from '../useInvalidate';

interface Params {
  onCancel?: () => void;
  onSuccess?: (variables: DeleteBoardDto) => void | Promise<void>;
}

export const useDeleteBoardMutation = ({ onCancel, onSuccess }: Params) => {
  const { t } = useTranslation();
  const invalidate = useInvalidate();

  return useMutation(
    (data: DeleteBoardDto) => {
      return apiBoard.deleteBoard(data);
    },
    {
      async onSuccess(_, variables) {
        notifySuc(t('common.notifications.boardDeleted'));
        await invalidate(`teams/one/${variables.teamId}`);
        await invalidate(`projects/${variables.projectId}`);

        onCancel && onCancel();
        onSuccess && onSuccess(variables);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
