import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useToggle } from 'hooks';
import { Member, Team } from 'models';
import styles from './Users.module.scss';

import { Button, IconPlus, Modal, SearchField } from 'components/atoms';
import { AddUsersForm, MemberList } from './components';

type UsersProps = {
  team: Team;
};

const Users: FC<UsersProps> = ({ team }) => {
  const { t } = useTranslation();
  const [addUsersModalActive, toggleAddUsersModalActive] = useToggle();

  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);
  useEffect(() => {
    if (team && team.members) {
      const filteredMembers = team.members.filter(({ username, email }) =>
        username.concat(email).toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredMembers(filteredMembers);
    }
  }, [searchValue, team]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.title}>{t('TeamPage.settings.teamUsers')}</h2>

        <div className='flex items-center gap-[20px]'>
          <SearchField
            placeholder={t('TeamPage.settings.searchUsers') as string}
            setSearchValue={setSearchValue}
          />

          <Button
            title={t('TeamPage.settings.addUsers')}
            type='button'
            variant='dark'
            size='small'
            icon={<IconPlus />}
            iconPosition='left'
            className='h-[40px]'
            onClick={toggleAddUsersModalActive}
          />
        </div>
      </header>

      <MemberList members={filteredMembers} className={styles['user-list']} />

      <Modal isOpen={addUsersModalActive} onClose={toggleAddUsersModalActive}>
        <AddUsersForm teamName={team.name} onCancel={toggleAddUsersModalActive} />
      </Modal>
    </div>
  );
};

export default Users;
