import { FC, useEffect } from 'react';

import { wbsInitialData } from 'consts';
import { useRFStore } from 'store';
import styles from './WBSPage.module.scss';

import { WBSFlow } from 'components/organisms';

const WBSPage: FC = () => {
  const setData = useRFStore(state => state.setData);

  useEffect(() => {
    setData(wbsInitialData);
  }, []);

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <WBSFlow />
      </div>
    </main>
  );
};

export default WBSPage;
