import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useMatch, useParams } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useTeam } from 'hooks/queries';
import styles from './TeamPage.module.scss';

import { Dashboard, Project, Settings, Sidebar, SidebarSettings, SidebarTeams } from './components';

type Params = { teamId: string };

const TeamPage: FC = () => {
  const { t } = useTranslation();
  const { teamId } = useParams<keyof Params>() as Params;
  const { data: team, isLoading: teamLoading } = useTeam(teamId);
  const match = useMatch(`${ROUTES.TEAMS}/:teamId/settings/*`);

  if (teamLoading) return <h2>{t('common.loading')}</h2>;
  if (!team) return <h2>{t('notifications.failedToLoadTeam')}</h2>;
  return (
    <main className='main'>
      <div
        className={
          `container ${styles.container} py-[32px] pr-[16px] items-start ` +
          `${match ? 'pl-[16px]' : 'pl-[80px]'}`
        }
      >
        {match ? (
          <SidebarSettings />
        ) : (
          <>
            <SidebarTeams />
            <Sidebar />
          </>
        )}

        <Routes>
          <Route path={`${ROUTES.PROJECTS}/:projectId/*`} element={<Project team={team} />} />
          <Route path='/settings/*' element={<Settings team={team} />} />
          <Route path='*' element={<Dashboard team={team} />} />
        </Routes>
      </div>
    </main>
  );
};

export default TeamPage;
