import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { SurveyScreens } from 'models';

import { Button } from 'components/atoms';

type EndScreenProps = {
  setCurrentScreen: Dispatch<SetStateAction<SurveyScreens>>;
  onClose?: () => void;
};

const EndScreen: FC<EndScreenProps> = ({ setCurrentScreen, onClose }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose?.();
    setCurrentScreen(SurveyScreens.START);
  };

  return (
    <div className='flex flex-col items-center'>
      <h1
        className={
          'mb-[44px] w-[490px] text-[32px] leading-[48px] text-center ' +
          'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
        }
      >
        {t('Survey.thankYou')}
      </h1>

      <Button
        variant='primary'
        size='big'
        title={t('Survey.close')}
        onClick={handleClose}
        className='min-w-[240px]'
      />
    </div>
  );
};

export default EndScreen;
