import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'consts';

import { IconBlocks, IconPremium, IconTeam, IconUsers } from 'components/atoms';
import { Team } from 'models';

type TabsProps = {
  team: Team;
};

const Tabs: FC<TabsProps> = ({ team }) => {
  const { t } = useTranslation();

  const tabs: {
    route: string;
    label: string;
    Icon: typeof IconTeam;
    disabled?: boolean;
  }[] = [
    { route: 'settings', label: t('TeamPage.settings.teamSettings'), Icon: IconTeam },
    { route: 'settings/users', label: t('TeamPage.settings.teamUsers'), Icon: IconUsers },
    {
      route: 'settings/apps-and-integrations',
      label: t('TeamPage.settings.appsAndIntegrations'),
      Icon: IconBlocks,
      disabled: true
    },
    { route: 'settings/upgrade', label: t('TeamPage.settings.billing'), Icon: IconPremium }
  ];

  return (
    <div className='py-[12px] flex flex-col gap-[10px]'>
      {tabs.map(({ route, label, Icon, disabled }) =>
        disabled ? (
          <button
            type='button'
            key={label}
            className={
              'h-[60px] p-[15px] flex items-center gap-[10px] rounded-[20px] bg-[#EDEFF2] ' +
              'shadow-[0_2px_5px_0_rgba(38,51,77,0.03)] select-none cursor-auto opacity-60'
            }
            disabled
          >
            <Icon className='h-[20px] w-[20px] text-[#2D3C59]' />
            <span className='text-[14px] font-[700] leading-[20px] tracking-[0.14px] text-[#2D3C59]'>
              {label}
            </span>
          </button>
        ) : (
          <NavLink
            key={label}
            to={`${ROUTES.TEAMS}/${team.id}/${route}`}
            end
            className={
              'h-[60px] flex items-center gap-[10px] p-[15px] rounded-[20px] bg-[#EDEFF2] ' +
              'shadow-[0_2px_5px_0_rgba(38,51,77,0.03)] select-none'
            }
          >
            {({ isActive }) => (
              <>
                <Icon
                  className={`h-[20px] w-[20px] ${isActive ? 'text-[#19B829]' : 'text-[#2D3C59]'}`}
                />
                <span className='text-[14px] font-[700] leading-[20px] tracking-[0.14px] text-[#2D3C59]'>
                  {label}
                </span>
              </>
            )}
          </NavLink>
        )
      )}
    </div>
  );
};

export default Tabs;
