import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ROUTES, TEAM_DASHBOARD_FILTER } from 'consts';
import { useTeam } from 'hooks/queries';
import styles from './Sidebar.module.scss';

import { IconClipboard, IconLayout } from 'components/atoms';
import { Projects, Users } from './components';

const Sidebar: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const { data: team, isLoading: teamLoading } = useTeam(teamId);
  const [searchParams] = useSearchParams();
  const activeFilter = useMemo(() => searchParams.get('filter'), [searchParams]);

  return (
    <aside className={styles.container}>
      {teamLoading ? (
        <h4>{t('common.loading')}</h4>
      ) : (
        <>
          {team ? (
            <>
              <div className={styles.lists}>
                <Projects projects={team.projects || []} />
                <Users members={team.members || []} />
              </div>

              <div className={styles.buttons}>
                <button
                  className={
                    activeFilter === TEAM_DASHBOARD_FILTER.MIND_CHARTS
                      ? `${styles.button} ${styles.button_active}`
                      : styles.button
                  }
                  onClick={() => {
                    if (activeFilter === TEAM_DASHBOARD_FILTER.MIND_CHARTS) {
                      navigate(`${ROUTES.TEAMS}/${teamId}`, { replace: true });
                    } else {
                      navigate(
                        `${ROUTES.TEAMS}/${teamId}?filter=${TEAM_DASHBOARD_FILTER.MIND_CHARTS}`
                      );
                    }
                  }}
                >
                  <div className={styles.icon}>
                    <IconLayout />
                  </div>
                  <p className={styles.text}>{t('TeamPage.sidebar.mindCharts')}</p>
                </button>

                <button
                  className={
                    activeFilter === TEAM_DASHBOARD_FILTER.RECENT_BOARDS
                      ? `${styles.button} ${styles.button_active}`
                      : styles.button
                  }
                  onClick={() => {
                    if (activeFilter === TEAM_DASHBOARD_FILTER.RECENT_BOARDS) {
                      navigate(`${ROUTES.TEAMS}/${teamId}`, { replace: true });
                    } else {
                      navigate(
                        `${ROUTES.TEAMS}/${teamId}?filter=${TEAM_DASHBOARD_FILTER.RECENT_BOARDS}`
                      );
                    }
                  }}
                >
                  <div className={styles.icon}>
                    <IconClipboard />
                  </div>
                  <p className={styles.text}>{t('TeamPage.sidebar.recentBoards')}</p>
                </button>
              </div>
            </>
          ) : (
            <h4>Can&apos;t fetch team</h4>
          )}
        </>
      )}
    </aside>
  );
};

export default Sidebar;
