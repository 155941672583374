import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { ROUTES } from 'consts';
import { useAuth } from 'hooks';
import { useUserQuery } from 'hooks/user';
import { Team } from 'models';
import styles from './UserBlock.module.scss';

import {
  IconBlocks,
  IconLogout,
  IconPremium,
  IconStrokeDown,
  IconTeam,
  IconUser,
  IconUsers
} from 'components/atoms';

interface UserBlockProps {
  team?: Team;
  className?: string;
}

const UserBlock: FC<UserBlockProps> = ({ team, className }) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { data: user, isLoading: userLoading } = useUserQuery();

  if (userLoading) return <h2>{t('common.loading')}</h2>;
  if (!user) return <h2>Failed to load the user</h2>;
  return (
    <div className={`${styles.container} ${className}`}>
      <button type='button' className={styles.header} data-tooltip-id='user-block-tooltip'>
        <h4 className={styles.name}>{user.username}</h4>
        <div className={styles.avatar}>
          <IconUser />
        </div>
        <div className={styles.stroke}>
          <IconStrokeDown />
        </div>
      </button>

      <Tooltip
        id='user-block-tooltip'
        className={styles.tooltip}
        offset={0}
        place='bottom'
        clickable
      >
        <div className={styles.profile}>
          <NavLink
            to={ROUTES.PROFILE}
            className={({ isActive }) =>
              isActive
                ? `${styles.tooltip__button} ${styles.tooltip__button_active}`
                : styles.tooltip__button
            }
          >
            <IconUser className='h-[20px] w-[20px]' />
            Profile details
          </NavLink>
        </div>

        {team && (
          <div className={styles.team}>
            <h4 className={styles.tooltip__title}>Team</h4>

            <NavLink
              to={`${ROUTES.TEAMS}/${team.id}/${ROUTES.TEAM_SETTINGS}`}
              className={styles.tooltip__button}
            >
              <IconTeam className='h-[20px] w-[20px]' />
              Team settings
            </NavLink>

            <NavLink
              to={`${ROUTES.TEAMS}/${team.id}/${ROUTES.TEAM_USERS}`}
              className={styles.tooltip__button}
            >
              <IconUsers className='h-[20px] w-[20px]' />
              Team users
            </NavLink>

            <button className={styles.tooltip__button} disabled>
              <IconBlocks className='h-[20px] w-[20px]' />
              Apps & Inregrations
            </button>

            <NavLink
              to={`${ROUTES.TEAMS}/${team.id}/${ROUTES.TEAM_UPGRADE}`}
              className={styles.tooltip__button}
            >
              <IconPremium className='h-[20px] w-[20px]' />
              Upgrade
            </NavLink>
          </div>
        )}

        <div className={styles.logout}>
          <button className={styles.tooltip__button} onClick={logout}>
            <IconLogout />
            Log out
          </button>
        </div>
      </Tooltip>
    </div>
  );
};

export default UserBlock;
