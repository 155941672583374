import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useSwitchRoleMutation } from 'hooks/mutations';
import { SelectOption, TEAM_ROLES } from 'models';
import styles from './SelectRole.module.scss';

import { Select } from 'components/atoms';

const roleOptions: SelectOption[] = [
  {
    value: TEAM_ROLES.VIEWER,
    label: 'Viewer'
  },
  {
    value: TEAM_ROLES.EDITOR,
    label: 'Editor'
  },
  {
    value: TEAM_ROLES.ADMIN,
    label: 'Admin'
  },
  {
    value: TEAM_ROLES.OWNER,
    label: 'Owner'
  }
];

interface SelectRoleProps {
  userId: number;
  role: TEAM_ROLES;
  className: string;
  disabled?: boolean;
}

const SelectRole: FC<SelectRoleProps> = ({ userId, role, className, disabled = false }) => {
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const { mutateAsync: switchRoleMutate, isLoading: switchRoleLoading } = useSwitchRoleMutation({});

  const handleRoleChange = (role: string) => {
    switchRoleMutate({
      teamId: Number(teamId),
      body: { memberId: userId, newRole: role as TEAM_ROLES }
    });
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <Select
        placeholder='Choose role'
        options={roleOptions}
        currentOption={roleOptions.find(option => option.value === role)}
        setCurrentOption={option => handleRoleChange(option.value)}
        headerClassName={styles.select__header}
        isDisabled={disabled || switchRoleLoading}
      />
    </div>
  );
};

export default SelectRole;
