import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import LogoHorizontalSVG from 'assets/images/svg/logo-horizontal.svg';

import { ROUTES } from 'consts';
import { useTeam } from 'hooks/queries';
import styles from './HeaderTeam.module.scss';

import { Button, IconPremium } from 'components/atoms';
import { FeedbackButton } from 'components/atoms/FeedbackButton';
import { SearchBoards, UserBlock } from 'components/molecules';

const HeaderTeam: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const { data: team } = useTeam(teamId);
  const isSubscribed = useMemo(() => team?.product?.subscriptionStatus === 'active', [team]);

  const goToPricingAndPlans = () => navigate(`${ROUTES.TEAMS}/${teamId}/${ROUTES.TEAM_UPGRADE}`);

  return (
    <>
      <div className={styles.logo}>
        <Link to={team ? `${ROUTES.TEAMS}/${team.id}` : ROUTES.HOME} className={styles.link}>
          <img src={LogoHorizontalSVG} alt='logo.svg' />
        </Link>
      </div>

      <SearchBoards className={styles['search-boards']} />

      <FeedbackButton className='mr-[16px]' />
      <Button
        type='button'
        variant='primary'
        size='small'
        title={isSubscribed ? t('common.upgrade') : t('common.upgradeToPro')}
        icon={<IconPremium />}
        iconPosition='left'
        onClick={goToPricingAndPlans}
        className={styles['button-premium']}
      />

      <UserBlock team={team} />
    </>
  );
};

export default HeaderTeam;
