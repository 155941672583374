import { FC, useEffect } from 'react';

import { roadmapInitialData } from 'consts';
import { useRFStore } from 'store';
import styles from './RoadmapPage.module.scss';

import { RoadmapFlow } from 'components/organisms';

const RoadmapPage: FC = () => {
  const setData = useRFStore(state => state.setData);

  useEffect(() => {
    setData(roadmapInitialData);
  }, []);

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <RoadmapFlow />
      </div>
    </main>
  );
};

export default RoadmapPage;
