import { ButtonHTMLAttributes, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useToggle } from 'hooks';
import { useDeleteTeamMutation } from 'hooks/mutations';

import { Modal } from 'components/atoms';
import { DeleteSmthForm } from 'components/organisms';

type DeleteTeamButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  teamId: number;
};

const DeleteTeamButton: FC<DeleteTeamButtonProps> = ({ teamId, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deleteTeamModalActive, toggleDeleteTeamModalActive] = useToggle(false);
  const { mutate: deleteTeamMutate, isLoading: deleteTeamLoading } = useDeleteTeamMutation();

  const onDelete = async () => {
    deleteTeamMutate(
      { teamId },
      {
        onSuccess: () => {
          toggleDeleteTeamModalActive();
          navigate(ROUTES.CHOOSE_TEAM);
        }
      }
    );
  };

  return (
    <>
      <button
        type='button'
        onClick={toggleDeleteTeamModalActive}
        className={
          'bg-transparent text-[14px] font-[700] leading-[20px] tracking-[0.14px] ' +
          'text-[#4971FF] select-none transition-all duration-300 ' +
          'disabled:opacity-30 disabled:cursor-auto hover:enabled:text-[var(--color-primary)]'
        }
        {...props}
      >
        {t('TeamPage.settings.deleteTeam')}
      </button>

      <Modal isOpen={deleteTeamModalActive} onClose={toggleDeleteTeamModalActive}>
        <DeleteSmthForm
          entity='team'
          onDelete={onDelete}
          isLoading={deleteTeamLoading}
          onCancel={toggleDeleteTeamModalActive}
        />
      </Modal>
    </>
  );
};

export default DeleteTeamButton;
