import { FC, useState } from 'react';

import { Team } from 'models/Team';
import { UnsubscribedScreens } from 'models/unsubscribed';

import { UnsubscribedModal, HaveUnsubscribedModal, Survey, ContactUs } from '../../organisms';

type CommonUnscubscribedProps = {
  onClose?: () => void;
  team: Team | undefined;
  unsubscribe: () => Promise<void>;
};

const CommonUnscubscribedModal: FC<CommonUnscubscribedProps> = ({ onClose, team, unsubscribe }) => {
  const [currentScreen, setCurrentScreen] = useState<UnsubscribedScreens>(
    UnsubscribedScreens.UNSUBSCRIPTION
  );

  return (
    <div>
      {currentScreen === UnsubscribedScreens.UNSUBSCRIPTION && (
        <UnsubscribedModal
          setCurrentScreen={setCurrentScreen}
          onClose={onClose}
          team={team}
          unsubscribe={unsubscribe}
        />
      )}

      {currentScreen === UnsubscribedScreens.HAVEUNSUBSCRIPTION && (
        <HaveUnsubscribedModal setCurrentScreen={setCurrentScreen} onClose={onClose} />
      )}

      {currentScreen === UnsubscribedScreens.FEEDBACK && <Survey onClose={onClose} />}

      {currentScreen === UnsubscribedScreens.CONTACTUS && <ContactUs onClose={onClose} />}
    </div>
  );
};

export default CommonUnscubscribedModal;
