import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms';
import { UnsubscribedScreens } from 'models/unsubscribed';

type HaveUnsubscribedProps = {
  setCurrentScreen: Dispatch<SetStateAction<UnsubscribedScreens>>;
  onClose?: () => void;
};

const HaveUnsubscribedModal: FC<HaveUnsubscribedProps> = ({ setCurrentScreen }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col items-center w-[740px] h-[380px]'>
      <h1
        className={
          'mb-[16px] w-[560px] text-[32px] leading-[48px] text-center ' +
          'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
        }
      >
        {t('HaveUnsubscribed.youHaveUnsubscribedFrom')}{' '}
        <span className='text-[#19B829]'>TEAM+</span> {t('HaveUnsubscribed.plan')}
      </h1>

      <p className='text-[20px] leading-[32px] font-[400] text-[var(--color-dark)]'>
        {t('HaveUnsubscribed.thankYou')}
      </p>
      <p className='text-[20px] leading-[32px] font-[400] text-[var(--color-dark)]'>
        {t('HaveUnsubscribed.youCan')}
      </p>
      <p className='text-[20px] leading-[32px] font-[400] text-[var(--color-dark)] mb-[10px]'>
        {t('HaveUnsubscribed.weWill')}
      </p>

      <div className='bg-[#F1F7FF] rounded-[10px] w-[560px] pt-[24px] pr-[32px] pl-[32px]'>
        <h2
          className={
            'text-[21px] leading-[48px] text-left ' +
            'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
          }
        >
          {t('HaveUnsubscribed.improveMigned')}
        </h2>
        <p className='text-[16px] leading-[32px] font-[400] text-[var(--color-dark)] mb-[24px]'>
          {t('HaveUnsubscribed.leaveYourFeedback')}
        </p>

        <div className='flex flex-row items-center justify-center gap-x-[12px]'>
          <Button
            variant='secondary'
            size='big'
            title={t('HaveUnsubscribed.leaveFeedback')}
            onClick={() => setCurrentScreen(UnsubscribedScreens.FEEDBACK)}
            className='mb-[32px] min-w-[240px]'
          />

          <Button
            variant='secondary'
            size='big'
            title={t('HaveUnsubscribed.contactUs')}
            onClick={() => setCurrentScreen(UnsubscribedScreens.CONTACTUS)}
            className='mb-[32px] min-w-[240px]'
          />
        </div>
      </div>
    </div>
  );
};

export default HaveUnsubscribedModal;
