import { BackgroundVariant } from 'reactflow';

export const RF_BACKGROUND_VARIANT = BackgroundVariant.Dots;

export const RF_DEFAULT_ZOOM = 1.85;
export const RF_DEFAULT_PAN_ON_SCROLL = 0.5;
export const RF_DEFAULT_ZOOM_ACTIVATION_KEY_CODE = ['Meta', 'Space', 'Shift', 'Ctrl', 'ctrl'];
export const RF_ZOOM_DURATION = 500;
export const RF_ZOOM_STEP = 0.1;
export const RF_ZOOM_MINIMUM = 0.1;
export const RF_ZOOM_MAXIMUM = 4;
export const RF_FIT_VIEW_ZOOM_MAXIMUM = 2;
export const RF_FIT_VIEW_DURATION = 500;
export const RF_FIT_VIEW_TIMEOUT = 100;

export enum NODE_TYPES {
  TEXT = 'textNode',

  MINDMAP = 'mindMapNode',

  ROADMAP_ROOT = 'roadmapRootNode',
  ROADMAP_EPIC = 'roadmapEpicNode',
  ROADMAP_EPIC_CONTAINER = 'roadmapEpicContainer',
  ROADMAP_TASK = 'roadmapTaskNode',
  ROADMAP_TASK_CONTAINER = 'roadmapTaskContainerNode',
  ROADMAP_INTERVAL = 'roadmapIntervalNode',
  ROADMAP_INTERVAL_CONTAINER = 'roadmapIntervalContainer',
  ROADMAP_MILESTONE = 'roadmapMilestoneNode',
  ROADMAP_MILESTONE_CONTAINER = 'roadmapMilestoneContainer',

  FLOWCHART_ROOT = 'flowchartRootNode',
  FLOWCHART_DEFAULT = 'flowchartDefaultNode',
  FLOWCHART_DATA = 'flowchartDataNode',
  FLOWCHART_QUESTION = 'flowchartQuestionNode',
  FLOWCHART_END = 'flowchartEndNode',

  WBS_ROOT = 'wbsRootNode',
  WBS_EPIC = 'wbsEpicNode',
  WBS_TASK = 'wbsTaskNode'
}

export enum EDGE_TYPES {
  MINDMAP = 'mindMapEdge',
  ROADMAP = 'roadmapEdge',
  FLOWCHART = 'flowchartEdge',
  WBS = 'wbsEdge'
}

export const KNOWLEDGE_MAX_LENGTH = 400;
export const INSTRUCTION_MAX_LENGTH = 2000;
