import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Link as RSLink } from 'react-scroll';

import LogoHorizontalSVG from 'assets/images/svg/logo-horizontal.svg';

import { ROUTES, TABLET_WIDTH } from 'consts';
import { Size, useWindowSize } from 'hooks/common';
import styles from './HeaderLanding.module.scss';

import {
  Button,
  IconAlignJustify,
  IconCross,
  IconFacebook,
  IconInstagram,
  IconLogin
} from 'components/atoms';
import { useToggle } from 'hooks';

const HeaderLanding: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const size: Size = useWindowSize();
  const [menuOpen, toogleMenu] = useToggle();

  return (
    <>
      {size.width < TABLET_WIDTH && (
        <>
          <button className={styles.burger} onClick={toogleMenu}>
            {menuOpen ? <IconCross /> : <IconAlignJustify />}
          </button>

          <div className={menuOpen ? `${styles.menu} ${styles.menu_active}` : styles.menu}>
            <div className={styles.header}>
              {menuOpen && (
                <button className={styles.burger} onClick={toogleMenu}>
                  <IconCross />
                </button>
              )}

              <Link to={ROUTES.HOME} className={styles.logo} onClick={toogleMenu}>
                <img src={LogoHorizontalSVG} alt='logo.svg' />
              </Link>

              <Button
                title={t('common.form.login')}
                type='button'
                variant='secondary'
                icon={size.width >= TABLET_WIDTH ? <IconLogin color='#19b829' /> : undefined}
                iconPosition='left'
                onClick={() => {
                  toogleMenu();
                  navigate(ROUTES.LOGIN);
                }}
              />
            </div>

            <div className={styles.menu__navigation}>
              <RSLink to='features' onClick={toogleMenu}>
                <button className={styles.navigation__link}>
                  {t('LandingPage.navigation.features')}
                </button>
              </RSLink>
              <RSLink to='testimonials' onClick={toogleMenu}>
                <button className={styles.navigation__link}>
                  {t('LandingPage.navigation.testimonials')}
                </button>
              </RSLink>
              <RSLink to='pricing' onClick={toogleMenu}>
                <button className={styles.navigation__link}>
                  {t('LandingPage.navigation.pricing')}
                </button>
              </RSLink>
              <RSLink to='footer' onClick={toogleMenu}>
                <button className={styles.navigation__link}>
                  {t('LandingPage.navigation.contact')}
                </button>
              </RSLink>
            </div>

            <div className={styles.contacts}>
              <p className={styles.address}>{t('Footer.info.address')}</p>
              <a href={`tel:${t('Footer.info.tel')}`} className={styles.tel}>
                {t('Footer.info.tel')}
              </a>
              <a href={`mailto:${t('Footer.info.email')}`} className={styles.email}>
                {t('Footer.info.email')}
              </a>
            </div>

            <div className={styles.links}>
              <a
                href={t('Footer.links.facebook') as string}
                target='_blank'
                rel='noreferrer'
                className={styles.link}
              >
                <IconFacebook />
              </a>
              <a
                href={t('Footer.links.instagram') as string}
                target='_blank'
                rel='noreferrer'
                className={styles.link}
              >
                <IconInstagram />
              </a>
            </div>

            <p className={styles.rights}>{t('Footer.info.rights')}</p>

            <div className={styles.policies}>
              <p className={styles.policy}>{t('Footer.policies.termsOfPolicy')}</p>
              <p className={styles.policy}>{t('Footer.policies.privacyPolicy')}</p>
              <p className={styles.policy}>{t('Footer.policies.gdprPolicy')}</p>
            </div>
          </div>

          {menuOpen && <div className={styles.backdrop} onClick={toogleMenu}></div>}
        </>
      )}

      <Link to={ROUTES.HOME} className={styles.logo}>
        <img src={LogoHorizontalSVG} alt='logo.svg' />
      </Link>

      {size.width >= TABLET_WIDTH && (
        <div className={styles.navigation}>
          {/* <RSLink to='features'>
            <button className={styles.navigation__link}>
              {t('LandingPage.navigation.features')}
            </button>
          </RSLink>
          <RSLink to='testimonials'>
            <button className={styles.navigation__link}>
              {t('LandingPage.navigation.testimonials')}
            </button>
          </RSLink>
          <RSLink to='pricing'>
            <button className={styles.navigation__link}>
              {t('LandingPage.navigation.pricing')}
            </button>
          </RSLink>
          <RSLink to='footer'>
            <button className={styles.navigation__link}>
              {t('LandingPage.navigation.contact')}
            </button>
          </RSLink>*/}
        </div>
      )}

      <div className={styles.controls}>
        <Button
          title={t('common.form.login')}
          type='button'
          variant='secondary'
          size='small'
          icon={size.width >= 768 ? <IconLogin color='#19b829' /> : undefined}
          iconPosition='left'
          onClick={() => navigate(ROUTES.LOGIN)}
          className={styles.button}
        />
        {size.width >= 768 && (
          <Button
            title={t('common.form.signup')}
            type='button'
            variant='light'
            size='small'
            onClick={() => navigate(ROUTES.SIGNUP)}
            className={styles.button}
          />
        )}
      </div>
    </>
  );
};

export default HeaderLanding;
