import { ConnectionLineComponentProps } from 'reactflow';

const MindMapConnectionLine = ({ fromX, fromY, toX, toY }: ConnectionLineComponentProps) => {
  return (
    <g>
      <path
        fill='none'
        stroke='#C3CAD9'
        strokeWidth={2}
        className='animated'
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
      <circle cx={toX} cy={toY} fill='none' r={3} stroke='#C3CAD9' strokeWidth={1.5} />
    </g>
  );
};

export default MindMapConnectionLine;
