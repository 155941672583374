import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Team } from 'models';

import { LeaveTeam, PlanDetails, UpdateTeam } from './components';

type MainProps = {
  team: Team;
};

const Main: FC<MainProps> = ({ team }) => {
  const { t } = useTranslation();

  return (
    <div className='grow flex flex-col gap-[20px]'>
      <h3 className='text-[21px] font-[500] leading-[30px] tracking-[0.21px] text-[#2D3C59]'>
        {t('TeamPage.settings.teamSettings')}
      </h3>

      <UpdateTeam team={team} />
      <PlanDetails team={team} />
      <LeaveTeam team={team} />
    </div>
  );
};

export default Main;
