import { FC, useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useCreateProjectMutation, useCreateTeamMutation } from 'hooks/mutations';
import styles from './CreateTeam.module.scss';

import { Button, InputText, IconCross } from 'components/atoms';

const CreateTeam: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });

  const { mutateAsync: createProjectMutate } = useCreateProjectMutation({});
  const { mutateAsync: createTeamMutate, isLoading: createTeamLoading } = useCreateTeamMutation();

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSubmit = async ({ name }: FieldValues) => {
    createTeamMutate(
      { name },
      {
        onSuccess: async data => {
          await createProjectMutate({ teamId: data.id, name: t('common.defaultProject') });
          navigate(`${ROUTES.TEAMS}/${data.id}`);
        }
      }
    );
  };

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          <button className={styles['button-delete']} onClick={handleClose}>
            <IconCross />
          </button>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <h4 className={styles.form__title}>{t('CreateTeam.form.title')}</h4>

            <div className={styles.form__fields}>
              <InputText
                type='text'
                width='big'
                name='name'
                label={t('CreateTeam.form.placeholder') as string}
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
                  validate: {
                    doesntConsistOfSpaces: (value: string) => {
                      return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                    }
                  }
                }}
                isLoading={createTeamLoading}
                autoComplete='off'
              />
            </div>

            <Button
              type='submit'
              variant='primary'
              size='big'
              wide
              title={t('CreateTeam.form.button')}
              isDisabled={!isDirty}
              isLoading={createTeamLoading}
            />
          </form>
        </div>
      </div>
    </main>
  );
};

export default CreateTeam;
