import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconRoadmap: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M10 4H16' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <path d='M10 8H16' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <path d='M16 20H22' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <path d='M16 16H22' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <path d='M10 12L22 12' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <path
        d='M7 3H2V8H7V3Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7 12H2V20H7V12Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
