import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconSmall: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15 6L11 6C9.89543 6 9 6.89543 9 8V10C9 11.1046 9.89543 12 11 12H13C14.1046 12 15 12.8954 15 14V16C15 17.1046 14.1046 18 13 18H9'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};
