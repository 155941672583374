import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { ROUTES } from 'consts';
import { useBoardIcon, useToggle } from 'hooks';
import { useUpdateBoardMutation } from 'hooks/mutations';
import { useDeleteBoardMutation } from 'hooks/mutations/useDeleteBoardMutation';
import { useProject, useTeam } from 'hooks/queries';
import { BOARD_TYPES } from 'models';
import { useBoardStore } from 'store';
import styles from './BoardBlock.module.scss';

import { IconStrokeDown, Modal } from 'components/atoms';
import { DeleteSmthForm } from 'components/organisms';
import { MoveToProjectForm } from '..';

type Params = { teamId: string; projectId: string; boardId: string };

interface BoardBlockProps {
  className: string;
}

const BoardBlock: FC<BoardBlockProps> = ({ className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId, projectId, boardId } = useParams<keyof Params>() as Params;
  const { data: team } = useTeam(teamId);
  const { data: project } = useProject(Number(projectId));
  const board = useBoardStore(state => state.board);
  const Icon = useBoardIcon(board?.type || BOARD_TYPES.MINDMAP);
  const [moveModalActive, toggleMoveModalActive] = useToggle();

  const inputRef = useRef<HTMLInputElement>(null);
  const [deleteModalActive, toggleDeleteModalActive] = useToggle();
  const { mutate: deleteBoardMutate, isLoading: deleteBoardLoading } = useDeleteBoardMutation({
    onSuccess: ({ teamId, projectId }) => {
      toggleDeleteModalActive();
      navigate(`${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${projectId}`);
    }
  });

  const [editMode, setEditMode] = useState<boolean>(false);
  const [newBoardName, setNewBoardName] = useState<string>('');
  const { mutateAsync: renameBoardMutate, isLoading: renameBoardLoading } = useUpdateBoardMutation({
    onSuccess: () => {
      setEditMode(false);
    }
  });

  const handleRename = () => {
    if (editMode) {
      if (!newBoardName.length || newBoardName === board?.name) {
        setEditMode(false);
        return;
      }
      renameBoard();
    } else {
      board && setNewBoardName(board.name);
      setEditMode(true);
      setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current?.select();
      });
    }
  };

  const handleDoubleClickTeam = () => {
    setEditMode(true);
    handleRename();
  };

  const renameBoard = () => {
    renameBoardMutate({
      teamId: Number(teamId),
      projectId: Number(projectId),
      boardId: Number(boardId),
      body: { name: newBoardName }
    });
  };

  return (
    <>
      <div className={`${styles.container} ${className}`}>
        {board ? (
          <div onDoubleClick={handleDoubleClickTeam}>
            <button type='button' className={styles.header} data-tooltip-id='board-block-tooltip'>
              {editMode ? (
                <input
                  ref={inputRef}
                  value={newBoardName}
                  onChange={e => setNewBoardName(e.target.value)}
                  onBlur={handleRename}
                  onFocus={e => e.target.select()}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      renameBoard();
                    }
                    if (event.key === 'Escape') {
                      event.preventDefault();
                      setEditMode(false);
                    }
                  }}
                  disabled={renameBoardLoading}
                  className={styles.input}
                />
              ) : (
                <>
                  <div className={styles['icon-wrapper']}>
                    <Icon className={styles.icon} />
                  </div>
                  <h5 className={styles.name}>{board.name}</h5>
                  <div className={styles.stroke}>
                    <IconStrokeDown />
                  </div>
                </>
              )}
            </button>

            <Tooltip
              id='board-block-tooltip'
              className={styles.tooltip}
              offset={11}
              place='bottom'
              clickable
            >
              <div className={styles['team-project']}>
                <div className={styles.team}>
                  <h4 className={styles.team__title}>Team</h4>
                  {team?.name && (
                    <p title={team.name} className={styles.team__name}>
                      {team.name}
                    </p>
                  )}
                </div>
                <div className={styles.project}>
                  <h4 className={styles.project__title}>Project</h4>
                  {project?.name && (
                    <p title={project.name} className={styles.project__name}>
                      {project.name}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles.move}>
                <button className={styles.move__button} onClick={toggleMoveModalActive}>
                  Move to project...
                </button>
              </div>
              <div className={styles.controls}>
                <button
                  className={styles.controls__button}
                  disabled={editMode}
                  onClick={handleRename}
                >
                  Rename
                </button>
                <button className={styles.controls__button} disabled>
                  Duplicate
                </button>
                <button className={styles.controls__button} disabled>
                  Export...
                </button>
              </div>
              <div className={styles.delete}>
                <button className={styles.delete__button} onClick={toggleDeleteModalActive}>
                  Delete...
                </button>
              </div>
            </Tooltip>

            <Modal isOpen={moveModalActive} onClose={toggleMoveModalActive}>
              <MoveToProjectForm
                boardId={board.id}
                onSuccess={async ({ id: boardId }, { teamId, projectId }) => {
                  navigate(
                    `${ROUTES.TEAMS}/${teamId}${ROUTES.PROJECTS}/${projectId}/${ROUTES.BOARDS}/${boardId}`
                  );
                }}
                onCancel={toggleMoveModalActive}
              />
            </Modal>

            <Modal isOpen={deleteModalActive} onClose={toggleDeleteModalActive}>
              <DeleteSmthForm
                entity='board'
                onDelete={async () => {
                  deleteBoardMutate({
                    teamId: Number(teamId),
                    projectId: Number(projectId),
                    boardId: board.id
                  });
                }}
                isLoading={deleteBoardLoading}
                onCancel={toggleDeleteModalActive}
              />
            </Modal>
          </div>
        ) : (
          <h4 className={styles.name}>{t('common.loading')}</h4>
        )}
      </div>
    </>
  );
};

export default BoardBlock;
