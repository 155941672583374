import { Node } from 'reactflow';

import { NodeData } from 'store';
import { DEFAULT_MINDMAP_NODE_GAP, DEFAULT_MINDMAP_NODE_HEIGHT } from '../const';

export const normalizeNodes = (nodes: Node<NodeData>[]) => {
  const firstLevelNodes = nodes.filter(node => node.data.level === 1);
  const otherNodes = nodes.filter(node => node.data.level !== 1);

  const totalNodes = firstLevelNodes.length;
  const totalHeight =
    totalNodes * DEFAULT_MINDMAP_NODE_HEIGHT +
    (totalNodes - 1) * DEFAULT_MINDMAP_NODE_GAP -
    DEFAULT_MINDMAP_NODE_HEIGHT;
  const offsetY = -(totalHeight / 2);

  const normalizedFirstLevelNodes = firstLevelNodes.map((node, index) => {
    const verticalOffset =
      offsetY + index * (DEFAULT_MINDMAP_NODE_HEIGHT + DEFAULT_MINDMAP_NODE_GAP);

    return {
      ...node,
      position: { ...node.position, y: verticalOffset }
    };
  });

  return [...normalizedFirstLevelNodes, ...otherNodes];
};
