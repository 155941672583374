import { NodeResizer } from '@reactflow/node-resizer';
import { ChangeEvent, FC, KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { NodeProps, NodeToolbar, Position } from 'reactflow';
import { shallow } from 'zustand/shallow';

import { NODE_SIZE, NodeData, RFState, useRFStore } from 'store';
import styles from './TextNode.module.scss';

import {
  IconBold,
  IconChain,
  IconCopy,
  IconDividerVertical,
  IconDotsHorizontal,
  IconItalic,
  IconLarge,
  IconMiddle,
  IconSmall,
  IconTrash,
  IconUnderscore
} from 'components/atoms';
import { useNodeSize, useSocket } from 'hooks';
import { useParams } from 'react-router-dom';

const selector = (state: RFState) => ({
  setData: state.setData,
  updateNodeValue: state.updateNodeValue,
  updateNodeSize: state.updateNodeSize,
  addNode: state.addNode,
  removeNode: state.removeNode
});

const sizes: {
  type: NODE_SIZE;
  icon: JSX.Element;
  label: string;
}[] = [
  { type: NODE_SIZE.SMALL, icon: <IconSmall />, label: 'Small' },
  { type: NODE_SIZE.MIDDLE, icon: <IconMiddle />, label: 'Middle' },
  { type: NODE_SIZE.LARGE, icon: <IconLarge />, label: 'Large' }
];

type Params = { boardId: string };

const TextNode: FC<NodeProps<NodeData>> = ({ id, data, selected, dragging }) => {
  const [inputActive, setInputActive] = useState<boolean>(false);
  const { updateNodeValue, updateNodeSize, removeNode } = useRFStore(selector, shallow);
  const { sizeIcon, sizeLabel, sizeClass, minHeight } = useNodeSize(data.size || NODE_SIZE.SMALL);
  const { boardId } = useParams<keyof Params>() as Params;
  const { updateNodeById } = useSocket(Number(boardId));

  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.value]);

  const handleChange = ({
    target: { scrollHeight, value, style, selectionStart }
  }: ChangeEvent<HTMLTextAreaElement>) => {
    style.height = '24px';
    style.height = `${scrollHeight}px`;

    setCursor(selectionStart);
    updateNodeValue(id, value);
    updateNodeById({ boardId: Number(boardId), nodeId: id, data: { value } });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      ref.current?.blur();
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.detail === 2) {
      setInputActive(true);
      setTimeout(() => ref.current?.focus());
    }
  };

  return (
    <>
      <NodeToolbar
        isVisible={!dragging && data.toolbar?.visible}
        position={Position.Top}
        offset={30}
      >
        <div className={styles.contextbar}>
          <button className={styles.contextbar__button} disabled>
            <IconChain />
          </button>

          <IconDividerVertical color='#6B7A99' />

          <button className={styles.contextbar__button} disabled>
            <IconBold />
          </button>

          <button className={styles.contextbar__button} disabled>
            <IconItalic />
          </button>

          <button className={styles.contextbar__button} disabled>
            <IconUnderscore />
          </button>

          <button className={styles.contextbar__button} disabled>
            <IconDotsHorizontal />
          </button>

          <IconDividerVertical color='#6B7A99' />

          <button
            className={`${styles.contextbar__button} ${styles.contextbar__button_size}`}
            data-tooltip-id='choose-size-tooltip'
          >
            {sizeIcon}
            {sizeLabel}
          </button>
          <Tooltip
            id='choose-size-tooltip'
            className={styles.tooltip}
            offset={20}
            place='top'
            clickable
          >
            <div className={styles.tooltip__list}>
              {sizes.map((size, index) => (
                <button
                  key={index}
                  className={
                    data.size === size.type
                      ? `${styles.tooltip__item} ${styles.tooltip__item_active}`
                      : styles.tooltip__item
                  }
                  onClick={() => updateNodeSize(id, size.type)}
                >
                  {size.icon}
                  {size.label}
                </button>
              ))}
            </div>
          </Tooltip>

          <button className={styles.contextbar__button} disabled>
            <IconDotsHorizontal />
          </button>

          <IconDividerVertical color='#6B7A99' />

          <button className={styles.contextbar__button} disabled>
            <IconCopy />
          </button>

          <IconDividerVertical color='#6B7A99' />

          <button
            className={`${styles.contextbar__button} ${styles.contextbar__button_delete}`}
            onClick={() => removeNode(id)}
          >
            <IconTrash />
            Delete
          </button>
        </div>
      </NodeToolbar>

      <button
        type='button'
        className={
          `${styles.container} ` +
          `${selected ? styles.container_active : ''} ` +
          `${inputActive ? styles.container_double : ''}`
        }
        onClick={handleClick}
      >
        <NodeResizer
          isVisible={selected}
          minHeight={minHeight}
          minWidth={50}
          lineStyle={{ display: 'none' }}
        />

        <form
          className={styles.form}
          onSubmit={e => {
            e.preventDefault();
            ref.current?.blur();
          }}
        >
          {inputActive ? (
            <textarea
              ref={ref}
              id={`roadmap-${id}`}
              name='roadmap-input'
              placeholder='Add text...'
              title={data.value || 'Add text...'}
              value={data.value}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onBlur={() => setInputActive(false)}
              className={`${styles.textarea} ${sizeClass} nodrag`}
            />
          ) : (
            <span className={`${styles.text} ${sizeClass}`}>{data.value || 'Add text...'}</span>
          )}
        </form>
      </button>
    </>
  );
};

export default TextNode;
