import { NodeResizer } from '@reactflow/node-resizer';
import { ChangeEvent, FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Handle, NodeProps, NodeToolbar, Position, useReactFlow } from 'reactflow';
import { shallow } from 'zustand/shallow';

import { RF_FIT_VIEW_DURATION, RF_FIT_VIEW_TIMEOUT, RF_FIT_VIEW_ZOOM_MAXIMUM } from 'consts';
import { notifyWarn } from 'helpers';
import { useSocket } from 'hooks';
import { useGenerateNodesMutation } from 'hooks/mutations';
import { NodeData, RFState, useRFStore } from 'store';
import { DEFAULT_ROADMAP_EPIC_COLOR } from '../../../const';
import { useRoadmap } from '../../../useRoadmap';
import styles from './RoadmapTaskNode.module.scss';

import {
  IconKnowledge,
  IconLoader,
  IconMagicWand,
  IconStrokeDown,
  IconStrokeUp
} from 'components/atoms';
import { Contextbar } from 'components/organisms/flows/common';

const selector = (state: RFState) => ({
  currentEdgeType: state.currentEdgeType,
  setData: state.setData,
  nodes: state.nodes,
  updateNodeValue: state.updateNodeValue
});

const RoadmapTaskNode: FC<NodeProps<NodeData>> = ({ id, data, selected, dragging }) => {
  const { boardId } = useParams<keyof { boardId: string }>() as { boardId: string };
  const { updateNodeById } = useSocket(Number(boardId));
  const { fitView } = useReactFlow();
  const documentCount = useMemo(() => data.linkedDocuments?.length, [data.linkedDocuments]);
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [showGenerateButton, setShowGenerateButton] = useState<boolean>(false);
  const { currentEdgeType, setData, nodes, updateNodeValue } = useRFStore(selector, shallow);
  const { handleMoveTask } = useRoadmap(Number(boardId));

  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.value]);

  const handleChange = ({ target: { selectionStart, value } }: ChangeEvent<HTMLInputElement>) => {
    setCursor(selectionStart);
    updateNodeValue(id, value);
    updateNodeById({ boardId: Number(boardId), nodeId: id, data: { value } });
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.detail === 2) {
      setInputActive(true);
      setTimeout(() => ref.current?.focus());
    }
  };

  // when node is selected, make calculations to determine whether to show active buttons or not
  useEffect(() => {
    const checkGenerateButton = () => {
      const currentNode = nodes.find(node => node.id === id);
      if (!currentNode) return;

      const taskNodes = nodes.filter(node => node.parentNode === currentNode.parentNode);
      if (!taskNodes.length) return;

      const lastTaskNode = taskNodes.reduce(
        (prev, next) => (next.position.x > prev.position.x ? next : prev),
        taskNodes[0]
      );

      if (id === lastTaskNode.id) {
        setShowGenerateButton(true);
      }
    };

    // const checkMoveButtons = () => {
    //   const currentTask = nodes.find(node => node.id === id);
    //   if (!currentTask) return;

    //   const currentTaskContainer = nodes.find(node => node.id === currentTask.parentNode);
    //   if (!currentTaskContainer) return;

    //   const currentEpic = nodes.find(node => node.id === currentTaskContainer.parentNode);
    //   if (!currentEpic) return;

    //   const currentEpicTaskContainers = nodes.filter(node => node.parentNode === currentEpic.id);
    //   const currentTaskContainerIndex = currentEpicTaskContainers.indexOf(currentTaskContainer);

    //   if (currentTaskContainerIndex > 0) {
    //     setShowMoveTopButton(true);
    //   }
    //   if (currentTaskContainerIndex < currentEpicTaskContainers.length - 1) {
    //     setShowMoveBottomButton(true);
    //   }
    // };

    if (selected) {
      checkGenerateButton();
      // checkMoveButtons();
    } else {
      setShowGenerateButton(false);
      // setShowMoveTopButton(false);
      // setShowMoveBottomButton(false);
    }
  }, [selected]);

  const { mutate: generateTaskNode, isLoading: generateDataLoading } = useGenerateNodesMutation();

  const handleGenerate = async () => {
    if (!data.value?.length) {
      notifyWarn('Please enter some value in the node');
      return;
    }

    generateTaskNode(
      { boardId: Number(boardId), nodeId: id },
      {
        onSuccess: data => {
          const parsedData = JSON.parse(data.schema);
          setData(parsedData);
          setTimeout(
            () => fitView({ maxZoom: RF_FIT_VIEW_ZOOM_MAXIMUM, duration: RF_FIT_VIEW_DURATION }),
            RF_FIT_VIEW_TIMEOUT
          );
        }
      }
    );
  };

  return (
    <>
      <Contextbar
        nodeId={id}
        isVisible={!dragging && data.toolbar?.visible}
        position={Position.Top}
        offset={30}
      />

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Top} offset={-13}>
        <button
          className={styles.buttonMoveTask}
          disabled={generateDataLoading}
          onClick={() => handleMoveTask(id, 'top')}
        >
          <IconStrokeUp />
        </button>
      </NodeToolbar>

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Bottom} offset={-13}>
        <button
          className={styles.buttonMoveTask}
          disabled={generateDataLoading}
          onClick={() => handleMoveTask(id, 'bottom')}
        >
          <IconStrokeDown />
        </button>
      </NodeToolbar>

      {showGenerateButton && (
        <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Right} offset={11}>
          <button
            className={styles.buttonGenerate}
            disabled={generateDataLoading}
            onClick={handleGenerate}
          >
            {generateDataLoading ? <IconLoader className={styles.spin} /> : <IconMagicWand />}
          </button>
        </NodeToolbar>
      )}

      <Handle
        type='target'
        position={Position.Left}
        id='d'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />
      <Handle
        type='source'
        position={Position.Right}
        id='b'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />

      <button
        className={
          `${styles.container} ` +
          `${selected ? styles.container_active : ''} ` +
          `${inputActive ? styles.container_double : ''}`
        }
        style={{
          background: data.color ? `${data.color}33` : `${DEFAULT_ROADMAP_EPIC_COLOR}33`,
          borderColor: data.color ? `${data.color}33` : `${DEFAULT_ROADMAP_EPIC_COLOR}33`
        }}
        disabled={generateDataLoading}
        onClick={handleClick}
      >
        <NodeResizer
          isVisible={selected}
          minHeight={44}
          maxHeight={44}
          minWidth={150}
          lineStyle={{ display: 'none' }}
        />

        <form
          className={styles.form}
          style={{
            maxWidth: `calc(100% - ${!inputActive && !!documentCount ? 34 : 0}px)`
          }}
          onSubmit={e => {
            e.preventDefault();
            handleGenerate();
          }}
        >
          {inputActive ? (
            <input
              ref={ref}
              id={`roadmap-task-${id}`}
              type='text'
              name='roadmap-task-input'
              placeholder='Please enter task'
              title={data.value || 'Please enter task'}
              value={data.value}
              onChange={handleChange}
              onBlur={() => setInputActive(false)}
              className={`${styles.input} nodrag`}
              disabled={generateDataLoading}
            />
          ) : (
            <span title={data.value || 'Please enter task'} className={styles.text}>
              {data.value || 'Please enter task'}
            </span>
          )}
        </form>

        {!inputActive && !!documentCount && (
          <div className={styles.documents}>
            <IconKnowledge className={styles.documents__icon} />
            <span className={styles.documents__badge}>{documentCount}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default RoadmapTaskNode;
