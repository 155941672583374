import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconLarge: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8 6V18' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <path d='M16 18H8' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
};
