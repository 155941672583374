import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useToggle } from 'hooks';
import { useUserQuery } from 'hooks/user';
import { Project, TEAM_ROLES, Team } from 'models';
import styles from './Header.module.scss';

import { Button, IconEdit, IconKnowledge, IconPresentation, Modal } from 'components/atoms';
import { EditProjectForm } from '..';

interface HeaderProps {
  team: Team;
  project: Project;
}

const Header: FC<HeaderProps> = ({ team, project }) => {
  const { t } = useTranslation();
  const { data: user } = useUserQuery();
  const [editProjectModalActive, toggleEditProjectModalActive] = useToggle(false);

  const showEditButton = useMemo(() => {
    if (team && team.members) {
      const members = team.members;
      const admins = members.filter(member => member.userRole.role === TEAM_ROLES.ADMIN);
      const owners = members.filter(member => member.userRole.role === TEAM_ROLES.OWNER);
      return !![...admins, ...owners].find(member => user?.id === member.id);
    }
  }, [team?.members, user]);

  return (
    <header className={styles.header}>
      <h2 className={styles.name} title={project.name}>
        {project.name}
      </h2>
      <div className={styles.links}>
        <NavLink
          to={`${ROUTES.TEAMS}/${team.id}${ROUTES.PROJECTS}/${project.id}/${ROUTES.BOARDS}`}
          end
          className={({ isActive }) =>
            isActive ? `${styles.link} ${styles.link_active}` : styles.link
          }
        >
          <IconPresentation className={styles.icon} />
          Boards
        </NavLink>
        <NavLink
          to={`${ROUTES.TEAMS}/${team.id}${ROUTES.PROJECTS}/${project.id}/${ROUTES.DOCUMENTS}`}
          className={({ isActive }) =>
            isActive ? `${styles.link} ${styles.link_active}` : styles.link
          }
        >
          <IconKnowledge className={styles.icon} />
          Documents
        </NavLink>
      </div>

      {showEditButton && (
        <Button
          title={t('TeamPage.project.editProject')}
          type='button'
          variant='light'
          size='small'
          icon={<IconEdit />}
          iconPosition='left'
          className={styles['button-edit']}
          onClick={toggleEditProjectModalActive}
        />
      )}

      <Modal isOpen={editProjectModalActive} onClose={toggleEditProjectModalActive}>
        <EditProjectForm currentName={project.name} onCancel={toggleEditProjectModalActive} />
      </Modal>
    </header>
  );
};

export default Header;
