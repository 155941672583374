import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { Control, UseFormGetValues, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyFormValues, SurveyScreens } from 'models';

import { Button, Textarea } from 'components/atoms';

type SuggestionsScreenProps = {
  control: Control<SurveyFormValues>;
  getValues: UseFormGetValues<SurveyFormValues>;
  trigger: UseFormTrigger<SurveyFormValues>;
  setCurrentScreen: Dispatch<SetStateAction<SurveyScreens>>;
  isLoading: boolean;
};

const SuggestionsScreen: FC<SuggestionsScreenProps> = ({
  control,
  getValues,
  trigger,
  setCurrentScreen,
  isLoading
}) => {
  const { t } = useTranslation();

  const isLastScreen = useMemo(() => {
    const satisfaction = getValues('satisfaction');
    switch (satisfaction) {
      case 1:
      case 2:
      case 3:
        return false;
      case 4:
      case 5:
        return true;
      default:
        return false;
    }
  }, [getValues]);

  const handleNext = async () => {
    const isValid = await trigger('suggestions');
    if (!isValid) return;

    if (isLastScreen) {
      setCurrentScreen(SurveyScreens.END);
    } else {
      setCurrentScreen(SurveyScreens.RECOMMENDATION);
    }
  };

  const handleSkip = () => {
    if (isLastScreen) {
      setCurrentScreen(SurveyScreens.END);
    } else {
      setCurrentScreen(SurveyScreens.RECOMMENDATION);
    }
  };

  return (
    <div className='flex flex-col items-center'>
      <h1
        className={
          'mb-[40px] w-[540px] text-[32px] leading-[48px] text-center ' +
          'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
        }
      >
        {t('Survey.whatSuggestions')}
      </h1>

      <Textarea
        name='suggestions'
        placeholder='Type your message here'
        width='big'
        control={control}
        rules={{ required: { value: true, message: t('common.validation.requiredField') } }}
        containerClassName='mb-[44px]'
      />

      {isLastScreen ? (
        <Button
          type='submit'
          variant='primary'
          size='big'
          title={t('Survey.done')}
          isLoading={isLoading}
          className='min-w-[240px]'
        />
      ) : (
        <div className='flex flex-col gap-[8px]'>
          <Button
            variant='primary'
            size='big'
            title={t('Survey.next')}
            onClick={handleNext}
            className='mb-[12px] min-w-[240px]'
          />
          <Button
            variant='secondary'
            size='big'
            title={t('Survey.skip')}
            onClick={handleSkip}
            className='min-w-[240px]'
          />
        </div>
      )}
    </div>
  );
};

export default SuggestionsScreen;
