import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useCreateProjectMutation, useCreateTeamMutation } from 'hooks/mutations';

import { Button, InputText } from 'components/atoms';

import styles from './NewTeam.module.scss';

type ContactUsProps = {
  onClose?: () => void;
};

const NewTeam: FC<ContactUsProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });

  const { mutateAsync: createProjectMutate } = useCreateProjectMutation({});
  const { mutateAsync: createTeamMutate, isLoading: createTeamLoading } = useCreateTeamMutation();

  const onSubmit = async ({ name }: FieldValues) => {
    createTeamMutate(
      { name },
      {
        onSuccess: async data => {
          await createProjectMutate({ teamId: data.id, name: t('common.defaultProject') });
          navigate(`${ROUTES.TEAMS}/${data.id}`);
        }
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerForm}>
        <div className={styles.content}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <h2 className={styles.title}>{t('CreateTeam.form.title')}</h2>
            <h3 className={styles.subtitle}>{t('CreateTeam.form.text')}</h3>

            <InputText
              type='text'
              width='big'
              name='name'
              label={t('CreateTeam.form.placeholder') as string}
              control={control}
              rules={{
                required: { value: true, message: t('common.validation.requiredField') },
                maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
                validate: {
                  doesntConsistOfSpaces: (value: string) => {
                    return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                  }
                }
              }}
              isLoading={createTeamLoading}
              autoComplete='off'
              containerClassName={styles.input}
            />

            <div className={styles.buttons}>
              <Button
                type='reset'
                variant='secondary'
                size='big'
                wide
                title={t('common.cancel')}
                isDisabled={createTeamLoading}
                onClick={onClose}
                className={styles['button-cancel']}
              />

              <Button
                type='submit'
                variant='primary'
                size='big'
                wide
                title={t('CreateTeam.form.button')}
                isDisabled={!isDirty}
                isLoading={createTeamLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewTeam;
