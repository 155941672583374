import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconLevel1: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 11L12 13'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 14H18'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 14L18 16'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 14L6 16'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 2H9C7.89543 2 7 2.89543 7 4V9C7 10.1046 7.89543 11 9 11H15C16.1046 11 17 10.1046 17 9V4C17 2.89543 16.1046 2 15 2Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 5L13 6.5L11 8V5Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 17H4C2.89543 17 2 17.8954 2 19V20C2 21.1046 2.89543 22 4 22H8C9.10457 22 10 21.1046 10 20V19C10 17.8954 9.10457 17 8 17Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 17H16C14.8954 17 14 17.8954 14 19V20C14 21.1046 14.8954 22 16 22H20C21.1046 22 22 21.1046 22 20V19C22 17.8954 21.1046 17 20 17Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
