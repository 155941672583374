import { useMemo } from 'react';

import { BOARD_TYPES } from 'models';

import { IconDiagram, IconFlowchart, IconMindMap, IconRoadmap, IconWBS } from 'components/atoms';

export const useBoardIcon = (type: BOARD_TYPES) => {
  const Icon = useMemo(() => {
    switch (type) {
      case BOARD_TYPES.MINDMAP:
        return IconMindMap;
      case BOARD_TYPES.ROADMAP:
        return IconRoadmap;
      case BOARD_TYPES.FLOWCHART:
        return IconFlowchart;
      case BOARD_TYPES.DIAGRAM:
        return IconDiagram;
      case BOARD_TYPES.WBS:
        return IconWBS;
    }
  }, [type]);
  return Icon;
};
