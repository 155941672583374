import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUserQuery } from 'hooks/user';
import { ContactUsInfoFormValues } from 'models';
import { SLACK_ENTERPRISE_CONTACT_WEBHOOK_URL } from 'appConfig';
import { useSendMessageToSlackMutation } from 'hooks/slack';

import styles from './ContactUs.module.scss';

import { EMAIL_REGEXP, PHONE_NUMBER_REGEX } from 'consts';
import { Button, InputText, Textarea } from 'components/atoms';

type ContactUsProps = {
  onClose?: () => void;
};

const ContactUs: FC<ContactUsProps> = () => {
  const { t } = useTranslation();

  const { data: viewer } = useUserQuery();
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<ContactUsInfoFormValues>({ mode: 'onSubmit' });

  const { mutate: sendMessageToSlack, isLoading: sendMessageSlackLoading } =
    useSendMessageToSlackMutation();

  const onSubmit = async (data: ContactUsInfoFormValues) => {
    const { firstName, lastName, email, phone, apprNumber, message } = data;

    let messageToSlack = `(Migned. User feedback) user id: ${viewer?.id}, user name: ${viewer?.username}`;
    firstName && (messageToSlack += `, firstName: ${firstName}`);
    lastName && (messageToSlack += `, lastName: ${lastName}`);
    email && (messageToSlack += `, email: ${email}`);
    phone && (messageToSlack += `, phone: ${phone}`);
    apprNumber && (messageToSlack += `, apprNumber: ${apprNumber}`);
    message && (messageToSlack += `, message: ${message}`);

    sendMessageToSlack({ webhookUrl: SLACK_ENTERPRISE_CONTACT_WEBHOOK_URL, text: messageToSlack });
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerForm}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={styles.title}>Contact Us</h2>
          <div className={styles['title-subtitle']}>
            Fill out this quick form and we’ll get back to you shortly
          </div>

          <div className='flex flex-row gap-x-[16px]'>
            <div className='flex flex-col'>
              <h5 className={styles.subtitle}>First name*</h5>
              <InputText
                type='text'
                width='big'
                name='firstName'
                label='Your first name'
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
                  validate: {
                    doesntConsistOfSpaces: (value: any) => {
                      return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                    }
                  }
                }}
                isLoading={sendMessageSlackLoading}
                autoComplete='off'
                containerClassName={styles.input}
              />
            </div>

            <div className='flex flex-col'>
              <h5 className={styles.subtitle}>Last name*</h5>
              <InputText
                type='text'
                width='big'
                name='lastName'
                label='Your last name'
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
                  validate: {
                    doesntConsistOfSpaces: (value: any) => {
                      return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                    }
                  }
                }}
                isLoading={sendMessageSlackLoading}
                autoComplete='off'
                containerClassName={styles.input}
              />
            </div>
          </div>

          <div className='flex flex-row gap-x-[16px]'>
            <div className='flex flex-col'>
              <h5 className={styles.subtitle}>Work email*</h5>
              <InputText
                type='text'
                width='big'
                name='email'
                label='Your business email'
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  maxLength: { value: 50, message: `${t('common.form.maximumLengthIs')} 50` },
                  validate: {
                    doesntContainSpaces: (value: string | undefined) => {
                      if (value !== undefined) {
                        return !value.includes(' ')
                          ? true
                          : t('common.validation.doesntContainSpaces');
                      }
                      return true;
                    },
                    validEmail: (value: string | undefined) => {
                      if (value !== undefined) {
                        return EMAIL_REGEXP.test(value)
                          ? true
                          : t('common.validation.incorrectEmail');
                      }
                      return true;
                    }
                  }
                }}
                isLoading={sendMessageSlackLoading}
                autoComplete='off'
                containerClassName={styles.input}
              />
            </div>

            <div className='flex flex-col'>
              <h5 className={styles.subtitle}>Phone number*</h5>
              <InputText
                type='text'
                width='big'
                name='phone'
                label='+5 (555) 555-5555'
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` },
                  validate: {
                    doesntConsistOfSpaces: (value: any) => {
                      return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                    }
                  },
                  pattern: {
                    value: PHONE_NUMBER_REGEX,
                    message: 'Please enter a valid phone number'
                  }
                }}
                isLoading={sendMessageSlackLoading}
                autoComplete='off'
                containerClassName={styles.input}
              />
            </div>
          </div>

          <div className='text-[12px] mt-[8px]'>Don’t use addresses from gmail.com</div>

          <h5 className={styles.subtitle}>Expected number of Team Users (optional)</h5>
          <InputText
            type='text'
            width='big'
            name='apprNumber'
            label='Approximate number'
            control={control}
            rules={{
              maxLength: { value: 32, message: `${t('common.form.maximumLengthIs')} 32` }
            }}
            isLoading={sendMessageSlackLoading}
            autoComplete='off'
          />

          <h5 className={styles.subtitle}>Message*</h5>
          <Textarea
            name='message'
            placeholder='Tell us about your goals or what problems of your business we could solve'
            width='big'
            control={control}
            rules={{
              required: { value: true, message: t('common.validation.requiredField') },
              maxLength: { value: 1000, message: `${t('common.form.maximumLengthIs')} 32` },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return value.trim() ? true : t('common.validation.doesntConsistOfSpaces');
                }
              }
            }}
            containerClassName='mb-[44px]'
          />

          <div className={styles.buttonSubmit}>
            <Button
              type='submit'
              variant='primary'
              size='big'
              wide
              title='Submit'
              isDisabled={!isDirty}
              isLoading={sendMessageSlackLoading}
              className={styles['button-submit']}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
