import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SLACK_FEEDBACK_WEBHOOK_URL } from 'appConfig';
import { useSendMessageToSlackMutation } from 'hooks/slack';
import { useUserQuery } from 'hooks/user';
import { SurveyFormValues, SurveyScreens } from 'models';

import {
  EndScreen,
  ProblemsScreen,
  RecommendationScreen,
  SatisfactionScreen,
  StartScreen,
  SuggestionsScreen,
  ValuableFeaturesScreen
} from './components';

type SurveyProps = {
  onClose?: () => void;
};

const Survey: FC<SurveyProps> = ({ onClose }) => {
  const { data: viewer } = useUserQuery();
  const [currentScreen, setCurrentScreen] = useState<SurveyScreens>(SurveyScreens.START);
  const { mutate: sendMessageToSlack } = useSendMessageToSlackMutation();
  const { control, getValues, trigger, handleSubmit, reset } = useForm<SurveyFormValues>({
    mode: 'onSubmit'
  });

  const onSubmit = (data: SurveyFormValues) => {
    setCurrentScreen(SurveyScreens.END);
    reset();

    const { satisfaction, valuableFeatures, recommendation, problems, suggestions } = data;

    let messageToSlack = `(Migned. User feedback) user id: ${viewer?.id}, user name: ${viewer?.username}`;
    satisfaction && (messageToSlack += `, satisfaction: ${satisfaction}`);
    valuableFeatures && (messageToSlack += `, valuableFeatures: ${valuableFeatures}`);
    recommendation && (messageToSlack += `, recommendation: ${recommendation}`);
    problems && (messageToSlack += `, problems: ${problems}`);
    suggestions && (messageToSlack += `, suggestions: ${suggestions}`);

    sendMessageToSlack({ webhookUrl: SLACK_FEEDBACK_WEBHOOK_URL, text: messageToSlack });
  };

  return (
    <form className='w-[740px] flex flex-col' onSubmit={handleSubmit(onSubmit)}>
      {currentScreen === SurveyScreens.START && (
        <StartScreen setCurrentScreen={setCurrentScreen} onClose={onClose} />
      )}

      {currentScreen === SurveyScreens.SATISFACTION && (
        <SatisfactionScreen
          control={control}
          getValues={getValues}
          trigger={trigger}
          setCurrentScreen={setCurrentScreen}
        />
      )}

      {currentScreen === SurveyScreens.VALUABLE_FEATURES && (
        <ValuableFeaturesScreen
          control={control}
          trigger={trigger}
          setCurrentScreen={setCurrentScreen}
        />
      )}

      {currentScreen === SurveyScreens.PROBLEMS && (
        <ProblemsScreen control={control} isLoading={false} />
      )}

      {currentScreen === SurveyScreens.SUGGESTIONS && (
        <SuggestionsScreen
          control={control}
          getValues={getValues}
          trigger={trigger}
          setCurrentScreen={setCurrentScreen}
          isLoading={false}
        />
      )}

      {currentScreen === SurveyScreens.RECOMMENDATION && (
        <RecommendationScreen control={control} isLoading={false} />
      )}

      {currentScreen === SurveyScreens.END && (
        <EndScreen setCurrentScreen={setCurrentScreen} onClose={onClose} />
      )}
    </form>
  );
};

export default Survey;
