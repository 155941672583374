import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { GenerateMindmapNodesDto, apiBoard } from 'api';
import { DEFAULT_RETRIES_AMOUNT } from 'consts';
import { handleError, notifySuc } from 'helpers';

export const useGenerateNodesMutation = () => {
  const { t } = useTranslation();

  return useMutation(
    (data: GenerateMindmapNodesDto) => {
      return apiBoard.generateMindmapNodes(data);
    },
    {
      retry(failureCount, error) {
        if (axios.isAxiosError(error) && error.response) {
          const statusCode = error.response.data.statusCode;
          if (statusCode === 403) return false;
        }

        if (failureCount < DEFAULT_RETRIES_AMOUNT) {
          return true;
        }
        return false;
      },
      onSuccess() {
        notifySuc(t('common.notifications.nodesGenerated'));
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
