import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Team } from 'models';

import { Button } from 'components/atoms';

type LeaveTeamProps = {
  team: Team;
};

const LeaveTeam: FC<LeaveTeamProps> = ({ team }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        'p-[13px] flex flex-col gap-[15px] rounded-[20px] ' +
        'border-[2px] border-solid border-[var(--color-light-light-gray)]'
      }
    >
      <h4 className='text-[20px] font-[400] leading-[30px] tracking-[0.2px] text-[var(--color-dark)]'>
        {t('TeamPage.settings.leave')} <b className='font-[700]'>{team.name}</b>
      </h4>

      <p className='text-[18px] leading-[24px] font-[400] text-[var(--color-gray)]'>
        {t('TeamPage.settings.byLeavingTeam')}
      </p>

      <Button
        variant='light'
        size='big'
        title={t('TeamPage.settings.leaveButton')}
        className='self-start'
        isDisabled
      />
    </div>
  );
};

export default LeaveTeam;
