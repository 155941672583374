import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconQuestion: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2.70089 10.3C2.47684 10.5238 2.2991 10.7896 2.17783 11.0821C2.05656 11.3747 1.99414 11.6883 1.99414 12.005C1.99414 12.3217 2.05656 12.6353 2.17783 12.9278C2.2991 13.2204 2.47684 13.4862 2.70089 13.71L10.2909 21.3C10.5147 21.524 10.7805 21.7018 11.073 21.823C11.3656 21.9443 11.6792 22.0067 11.9959 22.0067C12.3126 22.0067 12.6262 21.9443 12.9187 21.823C13.2113 21.7018 13.4771 21.524 13.7009 21.3L21.2909 13.71C21.5149 13.4862 21.6927 13.2204 21.8139 12.9278C21.9352 12.6353 21.9976 12.3217 21.9976 12.005C21.9976 11.6883 21.9352 11.3747 21.8139 11.0821C21.6927 10.7896 21.5149 10.5238 21.2909 10.3L13.7009 2.70998C13.4771 2.48593 13.2113 2.30819 12.9187 2.18692C12.6262 2.06565 12.3126 2.00323 11.9959 2.00323C11.6792 2.00323 11.3656 2.06565 11.073 2.18692C10.7805 2.30819 10.5147 2.48593 10.2909 2.70998L2.70089 10.3Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
