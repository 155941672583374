import { Edge, Node, Position } from 'reactflow';
import { v4 as uuid } from 'uuid';

import { EDGE_TYPES, NODE_TYPES } from 'consts';
import { NodeData } from 'store';
import { DEFAULT_WBS_TASK_X_OFFSET } from '../const';

export const generateTaskNodes = (
  currentNode: Node<NodeData>
): Promise<{ nodes: Node<NodeData>[]; edges: Edge[] }> => {
  const nodeId1 = uuid();
  const nodeId2 = uuid();
  const nodeId3 = uuid();
  const edgeId1 = uuid();
  const edgeId2 = uuid();
  const edgeId3 = uuid();

  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        nodes: [
          {
            id: nodeId1,
            type: NODE_TYPES.WBS_TASK,
            data: {
              value: 'Conduct Market Research',
              toolbar: { position: Position.Right }
            },
            position: { x: DEFAULT_WBS_TASK_X_OFFSET, y: 90 },
            parentNode: currentNode.id
          },
          {
            id: nodeId2,
            type: NODE_TYPES.WBS_TASK,
            data: {
              value: 'Analyze Competitors',
              toolbar: { position: Position.Right }
            },
            position: { x: DEFAULT_WBS_TASK_X_OFFSET, y: 144 },
            parentNode: currentNode.id
          },
          {
            id: nodeId3,
            type: NODE_TYPES.WBS_TASK,
            data: {
              value: 'Identify Target Audience',
              toolbar: { position: Position.Right }
            },
            position: { x: DEFAULT_WBS_TASK_X_OFFSET, y: 198 },
            parentNode: currentNode.id
          }
        ],
        edges: [
          {
            id: edgeId1,
            type: EDGE_TYPES.WBS,
            source: currentNode.id,
            target: nodeId1,
            sourceHandle: 'c',
            targetHandle: 'd'
          },
          {
            id: edgeId2,
            type: EDGE_TYPES.WBS,
            source: currentNode.id,
            target: nodeId2,
            sourceHandle: 'c',
            targetHandle: 'd'
          },
          {
            id: edgeId3,
            type: EDGE_TYPES.WBS,
            source: currentNode.id,
            target: nodeId3,
            sourceHandle: 'c',
            targetHandle: 'd'
          }
        ]
      });
    }, 0);
  });
};
