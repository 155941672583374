import { Node } from 'reactflow';

import { NodeData } from 'store';
import {
  DEFAULT_WBS_EPIC_HEIGHT,
  DEFAULT_WBS_TASK_GAP,
  DEFAULT_WBS_TASK_HEIGHT,
  DEFAULT_WBS_TASK_X_OFFSET,
  DEFAULT_WBS_TASK_Y_OFFSET
} from '../const';

export const normalizeTaskNodes = (taskNodes: Node<NodeData>[]) => {
  let currentOffset = DEFAULT_WBS_TASK_Y_OFFSET + DEFAULT_WBS_EPIC_HEIGHT;

  const normalizedTaskNodes = taskNodes.map(node => {
    const verticalOffset = currentOffset;
    currentOffset += DEFAULT_WBS_TASK_HEIGHT + DEFAULT_WBS_TASK_GAP;

    return {
      ...node,
      position: { ...node.position, x: DEFAULT_WBS_TASK_X_OFFSET, y: verticalOffset }
    };
  });

  return normalizedTaskNodes;
};
