import { useTranslation } from 'react-i18next';

import { ResetPasswordDto, apiAuth } from 'api';
import { handleError, notifySuc } from 'helpers';
import { useToggle } from 'hooks';

export const useResetPassword = () => {
  const { t } = useTranslation();
  const [isLoading, toggleLoading] = useToggle(false);

  const sendLink = async (email: string) => {
    toggleLoading();

    try {
      await apiAuth.resetPasswordSendLink(email);
      notifySuc(t('common.notifications.confirmationLetterSent'));
      return 'ok';
    } catch (error) {
      handleError(error);
    } finally {
      toggleLoading();
    }
  };

  const resetPassword = async (data: ResetPasswordDto) => {
    toggleLoading();

    try {
      await apiAuth.resetPassword(data);
      notifySuc(t('common.notifications.passwordUpdate'));
      return 'ok';
    } catch (error) {
      handleError(error);
    } finally {
      toggleLoading();
    }
  };

  return { sendLink, resetPassword, isLoading };
};
