import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconDiagram: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 7C12.663 7 13.2989 6.73661 13.7678 6.26777C14.2366 5.79893 14.5 5.16304 14.5 4.5C14.5 3.83696 14.2366 3.20107 13.7678 2.73223C13.2989 2.26339 12.663 2 12 2C11.337 2 10.7011 2.26339 10.2322 2.73223C9.76339 3.20107 9.5 3.83696 9.5 4.5C9.5 5.16304 9.76339 5.79893 10.2322 6.26777C10.7011 6.73661 11.337 7 12 7ZM12 22C12.663 22 13.2989 21.7366 13.7678 21.2678C14.2366 20.7989 14.5 20.163 14.5 19.5C14.5 18.837 14.2366 18.2011 13.7678 17.7322C13.2989 17.2634 12.663 17 12 17C11.337 17 10.7011 17.2634 10.2322 17.7322C9.76339 18.2011 9.5 18.837 9.5 19.5C9.5 20.163 9.76339 20.7989 10.2322 21.2678C10.7011 21.7366 11.337 22 12 22ZM7 9.5H2V14.5H7V9.5ZM22 9.5H17V14.5H22V9.5Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 4.5H4.5V9.5M9.5 19.5H4.5V14.5M14.5 4.5H20V9.5M14.5 19.5H19.5V14.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
