import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconMiddle: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 6V18' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <path d='M17 6V18' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <path d='M11.9497 10.9497L7 6' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <path
        d='M16.9497 5.99996L12 10.9497'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};
