import { FC, useMemo } from 'react';
import {
  ControlButton,
  ControlProps,
  Controls as RFControls,
  useReactFlow,
  useStore
} from 'reactflow';
import { shallow } from 'zustand/shallow';

import { RF_FIT_VIEW_DURATION, RF_FIT_VIEW_ZOOM_MAXIMUM, RF_ZOOM_DURATION } from 'consts';
import { RFState, useRFStore } from 'store';
import styles from './Controls.module.scss';

import {
  IconEye,
  IconHand,
  IconHelpCircle,
  IconMinus,
  IconMousePointer,
  IconPlus,
  IconRedo,
  IconUndo
} from 'components/atoms';

const selector = (state: RFState) => ({
  controlType: state.controlType,
  setControlType: state.setControlType
});

const Controls: FC<ControlProps> = ({ ...props }) => {
  const { controlType, setControlType } = useRFStore(selector, shallow);
  const zoomLevel = useStore(state => state.transform[2]);
  const { zoomOut, zoomIn, fitView } = useReactFlow();

  const zoomPercent = useMemo(() => {
    const calculatedZoomPercent = (zoomLevel / 2) * 100;
    return `${calculatedZoomPercent.toFixed()} %`;
  }, [zoomLevel]);

  const handleZoomOut = () => {
    zoomOut({ duration: RF_ZOOM_DURATION });
  };

  const handleZoomIn = () => {
    zoomIn({ duration: RF_ZOOM_DURATION });
  };

  const handleFitView = () => {
    fitView({ maxZoom: RF_FIT_VIEW_ZOOM_MAXIMUM, duration: RF_FIT_VIEW_DURATION });
  };

  return (
    <RFControls
      className={styles.container}
      showFitView={false}
      showZoom={false}
      showInteractive={false}
      {...props}
    >
      <div className={styles.block}>
        <ControlButton
          className={`${styles.button} ${styles.button_undo}`}
          disabled
          onClick={() => console.log('undo')}
        >
          <div className={styles.icon}>
            <IconUndo />
          </div>
        </ControlButton>
        <ControlButton className={styles.button} disabled onClick={() => console.log('redo')}>
          <div className={styles.icon}>
            <IconRedo />
          </div>
        </ControlButton>
      </div>

      <div className={styles.block}>
        <ControlButton
          className={`${styles.button} ${controlType === 'Figma-like' ? styles.button_active : ''}`}
          onClick={() => setControlType('Figma-like')}
        >
          <div className={styles.icon}>
            <IconMousePointer />
          </div>
        </ControlButton>
        <ControlButton
          className={`${styles.button} ${controlType === 'Default' ? styles.button_active : ''}`}
          onClick={() => setControlType('Default')}
        >
          <div className={styles.icon}>
            <IconHand />
          </div>
        </ControlButton>
      </div>

      <div className={styles.block}>
        <ControlButton className={styles.button} onClick={handleZoomOut}>
          <div className={styles.icon}>
            <IconMinus />
          </div>
        </ControlButton>
        <ControlButton className={`${styles.button} ${styles.button_percentage}`}>
          <span>{zoomPercent}</span>
        </ControlButton>
        <ControlButton className={styles.button} onClick={handleZoomIn}>
          <div className={styles.icon}>
            <IconPlus />
          </div>
        </ControlButton>
      </div>

      <div className={styles.block}>
        <ControlButton className={styles.button} onClick={handleFitView}>
          <div className={styles.icon}>
            <IconEye />
          </div>
        </ControlButton>
      </div>

      <div className={styles.block}>
        <ControlButton
          className={styles.button}
          disabled
          onClick={() => console.log('show help info')}
        >
          <div className={styles.icon}>
            <IconHelpCircle />
          </div>
        </ControlButton>
      </div>
    </RFControls>
  );
};

export default Controls;
