import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useToggle } from 'hooks';
import { useBoardStore } from 'store';
import styles from './ShareBlock.module.scss';

import { IconShare, UserBadge, Modal } from 'components/atoms';
import { ShareModal } from 'components/organisms';

interface ShareBlockProps {
  className: string;
}

const ShareBlock: FC<ShareBlockProps> = ({ className }) => {
  const { t } = useTranslation();
  const [shareModalActive, toggleShareModalActive] = useToggle();
  const users = useBoardStore(state => state.users);

  return (
    <div className={`${styles.container} ${className}`}>
      {!!users.length && (
        <div className={styles.users}>
          {users.map(user => (
            <UserBadge key={user.id} user={user} className={styles.badge} />
          ))}
        </div>
      )}

      <button type='button' className={styles.header} onClick={toggleShareModalActive}>
        <h4 className={styles.title}>{t('common.share')}</h4>
        <IconShare className={styles.icon} />
      </button>

      <Modal isOpen={shareModalActive} onClose={toggleShareModalActive} className={styles.modal}>
        <ShareModal onClose={toggleShareModalActive} />
      </Modal>
    </div>
  );
};

export default ShareBlock;
