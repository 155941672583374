import { useMutation, useQueryClient } from 'react-query';

import { UpdateSubscriptionDto, apiStripe } from 'api/stripe';
import { handleError, notifySuc } from 'helpers';
import { Product, Team } from 'models';

export const useUpdateSubscriptionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dto: UpdateSubscriptionDto) => {
      return apiStripe.updateSubscription(dto);
    },
    {
      onSuccess(_, { teamId, numberCustomer }) {
        notifySuc('Subscription terms and conditions have been updated');

        const teamQuery = `teams/one/${teamId}`;
        queryClient.invalidateQueries([teamQuery], {
          refetchActive: false,
          refetchInactive: false
        });

        const oldTeamData = queryClient.getQueryData<Team>([teamQuery]);
        if (!oldTeamData || !oldTeamData.product) return;

        const updatedProduct: Product = {
          ...oldTeamData.product,
          numberEditorsAllowed: numberCustomer
        };
        const updatedTeamData: Team = { ...oldTeamData, product: updatedProduct };

        queryClient.setQueryData([teamQuery], updatedTeamData);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
