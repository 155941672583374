import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { DeleteTeamDto, apiTeam } from 'api';
import { handleError, notifySuc } from 'helpers';

export const useDeleteTeamMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (data: DeleteTeamDto) => {
      return apiTeam.deleteTeam(data);
    },
    {
      async onSuccess() {
        notifySuc(t('common.notifications.teamDeleted'));
        await queryClient.invalidateQueries('teams/my');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
