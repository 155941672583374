import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Handle, NodeProps, NodeToolbar, Position } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { shallow } from 'zustand/shallow';

import { EDGE_TYPES, NODE_TYPES } from 'consts';
import { NodeData, RFState, useRFStore } from 'store';
import { normalizeTaskNodes } from '../../../utils';
import styles from './WBSTaskNode.module.scss';

import { IconKnowledge, IconPlusBold } from 'components/atoms';
import { Contextbar } from 'components/organisms/flows/common';

const selector = (state: RFState) => ({
  currentEdgeType: state.currentEdgeType,
  nodes: state.nodes,
  setNodes: state.setNodes,
  updateNodeValue: state.updateNodeValue,
  addNode: state.addNode,
  addEdges: state.addEdges
});

const WBSTaskNode: FC<NodeProps<NodeData>> = ({ id, data, selected, dragging }) => {
  const [inputActive, setInputActive] = useState<boolean>(false);
  const documentCount = useMemo(() => data.linkedDocuments?.length, [data.linkedDocuments]);
  const { currentEdgeType, nodes, setNodes, updateNodeValue, addEdges } = useRFStore(
    selector,
    shallow
  );

  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCursor(e.target.selectionStart);
    updateNodeValue(id, e.target.value);
  };

  const onDoubleClick = () => {
    setInputActive(true);
    setTimeout(() => {
      const input = ref.current;
      if (!input) return;
      input.focus();
    });
  };

  const handleAddTask = (position: 'top' | 'bottom') => {
    const currentNode = nodes.find(node => node.id === id);
    const rootNode = nodes.find(node => node.type === NODE_TYPES.WBS_ROOT);
    if (!currentNode || !rootNode) return;

    const epicNode = nodes.find(node => node.id === currentNode.parentNode);
    if (!epicNode) return;

    const taskNodes = nodes.filter(
      node => node.type === NODE_TYPES.WBS_TASK && node.parentNode === epicNode.id
    );
    const otherNodes = nodes.filter(
      node => node.type !== NODE_TYPES.WBS_TASK || node.parentNode !== epicNode.id
    );

    const currentNodeIndex = taskNodes.indexOf(currentNode);
    const newNodeIndex = position === 'top' ? currentNodeIndex : currentNodeIndex + 1;

    const newTaskNodeId = uuid();
    const newTaskNode = {
      id: newTaskNodeId,
      type: NODE_TYPES.WBS_TASK,
      data: {
        value: '',
        toolbar: { position: Position.Right }
      },
      position: { x: currentNode.position.x, y: currentNode.position.y },
      parentNode: epicNode.id
    };

    const newTaskEdgeId = uuid();
    const newTaskEdge = {
      id: newTaskEdgeId,
      type: EDGE_TYPES.WBS,
      source: epicNode.id,
      target: newTaskNodeId
    };

    taskNodes.splice(newNodeIndex, 0, newTaskNode);
    const normalizedTaskNodes = normalizeTaskNodes(taskNodes);

    setNodes([...normalizedTaskNodes, ...otherNodes]);
    addEdges([newTaskEdge]);
  };

  return (
    <>
      <Contextbar
        nodeId={id}
        isVisible={!dragging && data.toolbar?.visible}
        position={Position.Top}
        offset={30}
      />

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Top} offset={-13}>
        <button
          className={styles.buttonAddTask}
          // disabled={generateDataLoading}
          onClick={() => handleAddTask('top')}
        >
          <IconPlusBold />
        </button>
      </NodeToolbar>

      <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Bottom} offset={-13}>
        <button
          className={styles.buttonAddTask}
          // disabled={generateDataLoading}
          onClick={() => handleAddTask('bottom')}
        >
          <IconPlusBold />
        </button>
      </NodeToolbar>

      <Handle
        type='target'
        position={Position.Left}
        id='d'
        className={`${
          currentEdgeType ? styles.handle : `${styles.handle} ${styles.handle_disabled}`
        }`}
      />

      <button
        className={
          `${styles.container} ` +
          `${selected ? styles.container_active : ''} ` +
          `${inputActive ? styles.container_double : ''}`
        }
        // disabled={generateDataLoading}
        onDoubleClick={onDoubleClick}
      >
        <form
          className={styles.form}
          style={{
            maxWidth: 218 - (!inputActive && !!documentCount ? 34 : 0)
          }}
          onSubmit={e => e.preventDefault()}
        >
          {inputActive ? (
            <input
              ref={ref}
              id={`roadmap-task-${id}`}
              type='text'
              name='roadmap-task-input'
              placeholder='Please enter the title'
              title={data.value || 'Please enter the title'}
              value={data.value}
              onChange={handleChange}
              onBlur={() => setInputActive(false)}
              className={`${styles.input} nodrag`}
              // disabled={generateDataLoading}
            />
          ) : (
            <span title={data.value || 'Please enter the title'} className={styles.text}>
              {data.value || 'Please enter the title'}
            </span>
          )}
        </form>

        {!inputActive && !!documentCount && (
          <div className={styles.documents}>
            <IconKnowledge className={styles.documents__icon} />
            <span className={styles.documents__badge}>{documentCount}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default WBSTaskNode;
