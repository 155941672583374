/* eslint-disable camelcase */

import { FC, useEffect } from 'react';

import { clientId } from 'appConfig';
import { useAuth } from 'hooks';

interface ButtonGoogleProps {
  className?: string;
}

const ButtonGoogle: FC<ButtonGoogleProps> = ({ className = '' }) => {
  const { authGoogle } = useAuth();

  const handleCallbackResponse = (response: google.accounts.id.CredentialResponse) => {
    authGoogle(response.credential);
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: clientId as string,
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(document.getElementById('signInDiv') as HTMLDivElement, {
      type: 'standard',
      theme: 'outline',
      size: 'large'
    });
  }, []);

  return (
    <div className={className}>
      <div id='signInDiv'></div>
    </div>
  );
};

export default ButtonGoogle;
