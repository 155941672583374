import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EMAIL_REGEXP } from 'consts';
import { useUpdateProfileMutation } from 'hooks/user';

import { Button, InputText } from 'components/atoms';

type ChangeEmailFormValues = {
  email: string;
};

type ChangeEmailFormProps = {
  currentEmail: string;
  onClose?: () => void;
  className?: string;
};

const ChangeEmailForm: FC<ChangeEmailFormProps> = ({ currentEmail, onClose, className }) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } =
    useUpdateProfileMutation();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty }
  } = useForm<ChangeEmailFormValues>({
    mode: 'onSubmit'
  });

  const onSubmit = async (data: ChangeEmailFormValues) => {
    const { email } = data;
    updateProfileMutate({ body: { email } }, { onSuccess: () => setSuccess(true) });
  };

  const handleClose = () => {
    onClose?.();
    setSuccess(false);
  };

  const handleReset = () => {
    reset();
    handleClose();
  };

  return (
    <div className={`w-[480px] flex flex-col gap-[28px] ${className}`}>
      {success ? (
        <>
          <div className='flex flex-col items-center gap-[24px]'>
            <h1 className='text-[32px] font-[500] leading-[48px] tracking-[-0.96px] text-[var(--color-dark)]'>
              {t('ProfilePage.changeEmail.checkYour')}
            </h1>
            <p className='text-[18px] leading-[24px] font-[700] text-[var(--color-dark)]'>
              {getValues('email')}
            </p>
          </div>

          <Button
            variant='primary'
            size='big'
            title={t('common.ok')}
            onClick={handleReset}
            className='w-[240px] self-center uppercase'
          />
        </>
      ) : (
        <>
          <div className='flex flex-col items-center gap-[8px]'>
            <h1 className='text-[32px] font-[500] leading-[48px] tracking-[-0.96px] text-[var(--color-dark)]'>
              {t('ProfilePage.changeEmail.changeEmail')}
            </h1>
            <p className='text-[18px] leading-[24px] text-[var(--color-gray)]'>
              {t('ProfilePage.changeEmail.yourEmail')}{' '}
              <b className='font-[700] text-[var(--color-dark)]'>{currentEmail}</b>
            </p>
            <p className='text-[18px] leading-[24px] text-[var(--color-gray)]'>
              {t('ProfilePage.changeEmail.enterNewEmail')}
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-[32px]'>
            <InputText
              type='email'
              width='big'
              name='email'
              label={t('ProfilePage.changeEmail.newEmail') as string}
              control={control}
              rules={{
                required: { value: true, message: t('common.validation.requiredField') },
                maxLength: { value: 50, message: `${t('common.form.maximumLengthIs')} 50` },
                validate: {
                  doesntContainSpaces: (value: string) => {
                    return !value.includes(' ') ? true : t('common.validation.doesntContainSpaces');
                  },
                  validEmail: (value: string) => {
                    return EMAIL_REGEXP.test(value) ? true : t('common.validation.incorrectEmail');
                  }
                }
              }}
              isLoading={updateProfileLoading}
            />

            <div className='flex gap-[12px]'>
              <Button
                type='reset'
                variant='secondary'
                size='big'
                wide
                title={t('common.cancel')}
                isDisabled={updateProfileLoading}
                onClick={handleReset}
              />

              <Button
                type='submit'
                variant='primary'
                size='big'
                wide
                title={t('common.save')}
                isLoading={updateProfileLoading}
                isDisabled={!isDirty}
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ChangeEmailForm;
