import { Node } from 'reactflow';

import { NodeData } from 'store';
import {
  DEFAULT_WBS_EPIC_GAP,
  DEFAULT_WBS_EPIC_WIDTH,
  DEFAULT_WBS_ROOT_OFFSET,
  DEFAULT_WBS_ROOT_WIDTH
} from '../const';

export const normalizeEpicNodes = (epicNodes: Node<NodeData>[]) => {
  const totalNodes = epicNodes.length;
  const totalWidth =
    totalNodes * DEFAULT_WBS_EPIC_WIDTH +
    (totalNodes - 1) * DEFAULT_WBS_EPIC_GAP -
    DEFAULT_WBS_ROOT_WIDTH;
  const offsetX = -(totalWidth / 2);

  const normalizedEpicNodes = epicNodes.map((node, index) => {
    const horizontalOffset = offsetX + index * (DEFAULT_WBS_EPIC_WIDTH + DEFAULT_WBS_EPIC_GAP);

    return {
      ...node,
      position: { ...node.position, x: horizontalOffset, y: DEFAULT_WBS_ROOT_OFFSET }
    };
  });

  return normalizedEpicNodes;
};
