import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { ROUTES } from 'consts';
import { useProject } from 'hooks/queries';
import { Team } from 'models';
import styles from './Project.module.scss';

import { Boards, DocumentScreen, Documents, Header } from './components';

type ProjectProps = {
  team: Team;
};

const Project: FC<ProjectProps> = ({ team }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const { data: project, isLoading: projectLoading } = useProject(Number(projectId));

  if (projectLoading) return <h2>{t('common.loading')}</h2>;
  if (!project) return <h2>{t('notifications.failedToLoadTeamOrProject')}</h2>;
  return (
    <div className={styles.container}>
      <Header team={team} project={project} />

      <Routes>
        <Route path={ROUTES.BOARDS} element={<Boards project={project} />} />
        <Route path={ROUTES.DOCUMENTS} element={<Documents project={project} />} />
        <Route
          path={`${ROUTES.DOCUMENTS}/:documentId`}
          element={<DocumentScreen project={project} />}
        />
        <Route path='*' element={<Navigate to={ROUTES.BOARDS} replace />} />
      </Routes>
    </div>
  );
};

export default Project;
