import { FC } from 'react';

import RecentBoardsPlaceholderPNG from 'assets/images/png/recent-boards-placeholder.png';
import { Project } from 'models';
import styles from './ProjectBoards.module.scss';

import { ProjectBoard } from '..';

interface ProjectBoardsProps {
  project: Project;
}

const ProjectBoards: FC<ProjectBoardsProps> = ({ project }) => {
  return (
    <div className={styles.container}>
      {project.boards.length ? (
        <div className={styles['board-list']}>
          {project.boards.map((board, index) => (
            <ProjectBoard key={index} project={project} board={board} />
          ))}
        </div>
      ) : (
        <div className={styles.placeholder}>
          <img src={RecentBoardsPlaceholderPNG} />
        </div>
      )}
    </div>
  );
};

export default ProjectBoards;
