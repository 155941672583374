import { FC, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  useOnSelectionChange
} from 'reactflow';
import { shallow } from 'zustand/shallow';

import { EDGE_COLOR, EDGE_LINE, EdgeData, RFState, useRFStore } from 'store';
import styles from './FlowchartEdge.module.scss';

import {
  IconDividerVertical,
  IconLine,
  IconLineDashed,
  IconLineSolid,
  IconMoveHorizontal,
  IconMoveLeft,
  IconMoveRight,
  IconTrash
} from 'components/atoms';

const colors: {
  type: EDGE_COLOR;
  label: string;
}[] = [
  { type: EDGE_COLOR.PINK, label: 'Pink' },
  { type: EDGE_COLOR.YELLOW, label: 'Yellow' },
  { type: EDGE_COLOR.LIGHT_GREEN, label: 'Light Green' },
  { type: EDGE_COLOR.BLUE, label: 'Blue' },
  { type: EDGE_COLOR.RED, label: 'Red' },
  { type: EDGE_COLOR.ORANGE, label: 'Orange' },
  { type: EDGE_COLOR.GREEN, label: 'Green' },
  { type: EDGE_COLOR.PURPLE, label: 'Purple' },
  { type: EDGE_COLOR.BLACK, label: 'Black' },
  { type: EDGE_COLOR.GRAY, label: 'Gray' },
  { type: EDGE_COLOR.LIGHT_GRAY, label: 'Light Gray' },
  { type: EDGE_COLOR.WHITE, label: 'White' }
];

const selector = (state: RFState) => ({
  updateEdgeColor: state.updateEdgeColor,
  removeEdge: state.removeEdge
});

const FlowchartEdge: FC<EdgeProps<EdgeData>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  selected,
  data
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });
  const [isSingleSelected, setSingleSelected] = useState<boolean>(false);
  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      if (nodes.length === 0 && edges.length === 1) {
        setSingleSelected(true);
      } else {
        setSingleSelected(false);
      }
    }
  });
  const { updateEdgeColor, removeEdge } = useRFStore(selector, shallow);
  const [showToolbar, setShowToolbar] = useState(false);

  useEffect(() => {
    if (typeof selected === 'boolean' && isSingleSelected) {
      setShowToolbar(selected);
    }
  }, [selected, isSingleSelected]);

  const setLine = (line: EDGE_LINE) => {
    console.log('set line:', line);
  };

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{ stroke: selected ? '#19b829' : data?.color || '#C3CAD9', strokeWidth: 2 }}
      />

      {showToolbar && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              zIndex: '10001',
              transform: `translate(-50%, -200%) translate(${labelX}px,${labelY}px)`,
              pointerEvents: 'all'
            }}
            className='nodrag nopan'
          >
            <div className={styles.contextbar}>
              <button className={styles.contextbar__button} data-tooltip-id='choose-color-tooltip'>
                <span
                  className={styles.contextbar__color}
                  style={{ background: data?.color || '#C3CAD9' }}
                />
              </button>
              <Tooltip
                id='choose-color-tooltip'
                className={styles.tooltip}
                offset={20}
                place='top'
                clickable
              >
                <div className={styles.tooltip__list}>
                  {colors.map((color, index) => (
                    <button
                      key={index}
                      title={color.label}
                      className={
                        data?.color === color.type
                          ? `${styles.tooltip__item} ${styles.tooltip__item_active}`
                          : styles.tooltip__item
                      }
                      onClick={() => updateEdgeColor(id, color.type)}
                    >
                      <span
                        className={
                          data?.color === color.type
                            ? `${styles.tooltip__circle} ${styles.tooltip__circle_active}`
                            : styles.tooltip__circle
                        }
                        style={{ background: color.type }}
                      />
                    </button>
                  ))}
                </div>
              </Tooltip>

              <IconDividerVertical color='#6B7A99' />

              <button className={styles.contextbar__button}>
                <IconLine />
              </button>

              <button className={styles.contextbar__button}>
                <IconMoveLeft />
              </button>

              <button className={styles.contextbar__button}>
                <IconMoveHorizontal />
              </button>

              <button className={styles.contextbar__button}>
                <IconMoveRight />
              </button>

              <IconDividerVertical color='#6B7A99' />

              <button
                className={`${styles.contextbar__button} ${
                  data?.line === EDGE_LINE.SOLID ? styles.contextbar__button_active : ''
                }`}
                onClick={() => setLine(EDGE_LINE.SOLID)}
              >
                <IconLineSolid />
              </button>

              <button
                className={`${styles.contextbar__button} ${
                  data?.line === EDGE_LINE.DASHED ? styles.contextbar__button_active : ''
                }`}
                onClick={() => setLine(EDGE_LINE.DASHED)}
              >
                <IconLineDashed />
              </button>

              <IconDividerVertical color='#6B7A99' />

              <button
                className={`${styles.contextbar__button} ${styles.contextbar__button_delete}`}
                onClick={() => removeEdge(id)}
              >
                <IconTrash />
                Delete
              </button>
            </div>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default FlowchartEdge;
