import { instance } from 'api';
import { AddDocumentDto, DeleteDocumentsDto, Document } from 'models';

export const apiDocument = {
  addDocument: async ({ projectId, body }: AddDocumentDto) => {
    const formData = new FormData();
    formData.append('file', body.file);

    const response = await instance.post<Document>(`projects/file/${projectId}`, formData);
    return response.data;
  },
  deleteDocument: async ({ projectId, body }: DeleteDocumentsDto) => {
    const response = await instance.delete(`projects/${projectId}/files`, { data: body });
    return response.data;
  }
};
