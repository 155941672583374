import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { TEAM_DASHBOARD_FILTER } from 'consts';
import { useToggle } from 'hooks';
import { useUserQuery } from 'hooks/user';
import { TEAM_ROLES, Team } from 'models';
import styles from './Dashboard.module.scss';

import { Button, IconEdit, Modal } from 'components/atoms';
import { AddMindCharts, EditTeamForm, RecentBoards } from './components';

type DashboardProps = {
  team: Team;
};

const Dashboard: FC<DashboardProps> = ({ team }) => {
  const { t } = useTranslation();
  const { data: user } = useUserQuery();
  const [editTeamModalActive, toggleEditTeamModalActive] = useToggle(false);
  const [searchParams] = useSearchParams();
  const activeFilter = useMemo(() => searchParams.get('filter'), [searchParams]);

  const showEditButton = useMemo(() => {
    if (team && team.members) {
      const members = team.members;
      const admins = members.filter(member => member.userRole.role === TEAM_ROLES.ADMIN);
      const owners = members.filter(member => member.userRole.role === TEAM_ROLES.OWNER);
      return !![...admins, ...owners].find(member => user?.id === member.id);
    }
  }, [team?.members, user]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.title}>{t('TeamPage.dashboard.title')}</h2>

        {showEditButton && (
          <Button
            title={t('TeamPage.dashboard.editTeam')}
            type='button'
            variant='light'
            size='small'
            icon={<IconEdit />}
            iconPosition='left'
            className={styles['button-edit']}
            onClick={toggleEditTeamModalActive}
          />
        )}
      </header>

      {activeFilter ? (
        <>
          {activeFilter === TEAM_DASHBOARD_FILTER.MIND_CHARTS && (
            <AddMindCharts projects={team.projects} className={styles['mind-charts']} />
          )}
          {activeFilter === TEAM_DASHBOARD_FILTER.RECENT_BOARDS && <RecentBoards />}
        </>
      ) : (
        <>
          <AddMindCharts projects={team.projects} className={styles['mind-charts']} />
          <RecentBoards />
        </>
      )}

      <Modal isOpen={editTeamModalActive} onClose={toggleEditTeamModalActive}>
        <EditTeamForm currentName={team.name} onCancel={toggleEditTeamModalActive} />
      </Modal>
    </div>
  );
};

export default Dashboard;
