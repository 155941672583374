import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconTXT: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='512'
      height='512'
      viewBox='0 0 512 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M137 488H429C445.569 488 459 474.569 459 458V127.5L355 24H137C120.431 24 107 37.4315 107 54V458C107 474.569 120.431 488 137 488Z'
        fill='#EDEDED'
      />
      <path d='M385 128H459L355 24V98C355 114.569 368.431 128 385 128Z' fill='#D9D9D9' />
      <path
        d='M53 100C53 88.9543 61.9543 80 73 80H247C258.046 80 267 88.9543 267 100V174C267 185.046 258.046 194 247 194H73C61.9543 194 53 185.046 53 174V100Z'
        fill='#009CF4'
      />
      <rect x='53' y='174' width='54' height='40' fill='#009CF4' />
      <path
        d='M183 281C183 275.477 187.477 271 193 271H297C302.523 271 307 275.477 307 281C307 286.523 302.523 291 297 291H193C187.477 291 183 286.523 183 281Z'
        fill='#6B7A99'
      />
      <path
        d='M183 361C183 355.477 187.477 351 193 351H297C302.523 351 307 355.477 307 361C307 366.523 302.523 371 297 371H193C187.477 371 183 366.523 183 361Z'
        fill='#6B7A99'
      />
      <path
        d='M257 321C257 315.477 261.477 311 267 311H371C376.523 311 381 315.477 381 321C381 326.523 376.523 331 371 331H267C261.477 331 257 326.523 257 321Z'
        fill='#6B7A99'
      />
      <path
        d='M257 401C257 395.477 261.477 391 267 391H371C376.523 391 381 395.477 381 401C381 406.523 376.523 411 371 411H267C261.477 411 257 406.523 257 401Z'
        fill='#6B7A99'
      />
      <path
        d='M327 281C327 275.477 331.477 271 337 271H371C376.523 271 381 275.477 381 281C381 286.523 376.523 291 371 291H337C331.477 291 327 286.523 327 281Z'
        fill='#6B7A99'
      />
      <path
        d='M327 361C327 355.477 331.477 351 337 351H371C376.523 351 381 355.477 381 361C381 366.523 376.523 371 371 371H337C331.477 371 327 366.523 327 361Z'
        fill='#6B7A99'
      />
      <path
        d='M183 321C183 315.477 187.477 311 193 311H227C232.523 311 237 315.477 237 321C237 326.523 232.523 331 227 331H193C187.477 331 183 326.523 183 321Z'
        fill='#6B7A99'
      />
      <path
        d='M183 401C183 395.477 187.477 391 193 391H227C232.523 391 237 395.477 237 401C237 406.523 232.523 411 227 411H193C187.477 411 183 406.523 183 401Z'
        fill='#6B7A99'
      />
      <path
        d='M53 214C53 202.954 61.9543 194 73 194H107V244H73C61.9543 244 53 235.046 53 224V214Z'
        fill='#0087D3'
      />
      <path
        d='M116.18 107.125V164H106.453V107.125H116.18ZM134.031 107.125V114.938H88.7578V107.125H134.031Z'
        fill='white'
      />
      <path
        d='M149.792 107.125L161.863 127.867L173.933 107.125H185.222L168.034 135.289L185.652 164H174.245L161.863 142.867L149.48 164H138.034L155.691 135.289L138.464 107.125H149.792Z'
        fill='white'
      />
      <path
        d='M217.077 107.125V164H207.35V107.125H217.077ZM234.928 107.125V114.938H189.655V107.125H234.928Z'
        fill='white'
      />
    </svg>
  );
};
