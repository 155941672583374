import { ChangeEvent, InputHTMLAttributes, useEffect, useMemo } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';

import PlaceholderSVG from 'assets/images/svg/user.svg';

import { IconEdit } from 'components/atoms';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  cover?: string;
  accept?: string;
  rules?: RegisterOptions;
  containerClassname?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}

export type AvatarProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const Avatar = <T extends FieldValues>({
  name,
  cover,
  accept,
  control,
  rules = {},
  containerClassname,
  className,
  disabled = false,
  loading = false,
  ...rest
}: AvatarProps<T>) => {
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target?.files && event.target.files[0];
    fileUploaded && field.onChange(fileUploaded);
  };

  const imageUrl = useMemo<string | undefined>(() => {
    if (field.value) {
      const objectUrl = URL.createObjectURL(field.value);
      return objectUrl;
    }
    return cover;
  }, [cover, field.value]);

  useEffect(() => {
    return () => {
      if (imageUrl && imageUrl.startsWith('blob:')) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  return (
    <div className={`flex flex-col ${containerClassname}`}>
      <label
        className={
          'h-[120px] w-[120px] flex justify-center items-center relative select-none ' +
          'bg-[#FFFFFF] rounded-[50%] border-[2px] border-solid border-[#C3CAD9] ' +
          `${disabled ? 'cursor-auto' : 'cursor-pointer'} overflow-hidden` +
          className
        }
      >
        <input
          type='file'
          id={name}
          name={name}
          accept={accept}
          onChange={handleChange}
          disabled={disabled || loading}
          className='hidden'
          {...rest}
        />

        <img
          src={imageUrl ? imageUrl : cover || PlaceholderSVG}
          alt='avatar'
          className='h-[96px] w-[96px] rounded-[50%]'
        />

        <div
          className={
            'flex absolute right-0 bottom-0 h-[40px] w-[40px] z-[1] ' +
            'justify-center items-center rounded-[20px] bg-[#2D3C59]'
          }
        >
          <IconEdit className='h-[24px] w-[24px] text-[#19B829]' />
        </div>
      </label>

      {!!fieldState.error && (
        <span
          className={
            'block mt-[3px] ml-[10px] font-[300] text-[16px] ' +
            'leading-[24px] tracking-[0.01em] text-[#f01c35]'
          }
        >
          {fieldState.error.message}
        </span>
      )}
    </div>
  );
};

export default Avatar;
