import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import tranEn from './files/en/translation.json';

export const languages = ['en'];

export type Languages = (typeof languages)[number];

const resources = {
  en: { translation: tranEn }
};

export const userLanguage = window.navigator.language;

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  detection: {
    order: ['localStorage', 'cookie'],
    caches: ['localStorage', 'cookie']
  },
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
