export enum AuthProvider {
  GOOGLE = 'google',
  MIGNED = 'migned'
}

export type User = {
  id: number;
  email: string;
  emailConfirmed: boolean;
  authProvider?: AuthProvider;
  avatarUrl?: string;
  username: string;
  companyName?: string;
  industry?: string;
  position?: string;
};

export type UserInfoFormValues = {
  image?: File;
  username?: string;
  companyName?: string;
  industry?: string;
  position?: string;
};

export type ContactUsInfoFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  apprNumber?: string;
  message: string;
};
