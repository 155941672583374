import { t } from 'i18next';
import { ChangeEvent, DragEvent, FC, useMemo, useRef, useState } from 'react';

import PlaceholderPNG from 'assets/images/png/recent-boards-placeholder.png';

import { useAddDocumentMutation } from 'hooks/document';
import { Document, Project } from 'models';
import styles from './Documents.module.scss';

import { Button, IconUpload, SearchField } from 'components/atoms';
import { DocumentCard } from './components';

interface DocumentsProps {
  project: Project;
}

// get data from project.documents when backend is ready
const Documents: FC<DocumentsProps> = ({ project: { id: projectId, files: documents } }) => {
  const { mutate: addDocumentMutate, isLoading: addDocumentLoading } = useAddDocumentMutation();
  const uploadRef = useRef<HTMLInputElement>(null);
  const [dropZoneActive, setDropZoneActive] = useState<boolean>(false);

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    addDocumentMutate({ projectId, body: { file: files[0] } });
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDropZoneActive(true);
  };
  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.relatedTarget && event.currentTarget.contains(event.relatedTarget as Node)) return;
    setDropZoneActive(false);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDropZoneActive(false);

    const files = event.dataTransfer.files;
    if (!files) return;

    addDocumentMutate({ projectId, body: { file: files[0] } });
  };

  const [searchValue, setSearchValue] = useState<string>('');
  const filteredDocuments: Document[] = useMemo(() => {
    if (!documents.length) return [];
    return documents.filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [searchValue, documents]);

  return (
    <div
      className={`${styles.container} ${dropZoneActive ? styles.container_active : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <header className={styles.header}>
        <SearchField
          placeholder={t('common.searchDocument') as string}
          setSearchValue={setSearchValue}
        />

        <Button
          type='button'
          variant='dark'
          size='small'
          title={t('common.buttonUpload')}
          icon={<IconUpload />}
          iconPosition='left'
          className={styles['button-upload']}
          onClick={() => uploadRef.current?.click()}
        />
        <input
          type='file'
          id='upload-file'
          ref={uploadRef}
          className={styles['input-upload']}
          onChange={handleUpload}
          disabled={addDocumentLoading}
        />
      </header>

      {filteredDocuments.length ? (
        <div className={styles.list}>
          {filteredDocuments.map(document => (
            <DocumentCard key={document.id} document={document} />
          ))}
        </div>
      ) : (
        <div className={styles.placeholder}>
          <img src={PlaceholderPNG} />
        </div>
      )}
    </div>
  );
};

export default Documents;
