import { Dispatch, FC, SetStateAction } from 'react';
import { Control, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyFormValues, SurveyScreens } from 'models';

import { Button, Textarea } from 'components/atoms';

type ValuableFeaturesScreenProps = {
  control: Control<SurveyFormValues>;
  trigger: UseFormTrigger<SurveyFormValues>;
  setCurrentScreen: Dispatch<SetStateAction<SurveyScreens>>;
};

const ValuableFeaturesScreen: FC<ValuableFeaturesScreenProps> = ({
  control,
  trigger,
  setCurrentScreen
}) => {
  const { t } = useTranslation();

  const handleNext = async () => {
    const isValid = await trigger('valuableFeatures');
    if (!isValid) return;

    setCurrentScreen(SurveyScreens.SUGGESTIONS);
  };

  const handleSkip = () => {
    setCurrentScreen(SurveyScreens.SUGGESTIONS);
  };

  return (
    <div className='flex flex-col items-center'>
      <h1
        className={
          'mb-[40px] w-[450px] text-[32px] leading-[48px] text-center ' +
          'font-[500] text-[var(--color-dark)] tracking-[-0.96px]'
        }
      >
        {t('Survey.whatSpecific')}
      </h1>

      <Textarea
        name='valuableFeatures'
        placeholder='Type your message here'
        width='big'
        control={control}
        rules={{ required: { value: true, message: t('common.validation.requiredField') } }}
        containerClassName='mb-[44px]'
      />

      <div className='flex flex-col gap-[8px]'>
        <Button
          variant='primary'
          size='big'
          title={t('Survey.next')}
          onClick={handleNext}
          className='mb-[12px] min-w-[240px]'
        />
        <Button
          variant='secondary'
          size='big'
          title={t('Survey.skip')}
          onClick={handleSkip}
          className='min-w-[240px]'
        />
      </div>
    </div>
  );
};

export default ValuableFeaturesScreen;
