import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconPresentation: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Icons'>
        <path
          id='Vector'
          d='M2.16669 2.5H18.8334'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M18 2.5V11.6667C18 12.1087 17.8244 12.5326 17.5118 12.8452C17.1993 13.1577 16.7754 13.3333 16.3333 13.3333H4.66667C4.22464 13.3333 3.80072 13.1577 3.48816 12.8452C3.17559 12.5326 3 12.1087 3 11.6667V2.5'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M6.33331 17.5L10.5 13.3333L14.6666 17.5'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
