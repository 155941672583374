import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Team } from 'models';

import { Main, Upgrade, Users } from './components';

type SettingsProps = {
  team: Team;
};

const Settings: FC<SettingsProps> = ({ team }) => {
  return (
    <div className='max-w-[calc(100%-342px)] grow flex justify-center'>
      <Routes>
        <Route path='users' element={<Users team={team} />} />
        {/* <Route path='apps-and-integrations' Component={Integrations} /> */}
        <Route path='upgrade' element={<Upgrade team={team} />} />
        <Route path='*' element={<Main team={team} />} />
      </Routes>
    </div>
  );
};

export default Settings;
