import { FC } from 'react';
import { Link } from 'react-router-dom';

import LogoHorizontalSVG from 'assets/images/svg/logo-horizontal.svg';

import { ROUTES } from 'consts';
import styles from './HeaderAuth.module.scss';

import { FeedbackButton } from 'components/atoms/FeedbackButton';
import { UserBlock } from 'components/molecules';

const HeaderAuth: FC = () => {
  return (
    <>
      <Link to={ROUTES.HOME} className={styles.logo}>
        <img src={LogoHorizontalSVG} alt='logo.svg' />
      </Link>

      <FeedbackButton className='mr-[24px]' />
      <UserBlock />
    </>
  );
};

export default HeaderAuth;
