import { ChangeEvent, FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NodeProps, NodeToolbar, Position } from 'reactflow';
import { shallow } from 'zustand/shallow';

import { NODE_TYPES } from 'consts';
import { useSocket } from 'hooks';
import { NodeData, RFState, useRFStore } from 'store';
import styles from './RoadmapIntervalNode.module.scss';

import { IconPlusBold } from 'components/atoms';
import { Contextbar } from 'components/organisms/flows/common';

const selector = (state: RFState) => ({
  nodes: state.nodes,
  updateNodeValue: state.updateNodeValue
});

type Params = { boardId: string };

const RoadmapIntervalNode: FC<NodeProps<NodeData>> = ({ id, data, selected, dragging }) => {
  const { boardId } = useParams<keyof Params>() as Params;
  const { updateNodeById, addRoadmapInterval } = useSocket(Number(boardId));
  const [inputActive, setInputActive] = useState<boolean>(false);
  const { nodes, updateNodeValue } = useRFStore(selector, shallow);
  const [showAddButton, setShowAddButton] = useState<boolean>(false);
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.value]);

  const handleChange = ({ target: { selectionStart, value } }: ChangeEvent<HTMLInputElement>) => {
    setCursor(selectionStart);
    updateNodeValue(id, value);
    updateNodeById({ boardId: Number(boardId), nodeId: id, data: { value } });
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.detail === 2) {
      setInputActive(true);
      setTimeout(() => ref.current?.focus());
    }
  };

  // when selected, calculate whether the current interval is the last
  useEffect(() => {
    if (selected) {
      const intervalNodes = nodes.filter(node => node.type === NODE_TYPES.ROADMAP_INTERVAL);
      if (!intervalNodes.length) return;

      const lastIntervalNode = intervalNodes.reduce(
        (prev, next) => (next.position.x > prev.position.x ? next : prev),
        intervalNodes[0]
      );

      if (id === lastIntervalNode.id) {
        setShowAddButton(true);
        return;
      }
    }

    setShowAddButton(false);
  }, [selected]);

  const handleAddInterval = () => {
    addRoadmapInterval({ boardId: Number(boardId) });
  };

  return (
    <>
      <Contextbar
        nodeId={id}
        isVisible={!dragging && data.toolbar?.visible}
        position={Position.Top}
        offset={30}
      />

      {showAddButton && (
        <NodeToolbar isVisible={data.toolbar?.visible} position={Position.Right} offset={11}>
          <button
            className={styles.buttonAddInterval}
            // disabled={generateDataLoading}
            onClick={() => handleAddInterval()}
          >
            <IconPlusBold />
          </button>
        </NodeToolbar>
      )}

      <button
        className={
          `${styles.container} ` +
          `${selected ? styles.container_active : ''} ` +
          `${inputActive ? styles.container_double : ''}`
        }
        onClick={handleClick}
      >
        <form
          className={styles.form}
          onSubmit={e => {
            e.preventDefault();
            ref.current?.blur();
          }}
        >
          {inputActive ? (
            <input
              ref={ref}
              id={`roadmap-interval-${id}`}
              type='text'
              name='roadmap-interval-input'
              placeholder='Please enter interval'
              title={data.value || 'Please enter interval'}
              value={data.value}
              onChange={handleChange}
              onBlur={() => setInputActive(false)}
              className={`${styles.input} nodrag`}
            />
          ) : (
            <span title={data.value || 'Please enter interval'} className={styles.text}>
              {data.value || 'Please enter interval'}
            </span>
          )}
        </form>

        <span className={styles.line}></span>
      </button>
    </>
  );
};

export default RoadmapIntervalNode;
