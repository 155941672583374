import { Edge, Node, Position } from 'reactflow';
import { v4 as uuid } from 'uuid';

import { EDGE_TYPES, NODE_TYPES } from 'consts';
import { NodeData } from 'store';
import { WBS_EPIC_COLORS } from '../const';

export const generateEpicNodes = (
  currentNode: Node<NodeData>
): Promise<{ nodes: Node<NodeData>[]; edges: Edge[] }> => {
  const nodeId1 = uuid();
  const nodeId2 = uuid();
  const nodeId3 = uuid();
  const nodeId4 = uuid();
  const edgeId1 = uuid();
  const edgeId2 = uuid();
  const edgeId3 = uuid();
  const edgeId4 = uuid();

  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        nodes: [
          {
            id: nodeId1,
            type: NODE_TYPES.WBS_EPIC,
            data: {
              color: WBS_EPIC_COLORS.RED,
              value: 'Market Research',
              toolbar: { position: Position.Top }
            },
            position: { x: -440, y: 125 },
            parentNode: currentNode.id
          },
          {
            id: nodeId2,
            type: NODE_TYPES.WBS_EPIC,
            data: {
              color: WBS_EPIC_COLORS.LIGHT_GREEN,
              value: 'Business Plan',
              toolbar: { position: Position.Top }
            },
            position: { x: -120, y: 125 },
            parentNode: currentNode.id
          },
          {
            id: nodeId3,
            type: NODE_TYPES.WBS_EPIC,
            data: {
              color: WBS_EPIC_COLORS.YELLOW,
              value: 'Registration and Domain Name',
              toolbar: { position: Position.Top }
            },
            position: { x: 200, y: 125 },
            parentNode: currentNode.id
          },
          {
            id: nodeId4,
            type: NODE_TYPES.WBS_EPIC,
            data: {
              color: WBS_EPIC_COLORS.PINK,
              value: 'Website Development',
              toolbar: { position: Position.Top }
            },
            position: { x: 520, y: 125 },
            parentNode: currentNode.id
          }
        ],
        edges: [
          {
            id: edgeId1,
            type: EDGE_TYPES.WBS,
            source: currentNode.id,
            target: nodeId1,
            targetHandle: 'd',
            sourceHandle: 'b'
          },
          {
            id: edgeId2,
            type: EDGE_TYPES.WBS,
            source: currentNode.id,
            target: nodeId2,
            targetHandle: 'd',
            sourceHandle: 'b'
          },
          {
            id: edgeId3,
            type: EDGE_TYPES.WBS,
            source: currentNode.id,
            target: nodeId3,
            targetHandle: 'd',
            sourceHandle: 'b'
          },
          {
            id: edgeId4,
            type: EDGE_TYPES.WBS,
            source: currentNode.id,
            target: nodeId4,
            targetHandle: 'd',
            sourceHandle: 'b'
          }
        ]
      });
    }, 0);
  });
};
