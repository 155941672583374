/* eslint-disable react/no-unescaped-entities */
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';

import styles from './TermsOfService.module.scss';
import { useToggle } from 'hooks';
import { ROUTES } from 'consts';

import { IconCross, Modal } from 'components/atoms';
import { CustomiseCookies } from 'components/organisms';

const TermsOfService: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          <button className={styles['button-delete']} onClick={handleClose}>
            <IconCross />
          </button>
          <div className={styles['text-container']}>
            <div className={styles['title-block']}>
              <h3 className={styles['main-title']}>
                {t('TermsOfService.termsOfService.termsOfService')}
              </h3>
              <h3 className={styles['right-title']}>
                {t('TermsOfService.termsOfService.publicationDate')}
              </h3>
            </div>
            <div className={styles['text-block']}>
              <p className={styles.text}>
                <p>
                  These terms of service apply to all Migned customers, including self-serve and
                  reseller customers.
                </p>{' '}
                This Migned Terms of Service (“
                <strong>Agreement</strong>
                ”) is entered into by and between DigitMind Technologies dba Migned (“
                <strong>Migned</strong>”) and the entity or person placing an order for or accessing
                the Service (“<strong>Customer</strong>” or “<strong>you</strong>”). This Agreement
                consists of the terms and conditions set forth below and any Orders. If you are
                accessing or using the Service on behalf of your company, you represent that you are
                authorized to accept this Agreement on behalf of your company, and all references to
                “you” reference your company.
                <p>
                  The “<strong>Effective Date</strong>” of this Agreement is the date which is the
                  earlier of (a) Customer’s initial access to the Service through any online
                  provisioning, registration or order process or (b) the effective date of the first
                  Order. This Agreement will govern Customer’s initial purchase on the Effective
                  Date as well as any future purchases made by Customer that reference this
                  reference this Agreement. Migned may modify this Agreement from time to time as
                  permitted in Section 17 (Modifications to Agreement).{' '}
                  <strong>Purchase from Reseller</strong> : If Customer purchases the Service from
                  an authorized reseller of Migned (“<strong>Reseller</strong>”), Customer’s use of
                  the Service will be governed by this Agreement, subject to Section 18.8 (Reseller
                  Orders) below.
                </p>
                <strong>
                  By indicating your acceptance of this Agreement or accessing or using the Service,
                  you are agreeing to be bound by the terms and conditions of this Agreement. Each
                  party expressly agrees that this Agreement is legally binding upon it.
                </strong>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>1. Overview</strong>
              </p>
              <p className={styles.text}>
                Migned's Service provides visual collaboration tools, enabling Users to create,
                collaborate, and centralize communication through interactive online virtual
                whiteboards. Customer retains full control over the types and content of all
                Customer Content submitted to the Service.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>
                  <p>2. The Service</p>
                  <p>2.1 Permitted Use</p>
                </strong>
              </p>
              <p className={styles.text}>
                During the Subscription Term, Customer may access and use the Service solely for
                internal business or personal purposes, as outlined in this Agreement and any
                applicable usage limits specified in an Order. This includes the right to copy and
                use the Software as part of the authorized use of the Service.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>2.2 Users</strong>
              </p>
              <p className={styles.text}>
                Access and use of the Service are limited to authorized Users. Each User must keep
                their login credentials confidential and not share them with others. Customer is
                responsible for ensuring Users' compliance with this Agreement and for actions taken
                through their accounts (excluding misuse caused by Migned's breach). Prompt
                notification to Migned is required in the event of compromised User login
                credentials. Migned's use of User account information is governed by its{' '}
                <Link to={ROUTES.PRIVACY_POLICY}>{t('common.privacyPolicy')}</Link>.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>2.3 Admins</strong>
              </p>
              <p className={styles.text}>
                Customer may designate a User as an admin, granting them control over the Customer's
                Service account, including User and Customer Content management. Customer assumes
                full responsibility for selecting admins and their actions.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>2.4 Customer Affiliates</strong>
              </p>
              <p className={styles.text}>
                Customer’s Affiliates may use the Service as Users of Customer. Alternatively, an
                Affiliate of Customer may enter its own Order(s) as mutually agreed with Migned, and
                this creates a separate agreement between the Affiliate and Migned that incorporates
                this Agreement with the Affiliate treated as “Customer.” Neither Customer nor any
                Customer Affiliate has any rights under each other’s agreement with Migned, and
                breach or termination of any such agreement is not breach nor termination under any
                other.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>2.5 Registration Using Corporate Email</strong>
              </p>
              <p className={styles.text}>
                If you have registered an account using an email address associated with your
                employer or another entity, you confirm that you have the authority to create an
                account on behalf of that entity. Additionally, you acknowledge that Migned may
                share your email address with that entity (referred to as the "Customer") and that
                control of your account may be transferred to them. In such a case, the
                administrator in control of the account may have the ability to:
                <p>(a) access, disclose, restrict or remove information from the account,</p>
                <p>(b) restrict or terminate your access to the Service,</p>
                <p>(c) prevent you from later disassociating such account from the Customer.</p>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>2.6 Access to Third Party Projects</strong>
              </p>
              <p className={styles.text}>
                If you receive an invitation to participate in another Customer's Project, you
                understand that your access to that Project and any content you contribute will be
                subject to the exclusive control of that Customer.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>2.7 Sharing Settings</strong>
              </p>
              <p className={styles.text}>
                Through the Service, you have control over sharing Projects and Boards. Migned is
                not liable for how others may access or use Customer Content as a result of your or
                your Users' decision to share Projects or Boards.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>2.8 Age Requirement for Users</strong>
              </p>
              <p className={styles.text}>
                The Service is intended for use by individuals who are at least 16 years old. It
                should not be used by anyone under this age. As the Customer, you are responsible
                for ensuring that all Users meet this age requirement.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>2.9 Restrictions</strong>
              </p>
              <p className={styles.text}>
                Customer shall not engage in the following activities, nor permit others to do so:
                <p>
                  (a) provide access to, distribute, sell or sublicense the Service to a third
                  party,
                </p>
                <p>
                  (b) use the Service on behalf of, or to provide any product or service to, third
                  parties,
                </p>
                <p>(c) use the Service to develop a similar or competing product or service,</p>
                <p>
                  (d) scrape, data mine, reverse engineer, decompile, disassemble or seek to access
                  the source code or non-public APIs to or unauthorized data from the Service,
                  except to the extent expressly permitted by Law (and then only with prior notice
                  to Migned),
                </p>
                <p>
                  (e) modify or create derivative works of the Service or copy any element of the
                  Service (other than authorized copies of the Software),
                </p>
                <p>
                  (b) use the Service on behalf of, or to provide any product or service to, third
                  parties,
                </p>
                <p>
                  (f) remove or obscure any proprietary notices in the Service or otherwise
                  misrepresent the source of ownership of the Service,
                </p>
                <p>(g) publish benchmarks or performance information about the Service,</p>
                <p>
                  (h) interfere with the Service’s operation, circumvent its access restrictions or
                  conduct any security or vulnerability test of the Service,
                </p>
                <p>(i) transmit any viruses or other harmful materials to the Service,</p>
                <p>(j) allow Users to share User seats,</p>
                <p>
                  (k) engage in any fraudulent, misleading, illegal or unethical activities related
                  to the Service,
                </p>
                <p>
                  (l) use the Service to store or transmit material which contains illegal content.
                </p>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>3. Data Use</strong>
              </p>
              <p className={styles.text}>
                Customer grants Migned a non-exclusive, worldwide right to use, copy, store,
                transmit, and display Customer Content. This includes the ability to modify and
                create derivative works of Customer Content, but only as necessary to provide the
                Service, Support, and any Technical Services to Customer under this Agreement.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>
                  <p>4. Customer Obligations</p>
                  <p>4.1 Generally</p>
                </strong>
              </p>
              <p className={styles.text}>
                Customer bears responsibility for its Customer Content, including its content and
                accuracy, and agrees to adhere to applicable laws when using the Service. Customer
                represents and warrants that it has made all necessary disclosures and possesses all
                the rights, consents, and permissions required to utilize its Customer Content with
                the Service and grant Migned the rights specified in Section 3 (Data Use). This
                should be done without violating or infringing upon any laws, third-party rights
                (including intellectual property, publicity, or privacy rights), or any terms or
                privacy policies that govern the Customer Content.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>4.2 Prohibited Uses</strong>
              </p>
              <p className={styles.text}>
                Customer must refrain from using the Service to handle Prohibited Data or engage in
                High Risk Activities. Customer acknowledges that the Service is not designed to
                fulfill any legal obligations associated with such uses, and Migned is not acting as
                a Business Associate. Regardless of anything stated in this Agreement, Migned bears
                no liability for Prohibited Data or the use of the Service for High Risk Activities.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>4.3 Individual User Account Takeover</strong>
              </p>
              <p className={styles.text}>
                The Service may include functionality that allows Customer to convert accounts
                previously registered by individuals using email addresses from the Customer's
                domain into User accounts under Customer's control. Customer represents and warrants
                that it possesses all necessary rights and consents to perform such conversions for
                any existing accounts registered using email addresses from the Customer's domain
                into accounts under Customer's control.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>5. Suspension of Service</strong>
              </p>
              <p className={styles.text}>
                Migned may suspend Customer’s or a User’s access to and use of the Service and
                related services if Customer breaches Section 2.8 (Age Requirement for Users),
                Section 2.9 (Restrictions) or Section 4 (Customer Obligations), if Customer’s or
                User’s actions risk harm to other customers or the security, availability or
                integrity of the Service. Where practicable, Migned will use reasonable efforts to
                provide Customer with prior notice of the suspension. Once Customer resolves the
                issue requiring suspension, Migned will promptly restore Customer’s or User’s access
                to the Service in accordance with this Agreement.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>6. Integration with Third-Party Platforms</strong>
              </p>
              <p className={styles.text}>
                Customer may choose to integrate the Service with Third-Party Platforms. The use of
                Third-Party Platforms is subject to Customer's agreement with the respective
                provider and not covered by this Agreement. Migned does not control the Third-Party
                Platforms and bears no liability for them, including their security, functionality,
                operation, availability, or interoperability, or how the Third-Party Platforms or
                their providers handle Customer Content. If Customer enables a Third-Party Platform
                with the Service, Migned may access and exchange Customer Content with the
                Third-Party Platform on Customer's behalf.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>7. Technical Services</strong>
              </p>
              <p className={styles.text}>
                Any Technical Services purchased will be provided as described in the relevant
                Order. Customer must grant Migned timely access to Customer Materials that are
                reasonably necessary for the provision of Technical Services. Failure to do so by
                Customer will release Migned from its obligation to provide Technical Services until
                access is granted. Migned will use Customer Materials solely for the purpose of
                delivering Technical Services. The deliverables of Technical Services pertain to the
                configuration or use of the Service. Customer may use Technical Services
                deliverables solely as part of its authorized use of the Service, subject to the
                same terms outlined in Section 2 (The Service) and Section 4 (Customer
                Responsibilities).
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>
                  <p>8. Сommercial Terms</p>
                  <p>8.1 Subscription Term</p>
                </strong>
              </p>
              <p className={styles.text}>
                Unless otherwise specified in the applicable Order, each Subscription Term shall
                automatically renew for the same duration, unless either party provides notice of
                non-renewal to the other party. Upon receipt of a notice of non-renewal, Customer
                will not incur charges for the subsequent billing cycle; however, no refunds or
                credits shall be issued for any amounts already charged.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>8.2 Fees and Taxes</strong>
              </p>
              <p className={styles.text}>
                Fees are as described in each Order. Customer will reimburse Migned for pre-approved
                travel and lodging expenses it incurs in providing Technical Services. Fees are
                invoiced on the schedule in the Order and reimbursable expenses are invoiced in
                arrears. Customer agrees that Migned may bill Customer’s credit card or other
                payment method for renewals, expenses and any other unpaid fees, as applicable.
                Customer may change its payment method information by entering updated information
                through the user interface of the Service. Unless the Order provides otherwise, all
                fees and expenses are due within 30 days of the billing date specified in the
                applicable Order. Fees for renewal Subscription Terms are at Migned’s then-current
                rates, regardless of any discounted pricing in a prior Order. Late payments are
                subject to a service charge of 1.5% per month or the maximum amount allowed by Law,
                whichever is less. All fees and expenses are non-refundable except as set out in
                Section 9.2 (Warranty Remedy) and Section 13.4 (Mitigation and Exceptions). Customer
                is responsible for any sales, use, goods and services, value-added, withholding or
                similar taxes or levies that apply to its Orders, whether domestic or foreign (“
                <strong>Taxes</strong>
                ”), other than Migned’s income tax. Fees and expenses are exclusive of Taxes.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>
                  <p>9. Warranties and Disclaimers</p>
                  <p>9.1 Limited Warranty</p>
                </strong>
              </p>
              <p className={styles.text}>
                <p>Migned warrants to Customer that:</p>
                <p>
                  (a) Migned will not significantly reduce the overall functionality of the Service
                  during a Subscription Term
                </p>
                <p>
                  (b) Migned will perform any Technical Services in a professional and competent
                  manner.
                </p>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>9.2 Warranty Remedy</strong>
              </p>
              <p className={styles.text}>
                If Migned breaches Section 9.1 (Limited Warranty) and Customer submits a reasonably
                detailed warranty claim within 30 days of discovering the issue, Migned will make
                reasonable efforts to rectify the non-conformity. If Migned is unable to do so
                within 60 days of Customer's warranty claim, either party may terminate the affected
                Order related to the non-conforming Service or Technical Services. Migned will then
                refund to Customer any prepaid and unused fees for the terminated portion of the
                Subscription Term (for the Performance Warranty) or for the non-conforming Technical
                Services (for the Technical Services Warranty). These procedures constitute
                Customer's sole remedy and Migned's entire liability for breaching the warranties in
                Section 9.1. These warranties do not apply to
                <p>(a) issues caused by misuse or unauthorized modifications,</p>
                <p>
                  (b) issues in or caused by Third-Party Platforms or other third-party systems,
                </p>
                <p>(c) Trials and Betas or other free or evaluation use.</p>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>9.3 Disclaimers</strong>
              </p>
              <p className={styles.text}>
                <strong>
                  Except as expressly provided in Section 9.1 (Limited Warranty), the Service,
                  Support, Technical Services, and all other related Migned services are provided on
                  an "AS IS" basis. Migned and its suppliers make no other warranties, whether
                  express, implied, statutory, or otherwise, including warranties of
                  merchantability, fitness for a particular purpose, title, or non-infringement.
                  Migned does not warrant that Customer's use of the Service will be uninterrupted
                  or error-free, or that the Service will meet Customer's requirements, operate in
                  conjunction with third-party services used by Customer, or maintain Customer
                  Content without loss. Migned is not liable for delays, failures, or problems
                  inherent in the use of the Internet and electronic communications or other systems
                  beyond Migned's control. Customer may have other statutory rights, but any
                  statutorily required warranties will be limited to the shortest period permitted
                  by law.
                </strong>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>
                  <p>10. Term and Termination</p>
                  <p>10.1 Term</p>
                </strong>
              </p>
              <p className={styles.text}>
                This Agreement starts on the Effective Date and continues until 90 days after
                expiration or termination of all Subscription Terms.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>10.2 Termination for Cause</strong>
              </p>
              <p className={styles.text}>
                Either party may terminate this Agreement (including all Orders) if the other party:
                <p>
                  (a) fails to remedy a material breach of this Agreement (including a failure to
                  pay fees) within 30 days after receiving notice,
                </p>
                <p>(b) ceases its operations without a successor, or</p>
                <p>
                  (c) files for bankruptcy, receivership, trust deed, creditors' arrangement,
                  composition, or any similar proceeding, or if such a proceeding is initiated
                  against that party and not dismissed within 60 days.
                </p>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>10.3 Termination for Convenience</strong>
              </p>
              <p className={styles.text}>
                Either party may terminate this Agreement (including all Orders) at any time and for
                any reason by providing 90 days' notice to the other party. However, (i) Customer
                will not be entitled to a refund of any prepaid fees, and (ii) if Customer has any
                outstanding fees for the current Subscription Term, those fees will become
                immediately due and payable.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>10.4 Effect of Termination</strong>
              </p>
              <p className={styles.text}>
                Upon the expiration or termination of this Agreement or an Order, Customer's access
                to the Service and Technical Services will cease, except for limited use of the
                Service to export Customer Content. At the disclosing party's request upon
                expiration or termination of this Agreement, the receiving party will delete all of
                the disclosing party's Confidential Information, excluding Customer Content.
                Customer Content and other Confidential Information may be retained in the receiving
                party's standard backups after deletion but will remain subject to the
                confidentiality restrictions outlined in this Agreement.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>10.5 Survival</strong>
              </p>
              <p className={styles.text}>
                The following sections survive the expiration or termination of this Agreement: 2.9
                (Restrictions), 4 (Customer Obligations), 8.2 (Fees and Taxes), 9.3 (Disclaimers),
                10.4 (Effect of Termination), 10.5 (Survival), 11 (Ownership), 12 (Limitations of
                Liability), 13 (Indemnification), 14 (Confidentiality), 15 (Required Disclosures),
                18 (General Terms). Unless an exclusive remedy is provided, the exercise of a remedy
                under this Agreement, including termination, does not limit any other remedies a
                party may have.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>11. Ownership</strong>
              </p>
              <p className={styles.text}>
                Neither party grants the other party any rights or licenses not expressly stated in
                this Agreement. Except for Migned's use rights as specified in this Agreement,
                Customer retains all intellectual property and other rights in Customer Content and
                Customer Materials provided to Migned. Except for Customer's use rights as specified
                in this Agreement, Migned and its licensors retain all intellectual property and
                other rights in the Service, any Technical Services deliverables, and related Migned
                technology, templates, formats, and dashboards, including any modifications or
                improvements made by Migned to these items. Migned may generate and use Usage Data
                to operate, improve, analyze, and support the Service and for other lawful business
                purposes. If Customer provides Migned with feedback or suggestions regarding the
                Service or other Migned offerings, Migned may use the feedback or suggestions
                without any restrictions or obligations.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>
                  <p>12. Limitations of Liability</p>
                  <p>12.1 Consequential Damages Waiver </p>
                </strong>
              </p>
              <p className={styles.text}>
                <strong>
                  The disclaimer in this Section 12.1 (Consequential Damages Waiver) will not apply
                  to the extent prohibited by applicable Laws. Except for Excluded Claims, neither
                  party (nor its suppliers) will have any liability arising out of or related to
                  this Agreement for any loss of use, lost data, lost profits, failure of security
                  mechanisms, revenues, goodwill, interruption of business, or any indirect,
                  special, incidental, reliance, or consequential damages of any kind, even if
                  informed of the possibility of such damages in advance.
                </strong>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>12.2 Liability Cap</strong>
              </p>
              <p className={styles.text}>
                <strong>
                  Except for Excluded Claims, the aggregate liability of each party (and its
                  suppliers) arising out of or related to this Agreement will not exceed the total
                  amounts paid or payable by Customer to Migned during the preceding 12 months under
                  this Agreement.
                </strong>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>12.3 Excluded Claims</strong>
              </p>
              <p className={styles.text}>
                "Excluded Claims" refer to:
                <p>
                  (a) Customer's breach of Sections 2.9 (Restrictions) or 4 (Customer Obligations),
                </p>
                <p>
                  (b) either party's breach of Section 14 (Confidentiality) (excluding claims
                  related to Customer Content),
                </p>
                <p>
                  (c) amounts payable to third parties under Customer's obligations in Section 13.2
                  (Indemnification by Customer),
                </p>
                <p>(d) either party's willful misconduct,</p>
                <p>
                  (e) Migned's performance of the Service that results in death, personal injury, or
                  damage to tangible property.
                </p>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>12.4 Nature of Claims and Failure of Essential Purpose</strong>
              </p>
              <p className={styles.text}>
                The waivers and limitations outlined in this Section 12 shall be applicable
                irrespective of the nature of the legal action, whether arising from a contract,
                tort (including negligence), strict liability, or any other legal basis. These
                waivers and limitations shall remain in effect even if any limited remedy specified
                in this Agreement fails to fulfill its essential purpose.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>
                  <p>13. Indemnification</p>
                  <p>13.1 Indemnification by Migned</p>
                </strong>
              </p>
              <p className={styles.text}>
                Migned shall undertake the defense of Customer against any third-party claim to the
                extent that it alleges the infringement of a third party's patent, copyright,
                trademark, or trade secret by the Service, when used by Customer in accordance with
                the terms of this Agreement. Migned shall indemnify and hold Customer harmless from
                any damages or costs awarded against Customer (including reasonable attorneys' fees)
                or agreed upon in a settlement by Migned, resulting from such a claim.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>13.2 Indemnification by Customer</strong>
              </p>
              <p className={styles.text}>
                Customer will defend Migned against any third-party claim arising from Customer
                Content, Customer Materials, or Customer's actual or alleged breach of Section 4
                (Customer Obligations). Customer shall indemnify and hold Migned harmless from any
                damages or costs awarded against Migned (including reasonable attorneys' fees) or
                agreed upon in a settlement by Customer, resulting from such a claim.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>13.3 Procedures</strong>
              </p>
              <p className={styles.text}>
                The indemnifying party’s obligations in this Section 13 are subject to receiving (a)
                prompt notice of the claim, (b) the exclusive right to control and direct the
                investigation, defense and settlement of the claim and (c) all reasonably necessary
                cooperation of the indemnified party, at the indemnifying party’s expense for
                reasonable out-of-pocket costs. The indemnifying party may not settle any claim
                without the indemnified party’s prior consent if settlement would require the
                indemnified party to admit fault or take or refrain from taking any action (other
                than relating to use of the Service, when Migned is the indemnifying party). The
                indemnified party may participate in a claim with its own counsel at its own
                expense.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>13.4 Mitigation and Exceptions</strong>
              </p>
              <p className={styles.text}>
                In response to an actual or potential infringement claim, if required by settlement
                or injunction or as Migned determines necessary to avoid material liability, Migned
                may at its option: (a) procure rights for Customer’s continued use of the Service,
                (b) replace or modify the allegedly infringing portion of the Service to avoid
                infringement without reducing the Service’s overall functionality or (c) terminate
                the affected Order and refund to Customer any pre-paid, unused fees for the
                terminated portion of the Subscription Term. Migned’s obligations in this Section 13
                do not apply (1) to the extent infringement results from Customer’s modification of
                the Service, (2) to infringement resulting from Software other than the most recent
                release provided by Migned, (3) to unauthorized use of the Service, (4) if Customer
                settles or makes any admissions about a claim without Migned’s prior consent, (5) if
                Customer continues to use the Service (or any element thereof) after being notified
                of allegedly infringing activity or informed of modifications that would have
                avoided the alleged infringement or (6) to Trials and Betas or other free or
                evaluation use.{' '}
                <strong>
                  This Section 13 sets out Customer’s exclusive remedy and Migned’s entire liability
                  regarding infringement of third-party intellectual property rights.
                </strong>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>
                  <p>14. Confidentiality</p>
                  <p>14.1 Definition</p>
                </strong>
              </p>
              <p className={styles.text}>
                “Confidential Information” means information disclosed to the receiving party under
                this Agreement that is designated by the disclosing party as proprietary or
                confidential or that should be reasonably understood to be proprietary or
                confidential due to its nature and the circumstances of its disclosure. Migned’s
                Confidential Information includes the terms and conditions of this Agreement and any
                technical or performance information about the Service. Customer’s Confidential
                Information includes Customer Content.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>14.2 Obligations</strong>
              </p>
              <p className={styles.text}>
                As receiving party, each party will (a) hold Confidential Information in confidence
                and not disclose it to third parties except as permitted in this Agreement,
                including Section 3 (Data Use), and (b) only use Confidential Information to fulfill
                its obligations and exercise its rights in this Agreement. The receiving party may
                disclose Confidential Information to its employees, agents, contractors and other
                representatives having a legitimate need to know (including, for Migned, the
                subcontractors referenced in Section 18.6), provided it remains responsible for
                their compliance with this Section 14 and they are bound to confidentiality
                obligations no less protective than this Section 14.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>14.3 Exclusions</strong>
              </p>
              <p className={styles.text}>
                These confidentiality obligations do not apply to information that the receiving
                party can document (a) is or becomes public knowledge through no fault of the
                receiving party, (b) it rightfully knew or possessed prior to receipt under this
                Agreement, (c) it rightfully received from a third party without breach of
                confidentiality obligations or (d) it independently developed without using the
                disclosing party’s Confidential Information.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>14.4. Remedies</strong>
              </p>
              <p className={styles.text}>
                Unauthorized use or disclosure of Confidential Information may cause substantial
                harm for which damages alone are an insufficient remedy. Each party may seek
                appropriate equitable relief, in addition to other available remedies, for breach or
                threatened breach of this Section 14.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>15. Required Disclosures</strong>
              </p>
              <p className={styles.text}>
                Nothing in this Agreement prohibits either party from making disclosures, including
                of Customer Content and other Confidential Information, if required by Law, subpoena
                or court order, provided (if permitted by Law) it notifies the other party in
                advance and cooperates in any effort to obtain confidential treatment.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>16. Publicity</strong>
              </p>
              <p className={styles.text}>
                Neither party may publicly announce this Agreement except with the other party’s
                prior consent or as required by Laws. However, Migned may include Customer and its
                trademarks in Migned’s customer lists and promotional materials but will cease this
                use at Customer’s written request.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>17. Modifications to Agreement</strong>
              </p>
              <p className={styles.text}>
                Migned may modify this Agreement (which may include changes to Service pricing and
                plans) from time to time by giving notice to Customer by email or through the
                Service. Unless a shorter period is specified by Migned (e.g., due to changes in the
                Law or exigent circumstances), modifications become effective upon renewal of
                Customer’s current Subscription Term or entry into a new Order. If Migned specifies
                that the modifications to the Agreement will take effect prior to Customer’s next
                renewal or Order and Customer notifies Migned of its objection to the modifications
                within 30 days after the date of such notice, Migned (at its option and as
                Customer’s exclusive remedy) will either: (a) permit Customer to continue under the
                existing version of this Agreement until expiration of the then-current Subscription
                Term (after which time the modified Agreement will go into effect) or (b) allow
                Customer to terminate this Agreement and receive a refund of any pre-paid Service
                fees allocable to the terminated portion of the applicable Subscription Term.
                Customer may be required to click to accept or otherwise agree to the modified
                Agreement in order to continue using the Service, and, in any event, continued use
                of the Service after the updated version of this Agreement goes into effect will
                constitute Customer’s acceptance of such updated version.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>
                  <p>18. General Terms</p>
                  <p>18.1 Assignment</p>
                </strong>
              </p>
              <p className={styles.text}>
                Neither party may assign this Agreement without the prior consent of the other
                party, except that either party may assign this Agreement in connection with a
                merger, reorganization, acquisition or other transfer of all or substantially all
                its assets or voting securities. Any non-permitted assignment is void. This
                Agreement will bind and inure to the benefit of each party’s permitted successors
                and assigns.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>18.2 Entire Agreement</strong>
              </p>
              <p className={styles.text}>
                This Agreement (which includes all Orders, the Policies) is the parties’ entire
                agreement regarding its subject matter and supersedes any prior or contemporaneous
                agreements regarding its subject matter. In this Agreement, headings are for
                convenience only and “including” and similar terms are to be construed without
                limitation. This Agreement may be executed in counterparts (including electronic
                copies and PDFs), each of which is deemed an original and which together form one
                and the same agreement.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>18.3 Amendments</strong>
              </p>
              <p className={styles.text}>
                Except as otherwise provided herein, any amendments, modifications or supplements to
                this Agreement must be in writing and signed by each party’s authorized
                representatives or, as appropriate, agreed through electronic means provided by
                Migned. Nonetheless, with notice to Customer, Migned may modify the Policies to
                reflect new features or changing practices, but the modifications will not
                materially decrease Migned’s overall obligations during a Subscription Term. The
                terms in any past, contemporaneous or future Customer purchase order, business form
                or vendor management portal will not amend or modify this Agreement and are
                expressly rejected by Migned; any of these documents are for administrative purposes
                only and have no legal effect.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>18.4 Waivers and Severability</strong>
              </p>
              <p className={styles.text}>
                Waivers must be signed by the waiving party’s authorized representative and cannot
                be implied from conduct. If any provision of this Agreement is held invalid, illegal
                or unenforceable, it will be limited to the minimum extent necessary so the rest of
                this Agreement remains in effect.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>18.5 Force Majeure</strong>
              </p>
              <p className={styles.text}>
                Neither party is liable for any delay or failure to perform any obligation under
                this Agreement (except for a failure to pay fees) due to events beyond its
                reasonable control, such as a strike, blockade, war, act of terrorism, riot,
                Internet or utility failures, refusal of government license or natural disaster.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>18.6 Subcontractors</strong>
              </p>
              <p className={styles.text}>
                Migned may use subcontractors and permit them to exercise Migned’s rights, but
                Migned remains responsible for their compliance with this Agreement and for its
                overall performance under this Agreement.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>18.7 Independent Contractors</strong>
              </p>
              <p className={styles.text}>
                The parties are independent contractors, not agents, partners or joint venturers.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>18.8 Reseller Orders</strong>
              </p>
              <p className={styles.text}>
                This Section applies to any access to the Service purchased by Customer through a
                Reseller. (a) Commercial Terms. Instead of paying Migned, Customer will pay
                applicable amounts to the Reseller as agreed between Customer and the Reseller.
                Customer’s order details (e.g., scope of use and fees) will be as stated in the
                Order placed by the Reseller with Migned on Customer’s behalf. The Reseller is
                responsible for the accuracy of such Order. Migned may suspend or terminate
                Customer’s rights to use the Service if it does not receive the corresponding
                payment from the Reseller. If Customer is entitled to a refund under this Agreement,
                Migned will refund any applicable fees to the Reseller and the Reseller will be
                solely responsible for refunding the appropriate amounts to Customer, unless
                otherwise specified. (b) Relationship with Migned. This Agreement is directly
                between Migned and Customer and governs all use of the Service by Customer.
                Resellers are not authorized to modify this Agreement or make any promises or
                commitments on Migned’s behalf, and Migned is not bound by any obligations to
                Customer other than as set forth in this Agreement. Migned is not party to (or
                responsible under) any separate agreement between Customer and Reseller and is not
                responsible for the Reseller’s acts, omissions, products or services. The amount
                paid or payable by the Reseller to Migned for Customer’s use of the applicable
                Service under this Agreement will be deemed the amount paid or payable by Customer
                to Migned under this Agreement for purposes of Section 12 (Limitations of
                Liability).
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={toggleOpen} className='!px-[30px]'>
        <CustomiseCookies onClose={toggleOpen} />
      </Modal>
    </main>
  );
};

export default TermsOfService;
