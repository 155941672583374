import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { MoveBoardDto, MoveBoardResponse, apiProject } from 'api';
import { handleError, notifySuc } from 'helpers';
import { useInvalidate } from '../useInvalidate';

interface Params {
  onCancel?: () => void;
  onSuccess?: (data: MoveBoardResponse, variables: MoveBoardDto) => void | Promise<void>;
}

export const useMoveBoardMutation = ({ onCancel, onSuccess }: Params) => {
  const { t } = useTranslation();
  const invalidate = useInvalidate();

  return useMutation(
    (data: MoveBoardDto) => {
      return apiProject.moveBoard(data);
    },
    {
      async onSuccess(data, variables) {
        notifySuc(t('common.notifications.boardMoved'));
        await invalidate(`projects/${variables.projectId}`);

        onCancel && onCancel();
        onSuccess && onSuccess(data, variables);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
