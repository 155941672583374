import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTeams } from 'hooks/queries';
import { SelectOption, Team } from 'models';

import { SelectTeam } from '..';

type TeamSwitcherProps = {
  team: Team;
};

const TeamSwitcher: FC<TeamSwitcherProps> = ({ team }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: teamsData, isLoading: teamsLoading } = useTeams();

  const teamOptions = useMemo<SelectOption[]>(() => {
    if (!teamsData || !teamsData.teams) return [];

    const teamOptions: SelectOption[] = teamsData.teams.map(({ name, id }) => ({
      value: id.toString(),
      label: name
    }));
    return teamOptions;
  }, [teamsData]);

  const switchTeam = (teamId: string) => {
    const splittedPathname = location.pathname.split('/');
    splittedPathname[2] = teamId;
    const newPathname = splittedPathname.join('/');
    navigate(newPathname);
  };

  if (teamsLoading) return <h4>{t('common.loading')}</h4>;
  if (!team) return <h4>{t('TeamPage.settings.error2')}</h4>;
  return (
    <SelectTeam
      placeholder={t('ChooseTeam.form.placeholder')}
      options={teamOptions}
      currentOption={teamOptions.find(option => option.value === team.id.toString())}
      setCurrentOption={option => switchTeam(option.value)}
      className='w-full relative flex flex-col cursor-pointer select-none'
    />
  );
};

export default TeamSwitcher;
