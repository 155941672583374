import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { apiBoard } from 'api';
import { handleError } from 'helpers';

export const useBoard = (boardId: number) => {
  const {
    data: boardData,
    isLoading: boardLoading,
    refetch: refetchBoard
  } = useQuery([`boards/one/${boardId}`], async () => await apiBoard.getBoard(boardId), {
    onError(error) {
      handleError(error);
    }
  });
  const board = useMemo(() => boardData, [boardData]);

  return [board, boardLoading, refetchBoard] as const;
};
