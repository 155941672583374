import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useTeam } from 'hooks/queries';

import { Tabs, TeamSwitcher } from './components';

const SidebarSettings: FC = () => {
  const { t } = useTranslation();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const { data: team, isLoading: teamLoading } = useTeam(teamId);

  if (teamLoading) return <h4>{t('common.loading')}</h4>;
  if (!team) return <h4>{t('TeamPage.settings.error')}</h4>;
  return (
    <aside
      className={
        'w-[310px] shrink-0 p-[13px] flex flex-col gap-[10px] ' +
        'rounded-[20px] border-[2px] border-solid border-[#EDEFF2]'
      }
    >
      <TeamSwitcher team={team} />
      <Tabs team={team} />
    </aside>
  );
};

export default SidebarSettings;
