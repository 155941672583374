import { SelectOption, TEAM_ROLES } from 'models';

export const ROLE_OPTIONS: SelectOption[] = [
  {
    value: TEAM_ROLES.VIEWER,
    label: 'Viewer'
  },
  {
    value: TEAM_ROLES.EDITOR,
    label: 'Editor'
  },
  {
    value: TEAM_ROLES.ADMIN,
    label: 'Admin'
  },
  {
    value: TEAM_ROLES.OWNER,
    label: 'Owner'
  }
];
