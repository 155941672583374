/* eslint-disable react/no-unescaped-entities */
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './CookiePolicy.module.scss';
import { useToggle } from 'hooks';

import { IconCross, Modal } from 'components/atoms';
import { CustomiseCookies } from 'components/organisms';

const CookiePolicy: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          <button className={styles['button-delete']} onClick={handleClose}>
            <IconCross />
          </button>
          <div className={styles['text-container']}>
            <div className={styles['title-block']}>
              <h3 className={styles['main-title']}>
                {t('CookiePolicy.cookiesPolicy.cookiePolicy')}
              </h3>
              <h3 className={styles['right-title']}>
                {t('CookiePolicy.cookiesPolicy.publicationDate')}
              </h3>
            </div>
            <div className={styles['text-block']}>
              <p className={styles.text}>
                {t('CookiePolicy.cookiesPolicy.thisCookiesPolicyProvides')}
                <strong>{t('CookiePolicy.cookiesPolicy.migned')}</strong>
                {t('CookiePolicy.cookiesPolicy.andAlsoReferred')}"
                <strong>{t('CookiePolicy.cookiesPolicy.our')}</strong>", "
                <strong>{t('CookiePolicy.cookiesPolicy.us')}</strong>"{' '}
                {t('CookiePolicy.cookiesPolicy.and')} "
                <strong>{t('CookiePolicy.cookiesPolicy.we')}</strong>"){' '}
                {t('CookiePolicy.cookiesPolicy.usesCookiesOnOur')}{' '}
                <a href='https://www.migned.com'>https://www.migned.com</a>
                {t('CookiePolicy.cookiesPolicy.includingItsSubDomains')}"
                <strong>{t('CookiePolicy.cookiesPolicy.site')}</strong>"
                {t('CookiePolicy.cookiesPolicy.andTheOtherFeatures')}"
                <strong>{t('CookiePolicy.cookiesPolicy.services')}</strong>").
              </p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>
                {t('CookiePolicy.cookiesPolicy.anyInformation')}
                <a href='https://www.migned.com/privacy-policy'>
                  {t('CookiePolicy.cookiesPolicy.privacyPolicy')}
                </a>
                {t('CookiePolicy.cookiesPolicy.ifYouDoDotAccept')}
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>{t('CookiePolicy.cookie.cookie')}</p>
              <p className={styles.text}>{t('CookiePolicy.cookie.refersToASmall')}</p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>{t('CookiePolicy.cookie.cookiesAreSpecific')}</p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>{t('CookiePolicy.webBeacon.webBeacon')}</p>
              <p className={styles.text}>{t('CookiePolicy.webBeacon.mignedEmploysCookies')}</p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>{t('CookiePolicy.doesMigned.doesMigned')}</p>
              <p className={styles.text}>{t('CookiePolicy.doesMigned.mignedEmploysCookies')}</p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                {t('CookiePolicy.howDoesMigned.howDoesMigned')}
              </p>
              <p className={styles.text}>{t('CookiePolicy.howDoesMigned.mignedUtilizesCertain')}</p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>{t('CookiePolicy.howDoesMigned.cookiesServe')}</p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                {t('CookiePolicy.categoriesOfUse.categoriesOfUse')}
              </p>
              <p className={styles.text}>
                <strong>{t('CookiePolicy.categoriesOfUse.essentialCookies')}</strong>{' '}
                {t('CookiePolicy.categoriesOfUse.theseCookiesAre')}
              </p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>
                <strong>{t('CookiePolicy.categoriesOfUse.performanceCookies')}</strong>{' '}
                {t('CookiePolicy.categoriesOfUse.thisCategoryEncompasses')}
              </p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>
                <strong>{t('CookiePolicy.categoriesOfUse.targetingCookies')}</strong>{' '}
                {t('CookiePolicy.categoriesOfUse.weMayEmploy')}
              </p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>
                <strong>{t('CookiePolicy.categoriesOfUse.analyticsAndResearch')}</strong>{' '}
                {t('CookiePolicy.categoriesOfUse.cookiesPlayARole')}
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                {t('CookiePolicy.whatCookiesDoes.whatCookiesDoes')}
              </p>
              <p className={styles.text}>{t('CookiePolicy.whatCookiesDoes.pleaseNote')}</p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                {t('CookiePolicy.doesMignedUseCookies.doesMignedUseCookies')}
              </p>
              <p className={styles.text}>{t('CookiePolicy.doesMignedUseCookies.cookiesAssist')}</p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                {t('CookiePolicy.whatCanYouDo.whatCanYouDo')}
              </p>
              <p className={styles.text}>{t('CookiePolicy.whatCanYouDo.someCookies')}</p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                {t('CookiePolicy.browserSettings.browserSettings')}
              </p>
              <p className={styles.text}>{t('CookiePolicy.browserSettings.mostBrowsers')}</p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>
                {t('CookiePolicy.browserSettings.browserManufacturers')}
              </p>
              <div className={styles['text-indent']} />
              <ul className={styles['link-block']}>
                <li>
                  <a href='https://support.google.com/chrome/answer/95647?hl=en-GB'>
                    Google Chrome
                  </a>
                </li>
                <li>
                  <a href='https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer'>
                    Mozilla Firefox
                  </a>
                </li>
                <li>
                  <a href='https://support.apple.com/ru-ru/guide/safari/sfri11471/mac'>
                    Safari (Desktop)
                  </a>
                </li>
                <li>
                  <a href='https://support.apple.com/en-us/HT201265'>Safari (Mobile)</a>
                </li>
                <li>
                  <a href='https://www.opera.com/ru/help'>Opera</a>
                </li>
                <li>
                  <a href='https://help.opera.com/en/mobile/android/#privacy'>Opera Mobile</a>
                </li>
                <li>
                  <a href='https://support.microsoft.com/en-us/topic/description-of-cookies-ad01aa7e-66c9-8ab2-7898-6652c100999d'>
                    Internet Explorer
                  </a>
                </li>
                <li>
                  <a href='https://support.google.com/nexus/answer/54068?visit_id=637622391837997074-483593135&hl=en&rd=1'>
                    Android Browser
                  </a>
                </li>
              </ul>
              <div className={styles['text-indent']} />
              <p className={styles.text}>{t('CookiePolicy.browserSettings.forOtherBrowsers')}</p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>{t('CookiePolicy.browserSettings.ifYouWantToChange')}</p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                {t('CookiePolicy.changesToThisPolicy.changesToThisPolicy')}
              </p>
              <p className={styles.text}>{t('CookiePolicy.changesToThisPolicy.weMayUpdate')}</p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                {t('CookiePolicy.howToContactUs.howToContactUs')}
              </p>
              <p className={styles.text}>{t('CookiePolicy.howToContactUs.ifYouHaveAny')}</p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>
                {t('CookiePolicy.howToContactUs.mignedInc')}
                <a href='info@migned.com'>info@migned.com</a>
              </p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>
                {t('CookiePolicy.howToContactUs.toCommunicate')}
                <a href='info@migned.com'>info@migned.com</a>
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles.text}>
                {t('CookiePolicy.howToContactUs.youHaveThe')}
                <span onClick={toggleOpen} className={styles['span-button']}>
                  {t('CookiePolicy.howToContactUs.customizeYour')}
                </span>{' '}
                {t('CookiePolicy.howToContactUs.preferences')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={toggleOpen} className='!px-[30px]'>
        <CustomiseCookies onClose={toggleOpen} />
      </Modal>
    </main>
  );
};

export default CookiePolicy;
