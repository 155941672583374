import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EMAIL_REGEXP, PASSWORD_NO_MATCH_REGEXP, ROUTES } from 'consts';
import { useAuth } from 'hooks';
import styles from './LoginPage.module.scss';

import { Button, ButtonGoogle, Divider, InputText, TermsPrivacy } from 'components/atoms';

const LoginPage: FC = () => {
  const { t } = useTranslation();
  const { login, isLoading } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async ({ email, password }: FieldValues) => {
    login(email, password);
  };

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          <h1 className={styles.content__title}>{t('LoginPage.title')}</h1>
          <h2 className={styles.content__subtitle}>{t('LoginPage.subtitle')}</h2>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <h4 className={styles.form__title}>{t('LoginPage.form.title')}</h4>

            <div className={styles.form__fields}>
              <InputText
                type='email'
                width='big'
                name='email'
                label={t('common.form.email') as string}
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  maxLength: { value: 50, message: `${t('common.form.maximumLengthIs')} 50` },
                  validate: {
                    doesntContainSpaces: (value: string) => {
                      return !value.includes(' ')
                        ? true
                        : t('common.validation.doesntContainSpaces');
                    },
                    validEmail: (value: string) => {
                      return EMAIL_REGEXP.test(value)
                        ? true
                        : t('common.validation.incorrectEmail');
                    }
                  }
                }}
                isLoading={isLoading}
                autoComplete='off'
              />

              <InputText
                type='password'
                width='big'
                name='password'
                label={t('common.form.password') as string}
                control={control}
                rules={{
                  required: { value: true, message: t('common.validation.requiredField') },
                  minLength: { value: 6, message: `${t('common.form.minimumLengthIs')} 6` },
                  maxLength: { value: 20, message: `${t('common.form.maximumLengthIs')} 20` },
                  validate: {
                    doesntContainSpaces: (value: string) => {
                      return !value.includes(' ')
                        ? true
                        : t('common.validation.doesntContainSpaces');
                    },
                    validPassword: (value: string) => {
                      return !value.match(PASSWORD_NO_MATCH_REGEXP)
                        ? true
                        : t('common.validation.incorrectPassword');
                    }
                  }
                }}
                isLoading={isLoading}
                autoComplete='off'
              />
            </div>

            <Button
              type='submit'
              variant='primary'
              size='big'
              wide
              title={t('common.form.login') as string}
              isDisabled={!isDirty}
              isLoading={isLoading}
            />
          </form>

          <Divider className={styles.divider} />

          <ButtonGoogle className={styles.buttonGoogle} />

          <span className={styles.forgot}>
            <Link to={ROUTES.RESET_PASSWORD} className='link'>
              {t('common.forgotPassword')}
            </Link>
          </span>

          <span className={styles.signup}>
            {t('common.dontHaveAnAccount')}{' '}
            <Link to={ROUTES.SIGNUP} className='link'>
              {t('common.form.signup')}
            </Link>
          </span>

          <TermsPrivacy />
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
