/* eslint-disable react/no-unescaped-entities */
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './PrivacyPolicy.module.scss';
import { useToggle } from 'hooks';

import { IconCross, Modal } from 'components/atoms';
import { CustomiseCookies } from 'components/organisms';

const PrivacyPolicy: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          <button className={styles['button-delete']} onClick={handleClose}>
            <IconCross />
          </button>
          <div className={styles['text-container']}>
            <div className={styles['title-block']}>
              <h3 className={styles['main-title']}>Privacy Policy</h3>
              <h3 className={styles['right-title']}>
                {t('PrivacyPolicy.privacyPolicy.publicationDate')}
              </h3>
            </div>
            <div className={styles['text-block']}>
              <p className={styles.text}>
                This Privacy Policy outlines the manner in which DigitMind Technologies, operating
                under the name Migned (referred to as "our," "us," or "we"), collects, utilizes, and
                discloses personal data, along with the choices available to you regarding this
                personal data.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>Applicability of this Privacy Policy</strong>
              </p>
              <p className={styles.text}>
                This Privacy Policy is applicable to the online collaboration tools and platform
                provided by Migned, including the associated mobile and desktop applications
                (collectively referred to as the "Services"), the migned.com website, and other
                Migned websites (collectively referred to as the "Websites"). It also applies to
                other interactions you may have with Migned, such as customer support and
                participation in the Migned Community. This Privacy Policy encompasses the
                processing of any messages, files, video or audio recordings, prompts, or other
                content submitted through our Services (collectively referred to as "Customer
                Content"). However, it does not apply to any third-party applications or software
                that integrate with our Services ("Third-Party Services") or any other third-party
                products, services, or businesses.
              </p>
              <p className={styles.text}>
                The entity (such as your employer or another organization or individual)
                ("Customer") that entered into the Terms of Service and any specific terms for a
                product (together referred to as the "Customer Agreement") has control over their
                instance of the Services (referred to as their "Organization") and any associated
                Customer Content. Authorized Users, who have been granted access to an Organization
                by a Customer, regularly submit Customer Content to Migned when using the Services.
              </p>
              <p className={styles.text}>
                If you have any questions about specific Organization settings and privacy
                practices, please contact the Customer whose Organization you use. If you have
                received an invitation to join an Organization but have not yet created an account,
                you should request assistance from the Customer that sent the invitation.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>Identifying the Data Controller and Processor</strong>
              </p>
              <p className={styles.text}>
                Data protection law in certain jurisdictions differentiates between the “controller”
                and “processor” of personal data. In general, Customer is the controller and Migned
                is the processor of Customer Content.
              </p>
              <p className={styles.text}>
                As the controller for Customer Content, Customer may, for example, use the Services
                to grant and remove access to an Organization, assign roles and configure settings,
                access, modify, export, share and remove Customer Content and otherwise apply its
                policies to the Services.
              </p>
              <p className={styles.text}>
                As the processor for Customer Content, Migned processes Customer Content only on
                Customer’s request and in accordance with Customer’s written instructions, including
                the applicable terms in the Customer Agreement, Customer’s use of the Services, and
                as required by applicable law. For more information about how Customer Content is
                processed (such as how your personal data is processed, the purpose and legal basis
                for processing, and your data subject rights), we refer you to the relevant
                Customer’s privacy notice.
              </p>
              <p className={styles.text}>
                Migned is the controller for certain other categories of data (described in
                paragraph 3 below). If you have any questions or complaints, or would like to
                exercise your rights with regard to your personal data, please contact us at
                info@migned.com.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>The types of personal data we collect</strong>
              </p>
              <p className={styles.text}>
                Your personal data is provided by you, obtained from third parties, and/or created
                by us when you use the Services.
              </p>
              <p className={styles.text}>
                Migned may collect and receive Customer Content and other personal data (“Other
                Data”) in a variety of ways:
              </p>
              <ul className={styles['text-ul']}>
                <li>
                  • <strong>Customer Content:</strong> Customers or Authorized Users regularly
                  submit Customer Content to Migned while utilizing the Services.
                </li>
                <li>
                  • <strong>Other Data:</strong> Migned also collects, generates, and/or receives
                  Other Data, which includes.
                </li>
                <li>
                  • <strong>Organization and account information.</strong> To create or update an
                  Organization account, you or the relevant Customer (e.g. your employer) will
                  supply Migned with an email address, name, password, domain and/or similar account
                  details. This may also happen if you are a Free User whose employer purchases an
                  Enterprise version of the Services. In addition, Customers that purchase a paid
                  version of the Services provide Migned (or its payment processors) with billing
                  details such as credit card information, banking information and/or a billing
                  address.
                </li>
                <li>
                  • <strong>Usage data.</strong>
                  <ul className={styles['text-li']}>
                    <li>
                      - <strong>Service metadata.</strong> When an Authorized User interacts with
                      the Services, metadata is generated to provide additional context about their
                      use of the Services. For example, Migned logs the Organizations, boards,
                      people, features, content, and links that you view or interact with, as well
                      as the types of files shared and any Third-Party Services that you use.
                    </li>
                    <li>
                      - <strong>Log data.</strong> Like most websites and services delivered over
                      the Internet, our servers automatically collect information when you access or
                      use our Websites or Services, recording this information in log files. This
                      log data may include the Internet Protocol (IP) address, the address of the
                      web page visited before using the Website or Services, your browser type and
                      settings, the date and time the Services were used, information about browser
                      configuration and plugins, and language preferences.
                    </li>
                    <li>
                      - <strong>Device data.</strong> Migned collects information about devices
                      accessing the Services, including the type of device, operating system used,
                      device settings, application IDs, unique device identifiers, and crash data.
                      Whether we collect some or all of this Other Data often depends on the type of
                      device used and its settings.
                    </li>
                    <li>
                      - <strong>Location data.</strong> We receive information from you, the
                      relevant Customer, and other third parties that help us approximate your
                      location. We may, for example, use a business address submitted by your
                      employer or an IP address received from your browser or device to determine
                      the approximate location. Migned may also collect location information from
                      devices in accordance with the consent provided by your device
                    </li>
                    <li>
                      - <strong>Third-party data.</strong> Migned may receive data about
                      organizations, industries, lists of companies that are customers, Website
                      visitors, marketing campaigns, and other matters relevant to our business from
                      parent corporations, affiliates, and subsidiaries, our partners, or other
                      third parties that we use to make our own information more useful. This data
                      may be combined and may include aggregate-level data. For example, information
                      about how well an online marketing or email campaign performed or to create a
                      business contacts directory.
                    </li>
                    <li>
                      - <strong>Cookie data.</strong> Migned uses a variety of cookies and similar
                      technologies in our Websites and Services to help us collect Other Data.
                    </li>
                    <li>
                      - <strong>Third-Party Services data.</strong> A Customer may choose to use
                      Third-Party Services. If the Customer enables Third-Party Services, Migned may
                      access and exchange Customer Content and Other Data with the Third Party on
                      the Customer's behalf, in accordance with our agreement with the Third-Party
                      Services and any permissions granted by the Customer (including its Authorized
                      User(s)).
                    </li>
                    <li>
                      - <strong>Contact data.</strong> In accordance with the consent provided by
                      your device or other third-party API, we process any contact information that
                      an Authorized User chooses to import when using the Services.
                    </li>
                    <li>
                      - <strong>Additional data provided to Migned.</strong> We also receive Other
                      Data when submitted to our Websites or in other ways, such as when you request
                      support, interact with our social media accounts, or otherwise communicate
                      with Migned.
                    </li>
                  </ul>
                </li>
                <li>
                  • <strong>Business data.</strong> Migned may receive information about individuals
                  from organizations, industries, Customers, (potential) partners, parent
                  corporations, affiliates, and subsidiaries, and our partners for cooperation and
                  communication purposes.
                </li>
              </ul>
              <div className={styles['text-indent']} />
              <p className={styles.text}>
                Generally, no one is under a statutory or contractual obligation to provide any
                Customer Content or Other Data (collectively, "Personal Data"). However, certain
                Personal Data is collected automatically, and if some Personal Data, such as
                Organization setup details, is not provided, we may be unable to provide the
                Services.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>How we use personal data</strong>
              </p>
              <p className={styles.text}>
                Customer Content will be used by Migned in accordance with the Customer's
                instructions, including any applicable terms in the Customer Agreement and the
                Customer's use of the Services, and as required by applicable law.
              </p>
              <p className={styles.text}>
                Migned uses Other Data for the purposes of our legitimate interests in operating our
                Services, Websites, and business. More specifically, Migned uses Other Data:
              </p>
              <ul className={styles['text-ul']}>
                <li>
                  •{' '}
                  <strong>
                    To provide, update, maintain and protect our Services, Websites and business.
                  </strong>{' '}
                  This includes the use of Other Data to support the delivery of the Services under
                  a Customer Agreement, including creating or updating an Organization, preventing
                  or addressing service errors, security or technical issues, and analyzing and
                  monitoring usage of the product and its features, trends, and other activities.
                  <p>
                    <strong>As required by applicable law, legal process or regulation.</strong>
                  </p>
                </li>
                <li>
                  •{' '}
                  <strong>
                    To support and communicate with you by responding to your requests, comments and
                    questions.
                  </strong>{' '}
                  If you contact us, we may use your Other Data to respond.
                </li>
                <li>
                  •{' '}
                  <strong>
                    To develop, test and provide search, learning and productivity tools and
                    additional features.
                  </strong>{' '}
                  Migned strives to make the Services as useful as possible. For example, we make
                  Service suggestions based on historical use and predictive models, identify
                  organizational trends and insights, customize your experience of the Services, or
                  create and develop new features and products.
                </li>
                <li>
                  • <strong>To conduct market and user research.</strong>
                  To improve our Services and troubleshoot new products and features, we may carry
                  out research. For example, we may survey Customers (including Admins, Users, and
                  other contacts) or third parties about customer satisfaction, user experience, the
                  effectiveness of our marketing campaigns, and their broader interests.
                </li>
                <li>
                  • <strong>To send emails and other communications.</strong>
                  <ul className={styles['text-li']}>
                    <li>
                      - <strong>Transactional:</strong>
                      As part of our services, we provide users with certain communications and
                      updates. We may send you service, transactional, technical, and other
                      administrative communications, such as communications about your account, our
                      Service offerings, changes to the Services, and important Services-related
                      notices, such as security and fraud notices. We consider these communications
                      as part of our Services to you.
                    </li>
                    <li>
                      - <strong>Soft opt-in / Legitimate Interests:</strong>
                      In addition, where you are a non-enterprise user or if you have opted-in as an
                      enterprise user, we sometimes send emails about new product features,
                      recommendations and promotional communications, or other news about Migned.
                      You can opt-out of these messages at any time by using the unsubscribe link
                      included in all of these communications.
                    </li>
                  </ul>
                </li>
                <li>
                  •{' '}
                  <strong>For billing, account management and other administrative matters.</strong>{' '}
                  Migned may need to contact you for invoicing, account management, and similar
                  reasons, and we use account data to administer accounts and keep track of billing
                  and payments.
                </li>
                <li>
                  • <strong>To investigate and help prevent security issues and abuse.</strong>
                </li>
              </ul>
              <div className={styles['text-indent']} />
              <p className={styles.text}>
                If information is aggregated or de-identified so that it can no longer reasonably be
                associated with an identified or identifiable natural person, Migned may use it for
                any business purpose. To the extent information is associated with an identified or
                identifiable natural person and is protected as personal data under applicable data
                protection law, it is referred to in this Privacy Policy as "Personal Data."
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>Migned AI</strong>
              </p>
              <p className={styles.text}>
                In order to improve efficiency, take care of routine tasks, and work with teams as
                part of your creative process, Migned offers certain AI-supported functionalities
                within the product, Migned AI.
              </p>
              <p className={styles.text}>
                If you choose to use these features, Customer Content (including user-generated
                prompts) will be processed by AI and machine learning models to generate content or
                make changes to your board. Some of these models are internal to our product, and
                some are provided by a third party. We will not use your data to train the models.
              </p>
              <p className={styles.text}>
                In order to provide Migned AI, Migned uses the following types of personal data:
              </p>
              <ul className={styles['text-ul']}>
                <li>
                  • User-generated prompts submitted by Users, which the models will use to generate
                  content or make changes to your board. This is Customer Content, and we process it
                  as a data processor based on your instructions to provide the Services. Please
                  note that any personal data you submit as a prompt will be processed by Migned AI.
                </li>
                <li>
                  • Usage metadata about how Users engage with Migned AI, which Migned processes as
                  a data controller to prevent or address service errors, security or technical
                  issues, and to analyze and monitor usage of Migned AI. Usage metadata does not
                  contain Customer Content.
                </li>
              </ul>
              <p className={styles.text}>
                Migned AI may share limited data with Microsoft for the above purposes and to
                monitor compliance with codes of conduct.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>Data Retention</strong>
              </p>
              <p className={styles.text}>
                Migned will retain Customer Content in accordance with a Customer's instructions,
                including any applicable terms in the Customer Agreement and the Customer's use of
                the Services, and as required by applicable law. The deletion of Customer's Personal
                Data may result in the deletion and/or de-identification of an account and certain
                associated Other Data. Migned may retain Other Data for as long as necessary for the
                purposes described in this Privacy Policy. Furthermore, please note that we may keep
                certain types of Other Data after the deactivation of an account for the period
                needed for Migned to pursue legitimate business interests, conduct audits, comply
                with (and demonstrate compliance with) legal obligations, resolve disputes, and
                enforce our agreements.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>How we share and disclose personal data</strong>
              </p>
              <p className={styles.text}>
                This section describes how Migned may share and disclose personal data, as described
                in paragraph 3 above. Customers determine their own policies and practices for the
                sharing and disclosure of personal data. Migned does not control how they or any
                other third party choose to share or disclose personal data.
              </p>
              <p className={styles.text}>
                Migned may share and disclose personal data in accordance with a Customer's
                instructions, including any applicable terms in the Customer Agreement and the
                Customer's use of the Services, and in compliance with applicable law. We may only
                share personal data with third parties where we have obtained consent to do so, if
                necessary.
              </p>
              <div className={styles['text-indent']} />
              <p className={styles.text}>We may share personal data as follows:</p>
              <ul className={styles['text-ul']}>
                <li>
                  • <strong>Displaying the Services.</strong> When an Authorized User submits
                  Customer Content (including personal data), it may be displayed to other
                  Authorized Users who have access to the same Migned Board. For example, an
                  Authorized User's name and Migned profile may be displayed.
                </li>
                <li>
                  • <strong>Customer access.</strong> Owners, administrators, Authorized Users, and
                  other Customer representatives and personnel may be able to access, modify, or
                  restrict access to personal data. This may include, for example, your employer
                  using Service features to export logs of your activity or accessing or modifying
                  your profile details.
                </li>
                <li>
                  • <strong>Subcontractors.</strong> We may engage third-party companies or
                  individuals as sub-processors to process personal data. These third parties may,
                  for example, provide virtual computing and storage services, or we may share
                  business information to develop strategic partnerships to support our Customers.
                </li>
                <li>
                  • <strong>Third-Party Services.</strong> Customers may enable Third-Party
                  Services. When enabled, Migned may access and exchange Customer Content with the
                  provider of a Third-Party Service on the Customer's behalf. Third-Party Services
                  are not owned or controlled by Migned, and third parties that have been granted
                  access to personal data may have their own policies and practices for its
                  collection, use, and sharing. Please check the permissions, privacy settings, and
                  notices for these Third-Party Services or contact the relevant provider with any
                  questions.
                </li>
                <li>
                  • <strong>During a change to Migned’s business.</strong> If Migned engages in a
                  merger, acquisition, bankruptcy, dissolution, reorganization, sale of some or all
                  of Migned's assets or stock, financing, public offering of securities, acquisition
                  of all or a portion of our business, a similar transaction or proceeding, or steps
                  in contemplation of such activities, some or all personal data may be shared or
                  transferred, subject to standard confidentiality arrangements.
                </li>
                <li>
                  • <strong>To comply with laws.</strong> If we receive a request for personal data,
                  we may disclose personal data if we reasonably believe disclosure is in accordance
                  with or required by any applicable law, regulation, or legal process.
                </li>
                <li>
                  • <strong>To enforce our rights, prevent fraud, and for safety.</strong> To
                  protect and defend the rights, property, or safety of Migned, its users, or third
                  parties, including enforcing its contracts or policies, or in connection with
                  investigating and preventing illegal activity, fraud, or security issues,
                  including to prevent death or imminent bodily harm.
                </li>
              </ul>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>Security</strong>
              </p>
              <p className={styles.text}>
                Migned takes the security of personal data very seriously. We strive to protect all
                Personal Data from loss, misuse, and unauthorized access or disclosure. However,
                given the nature of communications and information processing technology, we cannot
                guarantee that personal data will be absolutely safe from intrusion by others during
                transmission through the internet or while stored on our systems or otherwise in our
                care.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>Our responsibility for third party links</strong>
              </p>
              <p className={styles.text}>
                Our Services may contain links to websites and services operated by third parties.
                If you follow a link to any of these websites, please note that these websites have
                their own privacy notices and terms and conditions. Furthermore, we have no
                responsibility for, or control over, the information collected by any third-party
                website, and we cannot be responsible for the protection and privacy of any
                information you may provide to these websites. We recommend reading the relevant
                privacy notices and terms and conditions before using their websites or services.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>Age Restriction</strong>
              </p>
              <p className={styles.text}>
                Migned does not allow the use of our Services and Websites by anyone younger than 16
                years old ("Minor"). If you learn that a Minor has unlawfully provided us with
                personal data, please contact us, and we will take steps to delete this information.
              </p>
              <p className={styles.text}>
                By using our Services and Websites, you represent and warrant that you are not a
                Minor as of the date of first access to our Services and Websites.
              </p>
              <p className={styles.text}>
                If you are a Minor, you represent and warrant that you are accessing the Services
                and Websites with the consent of a competent guardian over the age of 16 years old
                who takes responsibility for your use of the Services and Websites. If you are a
                Minor accessing Migned via an educational institution, that institution will have
                procured a license on your behalf and agreed to our terms and conditions. In
                particular, it will have agreed that it has obtained all necessary consents and will
                take responsibility for your use of the Services and Websites.
              </p>
            </div>
            <div className={styles['text-block']}>
              <p className={styles['text-heading']}>
                <strong>Changes to this Privacy Policy</strong>
              </p>
              <p className={styles.text}>
                Migned may change this Privacy Policy from time to time. Laws, regulations, and
                industry standards evolve, which may make those changes necessary, or we may make
                changes to our services or business. We will post the changes to this page, and we
                encourage you to review our Privacy Policy to stay informed. If you disagree with
                the changes to this Privacy Notice, you should deactivate your account. Please
                contact the relevant Customer if you wish to request the removal of your personal
                data under their control.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={toggleOpen} className='!px-[30px]'>
        <CustomiseCookies onClose={toggleOpen} />
      </Modal>
    </main>
  );
};

export default PrivacyPolicy;
