import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import RecentBoardsPlaceholderPNG from 'assets/images/png/recent-boards-placeholder.png';
import { useRecentBoardsQuery } from 'hooks/queries';
import styles from './RecentBoards.module.scss';

import { RecentBoard } from '../RecentBoard';

interface RecentBoardsProps {
  className?: string;
}

const RecentBoards: FC<RecentBoardsProps> = ({ className }) => {
  const { t } = useTranslation();
  const { data: recentBoards, isLoading: recentBoardsLoading } = useRecentBoardsQuery();

  return (
    <div className={`${styles.container} ${className}`}>
      <h3 className={styles.title}>{t('TeamPage.dashboard.recentBoards')}</h3>

      <div className={styles.list}>
        {recentBoardsLoading ? (
          <h2>{t('common.loading')}</h2>
        ) : (
          <>
            {recentBoards?.length ? (
              recentBoards.map((board, index) => <RecentBoard key={index} board={board} />)
            ) : (
              <div className={styles.placeholder}>
                <img src={RecentBoardsPlaceholderPNG} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RecentBoards;
