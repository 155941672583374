// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

import FlowchartPNG from 'assets/images/png/flowchart-example.png';
import MindmapPNG from 'assets/images/png/mindmap-example.png';
import PertchartPNG from 'assets/images/png/pertchart-example.png';
import RoadmapPNG from 'assets/images/png/roadmap-example.png';
import WbsPNG from 'assets/images/png/wbs-example.png';

import { BOARD_TYPES, Project } from 'models';
import styles from './AddMindCharts.module.scss';

import { AddBoardCard } from '..';

const boardsToCreate: {
  type: BOARD_TYPES;
  color: string;
  available: boolean;
  image?: string;
}[] = [
  {
    type: BOARD_TYPES.MINDMAP,
    color: '#25BD33',
    image: MindmapPNG,
    available: true
  },
  {
    type: BOARD_TYPES.ROADMAP,
    color: '#009CF4',
    image: RoadmapPNG,
    available: true
  },
  {
    type: BOARD_TYPES.FLOWCHART,
    color: '#FF6633',
    image: FlowchartPNG,
    available: true
  },
  {
    type: BOARD_TYPES.WBS,
    color: '#674FFF',
    image: WbsPNG,
    available: false
  },
  {
    type: BOARD_TYPES.DIAGRAM,
    color: '#FFCB33',
    image: PertchartPNG,
    available: false
  }
];

interface AddMindChartsProps {
  projects: Project[] | undefined;
  className?: string;
}

const AddMindCharts: FC<AddMindChartsProps> = ({ projects, className }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.container} ${className}`}>
      <h3 className={styles.title}>{t('TeamPage.dashboard.createFromTemplate')}</h3>

      <Swiper
        modules={[Navigation]}
        navigation
        slidesPerView='auto'
        spaceBetween='10px'
        className={styles.slider}
      >
        {boardsToCreate.map((boardToCreate, index) => (
          <SwiperSlide key={index} className={styles.slide}>
            <AddBoardCard projects={projects} board={boardToCreate} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default AddMindCharts;
