import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'consts';
import styles from './TeamCard.module.scss';

import { IconTeam } from 'components/atoms';

interface TeamCardProps {
  team: { id: number; name: string };
}

const TeamCard: FC<TeamCardProps> = ({ team }) => {
  return (
    <NavLink
      to={`${ROUTES.TEAMS}/${team.id}`}
      className={({ isActive }) =>
        `${styles.container} ` + `${isActive ? `${styles.container_active}` : ''}`
      }
      data-tooltip-id={`team-card-tooltip-${team.id}`}
    >
      <IconTeam className={styles.icon} />
    </NavLink>
  );
};

export default TeamCard;
