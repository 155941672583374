import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useToggle } from 'hooks';
import { useDeleteProfileMutation } from 'hooks/user';

import { Button, Modal } from 'components/atoms';
import { DeleteSmthForm } from 'components/organisms';

type DeleteProfileProps = {
  className?: string;
};

const DeleteProfile: FC<DeleteProfileProps> = ({ className }) => {
  const { t } = useTranslation();
  const [modalOpen, toggleModalOpen] = useToggle(false);
  const { mutate: deleteProfileMutate, isLoading: deleteProfileLoading } =
    useDeleteProfileMutation();

  const confirmDelete = async () => {
    deleteProfileMutate();
  };

  return (
    <div
      className={
        'flex flex-col gap-[15px] p-[15px] border-[#EDEFF2] ' +
        `rounded-[20px] border-[2px] border-solid ${className}`
      }
    >
      <h4 className='text-[20px] font-[400] leading-[30px] tracking-[0.2px] text-[#2D3C59]'>
        {t('ProfilePage.deleteProfile.title')}
      </h4>

      <p className='text-[18px] font-[400] leading-[24px] text-[#6B7A99]'>
        {t('ProfilePage.deleteProfile.text1')}
      </p>
      <p className='text-[18px] font-[400] leading-[24px] text-[#2D3C59]'>
        {t('ProfilePage.deleteProfile.text2')}
      </p>

      <Button
        type='submit'
        variant='warning'
        size='big'
        wide={false}
        title={t('ProfilePage.deleteProfile.deleteButton')}
        className='self-end min-w-[158px]'
        onClick={toggleModalOpen}
      />

      <Modal isOpen={modalOpen} onClose={toggleModalOpen}>
        <DeleteSmthForm
          entity='my profile'
          warning={
            <div className='mb-[28px] flex flex-col gap-[16px]'>
              <p className='text-[18px] font-[400] leading-[24px] text-[#6B7A99] text-center'>
                {t('ProfilePage.deleteProfile.text1')}
              </p>
              <p className='text-[18px] font-[400] leading-[24px] text-[#2D3C59] text-center'>
                {t('ProfilePage.deleteProfile.text2')}
              </p>
            </div>
          }
          onDelete={confirmDelete}
          isLoading={deleteProfileLoading}
          onCancel={toggleModalOpen}
        />
      </Modal>
    </div>
  );
};

export default DeleteProfile;
