import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CreateTeamDto, apiTeam } from 'api';
import { handleError, notifySuc } from 'helpers';
import { useInvalidate } from '../useInvalidate';

export const useCreateTeamMutation = () => {
  const { t } = useTranslation();
  const invalidate = useInvalidate();

  return useMutation(
    (data: CreateTeamDto) => {
      return apiTeam.createTeam(data);
    },
    {
      onSuccess() {
        notifySuc(t('common.notifications.teamCreated'));
        invalidate('teams/my');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
