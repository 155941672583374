import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { useToggle } from 'hooks';
import { useDeleteTeamUserMutation } from 'hooks/mutations';
import { useUserQuery } from 'hooks/user';
import { Member } from 'models';
import styles from './MemberCard.module.scss';

import { IconDotsVertical, IconUser, Modal } from 'components/atoms';
import { DeleteSmthForm } from 'components/organisms';
import { SelectRole } from '..';

interface MemberCardProps {
  member: Member;
}

const MemberCard: FC<MemberCardProps> = ({ member }) => {
  const { data: viewer } = useUserQuery();
  const { teamId } = useParams<keyof { teamId: string }>() as { teamId: string };
  const [deleteUserModalActive, toggleDeleteUserModalActive] = useToggle(false);
  const { mutate: deleteUserMutate, isLoading: deleteUserLoading } = useDeleteTeamUserMutation();

  const deleteUser = async () => {
    deleteUserMutate(
      { teamId: Number(teamId), userId: member.id },
      { onSuccess: toggleDeleteUserModalActive }
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <IconUser />
      </div>
      <h4 title={member.username} className={styles.name}>
        {member.username}
      </h4>
      <h4 title={member.email} className={styles.email}>
        {member.email}
      </h4>
      <SelectRole
        userId={member.id}
        role={member.userRole.role}
        className={styles['select-role']}
        disabled={member.id === viewer?.id}
      />

      <button className={styles.buttonOptions} data-tooltip-id={`user-options-${member.id}`}>
        <IconDotsVertical />
      </button>
      <Tooltip
        id={`user-options-${member.id}`}
        className={styles.tooltip}
        offset={10}
        place='bottom'
        clickable
      >
        <div className={styles.tooltip__list}>
          <button
            className={styles.tooltip__item}
            disabled={member.id === viewer?.id}
            onClick={toggleDeleteUserModalActive}
          >
            Delete user
          </button>
        </div>
      </Tooltip>

      <Modal isOpen={deleteUserModalActive} onClose={toggleDeleteUserModalActive}>
        <DeleteSmthForm
          entity='user from team'
          onDelete={deleteUser}
          isLoading={deleteUserLoading}
          onCancel={toggleDeleteUserModalActive}
        />
      </Modal>
    </div>
  );
};

export default MemberCard;
