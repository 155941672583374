import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconTeam: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2.75 10.25C3.7165 9.2835 5.2835 9.2835 6.25 10.25V10.25C7.2165 11.2165 7.2165 12.7835 6.25 13.75V13.75C5.2835 14.7165 3.7165 14.7165 2.75 13.75V13.75C1.7835 12.7835 1.7835 11.2165 2.75 10.25V10.25Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.25 2.75C11.2165 1.7835 12.7835 1.7835 13.75 2.75V2.75C14.7165 3.7165 14.7165 5.2835 13.75 6.25V6.25C12.7835 7.2165 11.2165 7.2165 10.25 6.25V6.25C9.2835 5.2835 9.2835 3.7165 10.25 2.75V2.75Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.75 10.25C18.7165 9.2835 20.2835 9.2835 21.25 10.25V10.25C22.2165 11.2165 22.2165 12.7835 21.25 13.75V13.75C20.2835 14.7165 18.7165 14.7165 17.75 13.75V13.75C16.7835 12.7835 16.7835 11.2165 17.75 10.25V10.25Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.25 17.75C11.2165 16.7835 12.7835 16.7835 13.75 17.75V17.75C14.7165 18.7165 14.7165 20.2835 13.75 21.25V21.25C12.7835 22.2165 11.2165 22.2165 10.25 21.25V21.25C9.2835 20.2835 9.2835 18.7165 10.25 17.75V17.75Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 5.3847C17.2753 5.91308 17.9528 6.57418 18.5 7.3353M7.5 5.3847C6.72475 5.91308 6.04718 6.57418 5.5 7.3353'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M7.5 18.6153C6.72475 18.0869 6.04718 17.4258 5.5 16.6647M16.5 18.6153C17.2753 18.0869 17.9528 17.4258 18.5 16.6647'
        stroke='currentColor'
        strokeWidth='2'
      />
    </svg>
  );
};
