import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconSpinner: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='200px'
      height='200px'
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
      {...props}
    >
      <g transform='matrix(1,0,0,1,0,0)'>
        <path
          d='M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843'
          fill='none'
          stroke='currentColor'
          strokeWidth='12'
        />
        <path d='M49 3L49 27L61 15L49 3' fill='currentColor' />
      </g>
    </svg>
  );
};
