import { useTranslation } from 'react-i18next';

import { apiAuth } from 'api';
import { handleError, notifySuc } from 'helpers';
import { useToggle } from 'hooks';

export const useVerifyEmail = () => {
  const { t } = useTranslation();
  const [isLoading, toggleLoading] = useToggle(false);

  const sendLink = async () => {
    toggleLoading();

    try {
      await apiAuth.verifyEmailSendLink();
      notifySuc(t('common.notifications.confirmationLetterSent'));
      return 'ok';
    } catch (error) {
      handleError(error);
    } finally {
      toggleLoading();
    }
  };

  const verifyEmail = async (token: string) => {
    toggleLoading();

    try {
      await apiAuth.verifyEmail(token);
      notifySuc(t('common.notifications.emailVerified'));
      return 'ok';
    } catch (error) {
      handleError(error);
    } finally {
      toggleLoading();
    }
  };

  return { sendLink, verifyEmail, isLoading };
};
