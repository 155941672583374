import { Edge, EdgeChange, Node, NodeChange, XYPosition } from 'reactflow';
import { socket } from 'socket';
import { shallow } from 'zustand/shallow';

import { EDGE_TYPES, NODE_TYPES } from 'consts';
import { notifyErr } from 'helpers';
import { User } from 'models';
import { EdgeData, NodeData, RFState, useBoardStore, useRFStore } from 'store';

export interface ReceivedUpdatedNode {
  boardId: number;
  nodeId: string;
  node: Node<NodeData>;
}

export interface ReceivedAddedNode {
  boardId: number;
  change: NodeChange;
}

export interface ReceivedAddedEpicToRoadmap {
  boardId: number;
  change: NodeChange;
  // index: number
}

export type ReceivedAddedTaskContainerToRoadmap = {
  boardId: number;
  schema: {
    nodes: Node<NodeData>[];
    edges: Edge<EdgeData>[];
  };
};

export type ReceivedSchema = {
  boardId: number;
  schema: {
    nodes: Node<NodeData>[];
    edges: Edge<EdgeData>[];
  };
};

export interface ReceivedDeletedNodes {
  boardId: number;
  changes: NodeChange[];
}

export interface ReceivedAddedEdge {
  boardId: number;
  change: EdgeChange;
}

export interface ReceivedDeletedEdges {
  boardId: number;
  changes: EdgeChange[];
}

export interface RecievedBoardOnline {
  boardId: number;
  users: User[];
}

export interface SocketError {
  type: string;
  message: string;
  statusCode: number;
}

export interface UpdateNodeByIdDto extends Partial<Node<NodeData>> {
  boardId: number;
  nodeId: string;
}

export interface AddNodeDto {
  boardId: number;
  type: NODE_TYPES;
  position: XYPosition;
}

export interface AddEpicToRoadmapDto {
  boardId: number;
  currentNodeId: string;
  position: 'top' | 'bottom';
}

export type AddTaskContainerDto = {
  boardId: number;
  epicId: string;
  position: 'top' | 'bottom';
};

export type AddRoadmapTask = {
  boardId: number;
  containerId: string;
};

export type MoveRoadmapTask = {
  boardId: number;
  taskId: string;
  position: 'top' | 'bottom';
};

export type AddRoadmapMilestoneDto = { boardId: number };
export type AddRoadmapIntervalDto = { boardId: number };

export interface DeleteNodesData {
  boardId: number;
  nodeIds: string[];
}

export interface AddEdgeDto {
  boardId: number;
  source: string;
  target: string;
  type?: EDGE_TYPES;
  sourceHandle?: string;
  targetHandle?: string;
}

export interface DeleteEdgesData {
  boardId: number;
  edgeIds: string[];
}

// TODO add ServerToClientEvents and ClientToServerEvents interfaces

const selector = (state: RFState) => ({
  setData: state.setData,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  updateNode: state.updateNode,
  addNode: state.addNode,
  addEdges: state.addEdges
});

export const useSocket = (boardId: number | undefined) => {
  const {
    setData,
    onNodesChange,
    onEdgesChange,
    updateNode,
    addNode: addNodeToBoard,
    addEdges: addEdgeToBoard
  } = useRFStore(selector, shallow);
  const setUsers = useBoardStore(state => state.setUsers);

  const onConnectionToBoard = (data: any) => {
    console.log('connection to board room:', data);
  };

  const onReceivedUpdatedNode = (receivedUpdatedNode: ReceivedUpdatedNode) => {
    updateNode(receivedUpdatedNode.nodeId, receivedUpdatedNode.node);
  };

  const onReceivedAddedNode = (receivedAddedNode: ReceivedAddedNode) => {
    if (receivedAddedNode.change.type === 'add' && receivedAddedNode.change.item) {
      addNodeToBoard(receivedAddedNode.change.item);
    }
  };

  const onReceivedSchema = (receivedSchema: ReceivedSchema) => {
    setData(receivedSchema.schema);
  };

  const onReceivedDeletedNodes = (receivedDeletedNodes: ReceivedDeletedNodes) => {
    onNodesChange(receivedDeletedNodes.changes);
  };

  const onReceivedAddedEdge = (receivedAddedEdge: ReceivedAddedEdge) => {
    if (receivedAddedEdge.change.type === 'add') {
      addEdgeToBoard([receivedAddedEdge.change.item]);
    }
  };

  const onReceivedDeletedEdges = (receivedDeletedEdges: ReceivedDeletedEdges) => {
    onEdgesChange(receivedDeletedEdges.changes);
  };

  const onBoardOnline = (recievedBoardOnline: RecievedBoardOnline) => {
    setUsers(recievedBoardOnline.users);
  };

  const onException = (data: SocketError) => {
    notifyErr(data.message);
  };

  const joinToBoard = () => {
    socket.auth = {
      token: localStorage.getItem('token')
    };
    socket.connect();
    socket.emit('join-to-board', { boardId });
    socket.on('connection-to-board', onConnectionToBoard);
    socket.on('receive-updated-node', onReceivedUpdatedNode);
    socket.on('receive-added-node', onReceivedAddedNode);
    socket.on('receive-schema', onReceivedSchema);
    socket.on('receive-deleted-nodes', onReceivedDeletedNodes);
    socket.on('receive-deleted-edges', onReceivedDeletedEdges);
    socket.on('receive-added-edge', onReceivedAddedEdge);
    socket.on('online', onBoardOnline);
    socket.on('exception', onException);
  };

  const leaveFromBoard = () => {
    socket.off('connection-to-board', onConnectionToBoard);
    socket.off('receive-updated-node', onReceivedUpdatedNode);
    socket.off('receive-added-node', onReceivedAddedNode);
    socket.off('receive-schema', onReceivedSchema);
    socket.off('receive-deleted-nodes', onReceivedDeletedNodes);
    socket.off('receive-deleted-edges', onReceivedDeletedEdges);
    socket.off('receive-added-edge', onReceivedAddedEdge);
    socket.off('online', getBoardOnline);
    socket.off('exception', onException);
    socket.emit('/leave-from-board', boardId);
    socket.disconnect();
  };

  const updateNodeById = (updatedNode: UpdateNodeByIdDto) => {
    socket.emit('update-node', updatedNode);
  };

  const addNode = (addNodeData: AddNodeDto) => {
    socket.emit('add-node', addNodeData);
  };

  const addEpicToRoadmap = (addEpicData: AddEpicToRoadmapDto) => {
    socket.emit('add-epic-to-roadmap', addEpicData);
  };

  const addTaskContainerToRoadmap = (addTaskContainerData: AddTaskContainerDto) => {
    socket.emit('add-task-container-to-roadmap', addTaskContainerData);
  };

  const addRoadmapTask = (addRoadmapTask: AddRoadmapTask) => {
    socket.emit('add-roadmap-task', addRoadmapTask);
  };

  const moveRoadmapTask = (moveRoadmapTask: MoveRoadmapTask) => {
    socket.emit('move-roadmap-task', moveRoadmapTask);
  };

  const addRoadmapMilestone = (addRoadmapMilestoneDto: AddRoadmapMilestoneDto) => {
    socket.emit('add-roadmap-milestone', addRoadmapMilestoneDto);
  };

  const addRoadmapInterval = (addRoadmapInterval: AddRoadmapIntervalDto) => {
    socket.emit('add-roadmap-interval', addRoadmapInterval);
  };

  const deleteNodes = (deleteNodesData: DeleteNodesData) => {
    socket.emit('delete-nodes', deleteNodesData);
  };

  const addEdge = (addEdgeData: AddEdgeDto) => {
    socket.emit('add-edge', addEdgeData);
  };

  const deleteEdges = (deleteEdgesData: DeleteEdgesData) => {
    socket.emit('delete-edges', deleteEdgesData);
  };

  const getBoardOnline = () => {
    socket.emit('get-board-online', { boardId });
  };

  return {
    joinToBoard,
    leaveFromBoard,
    updateNodeById,
    addNode,
    addEpicToRoadmap,
    addTaskContainerToRoadmap,
    addRoadmapTask,
    moveRoadmapTask,
    addRoadmapInterval,
    addRoadmapMilestone,
    deleteNodes,
    addEdge,
    deleteEdges,
    getBoardOnline
  };
};
