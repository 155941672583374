import { FC } from 'react';

import { useToggle } from 'hooks';
import { Modal, CookiesConsent } from 'components/atoms';
import { CustomiseCookies } from 'components/organisms';

interface CookiesAndModalProps {
  onAccept: () => void;
}

const CookiesAndModal: FC<CookiesAndModalProps> = ({ onAccept }) => {
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <div>
      <CookiesConsent onCustomizeClick={toggleOpen} onCustomizeClickAccept={onAccept} />
      <Modal isOpen={isOpen} onClose={toggleOpen} className='!px-[30px] z-[10]'>
        <CustomiseCookies onClose={toggleOpen} onCustomizeClickAccept={onAccept} />
      </Modal>
    </div>
  );
};

export default CookiesAndModal;
