import {
  BackgroundVariant,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  Position,
  ReactFlowInstance,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges
} from 'reactflow';
import { create } from 'zustand';

import { EDGE_TYPES, NODE_TYPES, RF_BACKGROUND_VARIANT } from 'consts';

export type ControlType = 'Default' | 'Figma-like';

export enum NODE_SIZE {
  SMALL = 'SMALL',
  MIDDLE = 'MIDDLE',
  LARGE = 'LARGE'
}

export enum EDGE_COLOR {
  PINK = '#FF61EF',
  YELLOW = '#FCE623',
  LIGHT_GREEN = '#8CED2A',
  BLUE = '#00E2E2',
  RED = '#FF4C25',
  ORANGE = '#FF8A1F',
  GREEN = '#00C77F',
  PURPLE = '#6865FF',
  BLACK = '#121417',
  GRAY = '#6B7A99',
  LIGHT_GRAY = '#C3CAD9',
  WHITE = '#FFFFFF'
}

export enum EDGE_LINE {
  SOLID = 'SOLID',
  DASHED = 'DASHED'
}

export enum EDGE_HEAD {
  NONE = 'NONE',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  BOTH = 'BOTH'
}

export type NodeToAdd = {
  nodeType: NODE_TYPES;
  level?: number;
  size?: NODE_SIZE;
};

export type NodeData = {
  color?: string;
  label?: string;
  value?: string;
  linkedDocuments?: string[];
  toolbar?: {
    position?: Position;
    visible?: boolean;
  };
  level?: number;
  size?: NODE_SIZE;
};

export type EdgeData = {
  color?: EDGE_COLOR;
  line?: EDGE_LINE;
  toolbar?: {
    position?: Position;
    visible?: boolean;
  };
};

type OnSetData = ({ nodes, edges }: { nodes: Node<NodeData>[]; edges: Edge<EdgeData>[] }) => void;
type OnSetNodes = (nodes: Node<NodeData>[]) => void;
type AddNode = (newNode: Node<NodeData>) => void;
type AddNodes = (newNodes: Node<NodeData>[]) => void;
type AddEdges = (newEdges: Edge<EdgeData>[]) => void;
type SelectNode = (nodeId: string) => void;
type DeselectNode = (nodeId: string) => void;
type RemoveNode = (nodeId: string) => void;
type RemoveEdge = (edgeId: string) => void;
type OnBackgroundChange = (backgroundVariant: BackgroundVariant) => void;
type OnUpdateNode = (nodeId: string, updatedNode: Node<NodeData>) => void;
type OnUpdateNodeColor = (nodeId: string, color: string) => void;
type OnUpdateNodeValue = (nodeId: string, value: string) => void;
type OnUpdateNodeSize = (nodeId: string, size: NODE_SIZE) => void;
type OnToggleDocument = (nodeId: string, documentId: string) => void;
type OnUpdateEdgeColor = (edgeId: string, color: EDGE_COLOR) => void;

export type RFState = {
  reactFlowInstance: ReactFlowInstance | null;
  setReactFlowInstance: (reactFlowInstance: ReactFlowInstance | null) => void;
  controlType: ControlType;
  setControlType: (controlType: ControlType) => void;
  currentEdgeType: EDGE_TYPES | null;
  setCurrentEdgeType: (edgeType: EDGE_TYPES | null) => void;
  currentInstruction: string | null;
  setCurrentInstruction: (nodeId: string | null) => void;
  nodeToAdd: NodeToAdd | null;
  setNodeToAdd: (nodeToAdd: NodeToAdd | null) => void;
  nodes: Node<NodeData>[];
  edges: Edge<EdgeData>[];
  setData: OnSetData;
  setNodes: OnSetNodes;
  addNode: AddNode;
  addNodes: AddNodes;
  addEdges: AddEdges;
  selectNode: SelectNode;
  deselectNode: DeselectNode;
  removeNode: RemoveNode;
  removeEdge: RemoveEdge;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  backgroundVariant: BackgroundVariant;
  setBackgroundVariant: OnBackgroundChange;
  updateNode: OnUpdateNode;
  updateNodeColor: OnUpdateNodeColor;
  updateNodeSize: OnUpdateNodeSize;
  updateNodeValue: OnUpdateNodeValue;
  toggleDocument: OnToggleDocument;
  updateEdgeColor: OnUpdateEdgeColor;
};

export const useRFStore = create<RFState>((set, get) => ({
  reactFlowInstance: null,
  setReactFlowInstance: (reactFlowInstance: ReactFlowInstance | null) => {
    set({ reactFlowInstance });
  },
  controlType: 'Default',
  setControlType: (controlType: ControlType) => {
    set({ controlType });
  },
  nodes: [],
  edges: [],
  setData: ({ nodes, edges }: { nodes: Node<NodeData>[]; edges: Edge<EdgeData>[] }) => {
    set({ nodes, edges });
  },
  setNodes: (nodes: Node<NodeData>[]) => {
    set({ nodes });
  },
  // TODO remove these actions as they are not registered in the Flow component listener?
  addNode: (newNode: Node<NodeData>) => {
    set({ nodes: get().nodes.concat(newNode) });
  },
  addNodes: (newNodes: Node<NodeData>[]) => {
    set({ nodes: get().nodes.concat(...newNodes) });
  },
  addEdges: (newEdges: Edge<EdgeData>[]) => {
    set({ edges: get().edges.concat(...newEdges) });
  },
  selectNode: (nodeId: string) => {
    set({
      nodes: get().nodes.map(node => (node.id === nodeId ? { ...node, selected: true } : node))
    });
  },
  deselectNode: (nodeId: string) => {
    set({
      nodes: get().nodes.map(node => (node.id === nodeId ? { ...node, selected: false } : node))
    });
  },
  removeNode: (nodeId: string) => {
    const nodeToDelete = get().nodes.find(node => node.id === nodeId);
    if (!nodeToDelete) return;

    // TODO simplify logic
    const nodesToDelete: Node<NodeData>[] = [nodeToDelete];
    const getNodesToDelete = (nodeToDelete: Node<NodeData>) => {
      const childNodes = get().nodes.filter(node => node.parentNode === nodeToDelete.id);
      if (childNodes.length) {
        nodesToDelete.push(...childNodes);
        childNodes.forEach(node => getNodesToDelete(node));
      }
    };
    getNodesToDelete(nodeToDelete);

    get().reactFlowInstance?.deleteElements({ nodes: nodesToDelete });
  },
  removeEdge: (edgeId: string) => {
    set({ edges: get().edges.filter(edges => edges.id !== edgeId) });
  },
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes)
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges)
    });
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges)
    });
  },
  updateNodeColor: (nodeId: string, color: string) => {
    set({
      nodes: get().nodes.map(node => {
        if (node.id === nodeId) {
          node.data = { ...node.data, color };
        }
        return node;
      })
    });
  },
  updateNode: (nodeId: string, updatedNode: Node<NodeData>) => {
    set({
      nodes: get().nodes.map(node => {
        if (node.id === nodeId) {
          return { ...node, ...updatedNode };
        }
        return node;
      })
    });
  },
  updateNodeValue: (nodeId: string, value: string) => {
    set({
      nodes: get().nodes.map(node => {
        if (node.id === nodeId) {
          node.data = { ...node.data, value };
        }
        return node;
      })
    });
  },
  updateNodeSize: (nodeId: string, size: NODE_SIZE) => {
    set({
      nodes: get().nodes.map(node => {
        if (node.id === nodeId) {
          node.data = { ...node.data, size };
        }
        return node;
      })
    });
  },
  toggleDocument: (nodeId: string, documentId: string) => {
    set({
      nodes: get().nodes.map(node => {
        if (node.id === nodeId) {
          if (node.data.linkedDocuments?.includes(documentId)) {
            const linkedDocuments = node.data.linkedDocuments.filter(id => id !== documentId);
            node.data = { ...node.data, linkedDocuments };
          } else {
            const linkedDocuments = [...(node.data.linkedDocuments || []), documentId];
            node.data = { ...node.data, linkedDocuments };
          }
        }
        return node;
      })
    });
  },
  updateEdgeColor: (edgeId: string, color: EDGE_COLOR) => {
    set({
      edges: get().edges.map(edge => {
        if (edge.id === edgeId) {
          edge.data = { ...edge.data, color };
        }
        return edge;
      })
    });
  },
  currentEdgeType: null,
  setCurrentEdgeType: (edgeType: EDGE_TYPES | null) => {
    set({ currentEdgeType: edgeType });
  },
  currentInstruction: null,
  setCurrentInstruction: (nodeId: string | null) => {
    set({ currentInstruction: nodeId });
  },
  nodeToAdd: null,
  setNodeToAdd: (nodeToAdd: NodeToAdd | null) => {
    set({ nodeToAdd });
  },
  backgroundVariant: RF_BACKGROUND_VARIANT,
  setBackgroundVariant: (backgroundVariant: BackgroundVariant) => {
    set({ backgroundVariant });
  }
}));
