import { Document, DocumentOld } from 'models';
import { create } from 'zustand';

export type DocumentsState = {
  // TODO remove when beckend is ready
  documents: DocumentOld[];
  // TODO remove when beckend is ready
  addDocuments: (newDocuments: DocumentOld[]) => void;
  selectedDocument: Document | null;
  selectDocument: (document: Document | null) => void;
  toggleNode: (documnetId: string, nodeId: string) => void;
};

export const useDocumentsStore = create<DocumentsState>((set, get) => ({
  documents: [],
  addDocuments: newDocuments => {
    set({ documents: get().documents.concat(...newDocuments) });
  },
  selectedDocument: null,
  selectDocument: document => {
    set({ selectedDocument: document });
  },
  toggleNode: (documentId, nodeId) => {
    set({
      documents: get().documents.map(document => {
        if (document.id === documentId) {
          if (document.linkedNodes?.includes(nodeId)) {
            const linkedNodes = document.linkedNodes.filter(id => id !== nodeId);
            document = { ...document, linkedNodes };
          } else {
            const linkedNodes = [...document.linkedNodes, nodeId];
            document = { ...document, linkedNodes };
          }
        }
        return document;
      })
    });
  }
}));
