import { useCallback } from 'react';
import { NodeDragHandler, NodeMouseHandler } from 'reactflow';
import { shallow } from 'zustand/shallow';

import { NODE_TYPES } from 'consts';
import { useSocket } from 'hooks';
import { RFState, useRFStore } from 'store';
import {
  DEFAULT_ROADMAP_MILESTONE_CONTAINER_WIDTH,
  DEFAULT_ROADMAP_MILESTONE_WIDTH,
  DEFAULT_ROADMAP_MILESTONE_X_OFFSET,
  DEFAULT_ROADMAP_MILESTONE_Y_OFFSET
} from './const';

const selector = (state: RFState) => ({
  nodes: state.nodes,
  nodeToAdd: state.nodeToAdd,
  setNodeToAdd: state.setNodeToAdd,
  setNodes: state.setNodes,
  updateNode: state.updateNode,
  selectNode: state.selectNode,
  deselectNode: state.deselectNode
});

export const useRoadmap = (boardId: number) => {
  const { nodes, nodeToAdd, setNodeToAdd, updateNode, selectNode, deselectNode } = useRFStore(
    selector,
    shallow
  );
  const {
    addEpicToRoadmap,
    addTaskContainerToRoadmap,
    addRoadmapTask,
    moveRoadmapTask,
    addRoadmapInterval,
    addRoadmapMilestone
  } = useSocket(boardId);

  const onNodeDragStop: NodeDragHandler = (event, node) => {
    if (node.type === NODE_TYPES.ROADMAP_MILESTONE) {
      const milestoneContainer = nodes.find(
        node => node.type === NODE_TYPES.ROADMAP_MILESTONE_CONTAINER
      );
      if (!milestoneContainer) return;

      const x =
        node.position.x === 0
          ? DEFAULT_ROADMAP_MILESTONE_X_OFFSET
          : node.position.x + (node.width || DEFAULT_ROADMAP_MILESTONE_WIDTH) ===
            (milestoneContainer.width || DEFAULT_ROADMAP_MILESTONE_CONTAINER_WIDTH)
          ? (milestoneContainer.width || DEFAULT_ROADMAP_MILESTONE_CONTAINER_WIDTH) -
            (node.width || DEFAULT_ROADMAP_MILESTONE_WIDTH) -
            1
          : node.position.x;
      const y = DEFAULT_ROADMAP_MILESTONE_Y_OFFSET;

      updateNode(node.id, {
        ...node,
        position: { x, y }
      });
    }
  };

  const onNodeMouseEnter = useCallback<NodeMouseHandler>(
    (event, node) => {
      if (!nodeToAdd) return;

      if (
        (nodeToAdd.nodeType === NODE_TYPES.ROADMAP_TASK &&
          node.type === NODE_TYPES.ROADMAP_TASK_CONTAINER) ||
        (nodeToAdd.nodeType === NODE_TYPES.ROADMAP_EPIC &&
          node.type === NODE_TYPES.ROADMAP_EPIC_CONTAINER) ||
        (nodeToAdd.nodeType === NODE_TYPES.ROADMAP_MILESTONE &&
          node.type === NODE_TYPES.ROADMAP_MILESTONE_CONTAINER) ||
        (nodeToAdd.nodeType === NODE_TYPES.ROADMAP_INTERVAL &&
          node.type === NODE_TYPES.ROADMAP_INTERVAL_CONTAINER)
      ) {
        selectNode(node.id);
      }

      if (
        (nodeToAdd.nodeType === NODE_TYPES.ROADMAP_TASK && node.type === NODE_TYPES.ROADMAP_TASK) ||
        (nodeToAdd.nodeType === NODE_TYPES.ROADMAP_EPIC && node.type === NODE_TYPES.ROADMAP_EPIC) ||
        (nodeToAdd.nodeType === NODE_TYPES.ROADMAP_MILESTONE &&
          node.type === NODE_TYPES.ROADMAP_MILESTONE) ||
        (nodeToAdd.nodeType === NODE_TYPES.ROADMAP_INTERVAL &&
          node.type === NODE_TYPES.ROADMAP_INTERVAL)
      ) {
        node.parentNode && selectNode(node.parentNode);
      }
    },
    [nodeToAdd]
  );

  const onNodeMouseLeave = useCallback<NodeMouseHandler>((event, node) => {
    if (
      node.type === NODE_TYPES.ROADMAP_TASK_CONTAINER ||
      node.type === NODE_TYPES.ROADMAP_EPIC_CONTAINER ||
      node.type === NODE_TYPES.ROADMAP_MILESTONE_CONTAINER
    ) {
      deselectNode(node.id);
    }

    if (
      node.type === NODE_TYPES.ROADMAP_TASK ||
      node.type === NODE_TYPES.ROADMAP_EPIC ||
      node.type === NODE_TYPES.ROADMAP_MILESTONE
    ) {
      node.parentNode && deselectNode(node.parentNode);
    }
  }, []);

  const onNodeClick = useCallback<NodeMouseHandler>(
    (event, node) => {
      if (!nodeToAdd) return;

      // create epic node in the epic container
      if (
        nodeToAdd.nodeType === NODE_TYPES.ROADMAP_EPIC &&
        node.type === NODE_TYPES.ROADMAP_EPIC_CONTAINER
      ) {
        addEpicNode();
        deselectNode(node.id);
        setNodeToAdd(null);
      }
      if (nodeToAdd.nodeType === NODE_TYPES.ROADMAP_EPIC && node.type === NODE_TYPES.ROADMAP_EPIC) {
        if (!node.parentNode) return;
        const containerNode = nodes.find(n => n.id === node.parentNode);
        if (!containerNode) return;
        addEpicNode();
        deselectNode(node.parentNode);
        setNodeToAdd(null);
      }

      // create task node in the task container
      if (
        nodeToAdd.nodeType === NODE_TYPES.ROADMAP_TASK &&
        node.type === NODE_TYPES.ROADMAP_TASK_CONTAINER
      ) {
        addTaskNode(node.id);
        deselectNode(node.id);
        setNodeToAdd(null);
      }
      if (nodeToAdd.nodeType === NODE_TYPES.ROADMAP_TASK && node.type === NODE_TYPES.ROADMAP_TASK) {
        if (!node.parentNode) return;
        const containerNode = nodes.find(n => n.id === node.parentNode);
        if (!containerNode) return;
        addTaskNode(node.parentNode);
        deselectNode(node.parentNode);
        setNodeToAdd(null);
      }

      // TODO handle milestone and interval nodes itself
      if (
        nodeToAdd.nodeType === NODE_TYPES.ROADMAP_MILESTONE &&
        node.type === NODE_TYPES.ROADMAP_MILESTONE_CONTAINER
      ) {
        // create milestone node in the milestone container
        addRoadmapMilestone({ boardId });
        deselectNode(node.id);
        setNodeToAdd(null);
      }

      if (
        nodeToAdd.nodeType === NODE_TYPES.ROADMAP_INTERVAL &&
        node.type === NODE_TYPES.ROADMAP_INTERVAL_CONTAINER
      ) {
        // create inteval node in the inteval container
        addRoadmapInterval({ boardId });
        deselectNode(node.id);
        setNodeToAdd(null);
      }
    },
    [nodeToAdd]
  );

  const handleAddEpic = (currentNodeId: string, position: 'top' | 'bottom') => {
    addEpicToRoadmap({ boardId, currentNodeId, position });
  };

  const handleAddTaskContainer = (epicId: string, position: 'top' | 'bottom') => {
    addTaskContainerToRoadmap({ boardId, epicId, position });
  };

  const addTaskNode = (containerId: string) => {
    addRoadmapTask({ boardId, containerId });
  };

  const handleMoveTask = (taskId: string, position: 'top' | 'bottom') => {
    moveRoadmapTask({ boardId, taskId, position });
  };

  const addEpicNode = () => {
    const epicContainer = nodes.find(node => node.type === NODE_TYPES.ROADMAP_EPIC_CONTAINER);
    if (!epicContainer || !epicContainer.parentNode) return;

    const epicNodes = nodes.filter(node => node.type === NODE_TYPES.ROADMAP_EPIC);
    const lastEpicNode = epicNodes.pop();
    if (!lastEpicNode) return;

    addEpicToRoadmap({ boardId, currentNodeId: lastEpicNode.id, position: 'bottom' });
  };

  return {
    onNodeDragStop,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onNodeClick,
    handleAddEpic,
    handleAddTaskContainer,
    handleMoveTask
  };
};
