import { FC, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { PASSWORD_NO_MATCH_REGEXP, ROUTES } from 'consts';
import { useResetPassword } from 'hooks';
import styles from './NewPasswordPage.module.scss';

import { Button, IconArrowLeft, InputText, TermsPrivacy } from 'components/atoms';

const NewPasswordPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>() as { token: string };
  const { resetPassword, isLoading } = useResetPassword();
  const [success, setSuccess] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async ({ password }: FieldValues) => {
    const response = await resetPassword({ token, body: { newPassword: password } });
    if (response === 'ok') {
      setSuccess(true);
    }
  };

  return (
    <main className='main'>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          {success ? (
            <div className={styles.success}>
              <h1 className={styles.success__title}>{t('NewPasswordPage.success')}</h1>
              <p className={styles.success__subtitle}>{t('NewPasswordPage.yourPasswordReset')}</p>
              <Button
                type='submit'
                variant='primary'
                size='big'
                wide
                title={t('NewPasswordPage.backToLogin')}
                onClick={() => navigate(ROUTES.LOGIN)}
              />
            </div>
          ) : (
            <>
              <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <h1 className={styles.form__title}>{t('NewPasswordPage.setNewPassword')}</h1>

                <div className={styles.form__fields}>
                  <InputText
                    type='password'
                    width='big'
                    name='password'
                    label={t('common.form.password') as string}
                    control={control}
                    rules={{
                      required: { value: true, message: t('common.validation.requiredField') },
                      minLength: { value: 6, message: `${t('common.form.minimumLengthIs')} 6` },
                      maxLength: { value: 20, message: `${t('common.form.maximumLengthIs')} 20` },
                      validate: {
                        doesntContainSpaces: (value: string) => {
                          return !value.includes(' ')
                            ? true
                            : t('common.validation.doesntContainSpaces');
                        },
                        validPassword: (value: string) => {
                          return !value.match(PASSWORD_NO_MATCH_REGEXP)
                            ? true
                            : t('common.validation.incorrectPassword');
                        }
                      }
                    }}
                    isLoading={isLoading}
                    autoComplete='off'
                    containerClassName={styles.password}
                  />

                  <InputText
                    type='password'
                    width='big'
                    name='confirm'
                    label={t('common.form.confirmPassword') as string}
                    control={control}
                    rules={{
                      required: { value: true, message: t('common.validation.requiredField') },
                      minLength: { value: 6, message: `${t('common.form.minimumLengthIs')} 6` },
                      maxLength: { value: 20, message: `${t('common.form.maximumLengthIs')} 20` },
                      validate: {
                        doesntContainSpaces: (value: string) => {
                          return !value.includes(' ')
                            ? true
                            : t('common.validation.doesntContainSpaces');
                        },
                        validPassword: (value: string) => {
                          return !value.match(PASSWORD_NO_MATCH_REGEXP)
                            ? true
                            : t('common.validation.incorrectPassword');
                        },
                        matchPassword: (value: string, formValues: any) => {
                          return value === formValues.password
                            ? true
                            : t('common.validation.incorrectConfirmPassword');
                        }
                      }
                    }}
                    isLoading={isLoading}
                    autoComplete='off'
                    containerClassName={styles.confirm}
                  />
                </div>

                <Button
                  type='submit'
                  variant='primary'
                  size='big'
                  wide
                  title={t('NewPasswordPage.saveNewPassword')}
                  isDisabled={!isDirty}
                  isLoading={isLoading}
                />
              </form>

              <span className={styles.backToLogin}>
                <Link to={ROUTES.LOGIN} className={`link ${styles.backToLogin__link}`}>
                  <IconArrowLeft />
                  {t('ResetPasswordPage.backToLogin')}
                </Link>
              </span>
            </>
          )}

          <TermsPrivacy />
        </div>
      </div>
    </main>
  );
};

export default NewPasswordPage;
