import { useQuery } from 'react-query';

import { apiTeam } from 'api';
import { handleError } from 'helpers';

export const useTeam = (teamId: string) => {
  return useQuery([`teams/one/${teamId}`], async () => await apiTeam.getTeam(teamId), {
    onError(error) {
      handleError(error);
    }
  });
};
