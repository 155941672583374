import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconBlocks: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Icons'>
        <path
          id='Vector'
          d='M16.6667 2.5H12.5001C12.0398 2.5 11.6667 2.8731 11.6667 3.33333V7.5C11.6667 7.96024 12.0398 8.33333 12.5001 8.33333H16.6667C17.127 8.33333 17.5001 7.96024 17.5001 7.5V3.33333C17.5001 2.8731 17.127 2.5 16.6667 2.5Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M8.33333 17.5002V6.66683C8.33333 6.44582 8.24554 6.23385 8.08926 6.07757C7.93298 5.92129 7.72101 5.8335 7.5 5.8335H3.33333C3.11232 5.8335 2.90036 5.92129 2.74408 6.07757C2.5878 6.23385 2.5 6.44582 2.5 6.66683V16.6668C2.5 16.8878 2.5878 17.0998 2.74408 17.2561C2.90036 17.4124 3.11232 17.5002 3.33333 17.5002H13.3333C13.5543 17.5002 13.7663 17.4124 13.9226 17.2561C14.0789 17.0998 14.1667 16.8878 14.1667 16.6668V12.5002C14.1667 12.2791 14.0789 12.0672 13.9226 11.9109C13.7663 11.7546 13.5543 11.6668 13.3333 11.6668H2.5'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
